import React, {useEffect, useState} from 'react';
import axios from "axios";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';

import ProductCreatedImage from '../../assets/product-created.svg';
import ErrorImage from '../../assets/error.svg';

// Steps
import StepMain from './StepMain';
import StepAddress from './StepAddress';
import StepOpeningHours from './StepOpeningHours';

import { useParams, useHistory } from "react-router-dom";

import './style.scss';

export default function PageRestaurantNew(props) {

	let params = useParams();
	let navigate = useHistory();

	const [isLoading, setIsLoading] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set());
	const [hasError, setHasError] = useState(false);
	const [categories, setCategories] = useState([]);

	// Form data
	const [formRestaurantName, setFormRestaurantName] = useState('');
	const [formRestaurantLegalName, setFormRestaurantLegalName] = useState('');
	const [formRestaurantTaxId, setFormRestaurantTaxId] = useState('');
	const [formRestaurantSupervisoryAuthority, setFormRestaurantSupervisoryAuthority] = useState('');
	const [formRestaurantPayoutName, setFormRestaurantPayoutName] = useState('');
	const [formRestaurantPayoutIban, setFormRestaurantPayoutIban] = useState('');
	const [formRestaurantPayoutBic, setFormRestaurantPayoutBic] = useState('');
	const [formRestaurantDescription, setFormRestaurantDescription] = useState('');
	const [formRestaurantHomeCategoryId, setFormRestaurantHomeCategoryId] = useState('');
	const [formRestaurantImage, setFormRestaurantImage] = useState(null);
	const [formRestaurantLogo, setFormRestaurantLogo] = useState(null);
	const [formRestaurantEnabled, setFormRestaurantEnabled] = useState(true);
	const [formCfgAcceptsCutleryEnabled, setFormCfgAcceptsCutleryEnabled] = useState(true);
	const [formCfgAcceptsTipEnabled, setFormCfgAcceptsTipEnabled] = useState(true);
	const [formCfgPickupOnlyEnabled, setFormCfgPickupOnlyEnabled] = useState(true);
	const [formRestaurantStreet, setFormRestaurantStreet] = useState("");
	const [formRestaurantCity, setFormRestaurantCity] = useState("");
	const [formRestaurantMail, setFormRestaurantMail] = useState("");
	const [formRestaurantPhone, setFormRestaurantPhone] = useState("");
	const [formRestaurantPhone2, setFormRestaurantPhone2] = useState("");
	const [formRestaurantOpeningHours, setFormRestaurantOpeningHours] = useState(null);

	const steps = [
		'Grunddaten',
		'Adresse',
		'Verfügbarkeit'
	];

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}
	
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		window.location.reload();
	};

	useEffect(() => {

		setIsLoading(true);

		document.title = 'Restaurant erstellen — ' + process.env.REACT_APP_APPLICATION_NAME;

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/categories`)
		.then((res) => {
			if (res.status === 200 && res.data) {
				// Everything OK
				setCategories(res.data);
				setIsLoading(false);
			} else {
				// Error
				setIsLoading(false)
				setHasError(true)
				setActiveStep(3);
			}
		})
		.catch((err) => {
			setIsLoading(false)
			setHasError(true)
			setActiveStep(3);
		})
		
	}, [params])

	const handleSubmit = (openingHours) => {

		// Create Product

		if (props.asStep === true) {
			props.setIsLoading(true);
		}

		const formData = new FormData();

		formData.append("formRestaurantName", formRestaurantName);
		formData.append("formRestaurantLegalName", formRestaurantLegalName);
		formData.append("formRestaurantTaxId", formRestaurantTaxId);
		formData.append("formRestaurantSupervisoryAuthority", formRestaurantSupervisoryAuthority);
		formData.append("formRestaurantDescription", formRestaurantDescription);
		formData.append("formRestaurantHomeCategoryId", formRestaurantHomeCategoryId);
		formData.append("formRestaurantImage", formRestaurantImage);
		formData.append("formRestaurantLogo", formRestaurantLogo);
		formData.append("formRestaurantEnabled", formRestaurantEnabled);
		formData.append("formCfgAcceptsCutleryEnabled", formCfgAcceptsCutleryEnabled);
		formData.append("formCfgAcceptsTipEnabled", formCfgAcceptsTipEnabled);
		formData.append("formCfgPickupOnlyEnabled", formCfgPickupOnlyEnabled);
		formData.append("formRestaurantStreet", formRestaurantStreet);
		formData.append("formRestaurantCity", formRestaurantCity);
		formData.append("formRestaurantMail", formRestaurantMail);
		formData.append("formRestaurantPhone", formRestaurantPhone);
		formData.append("formRestaurantPhone2", formRestaurantPhone2);
		formData.append("formRestaurantPayoutName", formRestaurantPayoutName);
		formData.append("formRestaurantPayoutIban", formRestaurantPayoutIban);
		formData.append("formRestaurantPayoutBic", formRestaurantPayoutBic);
		formData.append("formRestaurantOpeningHours", JSON.stringify(openingHours));

		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};
		const API = "api/backend/restaurants";
		const url = `${process.env.REACT_APP_API_URL}/${API}`;
	
		axios.post(url, formData)
			.then(function (response) {
				if (props.asStep === true) {
					props.setIsLoading(false);
				}
				if (response.status === 200) {
					if (response.data.status === 'success') {
						setIsLoading(false)

						if (props.asStep === true) {
							let tmpFormValues = props.formValues;
							tmpFormValues["restaurantId"] = response.data.restaurantId;
							props.setFormValues(tmpFormValues);
							props.handleNext();
							props.handleStepError(false);
						} else {
							handleNext();
						}

					} else {
						setIsLoading(false)
						setHasError(true)
						if (props.asStep === true) {
							props.handleBack();
							props.handleStepError(true);
						} else {
							handleNext();
						}
					}
				} else {
					setIsLoading(false)
					setHasError(true)
					if (props.asStep === true) {
						props.handleBack();
						props.handleStepError(true);
					} else {
						handleNext();
					}
				}
			})
			.catch(function (error) {
				setIsLoading(false)
				setHasError(true)
				if (props.asStep === true) {
					props.handleBack();
					props.handleStepError(true);
				} else {
					handleNext();
				}
			})

	}

	const StepperContent = () => {
		return (
			<>
				<Stepper activeStep={activeStep}>
					{steps.map((label, index) => {
						const stepProps = {};
						const labelProps = {};
						if (isStepSkipped(index)) {
							stepProps.completed = false;
						}
						return (
							<Step key={label} {...stepProps}>
								<StepLabel {...labelProps}>{label}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
				{activeStep === steps.length ? (
					<React.Fragment>
						<div style={{textAlign:'center',padding:'45px 0'}}>
							<img style={{width:'300px',marginBottom:'30px'}} src={(hasError ? ErrorImage : ProductCreatedImage)} alt="" /><br />
							<Typography variant="h6" gutterBottom>
								{hasError ? 'Ein Fehler ist aufgetreten. Bitte kontaktiere den Support: +49 8342 7050-752' : 'Dein Restaurant wurde erfolgreich erstellt!'}
							</Typography>
						</div>
						<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
							<Box sx={{ flex: '1 1 auto' }} />
							<Button onClick={handleReset}>Weiteren Restaurant erstellen</Button>
						</Box>
					</React.Fragment>
				) : (
					activeStep === 0 ? <StepMain
											isLoading={setIsLoading}
											handleNext={handleNext}
											handleBack={handleBack}
											categories={categories}

											formRestaurantName={formRestaurantName}
											setFormRestaurantName={setFormRestaurantName}

											formRestaurantLegalName={formRestaurantLegalName}
											setFormRestaurantLegalName={setFormRestaurantLegalName}

											formRestaurantTaxId={formRestaurantTaxId}
											setFormRestaurantTaxId={setFormRestaurantTaxId}

											formRestaurantSupervisoryAuthority={formRestaurantSupervisoryAuthority}
											setFormRestaurantSupervisoryAuthority={setFormRestaurantSupervisoryAuthority}
											
											formRestaurantDescription={formRestaurantDescription}
											setFormRestaurantDescription={setFormRestaurantDescription}
											
											formRestaurantHomeCategoryId={formRestaurantHomeCategoryId}
											setFormRestaurantHomeCategoryId={setFormRestaurantHomeCategoryId}

											formRestaurantImage={formRestaurantImage}
											setFormRestaurantImage={setFormRestaurantImage}

											formRestaurantLogo={formRestaurantLogo}
											setFormRestaurantLogo={setFormRestaurantLogo}

											formRestaurantEnabled={formRestaurantEnabled}
											setFormRestaurantEnabled={setFormRestaurantEnabled}

											formCfgAcceptsCutleryEnabled={formCfgAcceptsCutleryEnabled}
											setFormCfgAcceptsCutleryEnabled={setFormCfgAcceptsCutleryEnabled}
											formCfgAcceptsTipEnabled={formCfgAcceptsTipEnabled}
											setFormCfgAcceptsTipEnabled={setFormCfgAcceptsTipEnabled}
											formCfgPickupOnlyEnabled={formCfgPickupOnlyEnabled}
											setFormCfgPickupOnlyEnabled={setFormCfgPickupOnlyEnabled}

											formRestaurantPayoutName={formRestaurantPayoutName}
											setFormRestaurantPayoutName={setFormRestaurantPayoutName}
											formRestaurantPayoutIban={formRestaurantPayoutIban}
											setFormRestaurantPayoutIban={setFormRestaurantPayoutIban}
											formRestaurantPayoutBic={formRestaurantPayoutBic}
											setFormRestaurantPayoutBic={setFormRestaurantPayoutBic}
										
											asStep={props.asStep}

										/> :
					activeStep === 1 ? <StepAddress
											isLoading={setIsLoading}
											handleNext={handleNext}
											handleBack={handleBack}

											formRestaurantStreet={formRestaurantStreet}
											setFormRestaurantStreet={setFormRestaurantStreet}

											formRestaurantCity={formRestaurantCity}
											setFormRestaurantCity={setFormRestaurantCity}

											formRestaurantMail={formRestaurantMail}
											setFormRestaurantMail={setFormRestaurantMail}

											formRestaurantPhone={formRestaurantPhone}
											setFormRestaurantPhone={setFormRestaurantPhone}

											formRestaurantPhone2={formRestaurantPhone2}
											setFormRestaurantPhone2={setFormRestaurantPhone2}

											asStep={props.asStep}
										/> : 
										<StepOpeningHours
											isLoading={setIsLoading}
											handleNext={handleNext}
											handleBack={handleBack}
											handleSubmit={handleSubmit}

											formRestaurantOpeningHours={formRestaurantOpeningHours}
											setFormRestaurantOpeningHours={setFormRestaurantOpeningHours}

											asStep={props.asStep}
										/>
				)}
			</>
		)
	}

	return (
		<>
			{props.asStep === true ? (
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: '100%',
					justifyContent: 'space-between',
					padding: '20px'
				}}>
					<Box style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						rowGap: '20px'
					}}>
						{StepperContent(props)}
					</Box>
				</div>
			) : (
				<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
					<AppBar
						position="static"
						color="default"
						elevation={0}
						sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
					>
						<Toolbar className="PageMenu-AppBar">
							<Grid container style={{justifyContent:'space-between'}} spacing={1} alignItems="center">
								<Grid item>
									{activeStep === 0 && (<><b>Grunddaten</b> — Informationen zu Deinem Restaurant</>)}
									{activeStep === 1 && (<><b>Adresse</b> — Ort &amp; Kontaktmöglichkeit</>)}
									{activeStep === 2 && (<><b>Verfügbarkeit</b> — Öffnungszeiten &amp; Lieferzeiten</>)}
								</Grid>
								<Grid item>
									<Tooltip title="Aktualisieren">
										<IconButton onClick={() => {navigate.push(0)}}>
											<RefreshIcon color="inherit" sx={{ display: 'block' }} />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Toolbar>
					</AppBar>
					
					<Box padding={2}>

						{StepperContent(props)}

					</Box>

				</Paper>
			)}

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={isLoading}
				message="Wird gespeichert..."
				sx={{ zIndex: 9999999 }}
				action={(
					<CircularProgress style={{marginRight:'10px'}} size={20} />
				)}
			/>
			
		</>
	);
}