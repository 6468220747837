import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import NumberFormat from 'react-number-format';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Loader from '../../../components/Loader';

import './style.scss';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
			decimalSeparator=","
            isNumericString
            suffix=" EUR"
			allowNegative={false}
			decimalScale={2}
        />
    );
});

export default function PageAdministrateCityManagement() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [couponsItems, setCouponsItems] = useState([]);
	const [restaurants, setRestaurants] = useState([]);
	
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedCoupon, setSelectedCoupon] = useState(0);
	
	const [showCreateCouponModal, setShowCreateCouponModal] = useState(false);
	const [newCouponRestaurantId, setNewCouponRestaurantId] = useState(0); // 0 = all restaurants
	const [newCouponName, setNewCouponName] = useState("");
	const [newCouponDescription, setNewCouponDescription] = useState("");
	const [newCouponCode, setNewCouponCode] = useState(Math.random().toString(36).replace(/[^a-z0-9]/gi, '').substr(0, 5).toUpperCase().replace(/I/g, 'X').replace(/O/g, 'Y').replace(/0/g, 'Z').replace(/1/g, 'A'));
	const [newCouponType, setNewCouponType] = useState("percentage");
	const [newCouponValue, setNewCouponValue] = useState(0);
	const [newCouponMinOrderValue, setNewCouponMinOrderValue] = useState(0);
	const [newCouponMaxDiscountValue, setNewCouponMaxDiscountValue] = useState(0);
	const [newCouponMaxUsagePerUserValue, setNewCouponMaxUsagePerUserValue] = useState(0);

	useEffect(() => {
		document.title = 'Gutscheine — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
			.then((res) => {
				if (res.status === 200 && res.data) {
					// Everything OK
					setRestaurants(res.data);
				} else {
					// Error
					
				}
			})
			.catch((err) => {
				
			})

		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/coupons`).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setCouponsItems(res.data);
			} else {
				setCouponsItems([]);
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}, [reloadKey])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	const createCoupon = () => {
		setIsLoading(true);
		axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/coupons`, {
			restaurantId: newCouponRestaurantId,
			name: newCouponName,
			description: newCouponDescription,
			code: newCouponCode,
			type: newCouponType,
			value: newCouponValue,
			minOrderValue: newCouponMinOrderValue,
			maxDiscountValue: newCouponMaxDiscountValue,
			maxUsagePerUser: newCouponMaxUsagePerUserValue
		}).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setSuccessSnackbarText("Gutschein erfolgreich erstellt");
				setShowSuccessSnackbar(true);
				setReloadKey(Math.random());
				setShowCreateCouponModal(false);
			} else {
				handleError();
			}
		}).catch((err) => {
			if (err) {
				if (err.response.data.message) {
					if (err.response.data.message === "Coupon code already exists") {
						// Generate new coupon code
						setNewCouponCode(Math.random().toString(36).replace(/[^a-z0-9]/gi, '').substr(0, 5).toUpperCase().replace(/I/g, 'X').replace(/O/g, 'Y').replace(/0/g, 'Z').replace(/1/g, 'A'));
					}
				}
			}
			setIsLoading(false);
			handleError();
		})
	}

	const deleteCoupon = (id) => {
		setIsLoading(true);
		axios.delete(`${process.env.REACT_APP_API_URL}/api/administrate/coupons/${id}`).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setSuccessSnackbarText("Gutschein erfolgreich gelöscht");
				setShowSuccessSnackbar(true);
				setReloadKey(Math.random());
				setShowDeleteModal(false);
			} else {
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Button variant='contained' onClick={() => {setShowCreateCouponModal(true)}} sx={{ mr: 1 }}>Erstellen</Button>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<TableContainer className="PageMenu-Table">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Zweck</TableCell>
										<TableCell>Code</TableCell>
										<TableCell>Restaurant</TableCell>
										<TableCell>Beschreibung</TableCell>
										<TableCell>Rabatt-Wert</TableCell>
										<TableCell>Bedingungen</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{couponsItems.map((row, index) => (
										<TableRow
											key={index}
											onClick={() => {setShowDeleteModal(true);setSelectedCoupon(row.id)}}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="td" scope="row">
												{row.coupon_name}
											</TableCell>
											<TableCell style={{fontFamily: "'Fira Code', monospace"}} component="td" scope="row">
												{row.coupon_code}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.coupon_restaurant_name}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.coupon_description}
											</TableCell>
											<TableCell component="td" scope="row">
												{parseFloat(row.coupon_discount_value).toFixed(2)} {row.coupon_discount_type === 'percentage' ? '%' : '€'}
											</TableCell>
											<TableCell component="td" scope="row">
												Min. <b>{parseFloat(row.coupon_minimum_order_value).toFixed(2)} EUR</b> Bestellwert<br />
												Max. <b>{parseFloat(row.coupon_maximum_discount_value).toFixed(2)} EUR</b> Bestellwert<br />
												{(row.coupon_max_usage_per_user === 0) ? 'Unbegrenzt viele Verwendungen' : 'Max. ' + row.coupon_max_usage_per_user + 'x pro Kunde'}<br />
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>

			</Paper>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

			<Dialog
				open={showCreateCouponModal}
				onClose={() => {
					if (!isLoading) {
						setShowCreateCouponModal(false)
					} else {
						return;
					}
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					Gutschein erstellen
				</DialogTitle>
				<DialogContent style={{padding: '20px'}}>
					<Stack spacing={3}>
						<FormControl margin="dense" size='small' fullWidth variant="outlined">
							<InputLabel id="restaurant-label">Restaurant</InputLabel>
							<Select
								labelId="restaurant-label"
								id="restaurant"
								label="Restaurant"
								value={newCouponRestaurantId}
								onChange={(event) => {
									setNewCouponRestaurantId(event.target.value)
								}}				  
							>
								<MenuItem value={0}>Globaler Rabatt</MenuItem>
								{
									restaurants.map((restaurant) => {
										return (
											<MenuItem key={restaurant.id} value={restaurant.id}>{restaurant.restaurant_name} (#{restaurant.id})</MenuItem>
										)
									})
								}
							</Select>
						</FormControl>

						<TextField
							name="Bezeichnung"
							fullWidth
							size="small"
							label="Bezeichnung"
							value={newCouponName}
							onChange={(event) => {
								setNewCouponName(event.target.value)
							}}
						/>
						
						<TextField
							name="Beschreibung"
							fullWidth
							size="small"
							label="Beschreibung"
							value={newCouponDescription}
							onChange={(event) => {
								setNewCouponDescription(event.target.value)
							}}
						/>
						
						<TextField
							name="Gutschein-Code"
							fullWidth
							size="small"
							label="Gutschein-Code"
							value={newCouponCode}
							onChange={(event) => {
								setNewCouponCode(event.target.value)
							}}
						/>

						<FormControl margin="dense" size='small' fullWidth variant="outlined">
							<InputLabel id="discount-type-label">Rabatt-Typ</InputLabel>
							<Select
								labelId="discount-type-label"
								id="discount-type"
								label="Rabatt-Typ"
								value={newCouponType}
								onChange={(event) => {
									setNewCouponType(event.target.value)
								}}				  
							>
								<MenuItem value={"percentage"}>Prozent-Rabatt</MenuItem>
								<MenuItem value={"fixed"}>Fester EURO-Rabatt</MenuItem>
							</Select>
						</FormControl>

						{newCouponType === 'percentage' ? (
							<TextField
								name="price"
								fullWidth
								size="small"
								label="Rabatt-Wert in Prozent"
								type={'number'}
								inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: 100 }}
								value={newCouponValue}
								onChange={(event) => {
									setNewCouponValue(event.target.value);
								}}
							/>
						) : (
							<TextField
								name="price"
								fullWidth
								size="small"
								label="Rabatt-Wert in EUR"
								value={newCouponValue}
								InputProps={{
									inputComponent: NumberFormatCustom,
								}}
								onChange={(event) => {
									setNewCouponValue(event.target.value);
								}}
							/>
						)}

						<TextField
							name="Mindestbestellwert"
							fullWidth
							size="small"
							label="Mindestbestellwert (0,00 € für keinen)"
							value={newCouponMinOrderValue}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(event) => {
								setNewCouponMinOrderValue(event.target.value);
							}}
						/>

						<TextField
							name="Maximaler Rabatt"
							fullWidth
							size="small"
							label="Maximaler Rabatt (0,00 € für keinen)"
							value={newCouponMaxDiscountValue}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(event) => {
								setNewCouponMaxDiscountValue(event.target.value);
							}}
						/>

						<TextField
							name="Maximale Nutzung pro Kunde"
							fullWidth
							size="small"
							label="Maximale Nutzung pro Kunde (0 für unbegrenzt)"
							value={newCouponMaxUsagePerUserValue}
							type={'number'}
							
							inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}

							onChange={(event) => {
								// Replace negative values with 0
								if (event.target.value < 0) {
									setNewCouponMaxUsagePerUserValue(0);
								} else {
									setNewCouponMaxUsagePerUserValue(event.target.value);
								}
							}}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button disabled={isLoading} onClick={() => {
						if (!isLoading) {
							setShowCreateCouponModal(false)
						} else {
							return;
						}
					}}>Abbrechen</Button>
					<Button disabled={isLoading} onClick={() => {
						createCoupon()
					}} autoFocus>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={showDeleteModal}
				onClose={() => {setShowDeleteModal(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title">
					Gutschein wirklich löschen?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Möchtest du diesen Gutschein wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden!
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {setShowDeleteModal(false)}}>Nein, Abbrechen</Button>
					<Button color="error" onClick={() => {deleteCoupon(selectedCoupon)}} autoFocus>
						Ja, Löschen
					</Button>
				</DialogActions>
			</Dialog>

		</>
	);
}