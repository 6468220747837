/* eslint-disable */
import React, {useState, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RefreshIcon from '@mui/icons-material/Refresh';
import Snackbar from '@mui/material/Snackbar';
import Currency from 'react-currency-formatter';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import deLocale from 'date-fns/locale/de';
import newOrderSound from '../../assets/new-order.mp3';
import emptyImage from '../../assets/empty.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import NewDeliveryTimeHelper from '../../assets/new-delivery-time-helper.png';
import Box from '@mui/system/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import axios from 'axios';
import Loader from '../../components/Loader';

import './style.scss';

export default function PageHome() {

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));  

	const [isAdmin, setIsAdmin] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showDeliveryTimeModal, setShowDeliveryTimeModal] = useState(false);
	const [orders, setOrders] = useState([])
	const [isLoaded, setIsLoaded] = useState(false)
	const [calendarModal, setCalendarModal] = useState(false)
	const [isPlayingSound, setIsPlayingSound] = useState(false)
	const [showNewOrderOverlay, setShowNewOrderOverlay] = useState(false) // <- Show overlay because of the autoplay-policy. If there is no audio, the user will see the overlay.
	const [calendarDate, setCalendarDate] = useState(new Date());
	const [orderTime, setOrderTime] = useState(0);
	const [orderTimeMin, setOrderTimeMin] = useState(15);
	const [orderPickupTimeMin, setOrderPickupTimeMin] = useState(15);
	const [messages, setMessages] = useState([]);

	const [sliderDeliveryTime, setSliderDeliveryTime] = useState(30);
	const [sliderPickupTime, setSliderPickupTime] = useState(30);

	React.useEffect(() => {
		document.title = 'Mein Dashboard — ' + process.env.REACT_APP_APPLICATION_NAME;

		let userData = localStorage.getItem("data");
		userData = JSON.parse(userData)

		if (userData.role_name === "admin") {
			setIsAdmin(true);
		} else {
			setIsAdmin(false);
		}

		setTimeout(() => {
			loadOrders(calendarDate);
			loadOrderTime();
			loadMessages();
		}, 1000);
		let orderInterval = setInterval(() => {
			loadOrders(calendarDate);
			loadOrderTime();
			loadMessages();
		}, 30 * 1000);

		return () => clearInterval(orderInterval);
	},[calendarDate])

	const loadOrderTime = () => {
		let restaurantId = localStorage.getItem('data');
		if (restaurantId) {
			restaurantId = JSON.parse(restaurantId).restaurant_access_id;
			axios.get(process.env.REACT_APP_API_URL + '/api/backend/restaurants/' + restaurantId).then((response) => {
				if (response.data.restaurant_open_status) {
					if (response.data.restaurant_open_status === 'force_closed') {
						setOrderTime(0);
					}
					else if (response.data.restaurant_open_status === 'force_paused') {
						setOrderTime(2);
					}
					else if (response.data.restaurant_open_status === 'force_rushhour') {
						setOrderTime(3);
					}
					else {
						setOrderTime(1);
					}

					setOrderTimeMin(response.data.restaurant_delivery_time);
					setOrderPickupTimeMin(response.data.restaurant_pickup_time);
				}
			}).catch((error) => {
				console.error(error);
			})
		}
	}

	const updateOrderTime = (prepTime) => {
		setIsLoaded(false);

		axios.post(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/open-status/${prepTime}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			}
		})
		.then((response) => {
			setOrderTime(prepTime);
			setIsLoaded(true);
		})
	}

	const updateOrderTimeMinutes = (prepTime) => {
		setIsLoaded(false);

		axios.post(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/preparation-time/${prepTime}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			}
		})
		.then((response) => {
			setOrderTimeMin(prepTime);
			setIsLoaded(true);
		})
	}
	
	const updateOrderPickupTimeMinutes = (prepTime) => {
		setIsLoaded(false);

		axios.post(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/preparation-pickup-time/${prepTime}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			}
		})
		.then((response) => {
			setOrderPickupTimeMin(prepTime);
			setIsLoaded(true);
		})
	}

	const loadOrders = (calendarDateParam, muted = false) => {

		let formattedDate = "";
		let date = calendarDateParam;
		formattedDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
	
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/orders?date=` + formattedDate)
		.then((res) => {
			if (res.status === 200 && res.data) {

				let tmpOrders = res.data;

				// Remove orders with order_delivery_address_name = "Manuelle Bezahlung"
				tmpOrders = tmpOrders.filter(order => order.order_delivery_address_name !== "Manuelle Bezahlung");

				setOrders(tmpOrders);
				setIsLoaded(true);

				// Check if there are new orders with status pending
				if (!muted) {
					let newOrders = tmpOrders.filter(order => order.order_status === 'pending');
					if (newOrders.length != 0) {
						// Play Sound but prevent multiple sounds
						if (!isPlayingSound) {
							setIsPlayingSound(true);
							// ! Entfernt, da der untere Banner bereits vorhanden ist. Bessere UX
							//setShowNewOrderOverlay(true);
							
							let sound = new Audio(newOrderSound);
							sound.play();
							setTimeout(() => {
								setIsPlayingSound(false);
								// ! Entfernt, da der untere Banner bereits vorhanden ist. Bessere UX
								//setShowNewOrderOverlay(false);
							}, 3000);
						}
					}
				}

			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})

		formattedDate = "";
		date = "";
	}

	const handleError = (error) => {
		
		setShowErrorSnackbar(true)

	}

	const isToday = (someDate) => {
		const today = new Date()
		return someDate.getDate() == today.getDate() &&
			someDate.getMonth() == today.getMonth() &&
			someDate.getFullYear() == today.getFullYear()
	}

	function getFormattedDate(date) {
		var year = date.getFullYear();

		var month = (1 + date.getMonth()).toString();
		month = month.length > 1 ? month : '0' + month;

		var day = date.getDate().toString();
		day = day.length > 1 ? day : '0' + day;

		return day + '.' + month + '.' + year;
	}

	const dismissOverlay = () => {
		setShowNewOrderOverlay(false);
	}

	const loadMessages = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/dashboard-messages`)
		.then((res) => {
			if (res.status === 200 && res.data) {
				if (res.data.messages) {
					setMessages(res.data.messages);
				}
			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})
	}

	return (
		<>
			{showNewOrderOverlay && (
				<div onClick={dismissOverlay} className="new-order-overlay">
					<h1>Neue Bestellung!</h1>
				</div>
			)}

			{/* if the selected date (calendarDate) is not today, show a warning! */}
			{!isToday(calendarDate) && (
				<Alert sx={{ maxWidth: 'calc(100% - 100px)', margin: 'auto', overflow: 'hidden', marginBottom: 2 }} variant="filled" severity="warning">
					Hinweis! Du siehst die Bestellungen für den {getFormattedDate(calendarDate)}, nicht für heute!
				</Alert>
			)}

			{messages.length > 0 && (
				messages.map((message, index) => (
					<Alert key={index} sx={{ maxWidth: 'calc(100% - 100px)', margin: 'auto', overflow: 'hidden', marginBottom: 2 }} variant="filled" severity={message.message_type}>
						<b style={{fontSize:'17px'}}>{message.message_title}</b>
						<br />
						<p style={{fontSize:'15px'}}>{message.message_body}</p>
						<i style={{fontSize:'15px'}}>DeliverOne, {getFormattedDate(new Date(Date.parse(message.message_date.replace(/-/g, '/'))))}</i>
					</Alert>
				))
			)}

			{!isAdmin && (
				<>
					<Box sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }} className="PageHome-NewDeliveryTimeHelper">
						<div>
							<h2>Hinweis:</h2>
							<p>
								Ab sofort kannst du deine Liefer- und Abholzeiten besser festlegen. Klicke dazu auf das Uhr-Symbol oben rechts.
							</p>
						</div>
						<img src={NewDeliveryTimeHelper} />
					</Box>
					<br />
				</>
			)}
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					className='PageHome-AppBar'
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar>
						<Grid container spacing={2} alignItems="center" justifyContent="flex-end">
							<Grid item>
								<Tooltip title="Datum auswählen">
									<IconButton onClick={() => {
										setCalendarModal(true)
									}}>
										<CalendarTodayIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
								<Tooltip title="Lieferzeit festlegen">
									<IconButton onClick={() => {
										setShowDeliveryTimeModal(true)
									}}>
										<AccessTimeIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {
										setIsLoaded(false);
										loadOrders(calendarDate, true);
									}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>

				<Box padding={2} className="PageHome-Orders">
					{!isLoaded ? (
						<Loader />
					) : (
						<Grid marginBottom={3} container spacing={3}>
							{orders.length == 0 && (
								<center style={{padding: '30px 0px',margin: '0 auto'}}>
									<ShoppingBasketOutlinedIcon fontSize={"large"} />
									<br />
									<Typography style={{color:'#081627'}} variant="h6" color="textSecondary">Aktuell keine Bestellungen</Typography>
								</center>
							)}
							{orders.map((order, index) => (
								<Grid key={index} item xs={12} sm={12} md={12} lg={4}>
									<Card variant='outlined' className={`PageHome-Orders-Item ${'PageHome-Orders-Item--' + order.order_status}`}>
										<CardActionArea component={Link} to={`/orders/${order.id}`}>
											<CardContent>
												<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
													<div
														className={`PageHome-Orders-Item-Status PageHome-Orders-Item-Status--${order.order_status}`}
													></div> {order.order_status_string}
												</Typography>
												<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
													#{order.id} / {isToday(new Date(Date.parse(order.created_at.replace(/-/g, '/')))) ? 'Heute' : getFormattedDate(new Date(Date.parse(order.created_at.replace(/-/g, '/'))))},&nbsp;
													{new Date(Date.parse(order.created_at.replace(/-/g, '/'))).toLocaleTimeString()} Uhr
												</Typography>
												<Typography variant="h6" component="div">
													{order.order_delivery_address_name}
												</Typography>

												{isAdmin && (
													<div className="PageHome-Orders-Item-KeyValue">
														<Typography className="PageHome-Orders-Item-KeyValue-Key" variant="body1" component="div">
															Restaurant:
														</Typography>
														<Typography className="PageHome-Orders-Item-KeyValue-Value" variant="body1" component="div">
															{order.order_restaurants}
														</Typography>
													</div>
												)}
												<div className="PageHome-Orders-Item-KeyValue">
													<Typography className="PageHome-Orders-Item-KeyValue-Key" variant="body1" component="div">
														Typ:
													</Typography>
													<Typography className="PageHome-Orders-Item-KeyValue-Value" variant="body1" component="div">
														{order.order_delivery_type === "pickup" ? 'Abholung' : 'Lieferung'}
														{order.is_preorder == 1 && ' (Vorbestellung)'}
													</Typography>
												</div>
												<div className="PageHome-Orders-Item-KeyValue">
													<Typography className="PageHome-Orders-Item-KeyValue-Key" variant="body1" component="div">
														Zeit:
													</Typography>
													<Typography className="PageHome-Orders-Item-KeyValue-Value" variant="body1" component="div">
														{order.is_preorder == 1 ? 'Vorbestellung auf ' + (new Date(order.preorder_date).toLocaleTimeString([], {timeStyle: 'short'})) + ' Uhr' : order.order_delivery_minutes + ' Min.'}
													</Typography>
												</div>
												<div className="PageHome-Orders-Item-KeyValue">
													<Typography className="PageHome-Orders-Item-KeyValue-Key" variant="body1" component="div">
														Total:
													</Typography>
													<Typography className="PageHome-Orders-Item-KeyValue-Value" variant="body1" component="div">
														<Currency
															quantity={parseFloat(order.order_total_sum)}
															currency="EUR"
														/> ({
																order.order_payment_type === 'cash' ? 'Bar' :
																(order.order_payment_type == "eccard" ? "EC-Karte" : 
																(order.order_payment_type == "paypal" ? "PayPal" : 
																(order.order_payment_type == "apple-pay" ? "Apple Pay" : 
																(order.order_payment_type.includes("deliverone-payments") ? "DeliverOne Payments" : "Kreditkarte"))))
															})
													</Typography>
												</div>
											</CardContent>
										</CardActionArea>
									</Card>
								</Grid>
							))}

						</Grid>
					)}
				</Box>

			</Paper>

			<Dialog
				fullScreen={fullScreen}
				open={calendarModal}
				onClose={() => {
					setCalendarModal(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{fullScreen ? (
					<AppBar sx={{ position: 'relative' }}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={() => {
									setCalendarModal(false);
								}}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
							<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Datum auswählen
							</Typography>
						</Toolbar>
					</AppBar>
				) : (
					<DialogTitle id="alert-dialog-title">
						Datum auswählen
					</DialogTitle>
				)}
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Wähle ein Datum aus, um die Bestellungen für dieses Datum zu sehen.
					</DialogContentText>

					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
						<StaticDatePicker
							displayStaticWrapperAs="desktop"
							value={calendarDate}
							onChange={(newValue) => {
								setCalendarDate(newValue);
							}}
							renderInput={(params) => <TextField {...params} />}
							minDate={new Date("Sat Jan 01 2022 12:00:00 GMT+0100 (Mitteleuropäische Normalzeit)")}
							maxDate={new Date()}
							disableFuture
							openTo="day"
						/>
					</LocalizationProvider>

				</DialogContent>
				<DialogActions>
					<Button onClick={() => {
						setCalendarModal(false);
						loadOrders(calendarDate);
					}} autoFocus>
						Anwenden
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				fullScreen={fullScreen}
				open={showDeliveryTimeModal}
				onClose={() => {
					setShowDeliveryTimeModal(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth
			>
				{fullScreen ? (
					<AppBar sx={{ position: 'relative' }}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={() => {
									setShowDeliveryTimeModal(false);
								}}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
							<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
								Lieferzeit auswählen
							</Typography>
						</Toolbar>
					</AppBar>
				) : (
					<DialogTitle id="alert-dialog-title">
						Lieferzeit auswählen
					</DialogTitle>
				)}
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={6}>
							<LoadingButton loading={!isLoaded} color={orderTime == 0 ? 'success' : 'primary'} onClick={() => updateOrderTime(0)} variant="contained" size="large" fullWidth>Geschlossen</LoadingButton>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<LoadingButton loading={!isLoaded} color={orderTime == 1 ? 'success' : 'primary'} onClick={() => updateOrderTime(1)} variant="contained" size="large" fullWidth>Standard</LoadingButton>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<LoadingButton loading={!isLoaded} color={orderTime == 2 ? 'success' : 'primary'} onClick={() => updateOrderTime(2)} variant="contained" size="large" fullWidth>Pause</LoadingButton>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<LoadingButton loading={!isLoaded} color={orderTime == 3 ? 'success' : 'primary'} onClick={() => updateOrderTime(3)} variant="contained" size="large" fullWidth>Rush-Hour</LoadingButton>
						</Grid>
					</Grid>
					<div style={{
						margin: '30px 0px',
						textAlign: 'center',
						padding: '25px',
						border: '1px solid #1b485f36',
						borderRadius: '10px',
						background: '#101b3008'
					}}>
						<h3 style={{marginTop:0}}>Status: {
							(orderTime == 0 ? 'Geschlossen' : 
							orderTime == 1 ? 'Standard' : 
							orderTime == 2 ? 'Pause' : 
							orderTime == 3 ? 'Rush-Hour' : '')
						}</h3>
						<p style={{marginBottom:0}}>
							{orderTime == 0 ? 'Es werden keine neuen Bestellungen angenommen. Dein Restaurant ist in der App nicht sichtbar. Ab morgen gelten die Standard-Öffnungszeiten.' : ''}
							{orderTime == 1 ? 'DeliverOne richtet sich nach den Öffnungszeiten des Restaurants!' : ''}
							{orderTime == 2 ? 'Es werden keine neuen Bestellungen angenommen. Dein Restaurant ist in der App nicht sichtbar. Ab morgen gelten die Standard-Öffnungszeiten.' : ''}
							{orderTime == 3 ? 'Es werden neue Bestellungen angenommen. Dein Restaurant-Ranking wird vermindert. Ab morgen gelten die Standard-Öffnungszeiten.' : ''}
						</p>
					</div>
					{(orderTime == 1 || orderTime == 3) && (
						<div style={{margin:'20px 0',textAlign:'center', width:'100%',padding:'0 20px'}}>
							<br />
							<Typography gutterBottom>
								Lieferzeit in Minuten: {sliderDeliveryTime} Min.
							</Typography>

							<Slider
								marks={[
									{
										label: '15 Min.',
										value: 15,
									},
									{
										label: '30 Min.',
										value: 30,
									},
									{
										label: '45 Min.',
										value: 45,
									},
									{
										label: '60 Min.',
										value: 60,
									},
									{
										label: '90 Min.',
										value: 90,
									},
									{
										label: '120 Min.',
										value: 120,
									},
								]}
								valueLabelDisplay="auto"
								aria-label="Lieferzeit"
								step={null}
								defaultValue={30}
								min={15}
								max={120}
								value={sliderDeliveryTime}
								onChange={(event, value) => {
									setSliderDeliveryTime(value);
								}}
								onChangeCommitted={(event, value) => {
									updateOrderTimeMinutes(value);
								}}
							/>

							<br />
							<br />

							<Typography gutterBottom>
								Abholzeit in Minuten: {sliderPickupTime} Min.
							</Typography>

							<Slider
								marks={[
									{
										label: '15 Min.',
										value: 15,
									},
									{
										label: '30 Min.',
										value: 30,
									},
									{
										label: '45 Min.',
										value: 45,
									},
									{
										label: '60 Min.',
										value: 60,
									},
									{
										label: '90 Min.',
										value: 90,
									},
									{
										label: '120 Min.',
										value: 120,
									},
								]}
								valueLabelDisplay="auto"
								aria-label="Abholzeit"
								step={null}
								defaultValue={30}
								min={15}
								max={120}
								value={sliderPickupTime}
								onChange={(event, value) => {
									setSliderPickupTime(value);
								}}
								onChangeCommitted={(event, value) => {
									updateOrderPickupTimeMinutes(value);
								}}
							/>
						</div>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {
						setShowDeliveryTimeModal(false);
					}}>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: support@deliverone.de"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
		</>
	);
}