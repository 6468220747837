import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export default function ChartPopularProducts(props) {

	const [chartData, setChartData] = React.useState([]);
	const [chartLabels, setChartLabels] = React.useState([]);

	React.useEffect(() => {

		// Set datasets
		let data = [];
		let labels = [];

		if (props.data) {
			// Loop through data and create datasets
			for (let key in props.data) {
				labels.push(key);
				data.push(props.data[key]);
			}
		}

		setChartLabels(labels);
		setChartData(data);
		
	}, [props])

	const options = {
		elements: {
			bar: {
				borderWidth: 2,
			},
		},
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: true,
				text: 'Beliebteste Produkte (Top 10)',
			},
		},
	};

	const data = {
		labels: chartLabels,
		datasets: [
			{
			  label: 'Anzahl der Bestellungen',
			  data: chartData,
			  borderColor: 'rgb(161,197,61)',
			  backgroundColor: 'rgba(161,197,61, 0.5)',
			  lineTension: 0.7
			}
		]
	}

	return (
		<Bar
			options={options}
			data={data}
		/>
	);
}