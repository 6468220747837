import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../../components/Loader';

import './style.scss';

export default function PageAdministrateBankingAuthorize() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [status, setStatus] = useState("error"); // error, success

	
	useEffect(() => {
		document.title = 'Revolut Banking Einrichtung — DeliverOne Banking — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);
		

		if (findGetParameter('code')) {

			axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/banking/authorize`, {
				code: findGetParameter('code')
			}).then((res) => {
				setIsLoading(false);
				if (res.data) {

					localStorage.setItem('bankingAccessToken', (res.data.accessTokenJson));

					navigate.push('/administrate/banking/a-accounts');

					setStatus("success");

				} else {
					handleError();
				}
			}).catch((err) => {
				handleError();
			});

		}

	}, [window.location])

	const handleError = () => {
		setStatus("error");
		setIsLoading(false);
	}

	const findGetParameter = (parameterName) => {
		var result = null,
			tmp = [];
		window.location.search
			.substr(1)
			.split("&")
			.forEach(function (item) {
			  tmp = item.split("=");
			  if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
			});
		return result;
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						status === "error" ? (
							<center style={{margin: '60px 0'}}>
								<Typography style={{fontWeight:'bold'}} variant="h5" component="h5" gutterBottom>
									Ein Fehler ist aufgetreten!
								</Typography>
								<span>Ein Fehler ist aufgetreten. Bitte kontaktiere den Support: +49 8342 7050-752.</span>
							</center>
						) : (
							<center style={{margin: '60px 0'}}>
								<Typography style={{fontWeight:'bold'}} variant="h5" component="h5" gutterBottom>
									Banking erfolgreich autorisiert!
								</Typography>
								<span>Das Banking wurde erfolgreich autorisiert. Du kannst nun die Konten verwalten.</span>
							</center>
						)
					)}
				</Box>

			</Paper>
		</>
	);
}