import React, {useEffect, useState} from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { useHistory, useParams } from "react-router-dom";
import SubjectIcon from '@mui/icons-material/Subject';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {Editor} from "@jeremyling/react-material-ui-rich-text-editor";
import PersonIcon from '@mui/icons-material/Person';
import Loader from '../../components/Loader';

import './style.scss';

export default function PageInboxCreate() {

    let navigate = useHistory();
	let params = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [customerRestaurants, setCustomerRestaurants] = useState([]);

	const [message, setMessage] = useState(`Nachricht...`);
	const [messageSender, setMessageSender] = useState("DeliverOne");
	const [messageSubject, setMessageSubject] = useState("");
	const [messageRecipient, setMessageRecipient] = useState("");
	const [messageFileName, setMessageFileName] = useState("Datei hochladen...");
	const [messageFile, setMessageFile] = useState(null);

	useEffect(() => {

		document.title = 'Postfach — ' + process.env.REACT_APP_APPLICATION_NAME;
	
		setIsLoading(true)

		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
		}

		if (userData.role_name !== "admin") {
			setIsLoading(false);
			navigate.push("/");
		} else {

			axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
			.then((res) => {
				if (res.data) {
					setCustomerRestaurants(res.data);
				}
				setIsLoading(false);
			})
			.catch((err) => {})
		}
		
	}, [])

	const messageFileChange = (e) => {
		if (e.target.files[0]) {
			setIsLoading(true);
			setSuccessSnackbarText("Datei wird hochgeladen...");
			setShowSuccessSnackbar(true);

			if (e.target.files[0].size / 1024 / 1024 > 64) {
				setIsLoading(false);
				setSuccessSnackbarText("Bitte verwende eine kleinere Datei (max. 64 MB).");
				setTimeout(() => {setShowSuccessSnackbar(false);}, 5000)
				return;
			}

			setMessageFile(e.target.files[0]);
			setMessageFileName(e.target.files[0].name);
	
			setTimeout(() => {
				setIsLoading(false);
				setSuccessSnackbarText("Datei wurde hochgeladen.");
				setTimeout(() => {setShowSuccessSnackbar(false);}, 5000)
			}, 1000);
		}
	}

	const sendMessage = () => {

		if (messageSender === "") {
			setSuccessSnackbarText("Bitte gib einen Absender ein.");
			setShowSuccessSnackbar(true);
			return;
		}

		if (messageRecipient === "") {
			setSuccessSnackbarText("Bitte wähle einen Empfänger aus.");
			setShowSuccessSnackbar(true);
			return;
		}

		if (messageSubject === "") {
			setSuccessSnackbarText("Bitte gib einen Betreff ein.");
			setShowSuccessSnackbar(true);
			return;
		}

		if (message === "") {
			setSuccessSnackbarText("Bitte gib eine Nachricht ein.");
			setShowSuccessSnackbar(true);
			return;
		}

		setIsLoading(true);
		setSuccessSnackbarText("Nachricht wird gesendet...");
		setShowSuccessSnackbar(true);

		let formData = new FormData();
		formData.append("message", message);
		formData.append("sender", messageSender);
		formData.append("subject", messageSubject);
		formData.append("recipient", messageRecipient);
		formData.append("file", messageFile);

		axios.post(`${process.env.REACT_APP_API_URL}/api/backend/dashboard-inbox-send`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then((res) => {
			setIsLoading(false);
			setSuccessSnackbarText("Nachricht wurde gesendet.");
			setTimeout(() => {setShowSuccessSnackbar(false);}, 5000)
			navigate.push("/inbox");
		})
		.catch((err) => {
			setIsLoading(false);
			setSuccessSnackbarText("Nachricht konnte nicht gesendet werden.");
			setTimeout(() => {setShowSuccessSnackbar(false);}, 5000)
		})

	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container spacing={2} style={{flexDirection:'row'}} alignItems="center">
							<Grid item>
								<PersonIcon color="inherit" sx={{ display: 'block' }} />
							</Grid>
							<Grid item xs>
								<TextField
									value={messageSender}
									onChange={(e) => setMessageSender(e.target.value)}
									fullWidth
									placeholder="Absender"
									InputProps={{
										disableUnderline: true,
										sx: { fontSize: 'default' },
									}}
									variant="standard"
								/>
							</Grid>
						</Grid>
					</Toolbar>
					<Toolbar className="PageMenu-AppBar">
						<Grid container spacing={2} style={{flexDirection:'row'}} alignItems="center">
							<Grid item>
								<ContactMailIcon color="inherit" sx={{ display: 'block' }} />
							</Grid>
							<Grid item xs>
								<Select
									labelId="message-recipient-label"
									id="message-recipient"
									value={messageRecipient}
									label="Empfänger Restaurant"
									onChange={(e) => setMessageRecipient(e.target.value)}
									fullWidth
									inputProps={{
										disableUnderline: true,
										sx: { fontSize: 'default' },
									}}
									variant="standard"
									disableUnderline
								>
									<MenuItem value={0}>00000000 Globale Nachricht</MenuItem>
									{customerRestaurants.map((restaurant, index) => {
										return <MenuItem value={restaurant.id} key={index}>{restaurant.id} {restaurant.restaurant_name}</MenuItem>
									})}
								</Select>
							</Grid>
							<Grid item>
								<Button onClick={sendMessage} variant="contained" sx={{ mr: 1 }}>
									Absenden
								</Button>
							</Grid>
						</Grid>
					</Toolbar>
					<Toolbar className="PageMenu-AppBar">
						<Grid container spacing={2} style={{flexDirection:'row'}} alignItems="center">
							<Grid item>
								<SubjectIcon color="inherit" sx={{ display: 'block' }} />
							</Grid>
							<Grid item xs>
								<TextField
									value={messageSubject}
									onChange={(e) => setMessageSubject(e.target.value)}
									fullWidth
									placeholder="Betreff"
									InputProps={{
										disableUnderline: true,
										sx: { fontSize: 'default' },
									}}
									variant="standard"
								/>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>

				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<div className="PageInboxCreate-Editor">
							<TextField
								label="Nachricht eintippen"
								multiline
								maxRows={10}
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								variant="standard"
								fullWidth
								disableUnderline
							/>

							<br />
							<br />

							<Button
								variant="outlined"
								component="label"
								style={{marginBottom:'10px'}}
								color="primary"
							>
								{messageFileName}
								<input
									type="file"
									hidden
									onChange={messageFileChange}
								/>
							</Button>
						</div>
					)}
				</Box>
			</Paper>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>
		</>
	);
}