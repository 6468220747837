import React, { useState, useEffect } from "react";
import axios from "axios";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

function MenuCreate(props) {

	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [menuName, setMenuName] = useState('');
	const [menuStatus, setMenuStatus] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [uploadedImage, setUploadedImage] = useState(null);
	const [uploadedImageName, setUploadedImageName] = useState("Bild hochladen");

	useEffect(() => {}, [props])

	const resetForm = () => {
		setMenuName("")
		setMenuStatus(true)
		setUploadedImage(null)
		setUploadedImageName("Bild hochladen")
	}

	const onSave = () => {
		setIsLoading(true);
		setSnackbarMessage("Menü-Kategorie wird erstellt...");
		setShowSnackbar(true);

		try {
			const formData = new FormData();
			formData.append("categoryName", menuName);
			formData.append("categoryImage", uploadedImage);
			formData.append("categoryStatus", menuStatus);
			const config = {
				headers: {
					"content-type": "multipart/form-data"
				}
			};
			const API = "api/backend/restaurant/categories";
			const url = `${process.env.REACT_APP_API_URL}/${API}`;
		
			axios.post(url, formData)
				.then(function (response) {
					if (response.status === 200) {
						setIsLoading(false)
						resetForm()
						props.closeHandler()
						props.reloadIndex()
						setSnackbarMessage("Menü-Kategorie wurde erstellt.")			
						setTimeout(() => {setShowSnackbar(false)}, 5000)			
					} else {
						handleError(response)
					}
				})
				.catch(function (error) {
					handleError(error);
				})
		} catch (error) {
			handleError(error);
		}
		
		setTimeout(() => {
			setIsLoading(false);
			props.closeHandler()
			props.reloadIndex()
			setSnackbarMessage("Menü-Kategorie wurde erstellt.");

			

			setTimeout(() => {setShowSnackbar(false);}, 5000)
		}, 1000);
	}

	const imageChange = (e) => {
		if (e.target.files[0]) {
			setIsLoading(true);
			setSnackbarMessage("Bild wird hochgeladen...");
			setShowSnackbar(true);

			if (e.target.files[0].size / 1024 / 1024 > 8) {
				setIsLoading(false);
				setSnackbarMessage("Bitte verwende ein kleines Bild (max. 8 MB).");
				setTimeout(() => {setShowSnackbar(false);}, 5000)
				return;
			}

			setUploadedImage(e.target.files[0]);
			setUploadedImageName(e.target.files[0].name);
	
			setTimeout(() => {
				setIsLoading(false);
				setSnackbarMessage("Bild wurde hochgeladen.");
				setTimeout(() => {setShowSnackbar(false);}, 5000)
			}, 1000);
		}
	}

	const handleError = (error) => {
		setShowErrorSnackbar(true)
		
	}

	return (
		<>
			<Dialog fullWidth className="PageMenu-Modal" open={props.isOpen} onClose={props.closeHandler}>
				<DialogTitle>{menuName.trim() === '' ? 'Menü-Kategorie' : `"${menuName.trim()}"`} erstellen</DialogTitle>
				<DialogContent>
					<Button
						variant="outlined"
						component="label"
						style={{marginBottom:'10px'}}
					>
						{uploadedImageName}
						<input
							type="file"
							hidden
							accept="image/*"
							onChange={imageChange}
						/>
					</Button>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Bezeichnung"
						type="text"
						fullWidth
						variant="standard"
						value={menuName}
						style={{marginBottom: '10px'}}
						onChange={(event) => {
							setMenuName(event.target.value)
						}}
					/>
					<FormControlLabel
						control={
							<Switch
								checked={menuStatus}
								onChange={(event) => {
									setMenuStatus(event.target.checked);
								}}
							/>
						}
						label={menuStatus ? 'Menü-Kategorie ist sichtbar.' : 'Menü-Kategorie ist nicht sichtbar.'}
					/>
				</DialogContent>
				<DialogActions>
					<div></div>
					<div>
						<Button style={{marginRight:'5px'}} onClick={props.closeHandler}>Schließen</Button>
						<Button onClick={onSave}>Speichern</Button>
					</div>
				</DialogActions>
			</Dialog>

			<Backdrop
				sx={{ color: '#fff', zIndex: 999999 }}
				open={isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSnackbar}
				message={snackbarMessage}
				sx={{ zIndex: 9999999 }}
				action={(
					<React.Fragment>
						{isLoading ? (
							<CircularProgress size={20} />
						) : (
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() => {setShowSnackbar(false)}}
							>
								<CloseIcon fontSize="small" />
							</IconButton>
						)}
					</React.Fragment>
				)}
			/>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
		</>
	)
}

export default MenuCreate;