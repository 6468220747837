import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Backdrop from '@mui/material/Backdrop'
import CloseIcon from '@mui/icons-material/Close'
import Loader from '../../../components/Loader';

import './style.scss';

export default function PageAdministrateDeliverOneRewardsTransactions() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [currentJobsItems, setCurrentJobsItems] = useState([]);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [showDetailsModalInfo, setShowDetailsModalInfo] = useState("Lädt...");
	const [showDetailsModal, setShowDetailsModal] = useState(false);

	useEffect(() => {
		document.title = 'DeliverOne Rewards Transaktionen — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/deliverone-rewards-transactions`).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setCurrentJobsItems(res.data);
			} else {
				setCurrentJobsItems([]);
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}, [reloadKey])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<TableContainer className="PageMenu-Table">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Transaktions-Nr.</TableCell>
										<TableCell>Kunde</TableCell>
										<TableCell>Typ</TableCell>
										<TableCell>Zweck</TableCell>
										<TableCell>Absender</TableCell>
										<TableCell align="right">Punkte</TableCell>
										<TableCell align='right'>Zeitpunkt</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{currentJobsItems.map((row, index) => (
										<TableRow
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="td" scope="row">
												#{row.id}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.username} (#{row.user_id})
											</TableCell>
											<TableCell component="td" scope="row">
												{row.transaction_type}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.transaction_text}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.transaction_sender}
											</TableCell>
											<TableCell component="td" align="right" scope="row">
												{row.rewards_coins}
											</TableCell>
											<TableCell component="td" align="right" scope="row">
												{row.created_at}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>

			</Paper>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

		</>
	);
}