import React, { useState, useEffect } from "react";
import axios from "axios";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../components/Loader';

function AllergensCreate(props) {

	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [allergenName, setAllergenName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);

	useEffect(() => {}, [props])

	const resetForm = () => {
		setAllergenName("")
	}

	const onSave = () => {
		setIsLoading(true);
		setSnackbarMessage("Allergen wird erstellt...");
		setShowSnackbar(true);

		try {
			const formData = new FormData();
			formData.append("allergenName", allergenName);
			const API = "api/backend/restaurant/allergens";
			const url = `${process.env.REACT_APP_API_URL}/${API}`;
		
			axios.post(url, formData)
				.then(function (response) {
					if (response.status === 200) {
						setIsLoading(false)
						resetForm()
						props.closeHandler()
						props.reloadIndex()
						setSnackbarMessage("Allergen wurde erstellt.")			
						setTimeout(() => {setShowSnackbar(false)}, 5000)			
					} else {
						handleError(response)
					}
				})
				.catch(function (error) {
					handleError(error);
				})
		} catch (error) {
			handleError(error);
		}
		
		setTimeout(() => {
			setIsLoading(false);
			props.closeHandler()
			props.reloadIndex()
			setSnackbarMessage("Allergen wurde erstellt.");

			setTimeout(() => {setShowSnackbar(false);}, 5000)
		}, 1000);
	}

	const handleError = (error) => {
		setShowErrorSnackbar(true)
	}

	return (
		<>
			<Dialog fullWidth className="PageMenu-Modal" open={props.isOpen} onClose={props.closeHandler}>
				<DialogTitle>{allergenName.trim() === '' ? 'Allergen' : `"${allergenName.trim()}"`} erstellen</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Bezeichnung"
						type="text"
						fullWidth
						variant="standard"
						value={allergenName}
						style={{marginBottom: '10px'}}
						onChange={(event) => {
							setAllergenName(event.target.value)
						}}
					/>
				</DialogContent>
				<DialogActions>
					<div></div>
					<div>
						<Button style={{marginRight:'5px'}} onClick={props.closeHandler}>Schließen</Button>
						<Button onClick={onSave}>Speichern</Button>
					</div>
				</DialogActions>
			</Dialog>

			{isLoading && (<Loader />)}

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSnackbar}
				message={snackbarMessage}
				sx={{ zIndex: 9999999 }}
				action={(
					<React.Fragment>
						{isLoading ? (
							<></>
						) : (
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() => {setShowSnackbar(false)}}
							>
								<CloseIcon fontSize="small" />
							</IconButton>
						)}
					</React.Fragment>
				)}
			/>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
		</>
	)
}

export default AllergensCreate;