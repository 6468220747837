import React, { useState, useEffect } from "react"
import axios from "axios"

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close'

function AllergensEdit(props) {

	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false)
    const [editModalData, setEditModalData] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [snackbarMessage, setSnackbarMessage] = useState("")
	const [showSnackbar, setShowSnackbar] = useState(false)

	useEffect(() => {
        if (props.row) {
            setEditModalData(props.row)
        }
	}, [props])

	const resetForm = () => {
		setEditModalData(null)
	}

    const onSave = () => {
		setIsLoading(true)
		setSnackbarMessage("Allergen wird gespeichert...")
		setShowSnackbar(true)

		const formData = new FormData()
		formData.append("allergenName", editModalData.name)
		const API = "api/backend/restaurant/allergens/" + editModalData.id
		const url = `${process.env.REACT_APP_API_URL}/${API}`
	
		axios.post(url, formData)
			.then(function (response) {
				if (response.status === 200) {
					setIsLoading(false)
					resetForm()
					props.closeHandler()
					props.reloadIndex()
					setSnackbarMessage("Allergen wurde gespeichert.")			
					setTimeout(() => {setShowSnackbar(false)}, 5000)			
				} else {
					handleError(response)
				}
			})
			.catch(function (error) {
				handleError(error)
			})
	}

	const handleError = (error) => {
		setShowErrorSnackbar(true)
	}

	return (
        props.row && (
            <>
                <Dialog fullWidth className="PageMenu-Modal" open={props.isOpen} onClose={props.closeHandler}>
                    <DialogTitle>{editModalData ? editModalData.name : 'Wird geladen...'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Bezeichnung"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={editModalData ? editModalData.name : ''}
                            style={{marginBottom: '10px'}}
                            onChange={(event) => {
                                setEditModalData({
                                    ...editModalData,
                                    name: event.target.value
                                })
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <div>
                            <Button onClick={() => {props.deleteHandler(editModalData)}} color="error">Löschen</Button>
                        </div>
                        <div>
                            <Button style={{marginRight:'5px'}} onClick={props.closeHandler}>Schließen</Button>
                            <Button onClick={onSave}>Speichern</Button>
                        </div>
                    </DialogActions>
                </Dialog>
				<Snackbar
					open={showSnackbar}
					message={snackbarMessage}
					sx={{ zIndex: 9999999 }}
					action={(
						<React.Fragment>
							{isLoading ? (
								<CircularProgress size={20} />
							) : (
								<IconButton
									size="small"
									aria-label="close"
									color="inherit"
									onClick={() => {setShowSnackbar(false)}}
								>
									<CloseIcon fontSize="small" />
								</IconButton>
							)}
						</React.Fragment>
					)}
				/>
                <Snackbar
                    open={showErrorSnackbar}
                    message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
                    sx={{ zIndex: 9999999 }}
                    autoHideDuration={8000}
                    onClose={() => {setShowErrorSnackbar(false)}}
                />
            </>
        )
	)
}

export default AllergensEdit