import React, {useEffect, useState} from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './style.scss';
import HolidayConfigurationModal from '../../components/HolidayConfigurationModal';
import SingleHolidayConfigurationModal from '../../components/SingleHolidayConfigurationModal';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Loader from '../../components/Loader';
import { useHistory } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import singleHolidays from '../../assets/single-holidays.json';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import deLocale from 'date-fns/locale/de';

export default function PageSettingsSingleHolidays() {

	let navigate = useHistory();
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [isLoading, setIsLoading] = useState(true);
    const [possibleHolidays, setPossibleHolidays] = useState([]);
    const [singleHolidayValues, setSingleHolidayValues] = useState([]);
    const [showNewModal, setShowNewModal] = useState(false);
    const [newHolidayDate, setNewHolidayDate] = useState("");
    const [newHolidayName, setNewHolidayName] = useState('');
    const [loaderText, setLoaderText] = useState('');

	useEffect(() => {
		document.title = 'Feiertage — Konfiguration — ' + process.env.REACT_APP_APPLICATION_NAME;

        setPossibleHolidays([])
        setSingleHolidayValues([])

        loadSingleHolidays();
	}, [reloadKey])

    const loadSingleHolidays = () => {

        setPossibleHolidays([])
        setSingleHolidayValues([])

        let data = localStorage.getItem('data');
        let restaurantId;
        if (!data) {
            navigate.push('/login');
            return;
        } else {
            data = JSON.parse(data);
            restaurantId = data.restaurant_access_id;
        }

        const API = "api/backend/restaurants/" + restaurantId;
        const url = `${process.env.REACT_APP_API_URL}/${API}`;
    
        axios.get(url)
            .then(function (response) {
                if (response.status === 200) {

                    let tmpSingleHolidayValues = {};
                    let tmpPossibleHolidays = [];

                    singleHolidays.forEach((holiday) => {

                        // Check if the date is today or in future. If it is in the past, skip it
                        let holidayDate = new Date(holiday.date);
                        let today = new Date();
                        if (holidayDate < today) {
                            return;
                        }

                        tmpSingleHolidayValues[holiday.date] = {
                            closed: true,
                            name: holiday.fname,
                            openTime1: '11:00',
                            closeTime1: '14:00',
                            openTime2: '17:00',
                            closeTime2: '22:00',
                        }
                    })
                    tmpPossibleHolidays.push(...singleHolidays);
            
                    setTimeout(() => {

                        let single_holidays_list = response.data.single_holidays_list;

                        if (!single_holidays_list) {
                            setIsLoading(false);
                            return;
                        }
    
                        //tmpSingleHolidayValues = {};
    
                        single_holidays_list.forEach((holiday) => {
                            tmpSingleHolidayValues[holiday.holiday_date] = {
                                closed: holiday.is_open === 0 ? true : false,
                                name: holiday.holiday_name,
                                openTime1: holiday.open_time_1,
                                closeTime1: holiday.close_time_1,
                                openTime2: holiday.open_time_2,
                                closeTime2: holiday.close_time_2,
                            }
    
    
                            // Check if the holiday is already in the list "possibleHolidays" by date
                            let holidayAlreadyInList = false;
                            possibleHolidays.forEach((possibleHoliday) => {
                                
                                if (possibleHoliday.date === holiday.holiday_date) {
                                    holidayAlreadyInList = true;
                                }
                            })
    
                            // If not, add it
                            if (!holidayAlreadyInList) {
                                tmpPossibleHolidays.push({
                                    date: holiday.holiday_date,
                                    fname: holiday.holiday_name
                                });
                            }
                            
                        })
                        
                        setPossibleHolidays(tmpPossibleHolidays);
                        setSingleHolidayValues(tmpSingleHolidayValues);
   

                        setIsLoading(false);
                    }, 1500)

                } else {
                    
                }
            })
            .catch(function (error) {
                
            })

    }

    const addHandler = () => {
        setShowNewModal(true);
    }

    const submitHandler = () => {
        setIsLoading(true);

        let data = localStorage.getItem('data');
        let restaurantId;
        if (!data) {
            navigate.push('/login');
            return;
        } else {
            data = JSON.parse(data);
            restaurantId = data.restaurant_access_id;
        }

        setLoaderText("Wird gespeichert...");

        Object.keys(singleHolidayValues).forEach((key) => {

            let loaderTextPercent = Math.round((Object.keys(singleHolidayValues).indexOf(key) / Object.keys(singleHolidayValues).length) * 100);

            let holidayDate = key;
            let holidayName = singleHolidayValues[key].name;
            let holidayClosed = singleHolidayValues[key].closed;
            let holidayOpenTime1 = singleHolidayValues[key].openTime1;
            let holidayCloseTime1 = singleHolidayValues[key].closeTime1;
            let holidayOpenTime2 = singleHolidayValues[key].openTime2;
            let holidayCloseTime2 = singleHolidayValues[key].closeTime2;


            axios.post(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/singleHolidays/` + restaurantId, {
                restaurantId: restaurantId,
                holidayBegin: holidayDate,
                holidayText: holidayName,
                closed: holidayClosed,
                openTime1: holidayOpenTime1,
                closeTime1: holidayCloseTime1,
                openTime2: holidayOpenTime2,
                closeTime2: holidayCloseTime2
            })
            .then((res) => {
                setLoaderText("Wird gespeichert... " + loaderTextPercent + "%");
                

                if (Object.keys(singleHolidayValues).indexOf(key) === Object.keys(singleHolidayValues).length - 1) {
                    setIsLoading(false);
                    setLoaderText("Wird gespeichert... 100%");
                }

            })
            .catch((err) => {
                
            })

        })

    }

    const newHolidayHandler = () => {
        // Add a new holiday to the list
        let tmpPossibleHolidays = possibleHolidays;
        tmpPossibleHolidays.push({
            date: newHolidayDate.getFullYear() + '-' + (newHolidayDate.getMonth() + 1) + '-' + newHolidayDate.getDate(),
            fname: newHolidayName
        });
        setPossibleHolidays(tmpPossibleHolidays);

        // Add a new holiday to the values
        let tmpSingleHolidayValues = singleHolidayValues;
        tmpSingleHolidayValues[newHolidayDate.getFullYear() + '-' + (newHolidayDate.getMonth() + 1) + '-' + newHolidayDate.getDate()] = {
            closed: true,
            name: newHolidayName,
            openTime1: '11:00',
            closeTime1: '14:00',
            openTime2: '17:00',
            closeTime2: '22:00',
        }
        setSingleHolidayValues(tmpSingleHolidayValues);

        // Close the modal
        setShowNewModal(false);

        // Scroll to the bottom
        window.scrollTo(0,document.body.scrollHeight);

        // Reset the values
        setNewHolidayDate("");
        setNewHolidayName("");
    }

    const deleteHandler = (date) => {
        setIsLoading(true);
        setPossibleHolidays([])
        setSingleHolidayValues([])

        let data = localStorage.getItem('data');
        let restaurantId;
        if (!data) {
            navigate.push('/login');
            return;
        } else {
            data = JSON.parse(data);
            restaurantId = data.restaurant_access_id;
        }

        setLoaderText("Wird gespeichert...");

        axios.delete(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/singleHolidays/` + restaurantId + '/' + date)
        .then((res) => {
            setLoaderText("Wird gespeichert... 100%");
            setReloadKey(Math.random());
        })
        .catch((err) => {
            
            setIsLoading(false);
        })
    }

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<Box padding={2}>
					{isLoading ? (
						<Loader text={loaderText} />
					) : (
						<div>
                            {possibleHolidays.map((holiday, index) => (
                                singleHolidayValues[holiday.date] && (
                                    <div className="SingleHolidayItem">
                                        <div className="SingleHolidayItem-Header">
                                            <h2>{holiday.fname} am {holiday.date.split('-').reverse().join('.')}</h2>
                                            <Tooltip title="Feiertag löschen" placement="left" onClick={() => {deleteHandler(holiday.date)}}>
                                                <IconButton>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label="Geschlossen"
                                            checked={singleHolidayValues[holiday.date] ? singleHolidayValues[holiday.date].closed : false}
                                            value={singleHolidayValues[holiday.date] ? singleHolidayValues[holiday.date].closed : false}
                                            onChange={(event) => {
                                                setSingleHolidayValues({
                                                    ...singleHolidayValues,
                                                    [holiday.date]: {
                                                        ...singleHolidayValues[holiday.date],
                                                        closed: event.target.checked
                                                    }
                                                });
                                            }}
                                        />
                                        {singleHolidayValues[holiday.date].closed === false && (
                                            <>
                                            
                                                <Grid container style={{marginTop:'10px'}} spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            label="Öffnung 1"
                                                            value={singleHolidayValues[holiday.date] ? singleHolidayValues[holiday.date].openTime1 : ''}
                                                            name="openTime1"
                                                            onChange={(e) => {
                                                                setSingleHolidayValues({
                                                                    ...singleHolidayValues,
                                                                    [holiday.date]: {
                                                                        ...singleHolidayValues[holiday.date],
                                                                        openTime1: e.target.value
                                                                    }
                                                                });
                                                            }}
                                                            type="time"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            label="Schließung 1"
                                                            value={singleHolidayValues[holiday.date] ? singleHolidayValues[holiday.date].closeTime1 : ''}
                                                            name="closeTime1"
                                                            onChange={(e) => {
                                                                setSingleHolidayValues({
                                                                    ...singleHolidayValues,
                                                                    [holiday.date]: {
                                                                        ...singleHolidayValues[holiday.date],
                                                                        closeTime1: e.target.value
                                                                    }
                                                                });
                                                            }}
                                                            type="time"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            label="Öffnung 2"
                                                            value={singleHolidayValues[holiday.date] ? singleHolidayValues[holiday.date].openTime2 : ''}
                                                            name="openTime2"
                                                            onChange={(e) => {
                                                                setSingleHolidayValues({
                                                                    ...singleHolidayValues,
                                                                    [holiday.date]: {
                                                                        ...singleHolidayValues[holiday.date],
                                                                        openTime2: e.target.value
                                                                    }
                                                                });
                                                            }}
                                                            type="time"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            label="Schließung 2"
                                                            value={singleHolidayValues[holiday.date] ? singleHolidayValues[holiday.date].closeTime2 : ''}
                                                            name="closeTime2"
                                                            onChange={(e) => {
                                                                setSingleHolidayValues({
                                                                    ...singleHolidayValues,
                                                                    [holiday.date]: {
                                                                        ...singleHolidayValues[holiday.date],
                                                                        closeTime2: e.target.value
                                                                    }
                                                                });
                                                            }}
                                                            type="time"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <i>Uhrzeit mit 00:00 angeben, wenn geschlossen</i>

                                            </>
                                        )}
                                    </div>
                                )
                            ))}

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between' }}>
                                <LoadingButton startIcon={<AddIcon />} loading={isLoading} onClick={addHandler}>Weiteren hinzufügen</LoadingButton>
                                <LoadingButton endIcon={<SaveIcon />} variant="contained" onClick={submitHandler} loading={isLoading}>Speichern</LoadingButton>
                            </Box>

                        </div>
					)}
				</Box>
			</Paper>

            <Dialog
                open={showNewModal}
                onClose={() => {
                    if (!isLoading) {
                        setShowNewModal(false);
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    Feiertag hinzufügen
                </DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                        <DatePicker
                            renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" {...props} />}
                            label="Datum des Feiertags"
                            value={new Date(newHolidayDate)}
                            onChange={(newValue) => {
                                setNewHolidayDate(newValue)
                            }}
                        />
                    </LocalizationProvider>
                    <TextField label="Feiertag" fullWidth variant="standard" margin="dense" value={newHolidayName} onChange={(e) => {setNewHolidayName(e.target.value)}} />
                </DialogContent>
                <DialogActions>
                    <LoadingButton LoadingButton loading={isLoading} disabled={isLoading} onClick={() => {
                        if (!isLoading) {
                            setShowNewModal(false);
                        }
                    }}>
                        Schließen
                    </LoadingButton>
                    <LoadingButton loading={isLoading} disabled={isLoading} onClick={newHolidayHandler}>
                        Speichern
                    </LoadingButton>
                </DialogActions>
            </Dialog>

		</>
	);
}