import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../../components/Loader';

import './style.scss';

export default function PageAdministrateBankTransactions() {

	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [bankTransactionsItemsOriginal, setBankTransactionsItemsOriginal] = useState([]);
	const [bankTransactionsItems, setBankTransactionsItems] = useState([]);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [toolbarSearchQuery, setToolbarSearchQuery] = useState("");

	useEffect(() => {
		document.title = 'Bank Transaktionen — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/bank-transactions`).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setBankTransactionsItemsOriginal(res.data);
				setBankTransactionsItems(res.data);
			} else {
				setBankTransactionsItemsOriginal([]);
				setBankTransactionsItems([]);
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}, [reloadKey])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	useEffect(() => {
		if (toolbarSearchQuery !== '') {

			// This is the function that filters the table rows
			
			// If first char is # then search for id or order_id
			if (toolbarSearchQuery.charAt(0) === '#') {
				setBankTransactionsItems(bankTransactionsItemsOriginal.filter((item) => {
					return item.id === parseInt(toolbarSearchQuery.substring(1)) || item.order_id === parseInt(toolbarSearchQuery.substring(1));
				}));
			} else {
				// Search in the rows for name, category name, sku and price
				let filteredRows = bankTransactionsItemsOriginal.filter((row) => {
					return row.order_id.toString().includes(toolbarSearchQuery.toLowerCase()) ||
						row.payment_id.toLowerCase().includes(toolbarSearchQuery.toLowerCase()) || 
						parseFloat(row.payment_amount).toFixed(2).toString().includes(toolbarSearchQuery.toLowerCase()) || 
						row.id.toString().includes(toolbarSearchQuery.toLowerCase()) || 
						row.payment_status.toLowerCase().includes(toolbarSearchQuery.toLowerCase()) ||
						row.created_at.toLowerCase().includes(toolbarSearchQuery.toLowerCase())
				})
				setBankTransactionsItems(filteredRows);
			}

			
		} else {
			setBankTransactionsItems(bankTransactionsItemsOriginal);
		}
	}, [toolbarSearchQuery])

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container spacing={2} style={{flexDirection:'row'}} alignItems="center">
							<Grid item>
								<SearchIcon color="inherit" sx={{ display: 'block' }} />
							</Grid>
							<Grid item xs>
								<TextField
									value={toolbarSearchQuery}
									onChange={(e) => setToolbarSearchQuery(e.target.value)}
									fullWidth
									placeholder="Suche nach Bestellungen, Transaktionen, Status und Betrag..."
									InputProps={{
										disableUnderline: true,
										sx: { fontSize: 'default' },
									}}
									variant="standard"
								/>
							</Grid>
							<Grid item>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<TableContainer className="PageMenu-Table">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Transak.-Id</TableCell>
										<TableCell>Bestell-Nr.</TableCell>
										<TableCell>Zahlungs-Id</TableCell>
										<TableCell>Betrag</TableCell>
										<TableCell>Status</TableCell>
										<TableCell>Zahlung gestartet</TableCell>
										<TableCell align='right'>Erstellt</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{bankTransactionsItems.map((row, index) => (
										<TableRow
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="td" scope="row">
												#{row.id}
											</TableCell>
											<TableCell component="td" scope="row">
												#{row.order_id}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.payment_id}
											</TableCell>
											<TableCell component="td" scope="row">
												{parseFloat(row.payment_amount).toFixed(2)}&nbsp;EUR
											</TableCell>
											<TableCell component="td" scope="row">
												{row.payment_status}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.payment_created}
											</TableCell>
											<TableCell component="td" align='right' scope="row">
												{row.created_at}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>

			</Paper>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

		</>
	);
}