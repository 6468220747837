import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import { TextField, Drawer } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Loader from '../../../components/Loader';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import './style.scss';

let taxRegistrarList = [
    'Aachen',
    'Altenburg',
    'Amberg',
    'Ansbach',
    'Apolda',
    'Arnsberg',
    'Arnstadt',
    'Arnstadt Zweigstelle Ilmenau',
    'Aschaffenburg',
    'Augsburg',
    'Aurich',
    'Bad Hersfeld',
    'Bad Homburg v.d.H.',
    'Bad Kreuznach',
    'Bad Oeynhausen',
    'Bad Salzungen',
    'Bamberg',
    'Bayreuth',
    'Berlin (Charlottenburg)',
    'Bielefeld',
    'Bochum',
    'Bonn',
    'Braunschweig',
    'Bremen',
    'Chemnitz',
    'Coburg',
    'Coesfeld',
    'Cottbus',
    'Darmstadt',
    'Deggendorf',
    'Dortmund',
    'Dresden',
    'Duisburg',
    'Düren',
    'Düsseldorf',
    'Eisenach',
    'Erfurt',
    'Eschwege',
    'Essen',
    'Flensburg',
    'Frankfurt am Main',
    'Frankfurt/Oder',
    'Freiburg',
    'Friedberg',
    'Fritzlar',
    'Fulda',
    'Fürth',
    'Gelsenkirchen',
    'Gera',
    'Gießen',
    'Gotha',
    'Göttingen',
    'Greiz',
    'Gütersloh',
    'Hagen',
    'Hamburg',
    'Hamm',
    'Hanau',
    'Hannover',
    'Heilbad Heiligenstadt',
    'Hildburghausen',
    'Hildesheim',
    'Hof',
    'Homburg',
    'Ingolstadt',
    'Iserlohn',
    'Jena',
    'Kaiserslautern',
    'Kassel',
    'Kempten (Allgäu)',
    'Kiel',
    'Kleve',
    'Koblenz',
    'Köln',
    'Königstein',
    'Korbach',
    'Krefeld',
    'Landau',
    'Landshut',
    'Langenfeld',
    'Lebach',
    'Leipzig',
    'Lemgo',
    'Limburg',
    'Lübeck',
    'Ludwigshafen a. Rhein (Ludwigshafen)',
    'Lüneburg',
    'Mainz',
    'Mannheim',
    'Marburg',
    'Meiningen',
    'Memmingen',
    'Merzig',
    'Mönchengladbach',
    'Montabaur',
    'Mühlhausen',
    'München',
    'Münster',
    'Neubrandenburg',
    'Neunkirchen',
    'Neuruppin',
    'Neuss',
    'Nordhausen',
    'Nürnberg',
    'Offenbach am Main',
    'Oldenburg (Oldenburg)',
    'Osnabrück',
    'Ottweiler',
    'Paderborn',
    'Passau',
    'Pinneberg',
    'Pößneck',
    'Pößneck Zweigstelle Bad Lobenstein',
    'Potsdam',
    'Recklinghausen',
    'Regensburg',
    'Rostock',
    'Rudolstadt',
    'Rudolstadt Zweigstelle Saalfeld ',
    'Saarbrücken',
    'Saarlouis',
    'Schweinfurt',
    'Schwerin',
    'Siegburg',
    'Siegen',
    'Sömmerda',
    'Sondershausen',
    'Sonneberg',
    'Stadthagen',
    'Stadtroda',
    'Steinfurt',
    'Stendal',
    'St. Ingbert (St Ingbert)',
    'Stralsund',
    'Straubing',
    'Stuttgart',
    'St. Wendel (St Wendel)',
    'Suhl',
    'Tostedt',
    'Traunstein',
    'Ulm',
    'Völklingen',
    'Walsrode',
    'Weiden i. d. OPf.',
    'Weimar',
    'Wetzlar',
    'Wiesbaden',
    'Wittlich',
    'Wuppertal',
    'Würzburg',
    'Zweibrücken'
]

export default function PageAdministrateStripeConnectAccountCreate(props) {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [showCompanySuccessDialog, setShowCompanySuccessDialog] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [mccList, setMccList] = useState([]);
	const [restaurants, setRestaurants] = useState([]);
	const [successId, setSuccessId] = useState(0);
	
	const [newAccountEmail, setNewAccountEmail] = useState("");
	const [newAccountBusinessType, setNewAccountBusinessType] = useState("individual");
	const [newAccountBusinessMCC, setNewAccountBusinessMCC] = useState("");
	const [newAccountBusinessPhone, setNewAccountBusinessPhone] = useState("");
	const [newAccountBusinessURL, setNewAccountBusinessURL] = useState("");
	const [newAccountCompanyName, setNewAccountCompanyName] = useState("");
	const [newAccountAddressLine1, setNewAccountAddressLine1] = useState("");
	const [newAccountAddressPostalCode, setNewAccountAddressPostalCode] = useState("");
	const [newAccountAddressCity, setNewAccountAddressCity] = useState("");
	const [newAccountCountry, setNewAccountCountry] = useState("DE");
	const [newAccountCurrency, setNewAccountCurrency] = useState("EUR");
	const [newAccountExtAccountName, setNewAccountExtAccountName] = useState("");
	const [newAccountExtAccountType, setNewAccountExtAccountType] = useState("");
	const [newAccountExtAccountIban, setNewAccountExtAccountIban] = useState("");
	const [newAccountBusinessTaxId, setNewAccountBusinessTaxId] = useState("");
	const [newAccountBusinessTaxRegistrar, setNewAccountBusinessTaxRegistrar] = useState("");
	const [newAccountBusinessStructure, setNewAccountBusinessStructure] = useState("");
	const [newAccountBusinessRestaurantId, setNewAccountBusinessRestaurantId] = useState("");
	
	const [newAccountPersonFirstname, setNewAccountPersonFirstname] = useState("");
	const [newAccountPersonLastname, setNewAccountPersonLastname] = useState("");
	const [newAccountPersonAddressLine1, setNewAccountPersonAddressLine1] = useState("");
	const [newAccountPersonAddressPostCode, setNewAccountPersonAddressPostCode] = useState("");
	const [newAccountPersonAddressCity, setNewAccountPersonAddressCity] = useState("");
	const [newAccountPersonBirthday, setNewAccountPersonBirthday] = useState(new Date());

	useEffect(() => {
		document.title = 'Connect-Konten — Stripe — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);

		if (props.asStep === true) {
			setNewAccountBusinessRestaurantId(props.formValues["restaurantId"])
			setNewAccountCompanyName(props.formValues["company"])
			setNewAccountAddressLine1(props.formValues["street"])
			setNewAccountAddressPostalCode(props.formValues["zip"])
			setNewAccountAddressCity(props.formValues["city"])

			setNewAccountPersonFirstname(props.formValues["firstname"])
			setNewAccountPersonLastname(props.formValues["lastname"])
			setNewAccountPersonAddressLine1(props.formValues["street"])
			setNewAccountPersonAddressPostCode(props.formValues["zip"])
			setNewAccountPersonAddressCity(props.formValues["city"])
			
			setNewAccountBusinessMCC("5812 - Gaststätten und Restaurants")
		}

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
		.then((res) => {
			if (res.data) {
				setRestaurants(res.data);
			}
		});

		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/stripe/connect-accounts/mcc-list`).then((res) => {
			setIsLoading(false);
			if (res.data) {
				let list = [];
				for (let key in res.data) {
					list.push({
						value: ((key.toString().length === 3) ? "0" : "") + key.toString(),
						label: ((key.toString().length === 3) ? "0" : "") + key.toString() + " - " + res.data[key]
					})
				}
				
				setMccList(list);
			} else {
				setMccList([]);
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})

		// Check if localStorage element StripeConnectAccountCreateData exists, if so, load it
		if (localStorage.getItem("StripeConnectAccountCreateData")) {
			let data = JSON.parse(localStorage.getItem("StripeConnectAccountCreateData"));
			setNewAccountEmail(data.newAccountEmail);
			setNewAccountBusinessType(data.newAccountBusinessType);
			setNewAccountBusinessMCC(data.newAccountBusinessMCC);
			setNewAccountBusinessPhone(data.newAccountBusinessPhone);
			setNewAccountBusinessURL(data.newAccountBusinessURL);
			setNewAccountCompanyName(data.newAccountCompanyName);
			setNewAccountAddressLine1(data.newAccountAddressLine1);
			setNewAccountAddressPostalCode(data.newAccountAddressPostalCode);
			setNewAccountAddressCity(data.newAccountAddressCity);
			setNewAccountCountry(data.newAccountCountry);
			setNewAccountCurrency(data.newAccountCurrency);
			setNewAccountExtAccountName(data.newAccountExtAccountName);
			setNewAccountExtAccountType(data.newAccountExtAccountType);
			setNewAccountExtAccountIban(data.newAccountExtAccountIban);
			setNewAccountBusinessTaxId(data.newAccountBusinessTaxId);
			setNewAccountBusinessTaxRegistrar(data.newAccountBusinessTaxRegistrar);
			setNewAccountBusinessStructure(data.newAccountBusinessStructure);
			setNewAccountBusinessRestaurantId(data.newAccountBusinessRestaurantId);
			setNewAccountPersonFirstname(data.newAccountPersonFirstname);
			setNewAccountPersonLastname(data.newAccountPersonLastname);
			setNewAccountPersonAddressLine1(data.newAccountPersonAddressLine1);
			setNewAccountPersonAddressPostCode(data.newAccountPersonAddressPostCode);
			setNewAccountPersonAddressCity(data.newAccountPersonAddressCity);
			setNewAccountPersonBirthday(data.newAccountPersonBirthday);
		}

	}, [])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	const handleCreate = () => {
		setShowErrorSnackbar(false);
		setShowSuccessSnackbar(false);
		setIsLoading(true);

		if (props.asStep === true) {
			props.setIsLoading(true);
			props.handleStepError(false);
		}

		let data = {
			newAccountEmail: newAccountEmail,
			newAccountBusinessType: newAccountBusinessType,
			newAccountBusinessMCC: newAccountBusinessMCC,
			newAccountBusinessPhone: newAccountBusinessPhone,
			newAccountBusinessURL: newAccountBusinessURL,
			newAccountCompanyName: newAccountCompanyName,
			newAccountAddressLine1: newAccountAddressLine1,
			newAccountAddressPostalCode: newAccountAddressPostalCode,
			newAccountAddressCity: newAccountAddressCity,
			newAccountCountry: newAccountCountry,
			newAccountCurrency: newAccountCurrency,
			newAccountExtAccountName: newAccountExtAccountName,
			newAccountExtAccountType: newAccountExtAccountType,
			newAccountExtAccountIban: newAccountExtAccountIban,
			newAccountBusinessTaxId: newAccountBusinessTaxId,
			newAccountBusinessTaxRegistrar: newAccountBusinessTaxRegistrar,
			newAccountBusinessStructure: newAccountBusinessStructure,
			newAccountPersonFirstname: newAccountPersonFirstname,
			newAccountPersonLastname: newAccountPersonLastname,
			newAccountPersonAddressLine1: newAccountPersonAddressLine1,
			newAccountPersonAddressPostCode: newAccountPersonAddressPostCode,
			newAccountPersonAddressCity: newAccountPersonAddressCity,
			newAccountPersonBirthday: newAccountPersonBirthday,
			newAccountBusinessRestaurantId: newAccountBusinessRestaurantId
		}

		// Store data in local storage to be able to restore it if the user navigates away from the page
		localStorage.setItem("StripeConnectAccountCreateData", JSON.stringify(data));

		axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/stripe/connect-accounts`, {
			newAccountEmail: newAccountEmail,
			newAccountBusinessType: newAccountBusinessType,
			newAccountBusinessMCC: newAccountBusinessMCC,
			newAccountBusinessPhone: newAccountBusinessPhone,
			newAccountBusinessURL: newAccountBusinessURL,
			newAccountCompanyName: newAccountCompanyName,
			newAccountAddressLine1: newAccountAddressLine1,
			newAccountAddressPostalCode: newAccountAddressPostalCode,
			newAccountAddressCity: newAccountAddressCity,
			newAccountCountry: newAccountCountry,
			newAccountCurrency: newAccountCurrency,
			newAccountExtAccountName: newAccountExtAccountName,
			newAccountExtAccountType: newAccountExtAccountType,
			newAccountExtAccountIban: newAccountExtAccountIban,
			newAccountBusinessTaxId: newAccountBusinessTaxId,
			newAccountBusinessTaxRegistrar: newAccountBusinessTaxRegistrar,
			newAccountBusinessStructure: newAccountBusinessStructure,
			newAccountPersonFirstname: newAccountPersonFirstname,
			newAccountPersonLastname: newAccountPersonLastname,
			newAccountPersonAddressLine1: newAccountPersonAddressLine1,
			newAccountPersonAddressPostCode: newAccountPersonAddressPostCode,
			newAccountPersonAddressCity: newAccountPersonAddressCity,
			newAccountPersonBirthday: newAccountPersonBirthday,
			newAccountBusinessRestaurantId: newAccountBusinessRestaurantId
		}).then((res) => {
			setIsLoading(false);
			if (props.asStep === true) {
				props.setIsLoading(false);
			}
			if (res.data.id) {
				setSuccessSnackbarText("Connect-Konto angelegt!");
				setShowSuccessSnackbar(true);
				setReloadKey(Math.random());
				setSuccessId(res.data.id);
				

				if (newAccountBusinessType === "company") {
					setShowCompanySuccessDialog(true);
				}

				setNewAccountEmail("");
				setNewAccountBusinessType("individual");
				setNewAccountBusinessMCC("");
				setNewAccountBusinessPhone("");
				setNewAccountBusinessURL("");
				setNewAccountCompanyName("");
				setNewAccountAddressLine1("");
				setNewAccountAddressPostalCode("");
				setNewAccountAddressCity("");
				setNewAccountCountry("");
				setNewAccountCurrency("");
				setNewAccountExtAccountName("");
				setNewAccountExtAccountType("");
				setNewAccountExtAccountIban("");
				setNewAccountBusinessTaxId("");
				setNewAccountBusinessTaxRegistrar("");
				setNewAccountBusinessStructure("");
				setNewAccountPersonFirstname("");
				setNewAccountPersonLastname("");
				setNewAccountPersonAddressLine1("");
				setNewAccountPersonAddressPostCode("");
				setNewAccountPersonAddressCity("");
				setNewAccountPersonBirthday(new Date());
				setNewAccountBusinessRestaurantId("");

				if (props.asStep === true) {
					props.handleStepError(false);
					props.handleNext();
				}
			} else {
				if (props.asStep === true) {
					props.handleStepError(true);
				} else {
					handleError();
				}
			}
		}).catch((err) => {
			setIsLoading(false);
			if (props.asStep === true) {
				props.setIsLoading(false);
			}if (props.asStep === true) {
				props.handleStepError(true);
			} else {
				handleError();
			}handleError();
		})

	}

	const truncateText = (text, length) => {
		if (text.length <= length) {
			return text;
		}
		
		return text.substr(0, length) + '\u2026'
	}

	const FormContent = () => {
		return (
			<div>
				<Card variant="outlined" style={{background: 'rgb(250, 250, 250)'}}>
					<CardContent>
						<Typography sx={{ fontSize: 17 }} color="text.primary" borderBottom={'1px solid #d7dcde'} paddingBottom={1} marginBottom={3} gutterBottom>
							Geschäftsdaten
						</Typography>

						<FormControl margin="dense" size='small' fullWidth variant="outlined" style={{marginBottom: '10px'}}>
							<InputLabel id="newAccountBusinessType">Rechtsart</InputLabel>
							<Select
								labelId="newAccountBusinessType"
								id="newAccountBusinessType"
								label="Rechtsart"
								value={newAccountBusinessType}
								onChange={(event) => {
									setNewAccountBusinessType(event.target.value)
								}}				  
							>
								<MenuItem value="individual">Privatperson (ohne HR-Eintrag)</MenuItem>
								<MenuItem value="company">Eingetragenes Unternehmen</MenuItem>
							</Select>
						</FormControl>

						<FormControl margin="dense" size='small' fullWidth variant="outlined" style={{marginBottom: '10px'}}>
							<InputLabel id="newAccountBusinessType">Verknüpftes Restaurant</InputLabel>
							<Select
								labelId="newAccountBusinessRestaurantId"
								id="newAccountBusinessRestaurantId"
								label="Verknüpftes Restaurant"
								value={newAccountBusinessRestaurantId}
								onChange={(event) => {
									setNewAccountBusinessRestaurantId(event.target.value)
								}}				  
							>
								{restaurants.map((restaurant, index) => {
									return <MenuItem value={restaurant.id} key={index}>{restaurant.restaurant_name}&nbsp;<i style={{color:'#91a5b4'}}>— {truncateText((restaurant.restaurant_description != null ? restaurant.restaurant_description : ""), 20)} ({restaurant.restaurant_address_city})</i></MenuItem>
								})}
							</Select>
						</FormControl>

						<Autocomplete
							disablePortal
							id="newAccountBusinessMCC"
							options={mccList}
							onChange={(event, newValue) => {
								setNewAccountBusinessMCC(newValue?.value ? newValue.value : "");
							}}
							value={{"value":"5812","label":"5812 - Gaststätten und Restaurants"}}
							margin="dense"
							size="small"
							style={{marginBottom: '10px'}}
							renderInput={(params) => <TextField margin="dense" size="small" variant="outlined" {...params} label="Geschäfts-Kategorie" />}
						/>

						<TextField
							margin="dense"
							id="newAccountCompanyName"
							label="Unternehmensname"
							type="text"
							variant="outlined"
							value={newAccountCompanyName}
							style={{marginBottom: '10px'}}
							size='small'
							fullWidth
							onChange={(event) => {
								setNewAccountCompanyName(event.target.value)
							}}
						/>

						<TextField
							margin="dense"
							id="newAccountBusinessPhone"
							label="Telefonnummer"
							type="text"
							variant="outlined"
							value={newAccountBusinessPhone}
							style={{marginBottom: '10px'}}
							size='small'
							fullWidth
							onChange={(event) => {
								setNewAccountBusinessPhone(event.target.value)
							}}
						/>

						<TextField
							autoFocus
							margin="dense"
							id="mail"
							label="E-Mail"
							type="email"
							fullWidth
							variant="outlined"
							value={newAccountEmail}
							style={{marginBottom: '10px'}}
							size='small'
							onChange={(event) => {
								setNewAccountEmail(event.target.value)
							}}
						/>

						<TextField
							margin="dense"
							id="newAccountBusinessURL"
							label="Webseite"
							type="text"
							variant="outlined"
							value={newAccountBusinessURL}
							style={{marginBottom: '10px'}}
							size='small'
							fullWidth
							onChange={(event) => {
								setNewAccountBusinessURL(event.target.value)
							}}
						/>

						<TextField
							margin="dense"
							id="newAccountAddressLine1"
							label="Straße und Hausnr."
							type="text"
							variant="outlined"
							value={newAccountAddressLine1}
							style={{marginBottom: '10px'}}
							size='small'
							fullWidth
							onChange={(event) => {
								setNewAccountAddressLine1(event.target.value)
							}}
						/>

						<TextField
							margin="dense"
							id="newAccountAddressPostalCode"
							label="Postleitzahl"
							type="text"
							variant="outlined"
							value={newAccountAddressPostalCode}
							style={{marginBottom: '10px'}}
							maxLength="5"
							size='small'
							fullWidth
							onChange={(event) => {
								setNewAccountAddressPostalCode(event.target.value)
							}}
						/>

						<TextField
							margin="dense"
							id="newAccountAddressCity"
							label="Ort"
							type="text"
							variant="outlined"
							value={newAccountAddressCity}
							style={{marginBottom: '10px'}}
							size='small'
							fullWidth
							onChange={(event) => {
								setNewAccountAddressCity(event.target.value)
							}}
						/>

					</CardContent>
				</Card>

				{newAccountBusinessType === "individual" ? (
					<>
						<br />
						<br />
						<Card variant="outlined" style={{background: 'rgb(250, 250, 250)'}}>
							<CardContent>
								<Typography sx={{ fontSize: 17 }} color="text.primary" borderBottom={'1px solid #d7dcde'} paddingBottom={1} marginBottom={3} gutterBottom>
									Personendaten
								</Typography>

								<Alert sx={{ overflow: 'hidden', marginBottom: '20px' }} variant="filled" severity="warning">
									Hinweis! Angegebene Daten müssen mit den Daten in Ihrem Ausweis übereinstimmen.
								</Alert>

								<TextField
									margin="dense"
									id="newAccountPersonFirstname"
									label="Vorname"
									type="text"
									fullWidth
									variant="outlined"
									value={newAccountPersonFirstname}
									style={{marginBottom: '10px'}}
									size='small'
									onChange={(event) => {
										setNewAccountPersonFirstname(event.target.value)
									}}
								/>

								<TextField
									margin="dense"
									id="newAccountPersonLastname"
									label="Nachname"
									type="text"
									fullWidth
									variant="outlined"
									value={newAccountPersonLastname}
									style={{marginBottom: '10px'}}
									size='small'
									onChange={(event) => {
										setNewAccountPersonLastname(event.target.value)
									}}
								/>

								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
									<DatePicker
										renderInput={(props) => <TextField fullWidth variant="outlined" size='small' margin="dense" {...props} />}
										label="Geburtsdatum"
										value={new Date(newAccountPersonBirthday)}
										onChange={(newValue) => {
											setNewAccountPersonBirthday(newValue)
											
										}}
									/>
								</LocalizationProvider>

								<TextField
									margin="dense"
									id="newAccountPersonAddressLine1"
									label="Straße + Hausnr."
									type="text"
									fullWidth
									variant="outlined"
									value={newAccountPersonAddressLine1}
									style={{marginBottom: '10px'}}
									size='small'
									onChange={(event) => {
										setNewAccountPersonAddressLine1(event.target.value)
									}}
								/>

								<TextField
									margin="dense"
									id="newAccountPersonAddressPostCode"
									label="Postleitzahl"
									type="text"
									fullWidth
									variant="outlined"
									value={newAccountPersonAddressPostCode}
									style={{marginBottom: '10px'}}
									size='small'
									onChange={(event) => {
										setNewAccountPersonAddressPostCode(event.target.value)
									}}
								/>

								<TextField
									margin="dense"
									id="newAccountPersonAddressCity"
									label="Ort"
									type="text"
									fullWidth
									variant="outlined"
									value={newAccountPersonAddressCity}
									style={{marginBottom: '10px'}}
									size='small'
									onChange={(event) => {
										setNewAccountPersonAddressCity(event.target.value)
									}}
								/>

							</CardContent>
						</Card>
					</>
				) : (
					<>
						<br />
						<br />
						<Card variant="outlined" style={{background: 'rgb(250, 250, 250)'}}>
							<CardContent>
								<Typography sx={{ fontSize: 17 }} color="text.primary" borderBottom={'1px solid #d7dcde'} paddingBottom={1} marginBottom={3} gutterBottom>
									Unternehmensdaten
								</Typography>

								<FormControl margin="dense" size='small' fullWidth variant="outlined">
									<InputLabel id="newAccountBusinessStructure">Rechtsform</InputLabel>
									<Select
										labelId="newAccountBusinessStructure"
										id="newAccountBusinessStructure"
										label="Rechtsform"
										value={newAccountBusinessStructure}
										onChange={(event) => {
											setNewAccountBusinessStructure(event.target.value)
										}}				  
									>
										<MenuItem value="private_corporation">GmbH oder UG</MenuItem>
										<MenuItem value="public_corporation">Aktiengesellschaft</MenuItem>
										<MenuItem value="incorporated_partnership">OHG, KG oder GmbH & Co. KG</MenuItem>
										<MenuItem value="unincorporated_partnership">GbR oder PartG (Personengesellschaft)</MenuItem>
										<MenuItem value="incorporated_non_profit">e. V. oder e. G.</MenuItem>
										<MenuItem value="unincorporated_non_profit">n. e. V. (nicht eingetragener Verein)</MenuItem>
									</Select>
								</FormControl>

								<TextField
									margin="dense"
									id="newAccountBusinessTaxId"
									label="Handelsregisternummer"
									type="text"
									fullWidth
									variant="outlined"
									value={newAccountBusinessTaxId}
									style={{marginBottom: '10px'}}
									size='small'
									onChange={(event) => {
										setNewAccountBusinessTaxId(event.target.value)
									}}
									onBlur={(event) => {
										setNewAccountBusinessTaxId(event.target.value.toUpperCase().trim())
									}}
								/>

								<FormControl margin="dense" size='small' fullWidth variant="outlined">
									<InputLabel id="newAccountBusinessTaxRegistrar">Handelsregister</InputLabel>
									<Select
										labelId="newAccountBusinessTaxRegistrar"
										id="newAccountBusinessTaxRegistrar"
										label="Handelsregister"
										value={newAccountBusinessTaxRegistrar}
										onChange={(event) => {
											setNewAccountBusinessTaxRegistrar(event.target.value)
										}}				  
									>
										{taxRegistrarList.map((taxRegistry) => (
											<MenuItem value={taxRegistry}>{taxRegistry}</MenuItem>
										))}
									</Select>
								</FormControl>

							</CardContent>
						</Card>
					</>
				)}

				<br />
				<br />

				<Card variant="outlined" style={{background: 'rgb(250, 250, 250)'}}>
					<CardContent>
						<Typography sx={{ fontSize: 17 }} color="text.primary" borderBottom={'1px solid #d7dcde'} paddingBottom={1} marginBottom={3} gutterBottom>
							Wirtschaftsdaten
						</Typography>
				
						<TextField
							margin="dense"
							id="newAccountCurrency"
							label="Währung (z.B. EUR für Euro)"
							type="text"
							maxLength="3"
							minLength="3"
							fullWidth
							variant="outlined"
							value={newAccountCurrency}
							style={{marginBottom: '10px'}}
							size='small'
							onChange={(event) => {
								setNewAccountCurrency(event.target.value)
							}}
						/>

						<TextField
							margin="dense"
							id="newAccountCountry"
							label="Länder-Code (z.B. DE für Deutschland)"
							type="text"
							maxLength="2"
							minLength="2"
							fullWidth
							variant="outlined"
							value={newAccountCountry}
							style={{marginBottom: '10px'}}
							size='small'
							onChange={(event) => {
								setNewAccountCountry(event.target.value)
							}}
						/>
					</CardContent>
				</Card>

				<br />
				<br />

				<Card variant="outlined" style={{background: 'rgb(250, 250, 250)'}}>
					<CardContent>
						<Typography sx={{ fontSize: 17 }} color="text.primary" borderBottom={'1px solid #d7dcde'} paddingBottom={1} marginBottom={3} gutterBottom>
							Auszahlung
						</Typography>
						
						<TextField
							margin="dense"
							id="newAccountExtAccountName"
							label="Kontoinhaber"
							type="text"
							fullWidth
							variant="outlined"
							value={newAccountExtAccountName}
							style={{marginBottom: '10px'}}
							size='small'
							onChange={(event) => {
								setNewAccountExtAccountName(event.target.value)
							}}
						/>

						<FormControl margin="dense" size='small' fullWidth variant="outlined" style={{marginBottom: '10px'}}>
							<InputLabel id="newAccountExtAccountType">Inhaber-Art</InputLabel>
							<Select
								labelId="newAccountExtAccountType"
								id="newAccountExtAccountType"
								label="Inhaber-Art"
								value={newAccountExtAccountType}
								onChange={(event) => {
									setNewAccountExtAccountType(event.target.value)
								}}				  
							>
								<MenuItem value="individual">Privatkunde</MenuItem>
								<MenuItem value="company">Firmenkunde</MenuItem>
							</Select>
						</FormControl>

						<TextField
							margin="dense"
							id="newAccountExtAccountIban"
							label="IBAN"
							type="text"
							fullWidth
							variant="outlined"
							value={newAccountExtAccountIban}
							style={{marginBottom: '10px'}}
							size='small'
							onChange={(event) => {
								setNewAccountExtAccountIban(event.target.value.toUpperCase().trim())
							}}
						/>
					</CardContent>
				</Card>


				<br />
				<br />

	
				{props.asStep === true ? (
					<Box sx={{ display: "flex", flexDirection: "row" }}>
						<Box sx={{ flex: "1 1 auto" }} />
						<Button onClick={handleCreate}>
							Weiter
						</Button>
					</Box>
				) : (
					<Button onClick={handleCreate} variant="contained" sx={{ mr: 1 }}>
						Anlegen
					</Button>
				)}

			</div>
		)
	}

	return (
		<>

			{props.asStep === true ? (
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: '100%',
					justifyContent: 'space-between',
					padding: '20px'
				}}>
					<Box style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						rowGap: '20px'
					}}>
						{FormContent(props)}
					</Box>
				</div>
			) : (
				<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
					<AppBar
						position="static"
						color="default"
						elevation={0}
						sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
					>
						<Toolbar className="PageMenu-AppBar">
							<Grid container style={{flexDirection:'row'}} spacing={1} alignItems="center" justifyContent={"space-between"}>
								<Grid item>
									<b>Stripe Connect-Konto anlegen</b> — Administration
								</Grid>
								<Grid item>
									<Tooltip title="Aktualisieren">
										<IconButton onClick={() => {setReloadKey(Math.random())}}>
											<RefreshIcon color="inherit" sx={{ display: 'block' }} />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Toolbar>
					</AppBar>
					
					<Box padding={2}>
						{isLoading ? (
							<Loader />
						) : (
							FormContent(props)
						)}
					</Box>

				</Paper>
			)}

			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

			<Dialog
				open={showCompanySuccessDialog}
				onClose={() => {setShowCompanySuccessDialog(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title">
					Hinweis!
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Das Connect-Konto wurde erfolgreich erstellt. Unternehmens-Konten benötigen allerdings weitere Legitimationsdaten! <a href={"https://dashboard.stripe.com/connect/accounts/" + successId + "/activity"}>Zu Stripe</a>.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {setShowCompanySuccessDialog(false)}} autoFocus>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>

		</>
	);
}