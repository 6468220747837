import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Currency from 'react-currency-formatter';
import Drawer from '@mui/material/Drawer';
import Loader from '../../../components/Loader';

import './style.scss';

export default function PageAdministrateBankingPendingTransfers() {

	let navigate = useHistory();
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [isLoading, setIsLoading] = useState(false);
	const [status, setStatus] = useState("error"); // error, success
	const [accounts, setAccounts] = useState([]);
	const [showTransferDrawer, setShowTransferDrawer] = useState(false);
	const [selectedTransfer, setSelectedTransfer] = useState([]);

	useEffect(() => {
		document.title = 'Offene Buchungsvorgänge — DeliverOne Banking — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);
		

		if (localStorage.getItem('bankingAccessToken')) {

			axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/banking/pending-transfers?accessToken=${localStorage.getItem('bankingAccessToken')}`).then((res) => {
				setIsLoading(false);
				if (res.data) {

					if (res.data.status) {
						if (res.data.status === "success") {
							
							
							setAccounts(res.data.pending_bank_transfers);

						} else {
							handleError();
							return;
						}
					}

					setStatus("success");

				} else {
					handleError();
				}
			}).catch((err) => {
				handleError();
			});

		} else {
			handleError();
		}

	}, [reloadKey])

	const handleError = () => {
		setStatus("error");
		setIsLoading(false);
	}

	const approveTransfer = (transfer) => {

		setIsLoading(true);

		if (localStorage.getItem('bankingAccessToken')) {

			axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/banking/approve-transfer`, {
				accessToken: localStorage.getItem('bankingAccessToken'),
				transfer: transfer.id
			}).then((res) => {
				setIsLoading(false);
				if (res.data) {

					if (res.data.status) {
						if (res.data.status === "success") {
							setReloadKey(Math.random());
						} else {
							handleError();
							return;
						}
					}

					setStatus("success");

				} else {
					handleError();
				}
			}).catch((err) => {
				handleError();
			});

		} else {
			handleError();
		}

	}

	const rejectTransfer = (transfer) => {

		setIsLoading(true);

		if (localStorage.getItem('bankingAccessToken')) {

			axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/banking/reject-transfer`, {
				accessToken: localStorage.getItem('bankingAccessToken'),
				transfer: transfer.id
			}).then((res) => {
				setIsLoading(false);
				if (res.data) {

					if (res.data.status) {
						if (res.data.status === "success") {
							setReloadKey(Math.random());
						} else {
							handleError();
							return;
						}
					}

					setStatus("success");

				} else {
					handleError();
				}
			}).catch((err) => {
				handleError();
			});

		} else {
			handleError();
		}

	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						status === "error" ? (
							<center style={{margin: '60px 0'}}>
								<Typography style={{fontWeight:'bold'}} variant="h5" component="h5" gutterBottom>
									Ein Fehler ist aufgetreten!
								</Typography>
								<span>Ein Fehler ist aufgetreten. Bitte kontaktiere den Support: +49 8342 7050-752.</span>
							</center>
						) : (
							<TableContainer className="PageMenu-Table">
								<Table size="small" aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Restaurant</TableCell>
											<TableCell>SOLL</TableCell>
											<TableCell>HABEN</TableCell>
											<TableCell>Verwendungszweck</TableCell>
											<TableCell align='right'>Betrag</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{accounts.map((row, index) => (
											<TableRow
												key={index}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												onClick={() => {
													setSelectedTransfer(row);
													setShowTransferDrawer(true);
												}}
											>
												<TableCell component="td" scope="row">
													{row.restaurant}
												</TableCell>
												<TableCell component="td" scope="row">
													{row.debitor}
												</TableCell>
												<TableCell component="td" scope="row">
													{row.creditor.name}<br />
													{row.creditor.iban}<br />
													{row.creditor.bic}
												</TableCell>
												<TableCell component="td" scope="row">
													{row.transfer_reference}
												</TableCell>
												<TableCell component="td" align='right' scope="row" style={{minWidth:'150px'}}>
													<Currency
														quantity={parseFloat(row.transfer_total)}
														currency="EUR"
													/>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)
					)}
				</Box>

			</Paper>

			<Drawer
				anchor={"right"}
				open={showTransferDrawer}
				onClose={() => {
					setShowTransferDrawer(false);
				}}

				PaperProps={{
					style: {
						width: "40%",
						backgroundColor: "white",
						padding: "20px",
					}
				}}

			>
				<h2 style={{marginTop:0}}>{selectedTransfer?.restaurant_id} - {selectedTransfer?.restaurant}</h2>
				<br />

				<table style={{border:'0'}}>
					<tr>
						<td style={{verticalAlign:"top"}}>
							<span>TRANSFER-IDENT.:</span>
						</td>
						<td style={{verticalAlign:"top"}}>
							<span>{selectedTransfer?.transfer_uuid || "NICHT GEBUCHT!"}</span>
						</td>
					</tr>
					<tr>
						<td style={{verticalAlign:"top"}}>
							<span>VERW.:</span>
						</td>
						<td style={{verticalAlign:"top"}}>
							<span>{selectedTransfer?.transfer_reference}</span>
						</td>
					</tr>
					<tr>
						<td style={{verticalAlign:"top"}}>
							<span>DEBITOR:</span>
						</td>
						<td style={{verticalAlign:"top"}}>
							<span>{selectedTransfer?.debitor}</span>
						</td>
					</tr>
					<tr>
						<td style={{verticalAlign:"top"}}>
							<span>CREDITOR:</span>
						</td>
						<td style={{verticalAlign:"top"}}>
							<span><b>NAME: </b>{selectedTransfer?.creditor?.name}</span><br />
							<span><b>IBAN: </b>{selectedTransfer?.creditor?.iban}</span><br />
							<span><b>BIC: </b>{selectedTransfer?.creditor?.bic}</span>
						</td>
					</tr>
					<tr>
						<td style={{verticalAlign:"top"}}>
							<span>BETRAG:</span>
						</td>
						<td style={{verticalAlign:"top"}}>
							<span>
								<Currency
									quantity={parseFloat(selectedTransfer?.transfer_total)}
									currency="EUR"
								/>
							</span>
						</td>
					</tr>
				</table>

				<div style={{
					marginTop: '35px',
				}}>
					<Button onClick={() => {approveTransfer(selectedTransfer)}} style={{marginTop:'10px'}} fullWidth variant="contained">GENEHMIGEN</Button>
					<Button onClick={() => {rejectTransfer(selectedTransfer)}} style={{marginTop:'10px'}} fullWidth variant="contained" color="error">ABLEHNEN</Button>
				</div>

			</Drawer>
		</>
	);
}