import React, { useState, useEffect } from "react";

import Box from '@mui/system/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import StarIcon from '@mui/icons-material/Star';
import { useHistory } from "react-router-dom";

function createData(
	id,
	open_status,
	restaurant_address_city,
	restaurant_delivery_time,
	restaurant_image,
	restaurant_mail,
	restaurant_name,
	restaurant_phone,
	restaurant_rating
) {
	return { 
		id,
		open_status,
		restaurant_address_city,
		restaurant_delivery_time,
		restaurant_image,
		restaurant_mail,
		restaurant_name,
		restaurant_phone,
		restaurant_rating
	};
}

function RestaurantsIndex(props) {

	let navigate = useHistory();
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [tableRows, setTableRows] = useState([]);
	
	useEffect(() => {
		
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
		.then((res) => {
			if (res.status === 200 && res.data) {
				// Everything OK
				let temporaryTableRows = [];
				res.data.forEach((restaurant) => {
					temporaryTableRows.push(createData(
						restaurant.id,
						restaurant.open_status,
						restaurant.restaurant_address_city,
						restaurant.restaurant_delivery_time,
						restaurant.restaurant_image,
						restaurant.restaurant_mail,
						restaurant.restaurant_name,
						restaurant.restaurant_phone,
						restaurant.restaurant_rating
					));
				})
				setTableRows(res.data);
			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})
		
	}, [props.reloadKey])

	const handleError = (error) => {
		setShowErrorSnackbar(true)
		
	}

	return (
		<Box padding={2}>
			<TableContainer className="PageMenu-Table">
				<Table size="small" aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell style={{width: '60px', padding: '0'}}></TableCell>
							<TableCell style={{paddingLeft: 0}}>Bezeichnung</TableCell>
							<TableCell>Ort</TableCell>
							<TableCell>Telefon</TableCell>
							<TableCell>Lieferzeit</TableCell>
							<TableCell>Bewertung</TableCell>
							<TableCell>Öffnungszeiten</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tableRows.map((row) => (
							<TableRow
								onClick={() => {navigate.push(`/restaurants/${row.id}`)}}
								key={row.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell align='center' style={{width: '60px', padding: '0'}}>
									{row.restaurant_image != null && (
										<Avatar style={{margin: '0 auto'}} src={`${row.restaurant_image}`} />
									)}
								</TableCell>
								<TableCell style={{paddingLeft: 0}} component="th" scope="row">
									{row.restaurant_name}
								</TableCell>
								<TableCell>{row.restaurant_address_city}</TableCell>
								<TableCell>{row.restaurant_phone}</TableCell>
								<TableCell>{row.restaurant_delivery_time} Minuten</TableCell>
								<TableCell>
									{row.restaurant_rating === 0 ? 'Keine Bewertungen' : (
										<>
											{parseFloat(row.restaurant_rating).toFixed(1)} <StarIcon style={{
												fontSize: '16px',
												verticalAlign: 'middle',
												marginLeft: '4px',
												marginBottom: '3px',
												color: '#4a4a4a'
											}} />
										</>
									)}
								</TableCell>
								<TableCell>
									{row.open_status.status === "open" ? `Offen (schließt um ${row.open_status.closes_at} Uhr)` : (
										row.open_status.opens_in_minutes === 0 ? 'Geschlossen' : `Geschlossen (öffnet in ${
											(
												row.open_status.opens_in_minutes > 60 ? 
													parseInt(row.open_status.opens_in_minutes / 60) + ' Stunde(n)': 
													parseInt(row.open_status.opens_in_minutes) + ' Minuten'
											)
										})`
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
		</Box>
	)
}

export default RestaurantsIndex;