import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/system/Box';

import ProductsIndex from './ProductsIndex';

import './style.scss';

export default function PageProducts() {

	let navigate = useHistory();
	const [showEditModal, setShowEditModal] = useState(false);
	const [editModalData, setEditModalData] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteModalData, setDeleteModalData] = useState(null);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [toolbarSearchQuery, setToolbarSearchQuery] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [restaurantId, setRestaurantId] = useState(0);
	const [customerRestaurants, setCustomerRestaurants] = useState([]);

	useEffect(() => {
		document.title = 'Speisekarte — ' + process.env.REACT_APP_APPLICATION_NAME;	

		let userData = localStorage.getItem("data");
		if (userData) {
			userData = JSON.parse(userData);
		}
		if (userData.role_name === "admin") {setIsAdmin(true)}

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
			.then((res) => {
				if (res.data) {
					setCustomerRestaurants(res.data);
				}
			})
	}, [])

	const generateProductList = () => {

		let fetchUrl = "";

		if (isAdmin) {
			if (restaurantId === 0) {
				fetchUrl = `${process.env.REACT_APP_API_URL}/api/backend/restaurant/products/list`
			} else {
				fetchUrl = `${process.env.REACT_APP_API_URL}/api/backend/restaurant/products/list?restaurant=` + restaurantId
			}
		} else {
			fetchUrl = `${process.env.REACT_APP_API_URL}/api/backend/restaurant/products/list`
		}

		axios.get(fetchUrl, {
			responseType: 'blob'
		})
		.then((res) => {
			setIsLoading(false);
			if (res.data) {
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', "DELIVERONE-PRODUKT-LISTE.pdf");
				document.body.appendChild(link);
				link.click();
			} else {
				window.location.href = "/";
			}
		})
		.catch((err) => {
			window.location.href = "/";
		})

	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container spacing={2} style={{flexDirection:'row'}} alignItems="center">
							<Grid item>
								<SearchIcon color="inherit" sx={{ display: 'block' }} />
							</Grid>
							<Grid item xs>
								<TextField
									value={toolbarSearchQuery}
									onChange={(e) => setToolbarSearchQuery(e.target.value)}
									fullWidth
									placeholder="Suche nach Produkten, Kategorien oder ID"
									InputProps={{
										disableUnderline: true,
										sx: { fontSize: 'default' },
									}}
									variant="standard"
								/>
							</Grid>
							<Grid item>
								<Button onClick={generateProductList} variant="contained" sx={{ mr: 1 }}>
									Produktübersicht
								</Button>
								<Button onClick={() => {navigate.push('/products/new')}} variant="contained" sx={{ mr: 1 }}>
									Erstellen
								</Button>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>

				{isAdmin && (
					<Box padding={2}>
						<FormControl size="small" label="Restaurant auswählen" fullWidth>
							<InputLabel id="restaurant-selector-label">Restaurant auswählen</InputLabel>
							<Select
								labelId="restaurant-selector-label"
								id="restaurant-selector"
								label="Restaurant auswählen"
								value={restaurantId}
								onChange={(event) => {
									setRestaurantId(event.target.value)
								}}				  
							>
								<MenuItem value={0}>000000 GESAMTUNTERNEHMEN</MenuItem>
								{customerRestaurants.map((restaurant, index) => {
									return <MenuItem value={restaurant.id} key={index}>{restaurant.id} {restaurant.restaurant_name}</MenuItem>
								})}
							</Select>
						</FormControl><br />
					</Box>
				)}
				
				<ProductsIndex
					editHandler={(row) => {
						setEditModalData(row);
						setShowEditModal(true);
					}}
					reloadKey={reloadKey}
					restaurantId={restaurantId}

					toolbarSearchQuery={toolbarSearchQuery}
				/>

			</Paper>
			
		</>
	);
}