import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar'
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Loader from '../../../../components/Loader';
import ProcessOutlet from './ProcessOutlet';

import '../style.scss';

export default function PageAdministrateContractManagementSetup() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [restaurants, setRestaurants] = useState([]);
	const [products, setProducts] = useState([]);
	const [reloadKey, setReloadKey] = useState(Math.random());

	useEffect(() => {
		document.title = 'Vertragsanlage Neukunde — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;

		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
		.then((res) => {
			if (res.data) {
				setRestaurants(res.data);

                axios.get(process.env.REACT_APP_API_URL + '/api/administrate/contracts/sales-x-products').then((response) => {
                    
                    setProducts(response.data.products);
                    setIsLoading(false);
                })
                .catch((error) => {
                    handleError();
                    setIsLoading(false);
                })
			} else {
				setIsLoading(false);
				handleError();
			}
		})
		.catch((error) => {
			handleError();
			setIsLoading(false);
		})

	}, [reloadKey])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}
	
	return (
		<>
            {isLoading ? <Loader /> : (
                <ProcessOutlet
                    products={products}
                    restaurants={restaurants}
                    reloadKey={reloadKey}
                    setReloadKey={setReloadKey}
                    handleError={handleError}
                    handleSuccess={(text) => {
                        setSuccessSnackbarText(text);
                        setShowSuccessSnackbar(true);
                    }}
                    handleLoading={(value) => {
                        setIsLoading(value);
                    }}
                />
            )}

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

		</>
	);
}