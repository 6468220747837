import React, { useState, useEffect } from "react"
import axios from "axios"

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close'

function MenuEdit(props) {

	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false)
    const [editModalData, setEditModalData] = useState(null)
	const [editModalStatus, setEditModalStatus] = useState(true)
	const [isLoading, setIsLoading] = useState(false)
	const [snackbarMessage, setSnackbarMessage] = useState("")
	const [showSnackbar, setShowSnackbar] = useState(false)
	const [uploadedImage, setUploadedImage] = useState(null)
	const [uploadedMiniImage, setUploadedMiniImage] = useState(null)
	const [uploadedImageName, setUploadedImageName] = useState("Bild hochladen")
	const [uploadedMiniImageName, setUploadedMiniImageName] = useState("Mini-Bild hochladen")

	useEffect(() => {
        if (props.row) {
            setEditModalData(props.row)
            setEditModalStatus(props.row.status)
        }
	}, [props])

	const resetForm = () => {
		setEditModalData(null)
		setEditModalStatus(true)
		setUploadedImage(null)
		setUploadedMiniImage(null)
		setUploadedImageName("Bild hochladen")
		setUploadedMiniImageName("Mini-Bild hochladen")
	}

    const onSave = () => {
		setIsLoading(true)
		setSnackbarMessage("Menü-Kategorie wird gespeichert...")
		setShowSnackbar(true)

		const formData = new FormData()
		formData.append("categoryName", editModalData.name)
		formData.append("categoryImage", uploadedImage)
		formData.append("categoryMiniImage", uploadedMiniImage)
		formData.append("categoryStatus", editModalStatus)
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		}
		const API = "api/backend/restaurant/categories/" + editModalData.id
		const url = `${process.env.REACT_APP_API_URL}/${API}`
	
		axios.post(url, formData)
			.then(function (response) {
				if (response.status === 200) {
					setIsLoading(false)
					resetForm()
					props.closeHandler()
					props.reloadIndex()
					setSnackbarMessage("Menü-Kategorie wurde gespeichert.")			
					setTimeout(() => {setShowSnackbar(false)}, 5000)			
				} else {
					handleError(response)
				}
			})
			.catch(function (error) {
				handleError(error)
			})
	}

    const imageChange = (e) => {
		if (e.target.files[0]) {
			setIsLoading(true)
			setSnackbarMessage("Bild wird hochgeladen...")
			setShowSnackbar(true)

			if (e.target.files[0].size / 1024 / 1024 > 8) {
				setIsLoading(false)
				setSnackbarMessage("Bitte verwende ein kleines Bild (max. 8 MB).")
				setTimeout(() => {setShowSnackbar(false)}, 5000)
				return
			}

			setUploadedImage(e.target.files[0])
			setUploadedImageName(e.target.files[0].name)
	
			setTimeout(() => {
				setIsLoading(false)
				setSnackbarMessage("Bild wurde hochgeladen.")
				setTimeout(() => {setShowSnackbar(false)}, 5000)
			}, 1000)
		}
	}

    const miniImageChange = (e) => {
		if (e.target.files[0]) {
			setIsLoading(true)
			setSnackbarMessage("Mini-Bild wird hochgeladen...")
			setShowSnackbar(true)

			if (e.target.files[0].size / 1024 / 1024 > 4) {
				setIsLoading(false)
				setSnackbarMessage("Bitte verwende ein kleines Mini-Bild (max. 4 MB).")
				setTimeout(() => {setShowSnackbar(false)}, 5000)
				return
			}

			setUploadedMiniImage(e.target.files[0])
			setUploadedMiniImageName(e.target.files[0].name)
	
			setTimeout(() => {
				setIsLoading(false)
				setSnackbarMessage("Mini-Bild wurde hochgeladen.")
				setTimeout(() => {setShowSnackbar(false)}, 5000)
			}, 1000)
		}
	}

	const handleError = (error) => {
		setShowErrorSnackbar(true)
		
	}

	return (
        props.row && (
            <>
                <Dialog fullWidth className="PageMenu-Modal" open={props.isOpen} onClose={props.closeHandler}>
                    <DialogTitle>{editModalData ? editModalData.name : 'Wird geladen...'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Bezeichnung"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={editModalData ? editModalData.name : ''}
                            style={{marginBottom: '10px'}}
                            onChange={(event) => {
                                setEditModalData({
                                    ...editModalData,
                                    name: event.target.value
                                })
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={editModalStatus}
                                    onChange={(event) => {
                                        setEditModalStatus(event.target.checked)
                                    }}
                                />
                            }
                            label={editModalStatus ? 'Menü-Kategorie ist sichtbar.' : 'Menü-Kategorie ist nicht sichtbar.'}
                        />
                    </DialogContent>
                    <DialogActions>
                        <div>
                            <Button onClick={() => {props.deleteHandler(editModalData)}} color="error">Löschen</Button>
                            <Button
                                component="label"
                            >
                                {uploadedImageName}
                                <input
                                    type="file"
                                    hidden
                                    onChange={imageChange}
                                    accept="image/*"
                                />
                            </Button>
                            <Button
                                component="label"
                            >
                                {uploadedMiniImageName}
                                <input
                                    type="file"
                                    hidden
                                    onChange={miniImageChange}
                                    accept="image/*"
                                />
                            </Button>
                        </div>
                        <div>
                            <Button style={{marginRight:'5px'}} onClick={props.closeHandler}>Schließen</Button>
                            <Button onClick={onSave}>Speichern</Button>
                        </div>
                    </DialogActions>
                </Dialog>
				<Snackbar
					open={showSnackbar}
					message={snackbarMessage}
					sx={{ zIndex: 9999999 }}
					action={(
						<React.Fragment>
							{isLoading ? (
								<CircularProgress size={20} />
							) : (
								<IconButton
									size="small"
									aria-label="close"
									color="inherit"
									onClick={() => {setShowSnackbar(false)}}
								>
									<CloseIcon fontSize="small" />
								</IconButton>
							)}
						</React.Fragment>
					)}
				/>
                <Snackbar
                    open={showErrorSnackbar}
                    message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
                    sx={{ zIndex: 9999999 }}
                    autoHideDuration={8000}
                    onClose={() => {setShowErrorSnackbar(false)}}
                />
            </>
        )
	)
}

export default MenuEdit