import React, {useEffect, useState} from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Currency from 'react-currency-formatter';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import emptyImage from '../../assets/empty.png';
import { useHistory } from "react-router-dom";
import Loader from '../../components/Loader';

import './style.scss';

export default function PageInbox() {

    let navigate = useHistory();
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [isLoading, setIsLoading] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [messages, setMessages] = useState([]);
	const [messagesCount, setMessagesCount] = useState(0);

	useEffect(() => {

		document.title = 'Postfach — ' + process.env.REACT_APP_APPLICATION_NAME;
	
		setIsLoading(true)
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/dashboard-inbox`)
			.then(res => {
				
                setIsLoading(false);
                setMessagesCount(res.data.messages_count);
                setMessages(res.data.messages);
			})

		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
		}

		if (userData.role_name === "admin") {
			setIsAdmin(true);
		}
		
	}, [reloadKey])

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								{isAdmin && (
									<>
										<Button onClick={() => {navigate.push("/inbox-create")}} variant="contained" sx={{ mr: 1 }}>
											Nachricht senden
										</Button>
									</>
								)}
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>

				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
                        messages.length === 0 ? (
                            <center style={{padding: '30px 0px',margin: '0 auto'}}>
                                <img src={emptyImage} alt="Keine Bestellungen" style={{height:200}} /><br />
                                <Typography style={{color:'#081627'}} variant="h6" color="textSecondary">Dein Postfach ist leer</Typography>
                            </center>
                        ) : (
                            <TableContainer className="PageInbox-Table">
                                <Table size="small" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>Betreff</TableCell>
                                            <TableCell>Restaurant</TableCell>
                                            <TableCell>Absender</TableCell>
                                            <TableCell align='right'>Datum</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {messages.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                className={row.seen ? "PageInbox-TableRow" : "PageInbox-TableRow-unseen"}
                                                onClick={() => {
                                                    navigate.push(`/inbox/${row.id}`)
                                                }}
                                            >
                                                <TableCell component="td" scope="row">
                                                    #{row.id}
                                                </TableCell>
                                                <TableCell component="td" scope="row">
                                                    {row.subject}
                                                </TableCell>
                                                <TableCell component="td" scope="row">
                                                    {row.restaurant}
                                                </TableCell>
                                                <TableCell component="td" scope="row">
                                                    {row.sender}
                                                </TableCell>
                                                <TableCell component="td" align='right' scope="row" title={row.created_at_detailed}>
                                                    {row.created_at}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
					)}
				</Box>
			</Paper>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>
		</>
	);
}