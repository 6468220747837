import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../../components/Loader';

import './style.scss';

export default function PageAdministrateBanking() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [status, setStatus] = useState("error"); // error, success
	const [redirectionUrl, setRedirectionUrl] = useState("");

	
	useEffect(() => {
		document.title = 'Revolut Banking Einrichtung — DeliverOne Banking — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);
		
		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/banking/authorization-url`).then((res) => {
			setIsLoading(false);
			if (res.data) {
				

				setStatus("success");
				setRedirectionUrl(res.data.authorization_url);

				// Redirect to Revolut
				window.location.href = res.data.authorization_url;

			} else {
				handleError();
			}
		}).catch((err) => {
			handleError();
		});

	}, [])

	const handleError = () => {
		setStatus("error");
		setIsLoading(false);
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						status === "error" ? (
							<center style={{margin: '60px 0'}}>
								<Typography style={{fontWeight:'bold'}} variant="h5" component="h5" gutterBottom>
									Ein Fehler ist aufgetreten!
								</Typography>
								<span>Ein Fehler ist aufgetreten. Bitte kontaktiere den Support: +49 8342 7050-752.</span>
							</center>
						) : (
							<center style={{margin: '60px 0'}}>
								<Typography style={{fontWeight:'bold'}} variant="h5" component="h5" gutterBottom>
									Sie werden weitergeleitet...
								</Typography>
								<a href={redirectionUrl}>Hat die Weiterleitung nicht funktioniert? Klicken Sie hier.</a>
							</center>
						)
					)}
				</Box>

			</Paper>
		</>
	);
}