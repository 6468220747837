import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';

export default function StepInbox(props) {

    const documents = [
        {
            value: 'VERTRAG',
            name: '912.00.02 Vertrag',
            description: 'Vertrag zwischen DeliverOne und dem teilnehmenden Restaurant.',
            required: false,
        },
        {
            value: 'EINWILLIGUNG_DATENAUSTAUSCH',
            name: '912.00.03 Einwilligung zum elektronischen Datenaustausch mit Finanzbehörden',
            description: 'Einwilligung zum elektronischen Datenaustausch mit Finanzbehörden wie BaFin, Finanzamt, etc.',
            required: false,
        },
        {
            value: 'AGB_DATENSCHUTZ',
            name: 'AGB & Datenschutzerklärung',
            description: 'Allgemeine Geschäftsbedingungen & Datenschutzerklärung für die Nutzung der DeliverOne Plattform.',
            required: false,
        },
        {
            value: 'DELIVERONE_COURIER_GEBUEHRENAUFSTELLUNG',
            name: 'DeliverOne Courier Gebührenaufstellung',
            description: 'Gebührenaufstellung für die Nutzung der DeliverOne Courier Funktion.',
            required: false,
        },
        {
            value: 'DELIVERONE_TERMINALS_GEBUEHRENAUFSTELLUNG',
            name: 'DeliverOne Terminals Gebührenaufstellung',
            description: 'Gebührenaufstellung für die Nutzung der DeliverOne Terminals Geräte.',
            required: false,
        },
        {
            value: 'SEPA_LASTSCHRIFTMANDAT',
            name: 'SEPA-Lastschriftmandat',
            description: 'SEPA-Lastschriftmandat für die Nutzung der DeliverOne Plattform.',
            required: false,
        },
        {
            value: 'STRIPE_CONNECT_NUTZUNGSBEDINGUNGEN',
            name: 'Stripe Connect Nutzungsbedingungen',
            description: 'Nutzungsbedingungen für die Nutzung von Stripe Connect, zur Legitimation, Identifikation sowie zur wöchentlichen Abrechnung.',
            required: false,
        },
    ]

    const [checked, setChecked] = React.useState([0]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
  
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
  
        setChecked(newChecked);

        
    };

    useEffect(() => {
        setChecked(documents.filter(document => document.required).map(document => document.value));
    }, [])

    const handleNext = () => {
        let tmpFormValues = props.formValues;
        tmpFormValues.inboxDocuments = checked;
        props.setFormValues(tmpFormValues);
        props.handleNext();
    }

	return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
            padding: '20px'
        }}>
            <Box style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                rowGap: '20px'
            }}>

                <h3 style={{margin:0}}>Ein Elektronisches Postfach wird automatisch eingerichtet.</h3>
                <p style={{marginTop:0}}>Bitte wählen Sie die Dokumente aus, die Sie in das Postfach hochladen möchten.</p>
                
                <Divider />

                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {documents.map((document, index) => (
                        (props.formValues.documents.includes(document.value)) && (
                            <ListItem
                                disablePadding
                                key={index}
                            >
                                <ListItemButton role={undefined} onClick={handleToggle(document.value)} disabled={document.required} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={checked.indexOf(document.value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': index }}
                                            required={document.required}
                                            disabled={document.required}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={index} primary={document.name} secondary={document.description} />
                                </ListItemButton>
                            </ListItem>
                        )
                    ))}
                </List>

            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleNext}>
                    Prozess abschließen
                </Button>
            </Box>

        </div>
	);
}