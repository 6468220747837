import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

export default function StepCustomer(props) {

    const formInputs = [
        {
            label: 'Firma',
            name: 'company',
            type: 'text',
            required: true,
        },
        {
            label: 'Vorname GF',
            name: 'firstname',
            type: 'text',
            required: true,
        },
        {
            label: 'Nachname GF',
            name: 'lastname',
            type: 'text',
            required: true,
        },
        {
            label: 'Straße und Hausnummer',
            name: 'street',
            type: 'text',
            required: true,
        },
        {
            label: 'PLZ',
            name: 'zip',
            type: 'text',
            required: true,
        },
        {
            label: 'Ort',
            name: 'city',
            type: 'text',
            required: true,
        }
    ]

    const handleNext = () => {

        let formValid = true;
        formInputs.forEach((input) => {
            if (!props.formValues[input.name]) {
                formValid = false;
            } else {
                if (input.required && props.formValues[input.name] === "") {
                    formValid = false;
                }
            }
        })

        if (formValid) {
            
            props.handleNext();
        } else {
            props.handleStepError(true);
        }

    }

	return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
            padding: '20px'
        }}>
            <Box style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                rowGap: '20px'
            }}>
                
                {formInputs.map((input, index) => {
                    if (input.type === "text" || input.type === "number") {
                        return (
                            <TextField
                                key={index}
                                id={index + "_" + input.name}
                                label={input.label}
                                variant="outlined"
                                size='small'
                                type={input.type}
                                required={input.required}
                                value={props.formValues[input.name]}
                                onChange={(e) => {
                                    props.setFormValues({
                                        ...props.formValues,
                                        [input.name]: e.target.value
                                    })
                                }}
                            />
                        )
                    } else if (input.type === "checkbox") {
                        return (
                            <FormControlLabel
                                key={index}
                                control={<Checkbox
                                            id={index + "_" + input.name}
                                            required={input.required}
                                            defaultChecked={input.defaultChecked ? input.defaultChecked : false}
                                        />}
                                label={input.label}
                                value={props.formValues[input.name]}
                                onChange={(e) => {
                                    props.setFormValues({
                                        ...props.formValues,
                                        [input.name]: e.target.checked
                                    })
                                }}
                            />
                        )
                    } else if (input.type === "select") {
                        return (
                            <FormControl key={index} fullWidth>
                                <InputLabel id={index + "_" + input.name + "-label"}>{input.label}</InputLabel>
                                <Select
                                    id={index + "_" + input.name}
                                    label={input.label}
                                    variant="outlined"
                                    size='small'
                                    required={input.required}
                                    value={props.formValues[input.name] ? props.formValues[input.name] : input.default}
                                    onChange={(e) => {
                                        props.setFormValues({
                                            ...props.formValues,
                                            [input.name]: e.target.value
                                        })
                                    }}
                                >
                                    {input.options.map((option, index) => {
                                        return (
                                            <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        )
                    }
                })}

            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>

                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleNext}>
                    {props.activeStep === props.steps.length - 1 ? "Beenden" : "Weiter"}
                </Button>
            </Box>

        </div>
	);
}