import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import RefreshIcon from '@mui/icons-material/Refresh';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useParams, useLocation } from "react-router-dom";

import CustomerOrdersIndex from './CustomerOrdersIndex';

import './style.scss';

export default function PageCustomerOrders() {

	const [showEditModal, setShowEditModal] = useState(false);
	const [editModalData, setEditModalData] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteModalData, setDeleteModalData] = useState(null);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showGuestUsers, setShowGuestUsers] = useState(false);
	const [totalCustomers, setTotalCustomers] = useState(0);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [isAdmin, setIsAdmin] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	let params = useParams();

	useEffect(() => {

		document.title = 'Kundenbestellungen — ' + params.userId + ' — ' + process.env.REACT_APP_APPLICATION_NAME;

		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
		}
		if (userData.role_name === "admin") {
			setIsAdmin(true);
		}
		
	}, [])

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row',justifyContent:'space-between'}} spacing={1} alignItems="right">
							<Grid item>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<CustomerOrdersIndex
					reloadKey={reloadKey}
					setReloadKey={setReloadKey}
					userId={params.userId}
				/>

			</Paper>
		</>
	);
}