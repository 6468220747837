import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReceiptLong from '@mui/icons-material/ReceiptLong';
import RestaurantMenu from '@mui/icons-material/RestaurantMenu';
import Fastfood from '@mui/icons-material/Fastfood';
import Sell from '@mui/icons-material/Sell';
import Group from '@mui/icons-material/Group';
import Extension from '@mui/icons-material/Extension';
import Egg from '@mui/icons-material/Egg';
import SettingsIcon from '@mui/icons-material/Settings';
import Insights from '@mui/icons-material/Insights';
import { NavLink } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import Stack from '@mui/material/Stack';
import StorefrontIcon from '@mui/icons-material/Storefront';
import whiteLogo from '../assets/logos/Logo-White.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PolicyIcon from '@mui/icons-material/Policy';
import GavelIcon from '@mui/icons-material/Gavel';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import CachedIcon from '@mui/icons-material/Cached';
import Currency from 'react-currency-formatter';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ForestIcon from '@mui/icons-material/Forest';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PasswordIcon from '@mui/icons-material/Password';
import StoreIcon from '@mui/icons-material/Store';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import Badge from '@mui/material/Badge';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import EuroIcon from '@mui/icons-material/Euro';
import InboxIcon from '@mui/icons-material/Inbox';
import newOrderSound from '../assets/new-order.mp3';
import {Link, useLocation} from 'react-router-dom';
import { Money } from '@mui/icons-material';
import Euro from '@mui/icons-material/Euro';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ApprovalIcon from '@mui/icons-material/Approval';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import ContactlessIcon from '@mui/icons-material/Contactless';
import PsychologyIcon from '@mui/icons-material/Psychology';

import axios from 'axios';

import './Navigation.scss'

const item = {
	py: '2px',
	px: 3,
	color: 'rgba(255, 255, 255, 0.7)',
	'&:hover, &:focus': {
		bgcolor: 'rgba(255, 255, 255, 0.08)',
	},
};

const itemCategory = {
	boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
	py: 1.5,
	px: 3,
};

export default function Navigator(props) {
	const location = useLocation();
	const { ...other } = props;
	const [categoryItems, setCategoryItems] = React.useState([
		{
			id: 'Restaurant',
			children: [
				{
					id: 'Mein Dashboard',
					icon: <ReceiptLong />,
					active: true,
					path: '/',
					badge: '1'
				},
				{ id: 'Speisekarte', icon: <Fastfood />, path: '/products' },
				{ id: 'Aktionen', icon: <Sell />, path: '/promotions' },
				{ id: 'Extra Zutaten', icon: <Extension />, path: '/ingredients' },
				{ id: 'Kundenübersicht', icon: <Group />, path: '/customers' },
			],
		},
		{
			id: 'Sonstige Einstellungen',
			children: [
				{ id: 'Konfiguration', icon: <SettingsIcon />, path: '/settings' },
				{ id: 'Statistiken & Analyse', icon: <Insights />, path: '/cost-and-stats' },
				{ id: 'Finanz-Center', icon: <Euro />, path: '/finance-center' },
				{ id: 'Postfach', icon: <InboxIcon />, path: '/inbox' },
				{ id: 'Kuriere', icon: <DeliveryDiningIcon />, path: '/couriers' },
				{ id: 'Terminals', icon: <ContactlessIcon />, path: '/terminals' },
				{ id: 'DeliverOne Assist', icon: <PsychologyIcon />, path: '/deliverone-assist', beta: true }
			],
		}
	])
	
	const [userData, setUserData] = React.useState([])
	const [activeOrdersCounter, setActiveOrdersCounter] = React.useState(0)
	const [totalSalesToday, setTotalSalesToday] = React.useState(0)
	const [pendingOrdersCounter, setPendingOrdersCounter] = React.useState(0)
	const [isPlayingSound, setIsPlayingSound] = React.useState(false)
	const [isElectron, setIsElectron] = React.useState(true)

	React.useEffect(() => {
		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
			setUserData(userData);
		}

		var userAgent = navigator.userAgent.toLowerCase();
		if (userAgent.indexOf(' electron/') === -1) {
			setIsElectron(false);
		}

		if (userData.role_name === "admin") {
			// Add Category to menu
			setCategoryItems([...categoryItems, {
				id: 'Rechnungsmanagement',
				children: [
					{ id: 'Offene Genehmigungsvorgänge', icon: <ApprovalIcon />, path: '/administrate/invoice-management' },
					{ id: 'Rechnungshistorie', icon: <WorkHistoryIcon />, path: '/administrate/invoice-management/history' },
					{ id: 'Zukünftige RE bearbeiten', icon: <DriveFileRenameOutlineIcon />, path: '/administrate/invoice-management/editor' }
				]
			},{
				id: 'Vertragsmanagement',
				children: [
					{ id: 'Vertragsübersicht', icon: <ReceiptLongIcon />, path: '/administrate/contract-management' }
				]
			},{
				id: 'DeliverOne Banking',
				children: [
					{ id: 'Banking Sitzung öffnen', icon: <AccountBalanceIcon />, path: '/administrate/banking' },
					{ id: 'A-Konten Eigen', icon: <AccountBalanceIcon />, path: '/administrate/banking/a-accounts' },
					{ id: 'B-Konten Fremd', icon: <AccountBalanceIcon />, path: '/administrate/banking/b-accounts' },
					{ id: 'Offene Buchungsvorgänge', icon: <AccountBalanceIcon />, path: '/administrate/banking/pending-transfers' },
				]
			},{
				id: 'Stripe',
				children: [
					{ id: 'Connect-Konten', icon: <AccountBalanceIcon />, path: '/administrate/stripe-connect-accounts' },
					{ id: 'Terminal Zahlungen', icon: <ContactlessIcon />, path: '/terminals/payments' }
				]
			}, {
				id: 'Administration',
				children: [
					{ id: 'Menü-Kategorien', icon: <RestaurantMenu />, path: '/menu' },
					{ id: 'Allergene', icon: <Egg />, path: '/allergens' },  
					{ id: 'Restaurants', icon: <StorefrontIcon />, path: '/restaurants' },
					{ id: 'Cacheverwaltung', icon: <CachedIcon />, path: '/administrate/cache-management' },
					{ id: 'Gutscheine', icon: <LoyaltyIcon />, path: '/administrate/coupons' },
					{ id: 'Spenden', icon: <ForestIcon />, path: '/administrate/donations' },
					{ id: 'Aktuelle Aufgaben', icon: <AssignmentTurnedInIcon />, path: '/administrate/current-jobs' },
					{ id: 'Fehlerhafte Aufgaben', icon: <AssignmentLateIcon />, path: '/administrate/failed-jobs' },
					{ id: 'Bestellungs Transaktionen', icon: <AccountBalanceIcon />, path: '/administrate/bank-transactions' },
					{ id: 'Bezahlte Transaktionen', icon: <ReceiptIcon />, path: '/administrate/paid-charges' },
					{ id: 'Buchungslauf Ausz.', icon: <EuroIcon />, path: '/administrate/payouts' },
					{ id: 'Passwort Zurücksetzungen', icon: <PasswordIcon />, path: '/administrate/password-resets' },
					{ id: 'Restaurant Parameter', icon: <StoreIcon />, path: '/administrate/restaurant-parameter' },
					{ id: 'Liefergebiete', icon: <TravelExploreIcon />, path: '/administrate/delivery-areas' },
					{ id: 'DeliverOne Rewards Transakt.', icon: <MilitaryTechIcon />, path: '/administrate/deliverone-rewards-transactions' },
					{ id: 'Push Benachrichtigungen', icon: <NotificationsNoneIcon />, path: '/administrate/push-notifications' }
				]
			}])
		}

		let dateToday = new Date();
		dateToday = dateToday.getFullYear() + "-" + (dateToday.getMonth() + 1) + "-" + dateToday.getDate()

		// Get active orders every 5 seconds, prevent too many requests
		const interval = setInterval(() => {
			// Get active orders, run only if there is no other request running
			axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/orders?date=` + dateToday)
				.then((res) => {
					if (res.status === 200 && res.data) {

						let tmpOrders = res.data;

						// Remove orders with order_delivery_address_name = "Manuelle Bezahlung"
						tmpOrders = tmpOrders.filter(order => order.order_delivery_address_name !== "Manuelle Bezahlung");		

						let totalSalesTodayTmp = 0;

						// Loop through orders
						tmpOrders.forEach((order) => {
							totalSalesTodayTmp += order.order_total_sum;
						})

						setTotalSalesToday(totalSalesTodayTmp)

						// Get only orders with status pending or in_progress
						let activeOrders = tmpOrders.filter((order) => {
							return order.order_status === "pending" || order.order_status === "in_progress"
						})
						
						if (activeOrders.length !== undefined) {
							setActiveOrdersCounter(activeOrders.length)
						}

						// Get only orders with status pending or in_progress
						let pendingOrders = tmpOrders.filter((order) => {
							return order.order_status === "pending"
						})
						
						if (pendingOrders.length !== undefined) {
							setPendingOrdersCounter(pendingOrders.length)

							if (pendingOrders.length > 0) {
								// Play sound but only once per order (prevent multiple sounds)
								if (!isPlayingSound) {
									if (location.pathname !== "/") {
										setIsPlayingSound(true);
										let sound = new Audio(newOrderSound);
										sound.play();
										setTimeout(() => {
											setIsPlayingSound(false);
										}, 3000);
									}
								}
							}
						}

					}
				})
		}, 6000);

		return () => clearInterval(interval);

	}, [])

	return (
		<Drawer variant="permanent" {...other}>
			<List disablePadding>
				{
					isElectron === false && (
						<ListItem sx={{ ...item, ...itemCategory, fontSize: 13, color: '#fff' }} style={{
							background: '#ef5350',
							fontSize: '13px',
							padding: '10px 24px',
							color: 'white'
						}}>
							Du verwendest einen nicht unterstützten Browser. Bitte verwende den DeliveOne Desktop Client!
						</ListItem>
					)
				}
				<ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
					<Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" style={{width:'100%'}}>
						<img style={{
							height: '30px'
						}} src={whiteLogo} alt="DeliveOne Logo" />

						<IconButton onClick={() => {
							localStorage.clear();
							window.location.href = "/login?logout=true";
						}} aria-label="delete">
							<LogoutIcon color="primary" />
						</IconButton>
					</Stack>
				</ListItem>
				<ListItem title={userData ? userData.email : '...'} sx={{ ...item, ...itemCategory, fontSize: 16, color: '#fff' }}>
					Hallo, {userData ? userData.name : '...'}!
				</ListItem>
				<ListItem title={userData ? userData.email : '...'} sx={{ ...item, ...itemCategory, fontSize: 16, color: '#fff' }}>
					Aktive Bestellungen: {activeOrdersCounter}
				</ListItem>
				<ListItem title={userData ? userData.email : '...'} sx={{ ...item, ...itemCategory, fontSize: 16, color: '#fff' }}>
					Umsatz heute: <Currency quantity={parseFloat(totalSalesToday)} currency="EUR" />
				</ListItem>
				{userData && (
					categoryItems.map(({ id, children }) => (
						<Box key={id} sx={{ bgcolor: '#101F33' }}>
							<ListItem sx={{ py: 2, px: 3 }}>
								<ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
							</ListItem>
							{children.map(({ id: childId, icon, active, path, badge, beta }) => (
								!badge ? (
									<ListItem disablePadding key={childId}>
										<ListItemButton
											exact
											component={NavLink}
											sx={item}
											className="Navigation-Item-Button"
											to={path.includes('http') ? {pathname: path} : `${path}`}
											target={path.includes('http') ? '_blank' : ''}
										>
											<ListItemIcon>{icon}</ListItemIcon>
											<ListItemText>{childId}</ListItemText>
											{
												beta && (
													<Badge color="primary" badgeContent="BETA" sx={{
														marginRight: '10px'
													}} />
												)
											}
										</ListItemButton>
									</ListItem>
									) : (
									<ListItem key={childId} disablePadding>
										<ListItemButton
											exact
											component={NavLink}
											sx={item}
											className="Navigation-Item-Button"
											to={path.includes('http') ? {pathname: path} : `${path}`}
											target={path.includes('http') ? '_blank' : ''}
										>
											<Badge component={ListItemIcon} color="primary" badgeContent={activeOrdersCounter}>{icon}</Badge>
											<ListItemText>{childId}</ListItemText>
										</ListItemButton>
									</ListItem>
								)
							))}
	
							<Divider sx={{ mt: 2 }} />
						</Box>
					))
				)}

			</List>
			{pendingOrdersCounter > 0 && (
				<Link to="/" className="DrawerPendingOrder">
					<span>Neue Bestellung!</span>
				</Link>
			)}
		</Drawer>
	);
}