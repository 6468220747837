import React, {useEffect, useState} from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar'
import { Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField'
import AssessmentIcon from '@mui/icons-material/Assessment';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AppsIcon from '@mui/icons-material/Apps';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Currency from 'react-currency-formatter';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import './style.scss';

export default function PageFinanceCenter() {

	const [reloadKey, setReloadKey] = useState(Math.random());
	const [isLoading, setIsLoading] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);
	const [restaurants, setRestaurants] = useState([]);
	const [restaurantId, setRestaurantId] = useState(0);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [showModalYearlyReport, setShowModalYearlyReport] = useState(false);
	const [yearlyReportYear, setYearlyReportYear] = useState(new Date().getFullYear() - 1);
	const [invoiceMonth, setInvoiceMonth] = useState(new Date().getMonth());
	const [invoiceYear, setInvoiceYear] = useState(new Date().getFullYear());

	const [monthlyFee, setMonthlyFee] = useState(0);
	const [nextPaymentDate, setNextPaymentDate] = useState(0);
	const [openBalances, setOpenBalances] = useState(0);
	const [ordersOfMonth, setOrdersOfMonth] = useState([]);
	const [ordersOfYear, setOrdersOfYear] = useState([]);
	const [countRestaurants, setCountRestaurants] = useState(0);
	const [countUsers, setCountUsers] = useState(0);
	const [effectiveOrderFee, setEffectiveOrderFee] = useState(0);
	const [availableBalance, setAvailableBalance] = useState(0);
	const [pendingBalance, setPendingBalance] = useState(0);
	const [salesForecast, setSalesForecast] = useState(0);

	useEffect(() => {

		document.title = 'Finanz-Center — ' + process.env.REACT_APP_APPLICATION_NAME;
	
		setIsLoading(true)
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/financial`)
			.then(res => {
				
				if (res.data.overview) {
					setMonthlyFee(res.data.overview["monthlyFee"]);
					setNextPaymentDate(res.data.overview["nextPaymentDate"]);

					setOpenBalances(res.data.open_balances);
					
					setOrdersOfMonth(res.data.stats["orders_of_month"]);
					setOrdersOfYear(res.data.stats["orders_of_year"]);
					setSalesForecast(res.data.stats["sales_forecast"]);

					let effectiveFee = parseFloat(res.data.overview["monthlyFee"]) / parseFloat(res.data.stats["orders_of_month"].totalAmount) * 100;
					setEffectiveOrderFee(effectiveFee);

					setCountRestaurants(res.data.stats["count_restaurants"]);
					setCountUsers(res.data.stats["count_users"]);

					setAvailableBalance(res.data.stats["available_balance"]);
					setPendingBalance(res.data.stats["pending_balance"]);
				}

				axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
				.then((res) => {
					if (res.data) {
						setRestaurants(res.data);
					}
					setIsLoading(false);
				})
				.catch((err) => {})

			})

		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
		}

		if (userData.role_name === "admin") {
			setIsAdmin(true);
		}
		
	}, [reloadKey])

	const truncateText = (text, length) => {
		if (text.length <= length) {
			return text;
		}
		
		return text.substr(0, length) + '\u2026'
	}

	const createYearlyReport = (restaurantId) => {
		setShowModalYearlyReport(false);
		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/finance-center/getYearlyReport/` + (yearlyReportYear) + "/" + restaurantId, {
			responseType: 'blob'
		})
			.then(res => {
				var url = window.URL.createObjectURL(res.data)
				var a = document.createElement('a')
				a.href = url
				a.download = 'Jahresabschluss_' + (new Date().getFullYear() - 1) + '.pdf'
				a.click()
				a.remove()
				setTimeout(() => window.URL.revokeObjectURL(url), 100)

				setSuccessSnackbarText("Jahresabschluss wurde erfolgreich heruntergeladen!")
				setShowSuccessSnackbar(true)
				setIsLoading(false)
			})
	}

	const createYearlyReportDeliverOne = () => {
		createYearlyReport(0);
	}

	const createYearlyReportRestaurant = (restaurantId) => {

		if (restaurantId === 0) {
		
			setSuccessSnackbarText("Bitte wähle ein Restaurant aus!")
			setShowSuccessSnackbar(true)
			return;

		}

		createYearlyReport(restaurantId);
	}

	const createInvoice = () => {
		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/generateInvoice/${restaurantId}/${invoiceMonth}/${invoiceYear}`)
			.then(res => {
				setSuccessSnackbarText("Rechnung wurde an dich per E-Mail zugeschickt!")
				setShowSuccessSnackbar(true)
				setIsLoading(false)
			})
	}

	const invoicePreview = () => {
		

		setIsLoading(true)

		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
		}

		if (userData.restaurant_access_id !== "0") {
			axios.get(`${process.env.REACT_APP_API_URL}/api/backend/financial/getInvoicePreview/` + userData.restaurant_access_id, {
				responseType: 'blob'
			})
				.then(res => {
					window.open(URL.createObjectURL(res.data), "invoicepreview");
					setIsLoading(false)
				})
		}

	}

	const loadOrdersList = () => {
		setIsLoading(true)

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/financial/getOrdersList`, {
			responseType: 'blob'
		})
			.then(res => {

				var url = window.URL.createObjectURL(res.data)
				var a = document.createElement('a')
				a.href = url
				a.download = 'download.pdf'
				a.click()
				a.remove()
				setTimeout(() => window.URL.revokeObjectURL(url), 100)

				setSuccessSnackbarText("Bestellungs-Liste wurde erfolgreich heruntergeladen!")
				setShowSuccessSnackbar(true)
				setIsLoading(false)
			})
	}

	return (
		<>
			{isAdmin ? (
				isLoading ? (
					<div style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100px'
					}}><CircularProgress disableShrink /></div>
				) : (
					<>
						<Box sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto'}}>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="Jahresabschluss-Gesamthaus-content"
									id="Jahresabschluss-Gesamthaus-header"
								>
									<AssessmentIcon style={{marginRight:'10px'}} />
									<Typography>Jahresabschluss Gesamtunternehmen</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography>
										Der Jahresabschluss Gesamtunternehmen beinhaltet alle Daten aller Restaurants. Dieser Vorgang kann einige Minuten dauern.
									</Typography>
									<br />

									<FormControl margin="normal" fullWidth size='small' variant="outlined">
										<InputLabel id="report-year-label">Jahr</InputLabel>
										<Select
											labelId="report-year-label"
											id="report-year"
											label="Jahr"
											value={yearlyReportYear}
											onChange={(event) => {
												setYearlyReportYear(event.target.value)
											}}
										>
											<MenuItem value={2022}>2022</MenuItem>
											<MenuItem value={2023}>2023</MenuItem>
											<MenuItem value={2024}>2024</MenuItem>
											<MenuItem value={2025}>2025</MenuItem>
											<MenuItem value={2026}>2026</MenuItem>
											<MenuItem value={2027}>2027</MenuItem>
											<MenuItem value={2028}>2028</MenuItem>
											<MenuItem value={2029}>2029</MenuItem>
											<MenuItem value={2030}>2030</MenuItem>
											<MenuItem value={2031}>2031</MenuItem>
											<MenuItem value={2032}>2032</MenuItem>
											<MenuItem value={2033}>2033</MenuItem>
											<MenuItem value={2034}>2034</MenuItem>
											<MenuItem value={2035}>2035</MenuItem>
											<MenuItem value={2036}>2036</MenuItem>
											<MenuItem value={2037}>2037</MenuItem>
											<MenuItem value={2038}>2038</MenuItem>
											<MenuItem value={2039}>2039</MenuItem>
											<MenuItem value={2040}>2040</MenuItem>
											<MenuItem value={2041}>2041</MenuItem>
											<MenuItem value={2042}>2042</MenuItem>
											<MenuItem value={2043}>2043</MenuItem>
											<MenuItem value={2044}>2044</MenuItem>
											<MenuItem value={2045}>2045</MenuItem>
											<MenuItem value={2046}>2046</MenuItem>
											<MenuItem value={2047}>2047</MenuItem>
											<MenuItem value={2048}>2048</MenuItem>
											<MenuItem value={2049}>2049</MenuItem>
											<MenuItem value={2050}>2050</MenuItem>
										</Select>
									</FormControl>

									<br />

									<Button variant="contained" fullWidth onClick={createYearlyReportDeliverOne}>Jahresabschluss Gesamtunternehmen erstellen</Button>
								</AccordionDetails>
							</Accordion>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="Jahresabschluss-Restaurantspezifisch-content"
									id="Jahresabschluss-Restaurantspezifisch-header"
								>
									<AnalyticsIcon style={{marginRight:'10px'}} />
									<Typography>Jahresabschluss Restaurantspezifisch</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography>
										Der Jahresabschluss Restaurantspezifisch beinhaltet alle Daten eines Restaurants. Dieser Vorgang kann einige Minuten dauern.
									</Typography>
									<br />

									<FormControl margin="normal" fullWidth size='small' variant="outlined">
										<InputLabel id="restaurant-label">Restaurant auswählen</InputLabel>
										<Select
											labelId="restaurant-label"
											id="restaurant"
											label="Restaurant auswählen"
											value={restaurantId}
											onChange={(event) => {
												setRestaurantId(event.target.value)
											}}				  
										>
											{restaurants.map((restaurant, index) => {
												return <MenuItem value={restaurant.id} key={index}>{restaurant.restaurant_name}&nbsp;<i style={{color:'#91a5b4'}}>— {truncateText((restaurant.restaurant_description != null ? restaurant.restaurant_description : ""), 20)} ({restaurant.restaurant_address_city})</i></MenuItem>
											})}
										</Select>
									</FormControl>

									<FormControl margin="normal" fullWidth size='small' variant="outlined">
										<InputLabel id="report-year-label">Jahr</InputLabel>
										<Select
											labelId="report-year-label"
											id="report-year"
											label="Jahr"
											value={yearlyReportYear}
											onChange={(event) => {
												setYearlyReportYear(event.target.value)
											}}
										>
											<MenuItem value={2022}>2022</MenuItem>
											<MenuItem value={2023}>2023</MenuItem>
											<MenuItem value={2024}>2024</MenuItem>
											<MenuItem value={2025}>2025</MenuItem>
											<MenuItem value={2026}>2026</MenuItem>
											<MenuItem value={2027}>2027</MenuItem>
											<MenuItem value={2028}>2028</MenuItem>
											<MenuItem value={2029}>2029</MenuItem>
											<MenuItem value={2030}>2030</MenuItem>
											<MenuItem value={2031}>2031</MenuItem>
											<MenuItem value={2032}>2032</MenuItem>
											<MenuItem value={2033}>2033</MenuItem>
											<MenuItem value={2034}>2034</MenuItem>
											<MenuItem value={2035}>2035</MenuItem>
											<MenuItem value={2036}>2036</MenuItem>
											<MenuItem value={2037}>2037</MenuItem>
											<MenuItem value={2038}>2038</MenuItem>
											<MenuItem value={2039}>2039</MenuItem>
											<MenuItem value={2040}>2040</MenuItem>
											<MenuItem value={2041}>2041</MenuItem>
											<MenuItem value={2042}>2042</MenuItem>
											<MenuItem value={2043}>2043</MenuItem>
											<MenuItem value={2044}>2044</MenuItem>
											<MenuItem value={2045}>2045</MenuItem>
											<MenuItem value={2046}>2046</MenuItem>
											<MenuItem value={2047}>2047</MenuItem>
											<MenuItem value={2048}>2048</MenuItem>
											<MenuItem value={2049}>2049</MenuItem>
											<MenuItem value={2050}>2050</MenuItem>
										</Select>
									</FormControl>

									<br />

									<Button variant="contained" fullWidth onClick={() => {createYearlyReportRestaurant(restaurantId)}}>Jahresabschluss Restaurant erstellen</Button>
								</AccordionDetails>
							</Accordion>
							<Accordion expanded={false} onChange={(e) => {
								window.open("https://sales-x.deliverone.de", "SALESX");
								e.stopPropagation();
								e.preventDefault();
								return;
							}}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="SalesX-content"
									id="SalesX-header"
								>
									<AppsIcon style={{marginRight:'10px'}}/>
									<Typography>SalesX starten</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
										malesuada lacus ex, sit amet blandit leo lobortis eget.
									</Typography>
								</AccordionDetails>
							</Accordion>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="Rechnungserstellung-content"
									id="Rechnungserstellung-header"
								>
									<ReceiptIcon style={{marginRight:'10px'}} />
									<Typography>Rechnungserstellung</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography>
										Erstelle eine Rechnung für ein ausgewähltes Restaurant. Rechnungen werden über SalesX erstellt.
									</Typography>
									<br />

									<FormControl margin="normal" fullWidth size='small' variant="outlined">
										<InputLabel id="restaurant-label">Restaurant auswählen</InputLabel>
										<Select
											labelId="restaurant-label"
											id="restaurant"
											label="Restaurant auswählen"
											value={restaurantId}
											onChange={(event) => {
												setRestaurantId(event.target.value)
											}}				  
										>
											{restaurants.map((restaurant, index) => {
												return <MenuItem value={restaurant.id} key={index}>{restaurant.restaurant_name}&nbsp;<i style={{color:'#91a5b4'}}>— {truncateText((restaurant.restaurant_description != null ? restaurant.restaurant_description : ""), 20)} ({restaurant.restaurant_address_city})</i></MenuItem>
											})}
										</Select>
									</FormControl>

									<FormControl margin="normal" fullWidth size='small' variant="outlined">
										<InputLabel id="invoice-month-label">Monat</InputLabel>
										<Select
											labelId="invoice-month-label"
											id="invoice-month"
											label="Monat"
											value={invoiceMonth}
											onChange={(event) => {
												setInvoiceMonth(event.target.value)
											}}
										>
											<MenuItem value={1}>Januar</MenuItem>
											<MenuItem value={2}>Februar</MenuItem>
											<MenuItem value={3}>März</MenuItem>
											<MenuItem value={4}>April</MenuItem>
											<MenuItem value={5}>Mai</MenuItem>
											<MenuItem value={6}>Juni</MenuItem>
											<MenuItem value={7}>Juli</MenuItem>
											<MenuItem value={8}>August</MenuItem>
											<MenuItem value={9}>September</MenuItem>
											<MenuItem value={10}>Oktober</MenuItem>
											<MenuItem value={11}>November</MenuItem>
											<MenuItem value={12}>Dezember</MenuItem>
										</Select>
									</FormControl>

									<FormControl margin="normal" fullWidth size='small' variant="outlined">
										<InputLabel id="invoice-year-label">Jahr</InputLabel>
										<Select
											labelId="invoice-year-label"
											id="invoice-year"
											label="Jahr"
											value={invoiceYear}
											onChange={(event) => {
												setInvoiceYear(event.target.value)
											}}
										>
											<MenuItem value={2022}>2022</MenuItem>
											<MenuItem value={2023}>2023</MenuItem>
											<MenuItem value={2024}>2024</MenuItem>
											<MenuItem value={2025}>2025</MenuItem>
											<MenuItem value={2026}>2026</MenuItem>
											<MenuItem value={2027}>2027</MenuItem>
											<MenuItem value={2028}>2028</MenuItem>
											<MenuItem value={2029}>2029</MenuItem>
											<MenuItem value={2030}>2030</MenuItem>
											<MenuItem value={2031}>2031</MenuItem>
											<MenuItem value={2032}>2032</MenuItem>
											<MenuItem value={2033}>2033</MenuItem>
											<MenuItem value={2034}>2034</MenuItem>
											<MenuItem value={2035}>2035</MenuItem>
											<MenuItem value={2036}>2036</MenuItem>
											<MenuItem value={2037}>2037</MenuItem>
											<MenuItem value={2038}>2038</MenuItem>
											<MenuItem value={2039}>2039</MenuItem>
											<MenuItem value={2040}>2040</MenuItem>
											<MenuItem value={2041}>2041</MenuItem>
											<MenuItem value={2042}>2042</MenuItem>
											<MenuItem value={2043}>2043</MenuItem>
											<MenuItem value={2044}>2044</MenuItem>
											<MenuItem value={2045}>2045</MenuItem>
											<MenuItem value={2046}>2046</MenuItem>
											<MenuItem value={2047}>2047</MenuItem>
											<MenuItem value={2048}>2048</MenuItem>
											<MenuItem value={2049}>2049</MenuItem>
											<MenuItem value={2050}>2050</MenuItem>
										</Select>
									</FormControl>

									<br />

									<Button variant="contained" fullWidth onClick={createInvoice}>Rechnung erstellen</Button>
								</AccordionDetails>
							</Accordion>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="SonstigeAnalyse-content"
									id="SonstigeAnalyse-header"
								>
									<QueryStatsIcon style={{marginRight:'10px'}} />
									<Typography>Sonst. Analyse &amp; Statistik</Typography>
								</AccordionSummary>
								<AccordionDetails>
									
									<>
										<Grid
											container 
											spacing={2}
											style={{
												alignItems: "stretch"
											}}
										>
											<Grid item xs={12} lg={4}>
												<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
													<CardContent>
														<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
															Anstehende Rechnung
														</Typography>
														<Typography variant="h5" component="div">
															≈ <Currency
																quantity={parseFloat(monthlyFee)}
																currency="EUR"
															/>
														</Typography>
														<Typography sx={{ mb: 1.5 }} color="text.secondary">
															{nextPaymentDate > 1 ? `Anstehend in ${nextPaymentDate} Tagen` : ''}
															{nextPaymentDate === 1 ? `Morgen fällig` : ''}
															{nextPaymentDate === 0 ? `Heute fällig` : ''}
															{openBalances < 0 && (
																<>&nbsp;<i>
																	(Deine Gutschrift in Höhe von <Currency quantity={Math.abs(openBalances)} currency="EUR" /> wurde abgezogen.)
																</i></>
															)}
														</Typography>
													</CardContent>
													<CardActions>
														<Button onClick={invoicePreview} size="small">Voransicht</Button>
													</CardActions>
												</Card>
											</Grid>
											{openBalances > 0 && (
												<Grid item xs={12} lg={4}>
													<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
														<CardContent>
															<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
																Offene Rechnung
															</Typography>
															<Typography variant="h5" component="div">
																<Currency
																	quantity={parseFloat(openBalances)}
																	currency="EUR"
																/>
															</Typography>
															<Typography sx={{ mb: 1.5 }} color="text.secondary">
																Überfällig!
															</Typography>
														</CardContent>
													</Card>
												</Grid>
											)}
											<Grid item xs={12} lg={4}>
												<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
													<CardContent>
														<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
															Bestellungen diesen Monat
														</Typography>
														<Typography variant="h5" component="div">
															{ordersOfMonth.ordersCount} (<Currency currency='EUR' quantity={ordersOfMonth.totalAmount} />)
														</Typography>
														<Typography sx={{ mb: 1.5 }} color="text.secondary">&nbsp;</Typography>
													</CardContent>
													<CardActions>
														<Button href="/" size="small">Übersicht</Button>
													</CardActions>
												</Card>
											</Grid>
											<Grid item xs={12} lg={4}>
												<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
													<CardContent>
														<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
															Bestellungen dieses Jahr
														</Typography>
														<Typography variant="h5" component="div">
															{ordersOfYear.ordersCount} (<Currency currency='EUR' quantity={ordersOfYear.totalAmount} />)
														</Typography>
														<Typography sx={{ mb: 1.5 }} color="text.secondary">&nbsp;</Typography>
													</CardContent>
													<CardActions>
														<Button href="/" size="small">Übersicht</Button>
													</CardActions>
												</Card>
											</Grid>
											{isAdmin && (
												<>
													<Grid item xs={12} lg={4}>
														<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
															<CardContent>
																<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
																	Sales-Forecast
																</Typography>
																<Typography variant="h5" component="div">
																	<Currency currency='EUR' quantity={salesForecast} />
																</Typography>
																<Typography sx={{ mb: 1.5 }} color="text.secondary">&nbsp;</Typography>
															</CardContent>
															<CardActions>
																<Button href="/restaurants" size="small">Übersicht</Button>
															</CardActions>
														</Card>
													</Grid>
													<Grid item xs={12} lg={4}>
														<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
															<CardContent>
																<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
																	Anzahl Restaurants
																</Typography>
																<Typography variant="h5" component="div">
																	{countRestaurants}
																</Typography>
																<Typography sx={{ mb: 1.5 }} color="text.secondary">&nbsp;</Typography>
															</CardContent>
															<CardActions>
																<Button href="/restaurants" size="small">Übersicht</Button>
															</CardActions>
														</Card>
													</Grid>
												</>
											)}
											<Grid item xs={12} lg={4}>
												<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
													<CardContent>
														<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
															Anzahl Kunden
														</Typography>
														<Typography variant="h5" component="div">
															{countUsers}
														</Typography>
														<Typography sx={{ mb: 1.5 }} color="text.secondary">&nbsp;</Typography>
													</CardContent>
													<CardActions>
														<Button href="/customers" size="small">Übersicht</Button>
													</CardActions>
												</Card>
											</Grid>
										</Grid>
									</>

								</AccordionDetails>
							</Accordion>
						</Box>
					</>
				)
			) : (
				<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
					<AppBar
						position="static"
						color="default"
						elevation={0}
						sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
					>
						<Toolbar className="PageMenu-AppBar">
							<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
								<Grid item>
									<Button onClick={loadOrdersList} variant="contained" sx={{ mr: 1 }}>
										Bestellübersicht
									</Button>
									<Tooltip title="Aktualisieren">
										<IconButton onClick={() => {setReloadKey(Math.random())}}>
											<RefreshIcon color="inherit" sx={{ display: 'block' }} />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Toolbar>
					</AppBar>

					<Box padding={2}>
						{isLoading ? (
							<div style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100px'
							}}><CircularProgress disableShrink /></div>
						) : (
							<>
								<Grid
									container 
									spacing={2}
									style={{
										alignItems: "stretch"
									}}
								>
									<Grid item xs={12} lg={4}>
										<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
											<CardContent>
												<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
													Anstehende Rechnung
												</Typography>
												<Typography variant="h5" component="div">
													≈ <Currency
														quantity={parseFloat(monthlyFee)}
														currency="EUR"
													/>
												</Typography>
												<Typography sx={{ mb: 1.5 }} color="text.secondary">
													{nextPaymentDate > 1 ? `Anstehend in ${nextPaymentDate} Tagen` : ''}
													{nextPaymentDate === 1 ? `Morgen fällig` : ''}
													{nextPaymentDate === 0 ? `Heute fällig` : ''}
													{openBalances < 0 && (
														<>&nbsp;<i>
															(Deine Gutschrift in Höhe von <Currency quantity={Math.abs(openBalances)} currency="EUR" /> wurde abgezogen.)
														</i></>
													)}
												</Typography>
											</CardContent>
											<CardActions>
												<Button onClick={invoicePreview} size="small">Voransicht</Button>
											</CardActions>
										</Card>
									</Grid>
									{openBalances > 0 && (
										<Grid item xs={12} lg={4}>
											<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
												<CardContent>
													<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
														Offene Rechnung
													</Typography>
													<Typography variant="h5" component="div">
														<Currency
															quantity={parseFloat(openBalances)}
															currency="EUR"
														/>
													</Typography>
													<Typography sx={{ mb: 1.5 }} color="text.secondary">
														Überfällig!
													</Typography>
												</CardContent>
											</Card>
										</Grid>
									)}
									<Grid item xs={12} lg={4}>
										<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
											<CardContent>
												<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
													Bestellungen diesen Monat
												</Typography>
												<Typography variant="h5" component="div">
													{ordersOfMonth.ordersCount} (<Currency currency='EUR' quantity={ordersOfMonth.totalAmount} />)
												</Typography>
												<Typography sx={{ mb: 1.5 }} color="text.secondary"><i>Effektive Provision: {effectiveOrderFee.toFixed(3).replace(".",",")} %</i></Typography>
											</CardContent>
											<CardActions>
												<Button href="/" size="small">Übersicht</Button>
											</CardActions>
										</Card>
									</Grid>
									<Grid item xs={12} lg={4}>
										<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
											<CardContent>
												<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
													Bestellungen bisher gesamt
												</Typography>
												<Typography variant="h5" component="div">
													{ordersOfYear.ordersCount} (<Currency currency='EUR' quantity={ordersOfYear.totalAmount} />)
												</Typography>
											</CardContent>
											<CardActions>
												<Button href="/" size="small">Übersicht</Button>
											</CardActions>
										</Card>
									</Grid>
									{isAdmin && (
										<>
											<Grid item xs={12} lg={4}>
												<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
													<CardContent>
														<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
															Anzahl Restaurants
														</Typography>
														<Typography variant="h5" component="div">
															{countRestaurants}
														</Typography>
														<Typography sx={{ mb: 1.5 }} color="text.secondary">&nbsp;</Typography>
													</CardContent>
													<CardActions>
														<Button href="/restaurants" size="small">Übersicht</Button>
													</CardActions>
												</Card>
											</Grid>
										</>
									)}
									<Grid item xs={12} lg={4}>
										<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
											<CardContent>
												<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
													Anzahl Kunden (gesamt DeliverOne)
												</Typography>
												<Typography variant="h5" component="div">
													{countUsers}
												</Typography>
												<Typography sx={{ mb: 1.5 }} color="text.secondary">&nbsp;</Typography>
											</CardContent>
											<CardActions>
												<Button href="/customers" size="small">Übersicht</Button>
											</CardActions>
										</Card>
									</Grid>
									<Grid item xs={12} lg={4}>
										<Card variant="outlined" sx={{ minWidth: 275, height:'100%' }}>
											<CardContent>
												<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
													DeliverOne Payments Kontostand
												</Typography>
												<Typography variant="h5" component="div">
													<Currency currency='EUR' quantity={availableBalance} />
												</Typography>
												<Typography sx={{ mb: 1.5 }} color="text.secondary"><i>Vorgemerkt: <Currency currency='EUR' quantity={pendingBalance} /></i></Typography>
											</CardContent>
										</Card>
									</Grid>
								</Grid>
							</>
						)}
					</Box>
				</Paper>
			)}

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

			<Dialog
				open={showModalYearlyReport}
				onClose={() => {
					setShowModalYearlyReport(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title">
					Jahresabschluss
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Möchtest du wirklich einen Jahresabschluss erstellen?<br />
						<br />
						<b>Haftungsausschluss:</b><br />
						<i>
							DeliverOne (Inh. Berke Aras; Saliterstr. 56, 87616 Marktoberdorf), nachfolgend "wir" genannt, haftet nicht für den Inhalt des Jahresabschlusses. Wir sind nicht verantwortlich die korrekten Steuersätze zu erfassen. Du trägst die alleinige Verantwortung &amp; haftest in voller Höhe.
						</i>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {
						setShowModalYearlyReport(false);
					}}>Abbrechen</Button>
					<Button onClick={createYearlyReport} autoFocus>
						Ja, beantragen
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}