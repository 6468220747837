import React, { useState, useEffect } from "react";

import Box from '@mui/system/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../components/Loader';

import './style.scss';

function createData( id, name, phone, email, city, role, deleted, created_at, last_order_date, last_activity, rawDetails, restaurant_name) {
	return { id, name, phone, email, city, role, deleted, created_at, last_order_date, last_activity, rawDetails, restaurant_name };
}

function CouriersIndex(props) {

	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [tableRows, setTableRows] = useState([]);
	const [originalRows, setOriginalRows] = useState([]);
	let navigate = useHistory();

	useEffect(() => {

		setIsLoading(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/couriers`)
		.then((res) => {

			setIsLoading(false);
			
			if (res.status === 200 && res.data) {
				// Everything OK
				let temporaryTableRows = [];
				res.data.couriers.forEach((courier) => {
					temporaryTableRows.push(createData(courier.id, courier.name, courier.phone, courier.email, courier.city, courier.role_name, courier.deleted_at, courier.created_at, courier.last_order_date, courier.last_activity, courier, courier.restaurant_name));
				})
				setOriginalRows(temporaryTableRows);
				setTableRows(temporaryTableRows);
			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})
		
	}, [props.reloadKey])

	const handleError = (error) => {
		setShowErrorSnackbar(true)
		setIsLoading(false);
	}

	useEffect(() => {
		if (props.toolbarSearchQuery !== '') {
			// Search in the rows for name, category name, sku and price
			let filteredRows = originalRows.filter((row) => {
				return row.name.toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) ||
					row.name.toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) || 
					row.email.toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) || 
					row.phone.toString().toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) || 
					row.restaurant_name.toLowerCase().includes(props.toolbarSearchQuery.toLowerCase())
			})
			
			setTableRows(filteredRows);
		} else {
			setTableRows(originalRows);
		}
	}, [props.toolbarSearchQuery])

	return (
		<Box padding={2}>
			{isLoading ? (
				<Loader />
			) : (
				<TableContainer className="PageMenu-Table">
					<Table size="small" aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>#</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>E-Mail</TableCell>
								<TableCell>Telefon</TableCell>
								<TableCell>Restaurant</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableRows.map((row) => (
								<TableRow
									onClick={() => {props.setEditModalData(row); props.setEditModalOpen(true)}}
									key={row.id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell>{row.id}</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.email}</TableCell>
									<TableCell>{row.phone}</TableCell>
									<TableCell>{row.restaurant_name}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Box>
	)
}

export default CouriersIndex;