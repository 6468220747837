import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Drawer from '@mui/material/Drawer';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Autocomplete from '@mui/material/Autocomplete';
import NumberFormat from 'react-number-format';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Loader from '../../../components/Loader';

import './style.scss';

let IBAN = require('iban');

export default function PageAdministrateBankingBAccounts() {

	let navigate = useHistory();
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [restaurants, setRestaurants] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [status, setStatus] = useState("error"); // error, success
	const [accounts, setAccounts] = useState([]);
	const [showAccountDrawer, setShowAccountDrawer] = useState(false);
	const [selectedAccount, setSelectedAccount] = useState([]);
	const [showConnectModal, setShowConnectModal] = useState(false);

	const [newEntryRestaurantId, setNewEntryRestaurantId] = useState(0);
	const [newEntryType, setNewEntryType] = useState("personal"); // personal, business
	const [newEntryBusinessName, setNewEntryBusinessName] = useState("");
	const [newEntryFirstName, setNewEntryFirstName] = useState("");
	const [newEntryLastName, setNewEntryLastName] = useState("");
	const [newEntryBankCountry, setNewEntryBankCountry] = useState("DE");
	const [newEntryCurrency, setNewEntryCurrency] = useState("EUR");
	const [newEntryIban, setNewEntryIban] = useState("");
	const [newEntryBic, setNewEntryBic] = useState("");
	const [newEntryAddressStreetLine1, setNewEntryAddressStreetLine1] = useState("");
	const [newEntryAddressStreetLine2, setNewEntryAddressStreetLine2] = useState("");
	const [newEntryAddressCity, setNewEntryAddressCity] = useState("");
	const [newEntryAddressPostalCode, setNewEntryAddressPostalCode] = useState("");
	const [newEntryAddressCountry, setNewEntryAddressCountry] = useState("DE");
	
	useEffect(() => {
		document.title = 'B Konten Fremd 1.G — DeliverOne Banking — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);
		
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
		.then((res) => {
			if (res.data) {
				setRestaurants(res.data);
				
				if (localStorage.getItem('bankingAccessToken')) {
		
					axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/banking/b-accounts?accessToken=${localStorage.getItem('bankingAccessToken')}`).then((res) => {
						setIsLoading(false);
						if (res.data) {
		
							if (res.data.status) {
								if (res.data.status === "success") {
									
									
									setAccounts(res.data.accounts);
		
								} else {
									handleError();
									return;
								}
							}
		
							setStatus("success");
		
						} else {
							handleError();
						}
					}).catch((err) => {
						handleError();
					});
		
				} else {
					handleError();
				}
			}
		}).catch((err) => {
			handleError();
		});


	}, [reloadKey])

	const handleError = () => {
		setStatus("error");
		setIsLoading(false);
	}

	const showAccount = (account) => {
		setSelectedAccount(account);
		setShowAccountDrawer(true);
		
	}

	const connectWithDatabase = () => {

		// Ask the user with a dialog to which restaurant he wants to connect the account

		setShowConnectModal(true);

	}

	const createNewAccount = () => {

		setIsLoading(true);

		axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/banking/b-accounts/create`, {
			RestaurantId: newEntryRestaurantId,
			Type: newEntryType,
			BusinessName: newEntryBusinessName,
			FirstName: newEntryFirstName,
			LastName: newEntryLastName,
			BankCountry: newEntryBankCountry,
			Currency: newEntryCurrency,
			Iban: newEntryIban,
			Bic: newEntryBic,
			AddressStreetLine1: newEntryAddressStreetLine1,
			AddressStreetLine2: newEntryAddressStreetLine2,
			AddressCity: newEntryAddressCity,
			AddressPostalCode: newEntryAddressPostalCode,
			AddressCountry: newEntryAddressCountry,
			accessToken: localStorage.getItem('bankingAccessToken')
		}).then((res) => {
			setIsLoading(false);
			
			if (res.data) {
				if (res.data.status) {
					if (res.data.status === "success") {
						setShowConnectModal(false);
						setNewEntryRestaurantId(0);
						setNewEntryType("personal");
						setNewEntryBusinessName("");
						setNewEntryFirstName("");
						setNewEntryLastName("");
						setNewEntryBankCountry("DE");
						setNewEntryCurrency("EUR");
						setNewEntryIban("");
						setNewEntryBic("");
						setNewEntryAddressStreetLine1("");
						setNewEntryAddressStreetLine2("");
						setNewEntryAddressCity("");
						setNewEntryAddressPostalCode("");
						setNewEntryAddressCountry("DE");
						setReloadKey(Math.random());
					}
				}
			}
		}).catch((err) => {
			
			handleError();
		});

	}

	const truncateText = (text, length) => {
		if (text.length <= length) {
			return text;
		}
		
		return text.substr(0, length) + '\u2026'
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Button onClick={connectWithDatabase} variant="contained" sx={{ mr: 1 }}>
									Anlegen
								</Button>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>

				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						status === "error" ? (
							<center style={{margin: '60px 0'}}>
								<Typography style={{fontWeight:'bold'}} variant="h5" component="h5" gutterBottom>
									Ein Fehler ist aufgetreten!
								</Typography>
								<span>Ein Fehler ist aufgetreten. Bitte kontaktiere den Support: +49 8342 7050-752.</span>
							</center>
						) : (
							<TableContainer className="PageMenu-Table">
								<Table size="small" aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>DB-Satz</TableCell>
											<TableCell>PK/UK</TableCell>
											<TableCell>Name</TableCell>
											<TableCell>Land</TableCell>
											<TableCell>Bankkonten</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{accounts.map((row, index) => (
											<React.Fragment key={index}>
												<TableRow
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
													onClick={() => {
														showAccount(row);
													}}
												>
													<TableCell component="td" scope="row">
														<Chip
															label={row.in_database === true ? 'Ja' : 'Nein'}
															color={row.in_database === true ? 'success' : 'error'}
															size="small" 
														/>
													</TableCell>
													<TableCell component="td" scope="row">
														{row.profile_type === "personal" ? "PK" : "UK"}
													</TableCell>
													<TableCell component="td" scope="row">
														{row.name}
													</TableCell>
													<TableCell component="td" scope="row">
														{row.country || "N/A"}
													</TableCell>
													<TableCell component="td" scope="row">
														{row.accounts?.length || "0"}
													</TableCell>
												</TableRow>

												{row.accounts?.map((account, index) => (
													<TableRow
														sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
														key={index}
													>
														<TableCell>∟</TableCell>
														<TableCell component="td" scope="row" colSpan={2}>
															<b>IBAN: </b>{(account.iban).match(/.{1,4}/g).join(' ')}
														</TableCell>
														<TableCell component="td" scope="row">
															<b>BIC: </b>{account.bic}
														</TableCell>
														<TableCell component="td" scope="row">
															<b>Währung: </b>{account.currency}
														</TableCell>
													</TableRow>
												))}

											</React.Fragment>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)
					)}
				</Box>

			</Paper>

			<Drawer
				anchor={"right"}
				open={showAccountDrawer}
				onClose={() => {
					setShowAccountDrawer(false);
				}}

				PaperProps={{
					style: {
						width: "40%",
						backgroundColor: "white",
						padding: "20px",
					}
				}}

			>
				<h2 style={{marginTop:0}}>{selectedAccount.name}</h2>
				<br />

				{selectedAccount.in_database === false && (
					<Alert sx={{ overflow: 'hidden', marginBottom: '20px' }} variant="filled" severity="warning">
						Hinweis! Diese Person ist nicht in der DeliverOne-Datenbank gespeichert!
					</Alert>
				)}

				<table style={{border:'0'}}>
					<tr>
						<td>
							<span>IDENT-NR.:</span>
						</td>
						<td>
							<span>{selectedAccount.id}</span>
						</td>
					</tr>
					<tr>
						<td>
							<span>PROFIL-TYP:</span>
						</td>
						<td>
							<span>{selectedAccount.profile_type === "personal" ? "PK" : "UK"}</span>
						</td>
					</tr>
					<tr>
						<td>
							<span>LAND:</span>
						</td>
						<td>
							<span>{selectedAccount.country || "N/A"}</span>
						</td>
					</tr>
					<tr>
						<td>
							<span>TELEFON:</span>
						</td>
						<td>
							<span>{selectedAccount.phone || "N/A"}</span>
						</td>
					</tr>
				</table>

				{selectedAccount.accounts?.length > 0 && (

					<TableContainer component={Paper} style={{marginTop: '35px'}}>
						<Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>IBAN</TableCell>
									<TableCell>BIC</TableCell>
									<TableCell>Währung</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{selectedAccount.accounts.map((account, index) => (
									<TableRow
										key={index}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="td" scope="row">
											{(account.iban).match(/.{1,4}/g).join(' ')}
										</TableCell>
										<TableCell component="td" scope="row">
											{account.bic}
										</TableCell>
										<TableCell component="td" scope="row">
											{account.currency}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

				)}

				<div style={{
					marginTop: '35px',
				}}>
					{selectedAccount.in_database === false && (

						<Button style={{marginTop:'10px'}} onClick={connectWithDatabase} fullWidth variant="contained">System-Eintrag anlegen</Button>
						
					)}

					<Button style={{marginTop:'10px'}} fullWidth variant="contained">Bankkonto für B-Konto anlegen</Button>
				</div>

			</Drawer>

			<Dialog
				open={showConnectModal}
				onClose={() => {
					if (!isLoading) {
						setShowConnectModal(false)
					} else {
						return;
					}
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					System-Eintrag anlegen
				</DialogTitle>
				<DialogContent style={{padding: '20px'}}>
					<Stack spacing={3}>

						{isLoading ? (
							<Loader />
						) : (
							<>
								<FormControl size="small" label="Restaurant auswählen" fullWidth>
									<InputLabel id="user-type-label">Restaurant auswählen</InputLabel>
									<Select
										labelId="user-type-label"
										id="user-type"
										label="Restaurant auswählen"
										value={newEntryRestaurantId}
										required
										onChange={(event) => {
											setNewEntryRestaurantId(event.target.value)
										}}				  
									>
										{restaurants.map((restaurant, index) => {
											return <MenuItem value={restaurant.id} key={index}>{restaurant.restaurant_name}&nbsp;<i style={{color:'#91a5b4'}}>— {truncateText((restaurant.restaurant_description != null ? restaurant.restaurant_description : ""), 20)} ({restaurant.restaurant_address_city})</i></MenuItem>
										})}
									</Select>
								</FormControl>
		
								<FormControl size="small" label="Kundentyp auswählen (PK/UK)" fullWidth>
									<InputLabel id="newEntryType-label">Kundentyp auswählen (PK/UK)</InputLabel>
									<Select
										labelId="newEntryType-label"
										id="newEntryType"
										label="Kundentyp auswählen (PK/UK)"
										value={newEntryType}
										required
										onChange={(event) => {
											setNewEntryType(event.target.value)
											if (event.target.value === "personal") {
												setNewEntryBusinessName("")
											}
										}}				  
									>
										<MenuItem value="personal">PK Privatkunde</MenuItem>
										<MenuItem value="business">UK Unternehmenskunde</MenuItem>
									</Select>
								</FormControl>
		
								{newEntryType === "business" && (
									<TextField
										name="newEntryBusinessName-label"
										fullWidth
										size="small"
										label="Firmenname"
										required
										value={newEntryBusinessName}
										onChange={(event) => {
											setNewEntryBusinessName(event.target.value);
										}}
									/>
								)}
		
								<TextField
									name="newEntryFirstName-label"
									fullWidth
									size="small"
									label="Vorname"
									required
									value={newEntryFirstName}
									onChange={(event) => {
										setNewEntryFirstName(event.target.value);
									}}
								/>
		
								<TextField
									name="newEntryLastName-label"
									fullWidth
									size="small"
									label="Nachname"
									required
									value={newEntryLastName}
									onChange={(event) => {
										setNewEntryLastName(event.target.value);
									}}
								/>
		
								<TextField
									name="newEntryBankCountry-label"
									fullWidth
									size="small"
									label="Land der Bank (2-stellig, z.B. DE)"
									maxLength="2"
									required
									value={newEntryBankCountry}
									onChange={(event) => {
										setNewEntryBankCountry(event.target.value.toUpperCase());
									}}
								/>
		
								<TextField
									name="newEntryCurrency-label"
									fullWidth
									size="small"
									label="Währung (3-stellig, z.B. EUR)"
									required
									value={newEntryCurrency}
									onChange={(event) => {
										setNewEntryCurrency(event.target.value.toUpperCase());
									}}
								/>
		
								<TextField
									name="newEntryIban-label"
									fullWidth
									size="small"
									label="IBAN"
									required
									value={newEntryIban}
									onChange={(event) => {
										setNewEntryIban(event.target.value.toUpperCase());
		
										if (IBAN.isValid(event.target.value)) {
											axios.get('https://openiban.com/validate/' + event.target.value + '?getBIC=true')
												.then(res => {
													if (res.data) {
														if (res.data.bankData) {
															if (res.data.bankData.bic) {
																setNewEntryBic(res.data.bankData.bic)
															}
														}
													}
												})
										}
		
									}}
								/>						
		
								<TextField
									name="newEntryBic-label"
									fullWidth
									size="small"
									label="BIC"
									required
									value={newEntryBic}
									onChange={(event) => {
										setNewEntryBic(event.target.value.toUpperCase());
									}}
								/>
								
								<TextField
									name="newEntryAddressStreetLine1-label"
									fullWidth
									size="small"
									label="Adresszeile 1"
									required
									value={newEntryAddressStreetLine1}
									onChange={(event) => {
										setNewEntryAddressStreetLine1(event.target.value);
									}}
								/>
								
								<TextField
									name="newEntryAddressStreetLine2-label"
									fullWidth
									size="small"
									label="Adresszeile 2"
									value={newEntryAddressStreetLine2}
									onChange={(event) => {
										setNewEntryAddressStreetLine2(event.target.value);
									}}
								/>
								
								<TextField
									name="newEntryAddressCity-label"
									fullWidth
									size="small"
									label="Stadt"
									required
									value={newEntryAddressCity}
									onChange={(event) => {
										setNewEntryAddressCity(event.target.value);
									}}
								/>
								
								<TextField
									name="newEntryAddressPostalCode-label"
									fullWidth
									size="small"
									label="Postleitzahl"
									required
									value={newEntryAddressPostalCode}
									onChange={(event) => {
										setNewEntryAddressPostalCode(event.target.value);
									}}
								/>
								
								<TextField
									name="newEntryAddressCountry-label"
									fullWidth
									size="small"
									label="Land (2-stellig, z.B. DE)"
									maxLength="2"
									required
									value={newEntryAddressCountry}
									onChange={(event) => {
										setNewEntryAddressCountry(event.target.value.toUpperCase());
									}}
								/>
							</>
						)}

						
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button disabled={isLoading} onClick={() => {
						if (!isLoading) {
							setShowConnectModal(false)
						} else {
							return;
						}
					}}>Abbrechen</Button>
					<Button disabled={isLoading} onClick={createNewAccount} autoFocus>
						Anlegen
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}