import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import deLocale from 'date-fns/locale/de';
import TextField from '@mui/material/TextField'
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import './style.scss';

export default function HolidayConfigurationModal(props) {

	const [isLoading, setIsLoading] = useState(false);
    const [beginDate, setBeginDate] = useState();
    const [endDate, setEndDate] = useState();
    const [openTime, setOpenTime] = useState();
    const [text, setText] = useState("Wir haben Urlaub vom TT.MM.JJJJ bis TT.MM.JJJJ und sind am TT.MM.JJJJ wieder für euch da!");

    useEffect(() => {
        if (props.holidays.created_at) {
            // Holidays already created
            
            setBeginDate(new Date(props.holidays.holiday_start_date));
            setEndDate(new Date(props.holidays.holiday_end_date));
            setOpenTime(new Date(props.holidays.holiday_closed_until_time));
            setText(props.holidays.holiday_description);
        }
    }, [props])

    const onSave = () => {
        // Restaurant-ID:
        const restaurantId = props.restaurantId;

        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/holidays/` + restaurantId, {
            restaurantId: restaurantId,
            holidayBegin: beginDate,
            holidayEnd: endDate,
            openTime: openTime,
            holidayText: text
        })
        .then((res) => {
            props.onClose();
            setIsLoading(false)
        })
        .catch((err) => {
            
            setIsLoading(false)
        })
    }

    const deleteHoliday = () => {
        // Restaurant-ID:
        const restaurantId = props.restaurantId;

        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/holidays/delete/` + restaurantId, {
            restaurantId: restaurantId
        })
        .then((res) => {
            props.onClose();
            setIsLoading(false)
        })
        .catch((err) => {
            
            setIsLoading(false)
        })
    }

	return (
		<Dialog
            open={props.open}
            onClose={() => {
                if (!isLoading) {
                    props.onClose();
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                Urlaub konfigurieren
            </DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                    <DatePicker
                        renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" {...props} />}
                        label="Von (einschließlich)"
                        value={new Date(beginDate)}
                        onChange={(newValue) => {
                            setBeginDate(newValue)
                        }}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                    <DatePicker
                        renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" {...props} />}
                        label="Bis (einschließlich)"
                        value={new Date(endDate)}
                        onChange={(newValue) => {
                            setEndDate(newValue)
                        }}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                    <TimePicker
                        renderInput={(props) => <TextField fullWidth helperText="Nach dieser Uhrzeit werden Ihre eingestellten Öffnungszeiten befolgt." variant="standard" margin="dense" {...props} />}
                        label="Geschlossen bis (Uhrzeit)"
                        value={new Date(openTime)}
                        onChange={(newValue) => {
                            setOpenTime(newValue)
                        }}
                    />
                </LocalizationProvider>

                <br />
                <br />

                <TextField label="Urlaubs-Text (wird den Kunden angezeigt)" fullWidth variant="standard" margin="dense" value={text} onChange={(e) => {setText(e.target.value)}} />
            </DialogContent>
            <DialogActions>
                <LoadingButton color="error" loading={isLoading} disabled={isLoading} onClick={deleteHoliday}>
                    Urlaub löschen
                </LoadingButton>
                <LoadingButton loading={isLoading} disabled={isLoading} onClick={props.onClose}>
                    Schließen
                </LoadingButton>
                <LoadingButton loading={isLoading} disabled={isLoading} onClick={onSave}>
                    Speichern
                </LoadingButton>
            </DialogActions>
        </Dialog>
	);
}