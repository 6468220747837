import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import axios from 'axios';

import './style.scss';

export default function StepIngredients(props) {

	const [alertModalVisible, setAlertModalVisible] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [formProductIngredientsError, setFormProductIngredientsError] = useState(false);
	const [modalSkipTimeout, setModalSkipTimeout] = useState(3);
	const [allergens, setAllergens] = useState([]);

	const handleNextStep = () => {

		props.isLoading(true);
		setIsLoading(true);
		
		setTimeout(() => {
			props.handleNext();

			props.isLoading(false);
			setIsLoading(false);
		}, 100)
	}

	useEffect(() => {

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/allergens`)
			.then((res) => {
				if (res.status === 200 && res.data) {
					// Everything OK
					let temporaryTableRows = [];
					res.data.forEach((category) => {
						temporaryTableRows.push({id: category.id, title: category.allergen_name});
					})
					setAllergens(temporaryTableRows);
				} else {
				}
			})
			.catch((err) => {})

		const interval = setInterval(() => {
			setModalSkipTimeout(modalSkipTimeout - 1);
			if (modalSkipTimeout === 0 || modalSkipTimeout < 0) {
				clearInterval(interval);
			}
		}, 1000);

		return () => clearInterval(interval);

	}, [modalSkipTimeout]);

	return (
		<Box sx={{ width: '100%' }}>
			<div style={{padding: '30px 0'}}>
				<Stack spacing={2}>
					{/*
						<TextField
							autoFocus
							size="small"
							label="Zutaten"
							helperText="Gebe hier die Zutaten deines Produktes an."
							multiline
							rows={3}
							error={formProductIngredientsError}
							value={props.formProductIngredients}
							onChange={(e) => {setFormProductIngredientsError(false);props.setFormProductIngredients(e.target.value)}}
						/>
					*/}

					{allergens.length > 0 && (
						<Autocomplete
							autoFocus
							multiple
							freeSolo
							id="tags-outlined"
							options={allergens}
							getOptionLabel={(option) => option.title}
							defaultValue={[allergens[0]]}
							onChange={(event, newValue) => {
								props.setFormProductAllergens(newValue);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Allergene"
									placeholder="Allergene"
								/>
							)}
						/>
					)}

				</Stack>
			</div>

			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between' }}>
				<LoadingButton startIcon={<ArrowBackIcon />} loading={isLoading} onClick={props.handleBack}>Zurück</LoadingButton>
				<LoadingButton endIcon={<ArrowForwardIcon />} variant="contained" loading={isLoading} onClick={handleNextStep}>Nächster Schritt</LoadingButton>
			</Box>

			<Dialog
				open={alertModalVisible}
				onClose={() => {
					if (modalSkipTimeout > 0) {
						setAlertModalVisible(true)
					} else {
						setAlertModalVisible(false)
					}
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title">
					Wichtiger Hinweis!
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Bitte beachte die Lebensmittelinformations-Verordnung (LMIV):<br /><br />

						Alle Betriebe, die mit Lebensmitteln arbeiten, müssen Allergene in ihren Speisen und Getränken kennzeichnen. Nicht-Beachtungen können mit Bußgeldern geahndet werden.<br /><br />
						
						Mehr dazu findest du beim <a rel="noreferrer" href="https://www.bmel.de/DE/themen/ernaehrung/lebensmittel-kennzeichnung/pflichtangaben/allergenkennzeichnung.html" target="_blank">Bundesministerium für Ernährung und Landwirtschaft</a>.
						<br />
						<br />
						DeliverOne ist nicht verantwortlich für die Richtigkeit der Angaben und kann keine Haftung für Schäden übernehmen, die durch falsche Angaben entstehen.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{modalSkipTimeout > 0 ? (
						<Button disabled autoFocus>
							Verstanden (noch {modalSkipTimeout} Sek.)
						</Button>
					) : (
						<Button onClick={() => {setAlertModalVisible(false)}} autoFocus>
							Verstanden
						</Button>
					)}
				</DialogActions>
			</Dialog>

		</Box>
	);
}