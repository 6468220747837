import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import PageRestaurantNew from '../../../PageRestaurantNew';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import StorefrontIcon from '@mui/icons-material/Storefront';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import axios from 'axios';

export default function StepRestaurant(props) {

    const [showSearchModal, setShowSearchModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleSearchModal = () => {
        setSearchQuery('');
        setShowSearchModal(true);
    }

    const handleSearch = () => {

        if (searchQuery.trim() === '') {
            return;
        }

        props.setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/api/search-restaurants?query=${searchQuery}`).then((res) => {
            props.setIsLoading(false);
            if (res.data) {
                
                setSearchResults(res.data);
            } else {
                props.setIsLoading(false);
                props.handleStepError(true);
            }
        }).catch((err) => {
            props.setIsLoading(false);
            props.handleStepError(true);
        });
    }

	return (
        <>
            <div onClick={handleSearchModal} style={{
                width: 'calc(100% - 40px)',
                background: '#dee7ea',
                margin: '20px',
                borderRadius: '10px',
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                cursor: 'pointer'
            }}>
                <SearchIcon color="inherit" sx={{ display: 'inline-block' }} /> Bestehendes Restaurant suchen
            </div>

            <PageRestaurantNew
                formValues={props.formValues}
                setFormValues={props.setFormValues}				
                asStep={true}
                isLoading={props.isLoading}
                setIsLoading={props.setIsLoading}
                handleNext={props.handleNext}
                handleBack={props.handleBack}
                handleReset={props.handleReset}
                handleStepError={props.handleStepError}
            />

            <Dialog fullWidth open={showSearchModal} onClose={() => {
                setShowSearchModal(false)
            }}>
                <DialogTitle>Nach Restaurant suchen</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="search-query"
                        label="Suchbegriff"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value)
                        }}
                    />

                    {searchResults.length > 0 && (
                        <>
                            <Divider style={{
                                margin: '25px 0'
                            }} />
                            
                            <List disablePadding>
                                {searchResults.map((restaurant) => (
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => {
                                            let tmpFormValues = props.formValues;
                                            tmpFormValues["restaurantId"] = restaurant.id;
                                            props.setFormValues(tmpFormValues);
                                            props.handleNext();
                                            setShowSearchModal(false);
                                        }}>
                                            <ListItemIcon>
                                                <StorefrontIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={restaurant.restaurant_name}
                                                secondary={"#" + restaurant.id}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setShowSearchModal(false)
                    }}>Abbrechen</Button>
                    <Button onClick={handleSearch}>Suchen</Button>
                </DialogActions>
            </Dialog>

        </>
	);
}