import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Currency from 'react-currency-formatter';
import Loader from '../../../components/Loader';

import './style.scss';

export default function PageAdministrateBankingAAccounts() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [status, setStatus] = useState("error"); // error, success
	const [accounts, setAccounts] = useState([]);
	
	useEffect(() => {
		document.title = 'A Konten Eigen — DeliverOne Banking — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);
		

		if (localStorage.getItem('bankingAccessToken')) {

			axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/banking/accounts?accessToken=${localStorage.getItem('bankingAccessToken')}`).then((res) => {
				setIsLoading(false);
				if (res.data) {

					if (res.data.status) {
						if (res.data.status === "success") {
							
							
							setAccounts(res.data.accounts);

						} else {
							handleError();
							return;
						}
					}

					setStatus("success");

				} else {
					handleError();
				}
			}).catch((err) => {
				handleError();
			});

		} else {
			handleError();
		}

	}, [window.location])

	const handleError = () => {
		setStatus("error");
		setIsLoading(false);
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						status === "error" ? (
							<center style={{margin: '60px 0'}}>
								<Typography style={{fontWeight:'bold'}} variant="h5" component="h5" gutterBottom>
									Ein Fehler ist aufgetreten!
								</Typography>
								<span>Ein Fehler ist aufgetreten. Bitte kontaktiere den Support: +49 8342 7050-752.</span>
							</center>
						) : (
							<TableContainer className="PageMenu-Table">
								<Table size="small" aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Konto</TableCell>
											<TableCell align='right'>Kontostand</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{accounts.map((row, index) => (
											<TableRow
												key={index}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell component="td" scope="row">
													{row.name ? (<><b>{row.name}</b> {row.currency}</>)  : row.currency}
												</TableCell>
												<TableCell component="td" align='right' scope="row">
													<Currency
														quantity={parseFloat(row.balance)}
														currency="EUR"
													/>{(row.invoice_gocardless_payment === 1) && " (GoCardless Auto-Payment)"}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)
					)}
				</Box>

			</Paper>
		</>
	);
}