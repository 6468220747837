import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Currency from 'react-currency-formatter';
import Loader from '../../../components/Loader';
import Dialog from '@mui/material/Dialog'
import Alert from '@mui/material/Alert';
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import deLocale from 'date-fns/locale/de';

import './style.scss';

export default function PageAdministrateContractManagement() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [restaurants, setRestaurants] = useState([]);
	const [contracts, setContracts] = useState([]);
	const [products, setProducts] = useState([]);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [showCreateContractModal, setShowCreateContractModal] = useState(false);
	const [collapsedRows, setCollapsedRows] = useState([]);
	
	const [cancellationContext, setCancellationContext] = useState([]);
	const [showCancellationModal, setShowCancellationModal] = useState(false);
	
	const [editContext, setEditContext] = useState([]);
	const [showEditModal, setShowEditModal] = useState(false);

	const [newContractRestaurantId, setNewContractRestaurantId] = useState("");
	const [newContractRestaurantName, setNewContractRestaurantName] = useState("");
	const [newContractType, setNewContractType] = useState("");
	const [newContractSalesXCustomerNumber, setNewContractSalesXCustomerNumber] = useState("");
	const [newContractSalesXCustomerDetails, setNewContractSalesXCustomerDetails] = useState([]);
	const [newContractStartDate, setNewContractStartDate] = useState(new Date());
	const [newContractDuration, setNewContractDuration] = useState(3);
	const [newContractTestMonths, setNewContractTestMonths] = useState(1);
	
	const [newCancellationType, setNewCancellationType] = useState("ordinary");
	const [newCancellationDate, setNewCancellationDate] = useState();
	
	const [editContractRestaurantId, setEditContractRestaurantId] = useState("");
	const [editContractType, setEditContractType] = useState("");
	const [editContractSalesXCustomerNumber, setEditContractSalesXCustomerNumber] = useState("");
	const [editContractStartDate, setEditContractStartDate] = useState(new Date());
	const [editContractDuration, setEditContractDuration] = useState(3);
	const [editContractTestMonths, setEditContractTestMonths] = useState(1);
	const [editContractSalesXProduct, setEditContractSalesXProduct] = useState(null);

	useEffect(() => {
		document.title = 'Vertragsverwaltung — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;

		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
		.then((res) => {
			if (res.data) {
				setRestaurants(res.data);

				axios.get(process.env.REACT_APP_API_URL + '/api/administrate/contracts').then((response) => {
					setContracts(response.data.contracts);
					
					axios.get(process.env.REACT_APP_API_URL + '/api/administrate/contracts/sales-x-products').then((response) => {
						
						setProducts(response.data.products);
						setIsLoading(false);
					})
					.catch((error) => {
						handleError();
						setIsLoading(false);
					})

				})
				.catch((error) => {
					handleError();
					setIsLoading(false);
				})

			} else {
				setIsLoading(false);
				handleError();
			}
		})
		.catch((error) => {
			handleError();
			setIsLoading(false);
		})

	}, [reloadKey])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	const createNewContract = () => {
		
		if (newContractRestaurantId === "") {
			handleError();
			return;
		}

		if (newContractType === "") {
			handleError();
			return;
		}

		if (newContractSalesXCustomerNumber === "") {
			handleError();
			return;
		}

		if (newContractStartDate === "") {
			handleError();
			return;
		}

		if (newContractDuration === "" || newContractDuration === 0 || newContractDuration < 1) {
			handleError();
			return;
		}

		if (newContractTestMonths === "" || newContractTestMonths === 0 || newContractTestMonths < 0) {
			handleError();
			return;
		}

		setIsLoading(true);

		axios.post(process.env.REACT_APP_API_URL + '/api/administrate/contracts', {
			linked_restaurant: newContractRestaurantId,
			contract_type: newContractType,
			salesx_customer_number: newContractSalesXCustomerNumber,
			contract_start_date: newContractStartDate.toLocaleDateString(),
			contract_duration: newContractDuration,
			contract_test_months: newContractTestMonths
		}).then((response) => {
			setIsLoading(false);
			setShowSuccessSnackbar(true);
			setSuccessSnackbarText(response.data.message_de);
			setShowCreateContractModal(false);
			setReloadKey(Math.random());
		})
		.catch((error) => {
			handleError();
			setIsLoading(false);
		});

	}

	const editContract = () => {
		
		if (editContractRestaurantId === "") {
			handleError();
			return;
		}

		if (editContractType === "") {
			handleError();
			return;
		}

		if (editContractSalesXCustomerNumber === "") {
			handleError();
			return;
		}

		if (editContractStartDate === "") {
			handleError();
			return;
		}

		if (editContractDuration === "" || editContractDuration === 0 || editContractDuration < 1) {
			handleError();
			return;
		}

		if (editContractTestMonths === "" || editContractTestMonths === 0 || editContractTestMonths < 0) {
			handleError();
			return;
		}

		setIsLoading(true);

		axios.put(process.env.REACT_APP_API_URL + '/api/administrate/contracts/' + editContext.id, {
			linked_restaurant: editContractRestaurantId,
			contract_type: editContractType,
			salesx_customer_number: editContractSalesXCustomerNumber,
			contract_start_date: editContractStartDate.toLocaleDateString(),
			contract_duration: editContractDuration,
			contract_test_months: editContractTestMonths
		}).then((response) => {
			setIsLoading(false);
			setShowSuccessSnackbar(true);
			setSuccessSnackbarText(response.data.message_de);
			setShowEditModal(false);
			setReloadKey(Math.random());
		})
		.catch((error) => {
			handleError();
			setIsLoading(false);
		});

	}

	const cancelContract = () => {

		if (newCancellationType === "") {
			handleError();
			return;
		}

		if (newCancellationDate === "") {
			handleError();
			return;
		}

		setIsLoading(true);

		axios.delete(process.env.REACT_APP_API_URL + '/api/administrate/contracts/' + cancellationContext.id, {
			data: {
				cancellation_type: newCancellationType,
				cancellation_date: newCancellationDate.toLocaleDateString(),
			}
		}).then((response) => {
			setIsLoading(false);
			setShowSuccessSnackbar(true);
			setSuccessSnackbarText(response.data.message_de);
			setShowCancellationModal(false);
			setReloadKey(Math.random());
		}).catch((error) => {
			handleError();
			setIsLoading(false);
		})

	}

	const searchCustomer = (event) => {
		setIsLoading(true);
		setNewContractSalesXCustomerDetails([]);
		axios.get(process.env.REACT_APP_API_URL + '/api/administrate/contracts/sales-x-customer-search/' + event.target.value).then((response) => {
			setNewContractSalesXCustomerDetails(response.data);
			setIsLoading(false);
		})
		.catch((error) => {
			setIsLoading(false);
		})
	}
	
	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Button disabled={isLoading} onClick={() => {
									navigate.push("/administrate/contract-management/setup");
								}} variant="contained" sx={{ mr: 1 }}>
									Vertragsanlage Neukunde
								</Button>
								<Button disabled={isLoading} onClick={() => {setShowCreateContractModal(true)}} variant="contained" sx={{ mr: 1 }}>
									Vertragsanlage Bestandskunde
								</Button>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {
										setReloadKey(Math.random());
									}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<TableContainer className="PageMenu-Table">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Vertrags-Nr.</TableCell>
										<TableCell>Verknüpftes Objekt</TableCell>
										<TableCell>Vertragsart</TableCell>
										<TableCell><span title="SalesX">SaX</span>-Kundennr.</TableCell>
										<TableCell>Vertragsbeginn</TableCell>
										<TableCell>Laufzeit</TableCell>
										<TableCell>Testphase</TableCell>
										<TableCell>Nächstmögliche Kündigung</TableCell>
										<TableCell>Gekündigt zum</TableCell>
										<TableCell>Letzte Zahlung</TableCell>
										<TableCell>Risiko-Scoring</TableCell>
										<TableCell>Einnahmen bisher</TableCell>
										<TableCell>Vertragsstatus</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{contracts.length > 0 && (
										contracts.map((contract, index) => (
											<React.Fragment key={index}>
												<TableRow
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
													onClick={() => {
														if (collapsedRows.includes(index)) {
															setCollapsedRows([]);
														} else {
															setCollapsedRows([index]);
														}
													}}
												>
													<TableCell component="td" scope="row">
														{contract.contract_number}
													</TableCell>
													<TableCell component="td" scope="row">
														{contract.restaurant_name}
													</TableCell>
													<TableCell component="td" scope="row">
														{contract.sales_x_product?.id ? contract.sales_x_product.product_sku + " " + contract.sales_x_product.product_name : contract.contract_type}
													</TableCell>
													<TableCell component="td" scope="row">
														{contract.salesx_customer_number}
													</TableCell>
													<TableCell component="td" scope="row">
														{contract.contract_start_date_formatted}
													</TableCell>
													<TableCell component="td" scope="row">
														{contract.contract_duration } Monat(e)
													</TableCell>
													<TableCell component="td" scope="row">
														{contract.test_months } Monat(e)
													</TableCell>
													<TableCell component="td" scope="row">
														{contract.next_possible_cancellation_date_formatted}
														<br /><i style={{color:'#5c7f90'}}>2 wöchige Kündigungsfrist nicht berücksichtigt!</i>
													</TableCell>
													<TableCell component="td" scope="row">
														{contract.contract_end_date_formatted}
													</TableCell>
													<TableCell component="td" scope="row">
														{contract.last_payment_formatted}
													</TableCell>
													<TableCell component="td" scope="row">
														{contract.scoring}
													</TableCell>
													<TableCell component="td" scope="row">
														{contract.total_payments_formatted}
													</TableCell>
													<TableCell component="td" scope="row">
														{contract.contract_status === 'active' ? "Aktiv" : (contract.contract_status === 'expiring' ? "Gekündigt, auslaufend" : "Gekündigt")}
														{contract.test_phase === true ? " (Testphase)" : ""}
													</TableCell>
												</TableRow>
												{collapsedRows.includes(index) && (
													contract.contract_status === 'active' && (
														<React.Fragment key={index + "_context"}>
															<TableRow
																key={index + "_context_1"}
																sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
																onClick={() => {
																	setCancellationContext(contract);
																	setShowCancellationModal(true);
																}}
																style={{
																	background: '#eaeff1'
																}}
															>
																<TableCell component="td" scope="row" colSpan={13}>
																	└ <b>Aktion:</b> Kündigen
																</TableCell>
															</TableRow>
															<TableRow
																key={index + "_context_2"}
																sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
																onClick={() => {
																	setEditContext(contract);
																	setEditContractRestaurantId(contract.linked_restaurant)
																	setEditContractType(contract.contract_type)
																	setEditContractSalesXCustomerNumber(contract.salesx_customer_number)
																	setEditContractStartDate(new Date(contract.contract_start_date))
																	setEditContractDuration(contract.contract_duration)
																	setEditContractTestMonths(contract.test_months)
																	setEditContractSalesXProduct(contract.sales_x_product)
																	setShowEditModal(true);
																}}
																style={{
																	background: '#eaeff1'
																}}
															>
																<TableCell component="td" scope="row" colSpan={13}>
																	└ <b>Aktion:</b> Bearbeiten
																</TableCell>
															</TableRow>
														</React.Fragment>		
													)
												)}
											</React.Fragment>
										))
									)}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>

			</Paper>
			
			<Dialog
				open={showCreateContractModal}
				onClose={() => {
					if (!isLoading) {
						setShowCreateContractModal(false)
					} else {
						return;
					}
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					Vertrag anlegen
				</DialogTitle>
				<DialogContent style={{padding: '20px'}}>
					<Stack spacing={3}>

						{isLoading ? (
							<Loader />
						) : (
							<>

								{newContractRestaurantName.includes("TECHNISCHE") && (
									<Alert sx={{ overflow: 'hidden' }} variant="filled" severity="warning">
										Hinweis! Diese Person ist eine technische Person! Technische Personen erhalten standardmäßig das Vertragsobjekt "DeliverOne f. technische Restaurants"!
									</Alert>
								)}

								<FormControl size="small" label="Restaurant auswählen" fullWidth>
									<InputLabel id="user-type-label">Restaurant auswählen</InputLabel>
									<Select
										labelId="user-type-label"
										id="user-type"
										label="Restaurant auswählen"
										value={newContractRestaurantId}
										required
										onChange={(event) => {
											setNewContractRestaurantId(event.target.value)
											
											restaurants.forEach(restaurant => {
												if (restaurant.id === event.target.value) {
													setNewContractRestaurantName(restaurant.restaurant_name.toUpperCase())
												}
											})
											
										}}				  
									>
										{restaurants.map((restaurant, index) => {
											return <MenuItem value={restaurant.id} key={index}>{restaurant.id} · {restaurant.restaurant_name}</MenuItem>
										})}
									</Select>
								</FormControl>

								<FormControl size="small" label="Vertragsobjekt" fullWidth>
									<InputLabel id="contract-product-label">Vertragsobjekt</InputLabel>
									<Select
										labelId="contract-product-label"
										id="contract-product"
										label="Vertragsobjekt"
										value={newContractType}
										required
										onChange={(event) => {
											setNewContractType(event.target.value)
										}}				  
									>
										{products.map((product, index) => {
											return <MenuItem value={product.id} key={index}>{product.product_sku} {product.product_name} (<Currency currency="EUR" quantity={product.product_gross_price} />)</MenuItem>
										})}
									</Select>
								</FormControl>
		
								<TextField
									name="newContractSalesXCustomerNumber-label"
									fullWidth
									size="small"
									label="SalesX Kundennummer"
									required
									value={newContractSalesXCustomerNumber}
									onChange={(event) => {
										setNewContractSalesXCustomerNumber(event.target.value.replace(/\D/g,''));
									}}
									onBlur={searchCustomer}
								/>

								{newContractSalesXCustomerDetails["id"] && (
									<TextField
										name="newContractSalesXCustomerDetails-label"
										fullWidth
										size="small"
										label="SalesX Kundensatz"
										value={newContractSalesXCustomerDetails["company_name"] + ", " + newContractSalesXCustomerDetails["firstname"] + " " + newContractSalesXCustomerDetails["lastname"]}
										disabled={true}
									/>
								)}
		
								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
									<DatePicker
										renderInput={(props) => <TextField fullWidth size="small" margin="dense" {...props} />}
										label="Vertragsbeginn"
										value={new Date(newContractStartDate)}
										onChange={(newValue) => {
											setNewContractStartDate(newValue)
										}}
									/>
								</LocalizationProvider>
		
								<TextField
									name="newContractDuration-label"
									fullWidth
									size="small"
									label="Vertragslaufzeit in Monaten"
									required
									type="number"
									value={newContractDuration}
									min={1}
									onChange={(event) => {
										setNewContractDuration(event.target.value);
									}}
								/>
		
								<TextField
									name="newContractDuration-label"
									fullWidth
									size="small"
									label="Testphase in Monaten"
									required
									type="number"
									value={newContractTestMonths}
									min={0}
									onChange={(event) => {
										setNewContractTestMonths(event.target.value);
									}}
								/>
		
							</>
						)}

						
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button disabled={isLoading} onClick={() => {
						if (!isLoading) {
							setShowCreateContractModal(false)
						} else {
							return;
						}
					}}>Abbrechen</Button>
					<Button disabled={isLoading} onClick={createNewContract} autoFocus>
						Anlegen
					</Button>
				</DialogActions>
			</Dialog>
			
			<Dialog
				open={showEditModal}
				onClose={() => {
					if (!isLoading) {
						setShowEditModal(false)
					} else {
						return;
					}
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					Vertrag Nr. {editContext.contract_number} bearbeiten
				</DialogTitle>
				<DialogContent style={{padding: '20px'}}>
					<Stack spacing={3}>

						{isLoading ? (
							<Loader />
						) : (
							<>
								<FormControl size="small" label="Restaurant auswählen" fullWidth>
									<InputLabel id="editContractRestaurantId-label">Restaurant auswählen</InputLabel>
									<Select
										labelId="editContractRestaurantId-label"
										id="editContractRestaurantId"
										label="Restaurant auswählen"
										value={editContractRestaurantId}
										required
										onChange={(event) => {
											setEditContractRestaurantId(event.target.value)
										}}				  
									>
										{restaurants.map((restaurant, index) => {
											return <MenuItem value={restaurant.id} key={index}>{restaurant.id} · {restaurant.restaurant_name}</MenuItem>
										})}
									</Select>
								</FormControl>
	
								{editContractSalesXProduct?.id ? (
									<FormControl size="small" label="Vertragsobjekt" fullWidth>
										<InputLabel id="contract-product-label">Vertragsobjekt</InputLabel>
										<Select
											labelId="contract-product-label"
											id="contract-product"
											label="Vertragsobjekt"
											value={parseInt(editContractType)}
											required
											onChange={(event) => {
												setEditContractType(event.target.value)
											}}				  
										>
											{products.map((product, index) => {
												return <MenuItem
															value={product.id}
															key={index}
														>{product.product_sku} {product.product_name} (<Currency currency="EUR" quantity={product.product_gross_price} />)</MenuItem>
											})}
										</Select>
									</FormControl>
								) : (
									<TextField
										name="editContractType-label"
										fullWidth
										size="small"
										label="Primärprodukt"
										required
										value={editContractType}
										onChange={(event) => {
											setEditContractType(event.target.value.trim().toUpperCase());
										}}
									/>
								)}

								<TextField
									name="editContractSalesXCustomerNumber-label"
									fullWidth
									size="small"
									label="SalesX Kundennummer"
									required
									value={editContractSalesXCustomerNumber}
									onChange={(event) => {
										setEditContractSalesXCustomerNumber(event.target.value.replace(/\D/g,''));
									}}
								/>
		
								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
									<DatePicker
										renderInput={(props) => <TextField fullWidth size="small" margin="dense" {...props} />}
										label="Vertragsbeginn"
										value={new Date(editContractStartDate)}
										onChange={(newValue) => {
											setEditContractStartDate(newValue)
										}}
									/>
								</LocalizationProvider>
		
								<TextField
									name="editContractDuration-label"
									fullWidth
									size="small"
									label="Vertragslaufzeit in Monaten"
									required
									type="number"
									value={editContractDuration}
									min={1}
									onChange={(event) => {
										setEditContractDuration(event.target.value);
									}}
								/>
		
								<TextField
									name="editContractDuration-label"
									fullWidth
									size="small"
									label="Testphase in Monaten"
									required
									type="number"
									value={editContractTestMonths}
									min={0}
									onChange={(event) => {
										setEditContractTestMonths(event.target.value);
									}}
								/>
		
							</>
						)}

						
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button disabled={isLoading} onClick={() => {
						if (!isLoading) {
							setShowEditModal(false)
						} else {
							return;
						}
					}}>Abbrechen</Button>
					<Button disabled={isLoading} onClick={editContract} autoFocus>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={showCancellationModal}
				onClose={() => {
					if (!isLoading) {
						setShowCancellationModal(false)
					} else {
						return;
					}
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					Vertrag Nr. {cancellationContext.contract_number} kündigen
				</DialogTitle>
				<DialogContent style={{padding: '20px'}}>
					<Stack spacing={3}>

						{isLoading ? (
							<Loader />
						) : (
							<>
								<FormControl size="small" label="Art auswählen" fullWidth>
									<InputLabel id="user-type-label">Art auswählen</InputLabel>
									<Select
										labelId="user-type-label"
										id="user-type"
										label="Art auswählen"
										value={newCancellationType}
										required
										onChange={(event) => {
											setNewCancellationType(event.target.value)
										}}				  
									>
										<MenuItem value="ordinary">Ordentliche Kündigung</MenuItem>
										<MenuItem value="extraordinary">Außerordentliche Kündigung</MenuItem>
									</Select>
								</FormControl>

								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
									<DatePicker
										renderInput={(props) => <TextField fullWidth size="small" margin="dense" {...props} />}
										label="Kündigungstermin"
										value={new Date(newCancellationDate)}
										onChange={(newValue) => {
											setNewCancellationDate(newValue)
										}}
										minDate={
											newCancellationType === 'ordinary' ? new Date(cancellationContext.next_possible_cancellation_date) : new Date(cancellationContext.contract_start_date)
										}
									/>
								</LocalizationProvider>
							</>
						)}

						
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button disabled={isLoading} onClick={() => {
						if (!isLoading) {
							setShowCancellationModal(false)
						} else {
							return;
						}
					}}>Abbrechen</Button>
					<Button disabled={isLoading} onClick={cancelContract} autoFocus color="error">
						Kündigen
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

		</>
	);
}