import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField'
import NumberFormat from 'react-number-format';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Loader from '../../../components/Loader';

import './style.scss';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
			decimalSeparator=","
            isNumericString
            suffix=" EUR"
			allowNegative={false}
			decimalScale={2}
			isAllowed={(values, sourceInfo) => {
				const { value } = values;
				return value >= 0;
			}}
        />
    );
});

const PercentFormatCustom = React.forwardRef(function PercentFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
			decimalSeparator=","
            isNumericString
            suffix=" %"
			allowNegative={false}
			decimalScale={2}
			isAllowed={(values, sourceInfo) => {
				const { value } = values;
				return value < (100 + 1);
			}}			
        />
    );
});

export default function PageAdministrateDeliveryAreas() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [restaurants, setRestaurants] = useState([]);
	const [deliveryAreas, setDeliveryAreas] = useState([]);
	const [autocomplete, setAutocomplete] = useState([]);
	const [selectedRestaurant, setSelectedRestaurant] = useState("");
	
	const [showNewCityModal, setShowNewCityModal] = useState(false);
	const [newCityName, setNewCityName] = useState("");
	const [newCityMinimumOrder, setNewCityMinimumOrder] = useState("");
	const [newCityDeliveryFee, setNewCityDeliveryFee] = useState("");

	const [showEditCityModal, setShowEditCityModal] = useState(false);
	const [selectedCityId, setSelectedCityId] = useState(0);
	const [editCityName, setEditCityName] = useState("");
	const [editCityMinimumOrder, setEditCityMinimumOrder] = useState("");
	const [editCityDeliveryFee, setEditCityDeliveryFee] = useState("");
	
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	
	useEffect(() => {
		document.title = 'Liefergebiete — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
			.then((res) => {
				if (res.status === 200 && res.data) {
					// Everything OK
					setIsLoading(false);
					setRestaurants(res.data);
				} else {
					// Error
					
				}
			})
			.catch((err) => {
				
			})

		if (selectedRestaurant !== null && selectedRestaurant !== "") {
			setIsLoading(true);
			axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/delivery-areas/${selectedRestaurant}`).then((res) => {
				setIsLoading(false);
				if (res.data) {
					if (res.data.data) {
						setDeliveryAreas(res.data.data);
					}
					if (res.data.autocomplete) {
						setAutocomplete(res.data.autocomplete);
					}
				} else {
					handleError();
				}
			}).catch((err) => {
				setIsLoading(false);
				handleError();
			})
		}

	}, [reloadKey, selectedRestaurant])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	const createCity = () => {
		// Validate
		if (newCityName === "" || newCityName === null) {
			return;
		}
		if (newCityMinimumOrder === "" || newCityMinimumOrder === null) {
			return;
		}
		if (newCityDeliveryFee === "" || newCityDeliveryFee === null) {
			return;
		}

		// Create
		setIsLoading(true);
		axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/delivery-areas/${selectedRestaurant}`, {
			name: newCityName,
			minimumOrder: newCityMinimumOrder,
			deliveryFee: newCityDeliveryFee
		}).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setShowNewCityModal(false);
				setNewCityName("");
				setNewCityMinimumOrder("");
				setNewCityDeliveryFee("");
				setReloadKey(Math.random());
			} else {
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}

	const saveCity = () => {
		// Validate
		if (editCityName === "" || editCityName === null) {
			return;
		}
		if (editCityMinimumOrder === "" || editCityMinimumOrder === null) {
			return;
		}
		if (editCityDeliveryFee === "" || editCityDeliveryFee === null) {
			return;
		}

		// Save
		setIsLoading(true);
		axios.put(`${process.env.REACT_APP_API_URL}/api/administrate/delivery-areas/${selectedCityId}`, {
			name: editCityName,
			minimumOrder: editCityMinimumOrder,
			deliveryFee: editCityDeliveryFee
		}).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setShowEditCityModal(false);
				setEditCityName("");
				setSelectedCityId(0);
				setEditCityMinimumOrder("");
				setEditCityDeliveryFee("");
				setReloadKey(Math.random());
			} else {
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}

	const deleteCity = () => {
		setIsLoading(true);
		axios.delete(`${process.env.REACT_APP_API_URL}/api/administrate/delivery-areas/${selectedCityId}`).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setShowDeleteModal(false);
				setShowEditCityModal(false);
				setSelectedCityId(0);
				setEditCityName("");
				setEditCityMinimumOrder("");
				setEditCityDeliveryFee("");
				setReloadKey(Math.random());
			} else {
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								{selectedRestaurant !== null && selectedRestaurant !== "" && (
									<Button onClick={() => {setShowNewCityModal(true)}} disabled={isLoading} variant="contained" sx={{ mr: 1 }}>Ort hinzufügen</Button>
								)}
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<>
							<FormControl margin="dense" size='small' fullWidth variant="outlined">
								<InputLabel id="restaurant-label">Restaurant</InputLabel>
								<Select
									labelId="restaurant-label"
									id="restaurant"
									label="Restaurant"
									value={selectedRestaurant}
									onChange={(event) => {
										setSelectedRestaurant(event.target.value)
									}}				  
								>
									{
										restaurants.map((restaurant) => {
											return (
												<MenuItem key={restaurant.id} value={restaurant.id}>{restaurant.restaurant_name} (#{restaurant.id})</MenuItem>
											)
										})
									}
								</Select>
							</FormControl>
							{selectedRestaurant !== null && selectedRestaurant !== "" && (
								<>
									<br />
									<br />
									<TableContainer className="PageMenu-Table">
										<Table size="small" aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell>Stadt/Ort</TableCell>
													<TableCell>Mindestbestellwert</TableCell>
													<TableCell>Aufschlag</TableCell>
													<TableCell align='right'>Zeitpunkt</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{deliveryAreas.map((row, index) => (
													<TableRow
														key={index}
														sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
														onClick={() => {
															setSelectedCityId(row.id);
															setEditCityName(row.city_name);
															setEditCityMinimumOrder(row.minimum_order_amount);
															setEditCityDeliveryFee(row.delivery_fee);
															setShowEditCityModal(true);
														}}
													>
														<TableCell component="td" scope="row">
															{row.city_name}
														</TableCell>
														<TableCell component="td" scope="row">
															{parseFloat(row.minimum_order_amount).toFixed(2)} EUR
														</TableCell>
														<TableCell component="td" scope="row">
															{parseFloat(row.delivery_fee).toFixed(2)} EUR
														</TableCell>
														<TableCell component="td" align="right" scope="row">
															{row.created_at}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</>
							)}
						</>
					)}
				</Box>

			</Paper>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

			<Dialog
				open={showNewCityModal}
				onClose={() => {
					if (!isLoading) {
						setShowNewCityModal(false)
					} else {
						return;
					}
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					Neues Liefergebiet
				</DialogTitle>
				<DialogContent style={{padding: '20px'}}>
					<Stack spacing={3}>
						<Autocomplete
							freeSolo
							openOnFocus={false}
							options={autocomplete.map((option) => option.city_name)}
							value={newCityName}
							onInputChange={(event, newValue) => {
								setNewCityName(newValue);
							}}
							renderInput={(params) => 
								<TextField
									autoFocus
									{...params}
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
									}}
									size="small"
									label="Ort (Format: 00000 STADT)"
								/>}
						/>

						<TextField
							name="newCityMinimumOrder"
							fullWidth
							size="small"
							label="Mindestbestellwert"
							value={newCityMinimumOrder}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(event) => {
								setNewCityMinimumOrder(event.target.value);
							}}
						/>
						<TextField
							name="newCityDeliveryFee"
							fullWidth
							size="small"
							label="Lieferaufschlag"
							value={newCityDeliveryFee}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(event) => {
								setNewCityDeliveryFee(event.target.value);
							}}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button disabled={isLoading} onClick={() => {
						if (!isLoading) {
							setShowNewCityModal(false)
						} else {
							return;
						}
					}}>Abbrechen</Button>
					<Button disabled={isLoading} onClick={createCity} autoFocus>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={showEditCityModal}
				onClose={() => {
					if (!isLoading) {
						setShowEditCityModal(false)
					} else {
						return;
					}
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					Liefergebiet "{editCityName}" bearbeiten
				</DialogTitle>
				<DialogContent style={{padding: '20px'}}>
					<Stack spacing={3}>

						<Autocomplete
							freeSolo
							openOnFocus={false}
							options={autocomplete.map((option) => option.city_name)}
							value={editCityName}
							onInputChange={(event, newValue) => {
								setEditCityName(newValue);
							}}
							renderInput={(params) => 
								<TextField
									autoFocus
									{...params}
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
									}}
									size="small"
									label="Ort (Format: 00000 STADT)"
								/>
							}
						/>

						<TextField
							name="newCityMinimumOrder"
							fullWidth
							size="small"
							label="Mindestbestellwert"
							value={editCityMinimumOrder}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(event) => {
								setEditCityMinimumOrder(event.target.value);
							}}
						/>
						<TextField
							name="newCityDeliveryFee"
							fullWidth
							size="small"
							label="Lieferaufschlag"
							value={editCityDeliveryFee}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(event) => {
								setEditCityDeliveryFee(event.target.value);
							}}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button color="error" disabled={isLoading} onClick={() => {
						if (!isLoading) {
							setShowDeleteModal(true)
						} else {
							return;
						}
					}}>Löschen</Button>
					<Button disabled={isLoading} onClick={() => {
						if (!isLoading) {
							setShowEditCityModal(false)
						} else {
							return;
						}
					}}>Abbrechen</Button>
					<Button disabled={isLoading} onClick={saveCity} autoFocus>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={showDeleteModal}
				onClose={() => {setShowDeleteModal(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title">
					Lieferort wirklich löschen?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Möchtest du diese Stadt wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden!
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button disabled={isLoading} onClick={() => {setShowDeleteModal(false)}}>Nein, abbrechen</Button>
					<Button disabled={isLoading} color="error" onClick={deleteCity} autoFocus>
						Ja, löschen
					</Button>
				</DialogActions>
			</Dialog>

		</>
	);
}