import React, { useState, useEffect } from "react";
import axios from "axios";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { useHistory } from "react-router-dom";

function CustomersEdit(props) {

	let navigate = useHistory();
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [customerName, setCustomerName] = useState("")
	const [customerMail, setCustomerMail] = useState("")
	const [customerPhone, setCustomerPhone] = useState("")
	const [customerPassword, setCustomerPassword] = useState("")
	const [customerStreet, setCustomerStreet] = useState("")
	const [customerCities, setCustomerCities] = useState([])
	const [customerRestaurants, setCustomerRestaurants] = useState([])
	const [customerRestaurantId, setCustomerRestaurantId] = useState("")
	const [customerCity, setCustomerCity] = useState("")
	const [customerRole, setCustomerRole] = useState("")
	const [customerDataPolicy, setCustomerDataPolicy] = useState(false)
	const [customerTerms, setCustomerTerms] = useState(false)
	const [customerTracking, setCustomerTracking] = useState(false)
	const [isLoading, setIsLoading] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);

	const ucWords = (str) => {
		let splitStr = str.toLowerCase().split(' ');
		for (var i = 0; i < splitStr.length; i++) {
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
		}
		// Directly return the joined string
		return splitStr.join(' '); 
	}

	const truncateText = (text, length) => {
		if (text.length <= length) {
			return text;
		}
		
		return text.substr(0, length) + '\u2026'
	}

	useEffect(() => {
		if (props.row) {
			setCustomerName(props.row.name);
			setCustomerMail(props.row.email);
			setCustomerPhone(props.row.phone);
			setCustomerStreet(props.row.street);
			
			setCustomerCity(props.row.city);
			setCustomerRole(props.row.role_name);
			if (props.row.restaurant_access_id !== 0) {
				setCustomerRestaurantId(props.row.restaurant_access_id);
			}
			setCustomerDataPolicy(props.row.data_policy_agreed === 1 ? true : false);
			setCustomerTerms(props.row.terms_and_conditions_agreed === 1 ? true : false);
			setCustomerTracking(props.row.tracking_agreed === 1 ? true : false);
		}

		// Load Cities
		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/cities`)
		.then((res) => {
			if (res.data) {
				setCustomerCities(res.data);
			}
			setIsLoading(false);
		})
		.catch((err) => {})
		
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
		.then((res) => {
			if (res.data) {
				setCustomerRestaurants(res.data);
			}
			setIsLoading(false);
		})
		.catch((err) => {})
	}, [props])

	const resetForm = () => {
		setCustomerName("")
		setCustomerMail("")
		setCustomerPhone("")
		setCustomerPassword("")
		setCustomerStreet("")
		setCustomerCity("")
		setCustomerRole("")
		setCustomerDataPolicy("")
		setCustomerTerms("")
		setCustomerTracking("")
	}

	const onSave = () => {
		setIsLoading(true);
		setSnackbarMessage("Personensatz wird gespeichert...");
		setShowSnackbar(true);

		try {
			const formData = new FormData();
			formData.append("name", customerName)
			formData.append("email", customerMail)
			formData.append("phone", customerPhone)
			formData.append("street", (props.courierFormData ? props.courierFormData.customerStreet : customerStreet))
			formData.append("city", (props.courierFormData ? props.courierFormData.customerCity : customerCity))
			formData.append("password", (props.courierFormData ? props.courierFormData.customerPassword : customerPassword))
			formData.append("password_confirmation", (props.courierFormData ? props.courierFormData.customerPassword : customerPassword))
			formData.append("data_policy_agreed", (props.courierFormData ? props.courierFormData.customerDataPolicy : customerDataPolicy))
			formData.append("terms_and_conditions_agreed", (props.courierFormData ? props.courierFormData.customerTerms : customerTerms))
			formData.append("tracking_agreed", (props.courierFormData ? props.courierFormData.customerTracking : customerTracking))
			formData.append("role_name", (props.courierFormData ? props.courierFormData.customerRole : customerRole))
			formData.append("restaurant_access_id", (customerRestaurantId == "") ? (props.courierFormData ? props.courierFormData.customerRestaurantId : 0) : (props.courierFormData ? props.courierFormData.customerRestaurantId : customerRestaurantId))
			
			const API = "api/backend/restaurant/customers/" + props.row.id;
			const url = `${process.env.REACT_APP_API_URL}/${API}`;
		
			axios.post(url, formData)
				.then(function (response) {
					if (response.status === 200 || response.status === 201) {

						setIsLoading(false)
						resetForm()
						props.closeHandler()
						props.reloadIndex()
						setSnackbarMessage("Personensatz wurde gespeichert.")			
						setTimeout(() => {setShowSnackbar(false)}, 5000)		

					} else {

						setIsLoading(false)

						if (response.message) {
							if (response.message == "The given data was invalid.") {
								setSnackbarMessage("Personensatz bereits vorhanden!");
								setShowSnackbar(true);
								setTimeout(() => {setShowSnackbar(false);}, 5000)
							} else {
								handleError(response)
							}
						} else {
							handleError(response)
						}

					}
				})
				.catch(function (error) {
					setIsLoading(false)
					if (error.response.data.message) {
						if (error.response.data.message === "The given data was invalid.") {
							setSnackbarMessage("Personensatz bereits vorhanden!");
							setShowSnackbar(true);
							setTimeout(() => {setShowSnackbar(false);}, 5000)
						} else {
							handleError(error)
						}
					} else {
						handleError(error)
					}
				})
		} catch (error) {
			setIsLoading(false)
			handleError(error);
		}
	}

	const handleError = (error) => {
		setShowErrorSnackbar(true)
	}

	return (
		<>
			<Dialog fullWidth className="PageMenu-Modal" open={props.isOpen} onClose={props.closeHandler}>
				<DialogTitle>{customerName.trim() === '' ? (props.courier ? 'Kurier' : 'Kunde') : `"${customerName.trim()}"`} bearbeiten</DialogTitle>
				<DialogContent>

					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Vor- und Nachname"
						type="text"
						fullWidth
						variant="standard"
						value={customerName}
						onChange={(event) => {
							setCustomerName(ucWords(event.target.value))
						}}
					/>
					<TextField
						margin="dense"
						id="email"
						label="E-Mail Adresse"
						type="email"
						fullWidth
						variant="standard"
						value={customerMail}
						onChange={(event) => {
							setCustomerMail(event.target.value)
						}}
					/>
					<TextField
						margin="dense"
						id="phone"
						label="Telefonnummer"
						type="text"
						fullWidth
						variant="standard"
						value={customerPhone}
						onChange={(event) => {
							setCustomerPhone(event.target.value)
						}}
					/>
					{!props.courier && (
						<TextField
							margin="dense"
							id="password"
							label="Neues Passwort festlegen"
							type="text"
							fullWidth
							variant="standard"
							value={customerPassword}
							onChange={(event) => {
								setCustomerPassword(event.target.value)
							}}
						/>
					)}
					{!props.courier && (
						<Grid container columnSpacing={{ xs: 0, md: 2 }}>
							<Grid item xs={12} md={6}>
								<TextField
									margin="dense"
									id="customerStreet"
									label="Straße + Hausnr."
									type="text"
									fullWidth
									variant="standard"
									value={customerStreet}
									onChange={(event) => {
										setCustomerStreet(event.target.value)
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormControl margin="dense" fullWidth variant="standard">
									<InputLabel id="user-city-label">Ort</InputLabel>
									<Select
										labelId="user-city-label"
										id="user-city"
										label="Benutzer-Typ"
										value={customerCity}
										onChange={(event) => {
											setCustomerCity(event.target.value)
										}}				  
									>
										{customerCities.map((city, index) => {
											return <MenuItem value={city.value} key={index}>{city.label}</MenuItem>
										})}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					)}

					{!props.courier && (
						<FormControl margin="dense" fullWidth variant="standard">
							<InputLabel id="user-type-label">Benutzer-Typ</InputLabel>
							<Select
								labelId="user-type-label"
								id="user-type"
								label="Benutzer-Typ"
								value={customerRole}
								onChange={(event) => {
									setCustomerRole(event.target.value)
								}}				  
							>
								<MenuItem value={"user"}>Kunde</MenuItem>
								<MenuItem value={"restaurant"}>Restaurant</MenuItem>
								<MenuItem value={"admin"}>Administrator</MenuItem>
							</Select>
						</FormControl>
					)}

					{(customerRole === "restaurant" && !props.courier) && (
						<FormControl margin="dense" fullWidth variant="standard">
							<InputLabel id="user-type-label">Restaurant auswählen</InputLabel>
							<Select
								labelId="user-type-label"
								id="user-type"
								label="Benutzer-Typ"
								value={customerRestaurantId}
								onChange={(event) => {
									setCustomerRestaurantId(event.target.value)
								}}				  
							>
								{customerRestaurants.map((restaurant, index) => {
									return <MenuItem value={restaurant.id} key={index}>#{restaurant.id} {restaurant.restaurant_name}&nbsp;<i style={{color:'#91a5b4'}}>— {truncateText((restaurant.restaurant_description != null ? restaurant.restaurant_description : ""), 20)} ({restaurant.restaurant_address_city})</i></MenuItem>
								})}
							</Select>
						</FormControl>
					)}

					{!props.courier && (
						<FormGroup style={{marginTop:10,paddingTop:10,borderTop: '1px solid #b5c2c9'}}>
							<FormControlLabel control={<Checkbox disabled checked={customerDataPolicy} onChange={(event) => {setCustomerDataPolicy(event.target.checked)}} />} label="Ich stimme der Datenschutzerklärung zu" />
							<FormControlLabel control={<Checkbox disabled checked={customerTerms} onChange={(event) => {setCustomerTerms(event.target.checked)}} />} label="Ich stimme den Allgemeinen Geschäftsbedingungen zu." />
							<FormControlLabel control={<Checkbox checked={customerTracking} onChange={(event) => {setCustomerTracking(event.target.checked)}} />} label="Ich bin mit personalisierten Angeboten einverstanden" />
						</FormGroup>
					)}

				</DialogContent>
				<DialogActions>
					<div>
						{props.courier ? (
							<>
								<Button style={{marginRight:'5px'}} color="error" onClick={() => {props.deleteHandler(props.row)}}>Sperren</Button>
								<Button style={{marginRight:'5px'}} onClick={() => {
									window.open("https://deliverone.de/reset-password", "DELIVERONE_RESET_PASSWORD")
								}}>Passwort zurücksetzen</Button>
							</>
						) : (
							<Button style={{marginRight:'5px'}} onClick={() => {
								navigate.push("/customer-orders/" + props.row.id);
							}}>Bestellhistorie</Button>
						)}
					</div>
					<div>
						<Button style={{marginRight:'5px'}} onClick={props.closeHandler}>Schließen</Button>
						<Button onClick={onSave}>Speichern</Button>
					</div>
				</DialogActions>
			</Dialog>

			<Backdrop
				sx={{ color: '#fff', zIndex: 999999 }}
				open={isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSnackbar}
				message={snackbarMessage}
				sx={{ zIndex: 9999999 }}
				action={(
					<React.Fragment>
						{isLoading ? (
							<CircularProgress size={20} />
						) : (
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() => {setShowSnackbar(false)}}
							>
								<CloseIcon fontSize="small" />
							</IconButton>
						)}
					</React.Fragment>
				)}
			/>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
		</>
	)
}

export default CustomersEdit;