import React, { useState, useEffect } from "react";

import Box from '@mui/system/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import LockIcon from '@mui/icons-material/Lock';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonIcon from '@mui/icons-material/Person';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../components/Loader';
import useDebounce from '../../utils/useDebounce';
import { useHistory } from "react-router-dom";

import Pagination from '@mui/material/Pagination';

function createData(id, name, phone, mail, ordersCount, city, role, deleted, created_at, last_order_date, last_activity, rawDetails) {
	return { id, name, phone, mail, ordersCount, city, role, deleted, created_at, last_order_date, last_activity, rawDetails };
}

function CustomersIndex(props) {

	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [tableRows, setTableRows] = useState([]);
	const [paginationPage, setPaginationPage] = useState(1);
	const [paginationTotalPages, setPaginationTotalPages] = useState(1);
	const [isAdmin, setIsAdmin] = useState(false);
	let navigate = useHistory();

	useDebounce(() => {
		
		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/customers?page=${paginationPage}&showGuestUsers=${props.showGuestUsers}&sortBy=${props.sortBy}&searchQuery=${props.searchQuery}`)
		.then((res) => {
			setIsLoading(false);
			if (res.status === 200 && res.data) {
				// Everything OK
				if (res.data.current_page) {

					setPaginationPage(res.data.current_page);
					setPaginationTotalPages(res.data.last_page);

					let temporaryTableRows = [];
					res.data.data.forEach((customer) => {
						temporaryTableRows.push(createData(customer.id, customer.name, customer.phone, customer.email, customer.orders_count, customer.city, customer.role_name, customer.deleted_at, customer.created_at, customer.last_order_date, customer.last_activity, customer));
					})
					setTableRows(temporaryTableRows);
					setIsLoading(false);

					props.setTotalCustomers(res.data.total)
				}
			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})

	}, [props.searchQuery], 800);
	
	useDebounce(() => {
		
		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
		}
		if (userData.role_name === "admin") {
			setIsAdmin(true);
		}

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/customers?page=${paginationPage}&showGuestUsers=${props.showGuestUsers}&sortBy=${props.sortBy}`)
		.then((res) => {
			if (res.status === 200 && res.data) {
				// Everything OK
				if (res.data.current_page) {

					setPaginationPage(res.data.current_page);
					setPaginationTotalPages(res.data.last_page);

					let temporaryTableRows = [];
					res.data.data.forEach((customer) => {
						temporaryTableRows.push(createData(customer.id, customer.name, customer.phone, customer.email, customer.orders_count, customer.city, customer.role_name, customer.deleted_at, customer.created_at, customer.last_order_date, customer.last_activity, customer));
					})
					setTableRows(temporaryTableRows);
					setIsLoading(false);

					props.setTotalCustomers(res.data.total)
				}
			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})
		
	}, [props.reloadKey, props.sortBy], 800);

	const handleError = (error) => {
		setIsLoading(false);
		setShowErrorSnackbar(true)
	}

	return (
		<Box padding={2}>
			{isLoading ? (
				<Loader />
			) : (
				<TableContainer className="PageMenu-Table">
					<Table size="small" aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell style={{padding:'6px 16px'}}>Kundennr.</TableCell>
								<TableCell style={{padding:'6px 16px'}}>
									Name<br />
									<small><i>Letzte Aktivität</i></small>
								</TableCell>
								<TableCell style={{padding:'6px 16px'}}>Telefon</TableCell>
								<TableCell style={{padding:'6px 16px'}}>E-Mail</TableCell>
								<TableCell style={{padding:'6px 16px'}}>Ort</TableCell>
								<TableCell style={{padding:'6px 16px'}}>
									Bestellungen<br />
									<small><i>Letzte Bestellung</i></small>
								</TableCell>
								<TableCell style={{padding:'6px 16px'}}>Registriert</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableRows.map((row) => (
								<TableRow
									onClick={() => {
										if (isAdmin) {
											props.editHandler(row.rawDetails)
										} else {
											navigate.push("/customer-orders/" + row.id);
										}
									}}
									key={row.id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									style={(row.name.includes("Gast Nutzer") || row.deleted != null) ? {
										opacity: 0.6,
										pointerEvents: 'none'
									} : {}}
								>
									<TableCell component="td" style={{whiteSpace: 'nowrap'}} scope="row">
										{row.role === "admin" && (
											<LockIcon fontSize="small" style={{marginRight:'10px',float:'left'}} />
										)}
										{row.role === "restaurant" && (
											<StorefrontIcon fontSize="small" style={{marginRight:'10px',float:'left'}} />
										)}
										{row.name.includes("Gast Nutzer") && (
											<PersonRemoveIcon fontSize="small" style={{marginRight:'10px',float:'left'}} />
										)}
										{row.deleted != null && (
											<PersonRemoveIcon fontSize="small" style={{marginRight:'10px',float:'left'}} />
										)}
										{(row.role === "user" && row.name.includes("Gast Nutzer") === false && row.deleted == null) && (
											<PersonIcon fontSize="small" style={{marginRight:'10px',float:'left'}} />
										)}
										#{row.id}
									</TableCell>
									<TableCell component="td" scope="row">
										{row.name}{row.last_activity !== null && (<>
											<br />
											<i><small>{row.last_activity}</small></i>
										</>)}
									</TableCell>
									<TableCell component="td" scope="row">
										{row.phone} {(!row.name.includes("Gast Nutzer")) ? "(verifiziert)" : ""}
									</TableCell>
									<TableCell component="td" scope="row">
										{row.mail}
									</TableCell>
									<TableCell component="td" scope="row">
										{row.city}
									</TableCell>
									<TableCell component="td" scope="row">
										{row.ordersCount} Bestellung(en)
										{row.ordersCount > 0 && (<>
											<br />
											<i><small>{row.last_order_date}</small></i>
										</>)}
									</TableCell>
									<TableCell component="td" scope="row">
										{row.created_at}<br />
										<i><small>{row.rawDetails.created_at_raw} Uhr</small></i>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}

			<div style={{marginTop: '20px'}}>
				<center className="CustomersPagination">
					<Pagination
						count={paginationTotalPages}
						page={paginationPage}
						variant="outlined"
						color="primary"
						onChange={(event, value) => {
							setPaginationPage(value)
							setTimeout(() => {
								props.setReloadKey(Math.random())
							}, 250)
						}}
					/>
				</center>
			</div>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
		</Box>
	)
}

export default CustomersIndex;