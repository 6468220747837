import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ProductCreatedImage from '../../assets/product-created.svg';
import ErrorImage from '../../assets/error.svg';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Steps
import StepMain from './StepMain';
import StepIngredients from './StepIngredients';
import StepPrices from './StepPrices';

import { useParams, useHistory } from "react-router-dom";

import './style.scss';

export default function PageProductDetails() {

	let params = useParams();
	let navigate = useHistory();

	const [reloadKey, setReloadKey] = useState(0);
	const [alertModalVisible, setAlertModalVisible] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set());
	const [hasError, setHasError] = useState(false);

	// Form data
	const [formProductRestaurantId, setFormProductRestaurantId] = useState(0);
	const [formProductName, setFormProductName] = useState('');
	const [formProductDescription, setFormProductDescription] = useState('');
	const [formProductEnabled, setFormProductEnabled] = useState(true);
	const [formProductIngredients, setFormProductIngredients] = useState('');
	const [formProductAllergens, setFormProductAllergens] = useState('');
	const [formProductTaxValue, setFormProductTaxValue] = useState(0);
	const [formProductHasMultipleSizes, setFormProductHasMultipleSizes] = useState(false);
	const [formProductSizes, setFormProductSizes] = useState([]);
	const [formProductCategoryId, setFormProductCategoryId] = useState(0);
	const [formProductSinglePrice, setFormProductSinglePrice] = useState(0);

	const steps = [
		'Grunddaten',
		'Zutaten',
		'Preise'
	];

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}
	
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	useEffect(() => {

		setIsLoading(true);

		// Get Product Details
		// params.productId
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/products?id=${params.productId}`)
		.then((res) => {
			if (res.status === 200 && res.data) {

				setIsLoading(false);
				
				if (res.data.length === 1) {
					if (!res.data[0].id) {
						handleError(res)
					} else {
						
						setFormProductName(res.data[0].product_name);
						setFormProductDescription(res.data[0].product_description == null ? '' : res.data[0].product_description);
						setFormProductEnabled(res.data[0].product_status === 1 ? true : false);
						
						let allergensArray = [];
						if (res.data[0].allergens) {
							res.data[0].allergens.forEach(allergenElement => {
								allergensArray.push({
									id: allergenElement.id,
									title: allergenElement.allergen_name
								});
							});
						}
						setFormProductAllergens(allergensArray);
						setFormProductTaxValue(res.data[0].tax_value);
						setFormProductHasMultipleSizes(res.data[0].has_multiple_sizes);
						setFormProductSizes(res.data[0].sizes);
						setFormProductRestaurantId(res.data[0].product_restaurant_id);
						setFormProductCategoryId(res.data[0].product_category_id);

						if (res.data[0].has_multiple_sizes == false) {
							setFormProductSinglePrice(res.data[0].lowest_price);
						}

					}
				} else {
					handleError(res)
				}
			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})

		document.title = 'Produkt bearbeiten — Speisekarte — ' + process.env.REACT_APP_APPLICATION_NAME;
		
	}, [params, reloadKey])

	const handleError = (error) => {
		setShowErrorSnackbar(true)
		setIsLoading(false);
	}

	const handleSubmit = () => {

		setIsLoading(true);

		// Create Product

		const formData = new FormData();

		formData.append("formProductName", formProductName);
		formData.append("formProductDescription", formProductDescription);
		formData.append("formProductRestaurantId", formProductRestaurantId);
		formData.append("formProductCategoryId", formProductCategoryId);
		formData.append("formProductEnabled", formProductEnabled);
		formData.append("formProductIngredients", formProductIngredients);
		formData.append("formProductAllergens", JSON.stringify(formProductAllergens));
		formData.append("formProductSinglePrice", formProductSinglePrice);
		formData.append("formProductTaxValue", formProductTaxValue);
		formData.append("formProductHasMultiplePrices", formProductHasMultipleSizes);
		formData.append("formProductMultiplePrices", JSON.stringify(formProductSizes));

		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};
		const API = "api/backend/restaurant/products/" + params.productId;
		const url = `${process.env.REACT_APP_API_URL}/${API}`;
	
		axios.post(url, formData)
			.then(function (response) {
				
				setIsLoading(false);
				if (response.status === 200) {
					if (response.data.status === 'success') {
						setIsLoading(false)
						navigate.push("/products");
					} else {
						setIsLoading(false)
						setHasError(true)
						handleNext();
						
					}
				} else {
					setIsLoading(false)
					setHasError(true)
					handleNext();
					
				}
			})
			.catch(function (error) {
				setIsLoading(false)
				setHasError(true)
				handleNext();
				
			})
	}

	const deleteProduct = () => {
		setIsLoading(true);

		const API = "api/backend/restaurant/products/" + params.productId;
		const url = `${process.env.REACT_APP_API_URL}/${API}`;
	
		axios.delete(url)
			.then(function (response) {
				
				setIsLoading(false);
				if (response.status === 200) {
					if (response.data.status === 'success') {
						setIsLoading(false)
						window.location.href = "/products";
					} else {
						setIsLoading(false)
						setHasError(true)
						window.location.href = "/products";
						
					}
				} else {
					setIsLoading(false)
					setHasError(true)
					window.location.href = "/products";
					
				}
			})
			.catch(function (error) {
				setIsLoading(false)
				setHasError(true)
				window.location.href = "/products";
				
			})
	}

	const duplicateProduct = () => {
		setIsLoading(true);

		const API = "api/backend/restaurant/products/duplicate/" + params.productId;
		const url = `${process.env.REACT_APP_API_URL}/${API}`;

		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		}
	
		axios.post(url, {}, config)
			.then(function (response) {
				
				setIsLoading(false);
				if (response.status === 200) {
					if (response.data.status === 'success') {
						setIsLoading(false)
						setActiveStep(0);
						setReloadKey(Math.random())
						navigate.push("/products/" + response.data.productId);
					} else {
						setIsLoading(false)
						setHasError(true)
						setActiveStep(0);
						setReloadKey(Math.random())
						navigate.push("/products");
						
					}
				} else {
					setIsLoading(false)
					setHasError(true)
					setActiveStep(0);
					setReloadKey(Math.random())
					navigate.push("/products");
					
				}
			})
			.catch(function (error) {
				setIsLoading(false)
				setHasError(true)
				navigate.push("/products");
				
			})	
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{justifyContent:'space-between'}} spacing={1} alignItems="center">
							<Grid item>
								{activeStep === 0 && (<><b>Grunddaten</b> — Informationen zu Deinem Artikel</>)}
								{activeStep === 1 && (<><b>Zutaten</b> — Allergene und Zutatenliste</>)}
								{activeStep === 2 && (<><b>Preise</b> — Preisinformationen &amp; Steuern</>)}
							</Grid>
							<Grid item>
								<Tooltip title="Löschen">
									<IconButton onClick={() => {setAlertModalVisible(true)}}>
										<DeleteOutlineIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
								<Tooltip title="Kopieren">
									<IconButton onClick={duplicateProduct}>
										<CopyAllIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {navigate.push(0)}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>

				<Stepper activeStep={activeStep}>
					{steps.map((label, index) => {
						const stepProps = {};
						const labelProps = {};
						if (isStepSkipped(index)) {
							stepProps.completed = false;
						}
						return (
							<Step key={label} {...stepProps}>
								<StepLabel {...labelProps}>{label}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
				{activeStep === steps.length ? (
					<React.Fragment>
						<div style={{textAlign:'center',padding:'45px 0'}}>
							<img style={{width:'300px',marginBottom:'30px'}} src={(hasError ? ErrorImage : ProductCreatedImage)} alt="" /><br />
							<Typography variant="h6" gutterBottom>
								{hasError ? 'Ein Fehler ist aufgetreten. Bitte kontaktiere den Support: +49 8342 7050-752' : 'Dein Produkt wurde erfolgreich gespeichert!'}
							</Typography>
						</div>
					</React.Fragment>
				) : (
					activeStep === 0 ? <StepMain
											isLoading={setIsLoading}
											isLoadingState={isLoading}
											handleNext={handleNext}
											handleBack={handleBack}
											formProductName={formProductName}
											setFormProductName={setFormProductName}
											formProductDescription={formProductDescription}
											setFormProductDescription={setFormProductDescription}
											formProductEnabled={formProductEnabled}
											setFormProductEnabled={setFormProductEnabled}
											formProductCategoryId={formProductCategoryId}
											setFormProductCategoryId={setFormProductCategoryId}
											formProductRestaurantId={formProductRestaurantId}
											setFormProductRestaurantId={setFormProductRestaurantId}
										/> :
					activeStep === 1 ? <StepIngredients
											isLoading={setIsLoading}
											isLoadingState={isLoading}
											handleNext={handleNext}
											handleBack={handleBack}
											formProductIngredients={formProductIngredients}
											setFormProductIngredients={setFormProductIngredients}
											formProductAllergens={formProductAllergens}
											setFormProductAllergens={setFormProductAllergens}
										/> : 
										<StepPrices
											isLoading={setIsLoading}
											isLoadingState={isLoading}
											handleNext={handleNext}
											handleBack={handleBack}
											formProductTaxValue={formProductTaxValue}
											setFormProductTaxValue={setFormProductTaxValue}
											formProductHasMultipleSizes={formProductHasMultipleSizes}
											setFormProductHasMultipleSizes={setFormProductHasMultipleSizes}
											formProductSizes={formProductSizes}
											setFormProductSizes={setFormProductSizes}
											formProductSinglePrice={formProductSinglePrice}
											setFormProductSinglePrice={setFormProductSinglePrice}
											handleSubmit={handleSubmit}
										/>
				)}

				</Box>

			</Paper>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={isLoading}
				message="Wird geladen..."
				sx={{ zIndex: 9999999 }}
				action={(
					<CircularProgress style={{marginRight:'10px'}} size={20} />
				)}
			/>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>

			<Dialog
				open={alertModalVisible}
				onClose={() => {setAlertModalVisible(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title">
					Wirklich Löschen?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Möchtest du diesen Artikel wirklich <b>unwiderruflich</b> löschen?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {setAlertModalVisible(false)}} autoFocus>
						Abbrechen
					</Button>
					<Button onClick={deleteProduct} color="error">
						Ja, löschen
					</Button>
				</DialogActions>
			</Dialog>
			
		</>
	);
}