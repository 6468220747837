import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams, useHistory } from "react-router-dom";
import axios from 'axios';
import PaidIcon from '@mui/icons-material/Paid';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import Currency from 'react-currency-formatter';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './style.scss';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function PageOrderDetails() {

	let navigate = useHistory();
	let params = useParams();
	const [orderDetails, setOrderDetails] = useState({});
	const [dayIsToday, setDayIsToday] = useState(false);
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [moneyHasBeenPaidBack, setMoneyHasBeenPaidBack] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [loadingKey, setLoadingKey] = useState(Math.random());

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		document.title = 'Bestellung #' + params.orderId + ' — ' + process.env.REACT_APP_APPLICATION_NAME;

		if (params.orderId) {
			// Load Order
			axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/orders/${params.orderId}`)
			.then((res) => {
				if (res.status === 200 && res.data) {
					
					setOrderDetails(res.data[0]);

					let inputDate = new Date(res.data[0].created_at);
					let todaysDate = new Date();
					setDayIsToday(inputDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0));

					if (res.data[0].order_status == "canceled" || res.data[0].order_status == "canceled_payment") {
						setMoneyHasBeenPaidBack(true);
					}

					setIsLoaded(true);

				} else {
					// Error
					
				}
			})
			.catch((err) => {
				
			})
		}

	}, [params.orderId, loadingKey]);

	const updateOrderStatus = (status, deliveryTime = null) => {
		// Update Order Status
		setIsLoaded(false);
		axios.post((deliveryTime ? `${process.env.REACT_APP_API_URL}/api/backend/restaurant/orders/status/${params.orderId}/${status}/${deliveryTime}` : `${process.env.REACT_APP_API_URL}/api/backend/restaurant/orders/status/${params.orderId}/${status}`))
		.then((res) => {
			if (res.status === 200 && res.data) {
				
				setLoadingKey(Math.random());
			} else {
				// Error
				
			}
		})
		.catch((err) => {
			
		})
	}

	return (
		<>
			{(moneyHasBeenPaidBack && orderDetails.order_payment_type !== 'cash' && orderDetails.order_payment_type !== "eccard" && orderDetails.order_payment_type !== "paypal") && (
				<Alert sx={{ maxWidth: 'calc(100% - 100px)', margin: 'auto', overflow: 'hidden', marginBottom: 2 }} severity="success">
					{orderDetails.order_payment_type === "paypal" && 'Der Rechnungsbetrag wird dem Kunden per PayPal gutgeschrieben! Bitte zahle nichts aus.'}
					{orderDetails.order_payment_type === "creditcard" && 'Der Rechnungsbetrag wird dem Kunden auf die Kreditkarte gutgeschrieben! Bitte zahle nichts aus.'}
					{orderDetails.order_payment_type === "apple-pay" && 'Der Rechnungsbetrag wird dem Kunden auf die Kreditkarte gutgeschrieben! Bitte zahle nichts aus.'}
					{orderDetails.order_payment_type.includes("deliverone-payments") && 'Der Rechnungsbetrag wird dem Kunden über die ursprüngliche Zahlungsmethode gutgeschrieben! Bitte zahle nichts aus.'}
				</Alert>
			)}
			{(orderDetails.is_preorder === 1) && (
				<Alert sx={{ maxWidth: 'calc(100% - 100px)', margin: 'auto', overflow: 'hidden', marginBottom: 2 }} severity="info">
					<b>Vorbestellung: </b>{orderDetails.order_delivery_type === "pickup" ? 'Abholung' : 'Lieferung'} um {(new Date(orderDetails.preorder_date).toLocaleTimeString([], {timeStyle: 'short'}))} Uhr
				</Alert>
			)}
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				{!isLoaded ? (
					<div style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100px'
					}}><CircularProgress disableShrink /></div>
				) : (
					<>	
						{orderDetails.live_tracking && (
							<AppBar
								position="static"
								color="default"
								elevation={0}
								sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
								style={{
									background: '#329be5',
									color: 'white',
									borderBottom: '3px solid #2989cd',
									cursor: orderDetails.courier_position?.user_id ? 'pointer' : 'default'
								}}
								onClick={() => {
									if (orderDetails.courier_position?.user_id) {
										navigate.push("/couriers/" + orderDetails.courier_position.user_id);
									}
								}}
							>
								<Toolbar className="PageOrderDetails-AppBar">
									<Grid container style={{flexDirection:'row',justifyContent:'space-between'}} spacing={1} alignItems="center">
										<Grid item style={{
											display: 'flex',
											alignItems: 'center'
										}}>
											<b>Live-Tracking ist aktiviert. {orderDetails.courier_position?.user_id && "Klicke um den Standort anzuzeigen."}</b>
										</Grid>
									</Grid>
								</Toolbar>
							</AppBar>
						)}
						<AppBar
							position="static"
							color="default"
							elevation={0}
							sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
						>
							<Toolbar className="PageOrderDetails-AppBar">
								<Grid container style={{flexDirection:'row',justifyContent:'space-between'}} spacing={1} alignItems="center">
									<Grid item style={{
										display: 'flex',
										alignItems: 'center'
									}}>
										<div
											className={`PageOrderDetails-Status PageOrderDetails-Status--${orderDetails.order_status}`}
										></div> <b>{orderDetails.order_status_string}</b>
									</Grid>
								</Grid>
							</Toolbar>
						</AppBar>
						<AppBar
							position="static"
							color="default"
							elevation={0}
							sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
						>
							<Toolbar className="PageOrderDetails-AppBar">
								<Grid container style={{flexDirection:'row',justifyContent:'space-between'}} spacing={1} alignItems="center">
									<Grid item>
										<b>#{params.orderId} ({dayIsToday ? 'Heute' : orderDetails.order_date}, {orderDetails.order_time} Uhr)</b>
									</Grid>
									<Grid item>
										<b onClick={() => {
											navigate.push("/customer-orders/" + orderDetails.order_user_id);
										}} style={{
											textDecoration: 'underline',
											cursor: 'pointer',
											padding: '7px 0'
										}}>{orderDetails.order_delivery_address_name}</b>
									</Grid>
								</Grid>
							</Toolbar>
						</AppBar>
						<AppBar
							position="static"
							color="default"
							elevation={0}
							sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
						>
							<Toolbar className="PageOrderDetails-AppBar">
								<Grid container style={{flexDirection:'row',justifyContent:'space-between'}} spacing={1} alignItems="center">
									<Grid item>
										<PaidIcon style={{
											float: 'left',
											marginRight: '10px'
										}} />
										<b>
											{orderDetails.order_payment_type === 'cash' && 'Barzahlung'}
											{orderDetails.order_payment_type === 'eccard' && 'EC-Karte'}
											{orderDetails.order_payment_type === 'creditcard' && 'Kreditkarte (Zahlung abgeschlossen)'}
											{orderDetails.order_payment_type === 'apple-pay' && 'Apple Pay (Zahlung abgeschlossen)'}
											{orderDetails.order_payment_type === 'paypal' && 'PayPal (Zahlung abgeschlossen)'}
											{orderDetails.order_payment_type.includes("deliverone-payments") && 'DeliverOne Payments (Zahlung abgeschlossen)'}
										</b>
									</Grid>
								</Grid>
							</Toolbar>
						</AppBar>
						<AppBar
							position="static"
							color="default"
							elevation={0}
							sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
						>
							<Toolbar className="PageOrderDetails-AppBar">
								<Grid container style={{flexDirection:'row',justifyContent:'space-between'}} spacing={1} alignItems="center">
									{orderDetails.is_preorder === 1 ? (
										<Grid item>
											<DeliveryDiningIcon style={{
												float: 'left',
												marginRight: '10px'
											}} />
											{orderDetails.is_preorder === 1 ? (
												<>
													<b>{orderDetails.order_delivery_type === "pickup" ? 'Abholung' : 'Lieferung'} um {(new Date(orderDetails.preorder_date).toLocaleTimeString([], {timeStyle: 'short'}))} Uhr (Vorbestellung!)</b>
												</>
											) : (
												<>
													<b>{orderDetails.order_delivery_type === "pickup" ? 'Abholung' : 'Lieferung'} um {orderDetails.order_time} Uhr</b>
												</>
											)}
										</Grid>
									) : (
										<Grid item>
											<DeliveryDiningIcon style={{
												float: 'left',
												marginRight: '10px'
											}} />
											<b>{orderDetails.order_delivery_type === "pickup" ? 'Abholung' : 'Lieferung'} innerhalb von {orderDetails.order_delivery_minutes} Min. bis ca. {orderDetails.finish_time} Uhr</b>
										</Grid>
									)}
								</Grid>
							</Toolbar>
						</AppBar>
		
						<Box padding={2}>

							{(orderDetails.order_note !== "" && orderDetails.order_note != null) && (
								<Alert sx={{ maxWidth: '100%', margin: 'auto', overflow: 'hidden', marginBottom: '30px', marginTop: '10px' }} severity="warning">
									Nachricht vom Kunden: {orderDetails.order_note}
								</Alert>
							)}

							<div className="PageOrderDetails-OrderProducts">
								{orderDetails.order_products.map((orderItem, index) => (
									<React.Fragment key={index}>
										<div key={index} className={`PageOrderDetails-OrderProducts-Item${orderItem.product_extra_ingredients != "[]" ? ' PageOrderDetails-OrderProducts-Item--has-extras' : ''}`}>
											<div className="PageOrderDetails-OrderProducts-ItemDetails">
												<p className="PageOrderDetails-OrderProducts-ItemDetails-Category">{orderItem.product.category_name}</p>
												<p className="PageOrderDetails-OrderProducts-ItemDetails-Name">{orderItem.product.product_name} ({orderItem.product_price_name})</p>
											</div>
											<div className="PageOrderDetails-OrderProducts-ItemPrice">
												<p className="PageOrderDetails-OrderProducts-ItemPrice-Value">
													<Currency
														quantity={parseFloat(orderItem.product_price)}
														currency="EUR"
													/>
												</p>
											</div>
										</div>
										{orderItem.product_extra_ingredients != "[]" && (
											JSON.parse(orderItem.product_extra_ingredients).map((orderExtraIngredient, orderExtraIngredientIndex) => (
												<div key={orderExtraIngredientIndex} className="PageOrderDetails-OrderProducts-ExtraIngredient">
													<p className="PageOrderDetails-OrderProducts-ExtraIngredient-Name">+ {orderExtraIngredient.label.label}</p>
													<p className="PageOrderDetails-OrderProducts-ExtraIngredient-Price">
														+ <Currency
															quantity={parseFloat(orderExtraIngredient.label.priceRaw)}
															currency="EUR"
														/>
													</p>
												</div>
											))
										)}
									</React.Fragment>
								))}
							</div>

							<br /><br />

							<div className="PageOrderDetails-OrderSummary">
								<div className="PageOrderDetails-OrderSummary-Item">
									<p className="PageOrderDetails-OrderSummary-Item-Address">
										{orderDetails.order_delivery_type === "pickup" ? 'Abholung durch:' : 'Lieferung an:'}<br />
										{orderDetails.order_delivery_address_name}<br />
										{orderDetails.order_delivery_address_street !== "" && (
											<>
												{orderDetails.order_delivery_address_street}<br />
												{orderDetails.order_delivery_address_city}
											</>
										)}<br /><br />
										Kontaktdaten:<br />
										E-Mail: {orderDetails.order_customer.email}<br />
										Telefon: {orderDetails.order_customer.phone} (verifiziert)<br />
										Bestellt per {orderDetails.order_device === "App" ? "DeliverOne-App" : "Restaurant-Webseite"}<br />
									</p>
								</div>
								<div className="PageOrderDetails-OrderSummary-Item">
									<div className="PageOrderDetails-OrderSummary-Keys">
										<p className="PageOrderDetails-OrderSummary-Keys-Key">Gesamt:</p>
										{orderDetails.order_tip > 0 && (
											<p className="PageOrderDetails-OrderSummary-Keys-Key">Trinkgeld:</p>
										)}
										{orderDetails.order_round_up > 0 && (
											<p className="PageOrderDetails-OrderSummary-Keys-Key">Aufrunden für das Klima:</p>
										)}
										{orderDetails.order_discount > 0 && (
											<p className="PageOrderDetails-OrderSummary-Keys-Key">Rabatte:</p>
										)}
										{orderDetails.order_delivery_price > 0 && (
											<p className="PageOrderDetails-OrderSummary-Keys-Key">Liefergebühr:</p>
										)}
										<p className="PageOrderDetails-OrderSummary-Keys-Key PageOrderDetails-OrderSummary-Keys-Key-Total">TOTAL:</p>
										<p className="PageOrderDetails-OrderSummary-Keys-Key">Bezahlung:</p>
									</div>
									<div className="PageOrderDetails-OrderSummary-Values">
										<p className="PageOrderDetails-OrderSummary-Values-Value">
										+<Currency
												quantity={parseFloat(orderDetails.order_total_price)}
												currency="EUR"
											/>
										</p>
										{orderDetails.order_tip > 0 && (
											<p className="PageOrderDetails-OrderSummary-Values-Value">
												+<Currency
													quantity={parseFloat(orderDetails.order_tip)}
													currency="EUR"
												/>
											</p>
										)}
										{orderDetails.order_round_up > 0 && (
											<p className="PageOrderDetails-OrderSummary-Values-Value">
												+<Currency
													quantity={parseFloat(orderDetails.order_round_up)}
													currency="EUR"
												/>
											</p>
										)}
										{orderDetails.order_discount > 0 && (
											<p className="PageOrderDetails-OrderSummary-Values-Value">
												-<Currency
													quantity={parseFloat(orderDetails.order_discount)}
													currency="EUR"
												/>
											</p>
										)}
										{orderDetails.order_delivery_price > 0 && (
											<p className="PageOrderDetails-OrderSummary-Values-Value">
												+<Currency
													quantity={parseFloat(orderDetails.order_delivery_price)}
													currency="EUR"
												/>
											</p>
										)}
										<p className="PageOrderDetails-OrderSummary-Values-Value  PageOrderDetails-OrderSummary-Values-Value-Total">
											=<Currency
												quantity={parseFloat(orderDetails.order_total_sum)}
												currency="EUR"
											/>
										</p>
										<p className="PageOrderDetails-OrderSummary-Values-Value">
											{orderDetails.order_payment_type === "cash" && "Bar"}
											{orderDetails.order_payment_type === "eccard" && "EC-Karte an d. Tür"}
											{orderDetails.order_payment_type === "creditcard" && "Kreditkarte"}
											{orderDetails.order_payment_type === "apple-pay" && "Apple Pay"}
											{orderDetails.order_payment_type.includes("deliverone-payments") && "DeliverOne Payments"}
											{orderDetails.order_payment_type === "paypal" && "PayPal"}
										</p>
									</div>
								</div>
							</div>

						</Box>

						<div className="PageOrderDetails-Footer">
							{(orderDetails.order_status !== "canceled" && orderDetails.order_status !== "canceled_payment" && orderDetails.order_status !== "finished") ? (
								(orderDetails.order_status === "pending") ? (
									<>
										<Grid container style={{flexDirection:'row',justifyContent:'space-between'}} spacing={1} alignItems="center">
											{orderDetails.is_preorder === 1 ? (
												<Grid item sm={12}>
													<Button disableElevation onClick={() => {
														if (orderDetails.order_status !== "in_progress") {
															updateOrderStatus("in_progress", 0)
														}
													}} variant="contained" size="large">
														Bestellung annehmen<br />
														Fertig bis {(new Date(orderDetails.preorder_date).toLocaleTimeString([], {timeStyle: 'short'}))}
													</Button>
												</Grid>
											) : (
												<>
													<Grid item sm={12} md={3} xs={12}>
														<Button disableElevation onClick={() => {
															if (orderDetails.order_status !== "in_progress") {
																updateOrderStatus("in_progress", 15)
															}
														}} variant="contained" size="large">
															Bestellung annehmen<br />15 min.
														</Button>
													</Grid>
													<Grid item sm={12} md={3} xs={12}>
														<Button disableElevation onClick={() => {
															if (orderDetails.order_status !== "in_progress") {
																updateOrderStatus("in_progress", 30)
															}
														}} variant="contained" size="large">
															Bestellung annehmen<br />30 min.
														</Button>
													</Grid>
													<Grid item sm={12} md={3} xs={12}>
														<Button disableElevation onClick={() => {
															if (orderDetails.order_status !== "in_progress") {
																updateOrderStatus("in_progress", 45)
															}
														}} variant="contained" size="large">
															Bestellung annehmen<br />45 min.
														</Button>
													</Grid>
													<Grid item sm={12} md={3} xs={12}>
														<Button disableElevation onClick={() => {
															if (orderDetails.order_status !== "in_progress") {
																updateOrderStatus("in_progress", 60)
															}
														}} variant="contained" size="large">
															Bestellung annehmen<br />+60 Min.
														</Button>
													</Grid>
												</>
											)}
										</Grid>
										<Button disableElevation onClick={() => {
											if (orderDetails.order_status !== "canceled" && orderDetails.order_status !== "canceled_payment") {
												setShowCancelModal(true)
											}
										}} color="error" variant="contained" size="large">
											Bestellung stornieren
										</Button>
									</>
								) : (
									<>
										{orderDetails.order_delivery_type === "pickup" ? (
											<>
												<Button disableElevation onClick={() => {
													if (orderDetails.order_status !== "finished") {
														updateOrderStatus("finished")
													}
												}} variant="contained" size="large">
													Bestellung abgeholt
												</Button>
												<Button disableElevation onClick={() => {
													if (orderDetails.order_status !== "ready") {
														updateOrderStatus("ready")
													}
												}} variant="contained" size="large">
													Bestellung abholbereit
												</Button>
											</>
										) : (
											<Grid container spacing={1}>
												<Grid item sm={6} xs={12}>
													<Button disableElevation onClick={() => {
														if (orderDetails.order_status !== "finished") {
															updateOrderStatus("finished")
														}
													}} variant="contained" size="large">
														Bestellung erledigt
													</Button>
												</Grid>
												<Grid item sm={6} xs={12}>
													<Button disableElevation onClick={() => {
														if (orderDetails.order_status !== "ready") {
															updateOrderStatus("ready")
														}
													}} variant="contained" size="large">
														Bestellung wird ausgeliefert
													</Button>
												</Grid>
											</Grid>
										)}
										<Button onClick={() => {
											if (orderDetails.order_status !== "canceled" && orderDetails.order_status !== "canceled_payment") {
												setShowCancelModal(true)
											}
										}} disableElevation color="error" variant="contained" size="large">
											Bestellung stornieren
										</Button>
										{orderDetails.is_preorder !== 1 && (
											<>
												<br/>
												<Button onClick={(event) => {
													if (orderDetails.order_status !== "canceled" && orderDetails.order_status !== "finished" && orderDetails.order_status !== "canceled_payment") {
														handleClick(event)
													}
												}} disableElevation color="primary" variant="contained" size="medium">
													Zubereitungszeit ändern
												</Button>
											</>
										)}

										<Menu
											id="deliver-time-menu"
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button',
											}}
											anchorOrigin={{
												vertical: 'center',
												horizontal: 'center',
											}}
											transformOrigin={{
												vertical: 'center',
												horizontal: 'center',
											}}
										>
											<MenuItem onClick={() => {
												handleClose();
												updateOrderStatus("in_progress", 15)
											}}>In Zubereitung: 15 min.</MenuItem>
											<MenuItem onClick={() => {
												handleClose();
												updateOrderStatus("in_progress", 30)
											}}>In Zubereitung: 30 min.</MenuItem>
											<MenuItem onClick={() => {
												handleClose();
												updateOrderStatus("in_progress", 45)
											}}>In Zubereitung: 45 min.</MenuItem>
											<MenuItem onClick={() => {
												handleClose();
												updateOrderStatus("in_progress", 60)
											}}>In Zubereitung: +60 Min.</MenuItem>
										</Menu>
									</>
								)
							) : (
								<Button disableElevation color="warning" style={{pointerEvents:'none',opacity:0.7}} variant="contained" size="large">
									Bestellung abgeschlossen
								</Button>
							)}
						</div>
					</>
				)}

			</Paper>

			<Dialog
				open={showCancelModal}
				onClose={() => {setShowCancelModal(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title">
					Wirklich stornieren?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						ACHTUNG: Durch das Stornieren wird die Bestellung aufgehoben und kann nicht wiederhergestellt werden. DeliverOne benachrichtigt deinen Kunden!<br />
						<p style={{
							color: 'black',
							fontWeight: '500'
						}}>{(orderDetails.order_payment_type === "cash" || orderDetails.order_payment_type === "eccard") ? '' : 'Der Rechnungsbetrag wird auf die Kreditkarte / das PayPal Account überwiesen. Bitte zahle nichts aus!'}</p>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {setShowCancelModal(false)}}>
						Abbrechen
					</Button>
					<Button color="error" onClick={() => {
						setShowCancelModal(false);
						updateOrderStatus("canceled")
						setMoneyHasBeenPaidBack(true);
					}} autoFocus>
						Stornieren
					</Button>
				</DialogActions>
			</Dialog>
			
		</>
	);
}