import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import RefreshIcon from '@mui/icons-material/Refresh';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import CustomersIndex from './CustomersIndex';
import CustomersEdit from './CustomersEdit';
import CustomersDelete from './CustomersDelete';
import CustomersCreate from './CustomersCreate';

import './style.scss';

export default function PageCustomers() {

	const [showEditModal, setShowEditModal] = useState(false);
	const [editModalData, setEditModalData] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteModalData, setDeleteModalData] = useState(null);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showGuestUsers, setShowGuestUsers] = useState(false);
	const [totalCustomers, setTotalCustomers] = useState(0);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [isAdmin, setIsAdmin] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	
	const [sortBy, setSortBy] = useState("created_at_desc");
		/**
		 * - created_at_asc
		 * - created_at_desc
		 * - name_asc
		 * - name_desc
		 * - email_asc
		 * - email_desc
		 * - city_asc
		 * - city_desc
		 * - type_asc
		 * - type_desc
		 * - orders_asc
		 * - orders_desc
		 */

	useEffect(() => {

		document.title = 'Kunden — ' + process.env.REACT_APP_APPLICATION_NAME;

		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
		}
		if (userData.role_name === "admin") {
			setIsAdmin(true);
		}
		
	}, [])

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row',justifyContent:'space-between'}} spacing={1} alignItems="right">
							<Grid style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '10px',
								flexGrow: 1
							}} item>
								{isAdmin && (
									<FormGroup>
										<FormControlLabel
											control={<Switch />}
											label="Gast-Kunden anzeigen"
											checked={showGuestUsers}
											value={showGuestUsers}
											onChange={(event) => {
												setShowGuestUsers(event.target.checked)
												setTimeout(() => {
													setReloadKey(Math.random())
												}, 250)
											}}
										/>
									</FormGroup>
								)}
								<FormControl variant='standard' size="small" style={{
									minWidth: 250,
									height: 36
								}}>
									<Select
										labelId="sort-by-label"
										id="sort-by"
										label="Sortierung"
										value={sortBy}
										onChange={(event) => {
											setSortBy(event.target.value)
										}}
										disableUnderline
									>
										<MenuItem value="created_at_asc">Registriert am (A → Z)</MenuItem>
										<MenuItem value="created_at_desc">Registriert am (Z → A)</MenuItem>
										<MenuItem value="name_asc">Name (A → Z)</MenuItem>
										<MenuItem value="name_desc">Name (Z → A)</MenuItem>
										<MenuItem value="email_asc">E-Mail (A → Z)</MenuItem>
										<MenuItem value="email_desc">E-Mail (Z → A)</MenuItem>
										<MenuItem value="city_asc">Ort (A → Z)</MenuItem>
										<MenuItem value="city_desc">Ort (Z → A)</MenuItem>
										<MenuItem value="type_asc">Benutzer-Typ (A → Z)</MenuItem>
										<MenuItem value="type_desc">Benutzer-Typ (Z → A)</MenuItem>
										<MenuItem value="orders_asc">Bestellungen (A → Z)</MenuItem>
										<MenuItem value="orders_desc">Bestellungen (Z → A)</MenuItem>
									</Select>
								</FormControl>								
								<TextField
									placeholder="Suche nach Kunden"
									InputProps={{
										disableUnderline: true,
										sx: { fontSize: 'default' },
									}}
									value={searchQuery}
									onChange={(event) => {
										setSearchQuery(event.target.value)
									}}
									variant="standard"
								/>
							</Grid>
							<Grid item>
								<i style={{fontSize:'14px',marginRight:'15px'}}>{totalCustomers} Gesamt</i>
								{isAdmin && (
									<Button onClick={() => {setShowCreateModal(true)}} variant="contained" sx={{ mr: 1 }}>
										Erstellen
									</Button>
								)}
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<CustomersIndex
					editHandler={(row) => {
						setEditModalData(row);
						setShowEditModal(true);
					}}
					reloadKey={reloadKey}
					setReloadKey={setReloadKey}
					showGuestUsers={showGuestUsers}
					setTotalCustomers={setTotalCustomers}
					sortBy={sortBy}
					searchQuery={searchQuery}
				/>

			</Paper>

			{showEditModal && (
				<CustomersEdit
					isOpen={showEditModal}
					closeHandler={() => {setShowEditModal(false)}}
					row={editModalData}
					reloadIndex={reloadKey}
					deleteHandler={(row) => {setDeleteModalData(row);setShowDeleteModal(true)}}
				/>
			)}

			{showDeleteModal && (
				<CustomersDelete
					reloadIndex={reloadKey}
					isOpen={showDeleteModal}
					closeHandler={() => {setShowDeleteModal(false)}}
					closeAllHandler={() => {setShowEditModal(false);setShowDeleteModal(false)}}
					row={deleteModalData}
				/>
			)}

			{showCreateModal && (
				<CustomersCreate
					isOpen={showCreateModal}
					closeHandler={() => {setShowCreateModal(false)}}
					reloadIndex={reloadKey}
				/>
			)}
		</>
	);
}