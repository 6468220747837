import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";

import PromotionsIndex from './PromotionsIndex';
import PromotionsEdit from './PromotionsEdit';
import PromotionsDelete from './PromotionsDelete';
import PromotionsCreate from './PromotionsCreate';

import './style.scss';

export default function PagePromotions() {

	let navigate = useHistory();
	const [showMessageSnackbar, setShowMessageSnackbar] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editModalData, setEditModalData] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteModalData, setDeleteModalData] = useState(null);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [reloadKey, setReloadKey] = useState(Math.random());

	useEffect(() => {
		document.title = 'Aktionen — ' + process.env.REACT_APP_APPLICATION_NAME;	
	})

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Button onClick={() => {setShowMessageSnackbar(true)}} variant="contained" sx={{ mr: 1 }}>
									Erstellen
								</Button>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<PromotionsIndex
					editHandler={(row) => {
						setEditModalData(row);
						setShowEditModal(true);
					}}
					reloadKey={reloadKey}
				/>

			</Paper>

			<PromotionsEdit
				isOpen={showEditModal}
				closeHandler={() => {setShowEditModal(false)}}
				row={editModalData}
				reloadIndex={() => {setReloadKey(Math.random())}}
				deleteHandler={(row) => {setDeleteModalData(row);setShowDeleteModal(true)}}
			/>

			<PromotionsDelete
				reloadIndex={() => {setReloadKey(Math.random())}}
				isOpen={showDeleteModal}
				closeHandler={() => {setShowDeleteModal(false)}}
				closeAllHandler={() => {setShowEditModal(false);setShowDeleteModal(false)}}
				row={deleteModalData}
			/>

			<PromotionsCreate
				isOpen={showCreateModal}
				closeHandler={() => {setShowCreateModal(false)}}
				reloadIndex={() => {setReloadKey(Math.random())}}
			/>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showMessageSnackbar}
				message="Bitte kontaktieren Sie uns um neue Aktionen/Promotions zu erstellen: sales@deliverone.de"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowMessageSnackbar(false)}}
			/>
			
		</>
	);
}