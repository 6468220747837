import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import './style.scss';

export default function StepOpeningHours(props) {

	const [isLoading, setIsLoading] = useState(false);
	
	const [mondayIsOpen, setMondayIsOpen] = useState(true);
	const [mondayOpenTime1, setMondayOpenTime1] = useState("10:00");
	const [mondayCloseTime1, setMondayCloseTime1] = useState("12:00");
	const [mondayOpenTime2, setMondayOpenTime2] = useState("14:00");
	const [mondayCloseTime2, setMondayCloseTime2] = useState("22:00");
	
	const [tuesdayIsOpen, setTuesdayIsOpen] = useState(true);
	const [tuesdayOpenTime1, setTuesdayOpenTime1] = useState("10:00");
	const [tuesdayCloseTime1, setTuesdayCloseTime1] = useState("12:00");
	const [tuesdayOpenTime2, setTuesdayOpenTime2] = useState("14:00");
	const [tuesdayCloseTime2, setTuesdayCloseTime2] = useState("22:00");
	
	const [wednesdayIsOpen, setWednesdayIsOpen] = useState(true);
	const [wednesdayOpenTime1, setWednesdayOpenTime1] = useState("10:00");
	const [wednesdayCloseTime1, setWednesdayCloseTime1] = useState("12:00");
	const [wednesdayOpenTime2, setWednesdayOpenTime2] = useState("14:00");
	const [wednesdayCloseTime2, setWednesdayCloseTime2] = useState("22:00");
	
	const [thursdayIsOpen, setThursdayIsOpen] = useState(true);
	const [thursdayOpenTime1, setThursdayOpenTime1] = useState("10:00");
	const [thursdayCloseTime1, setThursdayCloseTime1] = useState("12:00");
	const [thursdayOpenTime2, setThursdayOpenTime2] = useState("14:00");
	const [thursdayCloseTime2, setThursdayCloseTime2] = useState("22:00");
	
	const [fridayIsOpen, setFridayIsOpen] = useState(true);
	const [fridayOpenTime1, setFridayOpenTime1] = useState("10:00");
	const [fridayCloseTime1, setFridayCloseTime1] = useState("12:00");
	const [fridayOpenTime2, setFridayOpenTime2] = useState("14:00");
	const [fridayCloseTime2, setFridayCloseTime2] = useState("22:00");
	
	const [saturdayIsOpen, setSaturdayIsOpen] = useState(true);
	const [saturdayOpenTime1, setSaturdayOpenTime1] = useState("10:00");
	const [saturdayCloseTime1, setSaturdayCloseTime1] = useState("12:00");
	const [saturdayOpenTime2, setSaturdayOpenTime2] = useState("14:00");
	const [saturdayCloseTime2, setSaturdayCloseTime2] = useState("22:00");
	
	const [sundayIsOpen, setSundayIsOpen] = useState(true);
	const [sundayOpenTime1, setSundayOpenTime1] = useState("10:00");
	const [sundayCloseTime1, setSundayCloseTime1] = useState("12:00");
	const [sundayOpenTime2, setSundayOpenTime2] = useState("14:00");
	const [sundayCloseTime2, setSundayCloseTime2] = useState("22:00");

	const submitForm = () => {

		const openingHours = {
			'monday': {
				'IsOpen': mondayIsOpen,
				'OpenTime1': mondayOpenTime1,
				'CloseTime1': mondayCloseTime1,
				'OpenTime2': mondayOpenTime2,
				'CloseTime2': mondayCloseTime2
			},
			'tuesday': {
				'IsOpen': tuesdayIsOpen,
				'OpenTime1': tuesdayOpenTime1,
				'CloseTime1': tuesdayCloseTime1,
				'OpenTime2': tuesdayOpenTime2,
				'CloseTime2': tuesdayCloseTime2
			},
			'wednesday': {
				'IsOpen': wednesdayIsOpen,
				'OpenTime1': wednesdayOpenTime1,
				'CloseTime1': wednesdayCloseTime1,
				'OpenTime2': wednesdayOpenTime2,
				'CloseTime2': wednesdayCloseTime2
			},
			'thursday': {
				'IsOpen': thursdayIsOpen,
				'OpenTime1': thursdayOpenTime1,
				'CloseTime1': thursdayCloseTime1,
				'OpenTime2': thursdayOpenTime2,
				'CloseTime2': thursdayCloseTime2
			},
			'friday': {
				'IsOpen': fridayIsOpen,
				'OpenTime1': fridayOpenTime1,
				'CloseTime1': fridayCloseTime1,
				'OpenTime2': fridayOpenTime2,
				'CloseTime2': fridayCloseTime2
			},
			'saturday': {
				'IsOpen': saturdayIsOpen,
				'OpenTime1': saturdayOpenTime1,
				'CloseTime1': saturdayCloseTime1,
				'OpenTime2': saturdayOpenTime2,
				'CloseTime2': saturdayCloseTime2
			},
			'sunday': {
				'IsOpen': sundayIsOpen,
				'OpenTime1': sundayOpenTime1,
				'CloseTime1': sundayCloseTime1,
				'OpenTime2': sundayOpenTime2,
				'CloseTime2': sundayCloseTime2
			}
		}

		props.handleSubmit(openingHours);

	}

	return (
		<Box sx={{ width: '100%' }}>
			<div style={{padding: '30px 0'}}>
				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={mondayIsOpen} onChange={(e) => {setMondayIsOpen(e.target.checked)}} color="primary" />}
								label="Montag"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!mondayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={mondayOpenTime1}
								name="mondayOpenTime1"
								onChange={(e) => {setMondayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!mondayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={mondayCloseTime1}
								name="mondayCloseTime1"
								onChange={(e) => {setMondayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!mondayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={mondayOpenTime2}
								name="mondayOpenTime2"
								onChange={(e) => {setMondayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!mondayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={mondayCloseTime2}
								name="mondayCloseTime2"
								onChange={(e) => {setMondayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>

				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={tuesdayIsOpen} onChange={(e) => {setTuesdayIsOpen(e.target.checked)}} color="primary" />}
								label="Dienstag"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!tuesdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={tuesdayOpenTime1}
								name="tuesdayOpenTime1"
								onChange={(e) => {setTuesdayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!tuesdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={tuesdayCloseTime1}
								name="tuesdayCloseTime1"
								onChange={(e) => {setTuesdayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!tuesdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={tuesdayOpenTime2}
								name="tuesdayOpenTime2"
								onChange={(e) => {setTuesdayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!tuesdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={tuesdayCloseTime2}
								name="tuesdayCloseTime2"
								onChange={(e) => {setTuesdayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>

				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={wednesdayIsOpen} onChange={(e) => {setWednesdayIsOpen(e.target.checked)}} color="primary" />}
								label="Mittwoch"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!wednesdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={wednesdayOpenTime1}
								name="wednesdayOpenTime1"
								onChange={(e) => {setWednesdayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!wednesdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={wednesdayCloseTime1}
								name="wednesdayCloseTime1"
								onChange={(e) => {setWednesdayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!wednesdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={wednesdayOpenTime2}
								name="wednesdayOpenTime2"
								onChange={(e) => {setWednesdayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!wednesdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={wednesdayCloseTime2}
								name="wednesdayCloseTime2"
								onChange={(e) => {setWednesdayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>

				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={thursdayIsOpen} onChange={(e) => {setThursdayIsOpen(e.target.checked)}} color="primary" />}
								label="Donnerstag"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!thursdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={thursdayOpenTime1}
								name="thursdayOpenTime1"
								onChange={(e) => {setThursdayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!thursdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={thursdayCloseTime1}
								name="thursdayCloseTime1"
								onChange={(e) => {setThursdayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!thursdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={thursdayOpenTime2}
								name="thursdayOpenTime2"
								onChange={(e) => {setThursdayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!thursdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={thursdayCloseTime2}
								name="thursdayCloseTime2"
								onChange={(e) => {setThursdayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>

				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={fridayIsOpen} onChange={(e) => {setFridayIsOpen(e.target.checked)}} color="primary" />}
								label="Freitag"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!fridayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={fridayOpenTime1}
								name="fridayOpenTime1"
								onChange={(e) => {setFridayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!fridayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={fridayCloseTime1}
								name="fridayCloseTime1"
								onChange={(e) => {setFridayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!fridayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={fridayOpenTime2}
								name="fridayOpenTime2"
								onChange={(e) => {setFridayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!fridayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={fridayCloseTime2}
								name="fridayCloseTime2"
								onChange={(e) => {setFridayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>

				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={saturdayIsOpen} onChange={(e) => {setSaturdayIsOpen(e.target.checked)}} color="primary" />}
								label="Samstag"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!saturdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={saturdayOpenTime1}
								name="saturdayOpenTime1"
								onChange={(e) => {setSaturdayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!saturdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={saturdayCloseTime1}
								name="saturdayCloseTime1"
								onChange={(e) => {setSaturdayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!saturdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={saturdayOpenTime2}
								name="saturdayOpenTime2"
								onChange={(e) => {setSaturdayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!saturdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={saturdayCloseTime2}
								name="saturdayCloseTime2"
								onChange={(e) => {setSaturdayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>
				
				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={sundayIsOpen} onChange={(e) => {setSundayIsOpen(e.target.checked)}} color="primary" />}
								label="Sonntag"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!sundayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={sundayOpenTime1}
								name="sundayOpenTime1"
								onChange={(e) => {setSundayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!sundayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={sundayCloseTime1}
								name="sundayCloseTime1"
								onChange={(e) => {setSundayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!sundayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={sundayOpenTime2}
								name="sundayOpenTime2"
								onChange={(e) => {setSundayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!sundayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={sundayCloseTime2}
								name="sundayCloseTime2"
								onChange={(e) => {setSundayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>
			</div>

			{props.asStep === true ? (
				<Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
					<Box sx={{ flex: "1 1 auto" }} />
					<Button onClick={props.handleBack}>
						Zurück
					</Button>
					<Button onClick={submitForm}>
						Weiter
					</Button>
				</Box>
			) : (
				<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between' }}>
					<LoadingButton startIcon={<ArrowBackIcon />} loading={isLoading} onClick={props.handleBack}>Zurück</LoadingButton>
					<LoadingButton endIcon={<SaveIcon />} variant="contained" onClick={submitForm} loading={isLoading}>Speichern</LoadingButton>
				</Box>
			)}

		</Box>
	);
}