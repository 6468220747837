import React, { useState, useEffect } from "react";

import Box from '@mui/system/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../components/Loader';

import './style.scss';

function createData( id, terminal_name, terminal_serial_number, terminal_location_id, terminal_type, status ) {
	return { id, terminal_name, terminal_serial_number, terminal_location_id, terminal_type, status };
}

function TerminalsIndex(props) {

	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [tableRows, setTableRows] = useState([]);
	const [originalRows, setOriginalRows] = useState([]);

	useEffect(() => {

		setIsLoading(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/terminals/getRegisteredTerminals`)
		.then((res) => {

			

			setIsLoading(false);
			
			if (res.status === 200 && res.data) {
				// Everything OK
				let temporaryTableRows = [];
				res.data.terminals.forEach((terminal) => {

					let status = "Unbekannt";

					if (terminal.details?.status === "online") {status = "Online";}
					if (terminal.details?.status === "offline") {status = "Offline";}
					if (terminal.details?.status === "unreachable") {status = "Nicht erreichbar";}
					if (terminal.details?.status === "unregistered") {status = "Nicht registriert";}

					temporaryTableRows.push(createData(terminal.id, terminal.terminal_name, terminal.terminal_serial_number, terminal.terminal_location_id, terminal.terminal_type, status));
				})
				setOriginalRows(temporaryTableRows);
				setTableRows(temporaryTableRows);
			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})
		
	}, [props.reloadKey])

	const handleError = (error) => {
		setShowErrorSnackbar(true)
		setIsLoading(false);
	}

	useEffect(() => {
		if (props.toolbarSearchQuery !== '') {
			// Search in the rows for name, category name, sku and price
			let filteredRows = originalRows.filter((row) => {
				return row.terminal_name.toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) ||
					row.terminal_serial_number.toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) || 
					row.terminal_location_id.toString().toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) || 
					row.terminal_type.toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) ||
					row.status.toLowerCase().includes(props.toolbarSearchQuery.toLowerCase())
			})
			
			setTableRows(filteredRows);
		} else {
			setTableRows(originalRows);
		}
	}, [props.toolbarSearchQuery])

	return (
		<Box padding={2}>
			{isLoading ? (
				<Loader />
			) : (
				<>
					{originalRows.length === 0 ? (
						<center style={{
							display: 'block',
							padding: '35px',
							borderRadius: '15px',
							background: 'whitesmoke',
							margin: '5px',
							border: '1px solid #d7d7d7',
						}}>

							<h1 style={{marginTop:'0'}}>Jetzt DeliverOne Terminal bestellen!</h1>

							<h3 style={{fontWeight:"normal"}}>
								Bestellen Sie jetzt Ihr neues DeliverOne Terminal und nehmen Sie Zahlungen per Karte entgegen!
							</h3>

							<Button onClick={() => {
								window.open("https://deliverone.de/deliverone-terminals?utm_source=dashboard&utm_medium=button&utm_campaign=terminals", "_blank");
							}} variant="contained" sx={{ mr: 1 }}>
								Jetzt einkaufen!
							</Button>

						</center>
					) : (
						<TableContainer className="PageMenu-Table">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>#</TableCell>
										<TableCell>Gerätebezeichnung</TableCell>
										<TableCell>Geräteart</TableCell>
										<TableCell>Seriennr.</TableCell>
										<TableCell>Standort-Identifikation</TableCell>
										<TableCell>Status</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{tableRows.map((row) => (
										<TableRow
											key={row.id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell>{row.id}</TableCell>
											<TableCell>{row.terminal_name}</TableCell>
											<TableCell>{row.terminal_type.toUpperCase()}</TableCell>
											<TableCell>{row.terminal_serial_number}</TableCell>
											<TableCell>{row.terminal_location_id}</TableCell>
											<TableCell>{row.status}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</>
			)}
		</Box>
	)
}

export default TerminalsIndex;