import React, { useState, useEffect } from "react";

import Box from '@mui/system/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Currency from 'react-currency-formatter';

import './style.scss';

function createData(id, category_name, product_name, product_id, start_date, end_date, start_date_mdy, end_date_mdy, costs, status) {
	return { id, category_name, product_name, product_id, start_date, end_date, start_date_mdy, end_date_mdy, costs, status };
}

function PromotionsIndex(props) {

	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [tableRows, setTableRows] = useState([]);
	let navigate = useHistory();

	useEffect(() => {

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/promotions`)
		.then((res) => {
			if (res.status === 200 && res.data) {
				// Everything OK
				let temporaryTableRows = [];
				res.data.forEach((promotion) => {

					let status = false;

					if (promotion.start_date <= promotion.current_date && promotion.end_date >= promotion.current_date) {
						status = true;
					}

					temporaryTableRows.push(createData(promotion.id, promotion.category.category_name, promotion.restaurant.restaurant_name + " / " + promotion.product.product_name, promotion.product.id, promotion.start_date_readable, promotion.end_date_readable, promotion.start_date_mdy, promotion.end_date_mdy, promotion.costs, status));
				})
				setTableRows(temporaryTableRows);
			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})
		
	}, [props.reloadKey])

	const handleError = (error) => {
		setShowErrorSnackbar(true)
		
	}

	return (
		<Box padding={2}>
			<TableContainer className="PageMenu-Table">
				<Table size="small" aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Aktions-ID</TableCell>
							<TableCell>Kategorie</TableCell>
							<TableCell>Artikel</TableCell>
							<TableCell>Laufzeit</TableCell>
							<TableCell>Anfallende Kosten (EUR)</TableCell>
							<TableCell align="right">Status</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tableRows.map((row) => (
							<TableRow
								onClick={() => {props.editHandler(row)}}
								key={row.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell component="th" scope="row">
									#{row.id}
								</TableCell>
								<TableCell>
									{row.category_name}
								</TableCell>
								<TableCell>
									{row.product_name}
								</TableCell>
								<TableCell>
									{row.start_date}&nbsp;&nbsp;—&nbsp;&nbsp;{row.end_date}
								</TableCell>
								<TableCell>
									{/*
									<Currency
										quantity={row.costs}
										currency="EUR"
									/>
									*/}
									- kostenlos -
								</TableCell>
								<TableCell align="right">
									<div className={row.status ? 'PagePromotions-Table-Status-Active' : 'PagePromotions-Table-Status-Inactive'}>{row.status ? 'Läuft' : 'Abgelaufen'}</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
		</Box>
	)
}

export default PromotionsIndex;