import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Currency from 'react-currency-formatter';

import PaymentsIndex from './PaymentsIndex';

import axios from 'axios';

import './style.scss';

export default function PageTerminalPayments() {

	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [toolbarSearchQuery, setToolbarSearchQuery] = useState("");
	const [editModalData, setEditModalData] = useState(null);
	const [showAllData, setShowAllData] = useState(false);
	const [showRefundModal, setShowRefundModal] = useState(false);
	const [showRefundSuccessModal, setShowRefundSuccessModal] = useState(false);
	const [refundData, setRefundData] = useState([]);
	
	useEffect(() => {
		setIsLoading(true);

		document.title = 'Terminal Zahlungen — ' + process.env.REACT_APP_APPLICATION_NAME;
	}, [])

	const refundPayment = () => {
		setIsLoading(true);

		axios.post(`${process.env.REACT_APP_API_URL}/api/terminals/payments/refund/${refundData.id}`)
        .then((res) => {
            setShowRefundModal(false);
            setShowRefundSuccessModal(true);
            setIsLoading(false)
        })
        .catch((err) => {
            
            setIsLoading(false)
        })

	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container spacing={2} style={{flexDirection:'row'}} alignItems="center" justifyContent="flex-end">
							<Grid item>
								<FormGroup>
									<FormControlLabel
										control={<Switch />}
										label="Alle Zahlungen anzeigen"
										checked={showAllData}
										value={showAllData}
										onChange={(event) => {
											setShowAllData(event.target.checked)
											setTimeout(() => {
												setReloadKey(Math.random())
											}, 250)
										}}
									/>
								</FormGroup>
							</Grid>
							<Grid item>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<PaymentsIndex setRefundData={setRefundData} setShowRefundModal={setShowRefundModal} showAllData={showAllData} setEditModalOpen={() => {}} setEditModalData={setEditModalData} toolbarSearchQuery={toolbarSearchQuery} reloadKey={reloadKey} />
			</Paper>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Dialog
                open={showRefundModal}
                onClose={() => {setShowRefundModal(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
            >
                <DialogTitle id="alert-dialog-title">
                    Zahlung zurückerstatten
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
						Möchtest Du die Zahlung i. H. v. <b><Currency currency="EUR" quantity={refundData.order_total_sum || 0} /></b> wirklich zurückerstatten? Dieser Vorgang kann nicht rückgängig gemacht werden!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setShowRefundModal(false)}} color="primary" autoFocus>
                        Abbrechen
                    </Button>
                    <Button onClick={refundPayment} color="error">
                        Ja, zurückerstatten
                    </Button>
                </DialogActions>
            </Dialog>
			
			<Dialog
                open={showRefundSuccessModal}
                onClose={() => {setShowRefundSuccessModal(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
            >
                <DialogTitle id="alert-dialog-title">
                    Die Zahlung wird zurückerstattet.
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
						Dies kann einige Tage in Anspruch nehmen.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setShowRefundSuccessModal(false)}} color="primary" autoFocus>
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>

		</>
	);
}