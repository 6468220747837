import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Backdrop from '@mui/material/Backdrop'
import CloseIcon from '@mui/icons-material/Close'
import NumberFormat from 'react-number-format';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField'
import Loader from '../../../components/Loader';

import './style.scss';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
			decimalSeparator=","
            isNumericString
            suffix=" EUR"
			allowNegative={false}
			decimalScale={2}
        />
    );
});

export default function PageAdministratePaidCharges() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [paidChargesItems, setPaidChargesItems] = useState([]);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedPaymentId, setSelectedPaymentId] = useState(0);
	const [restaurants, setRestaurants] = useState([]);

	const [newPaymentRestaurantId, setNewPaymentRestaurantId] = useState("");
	const [newPaymentMonth, setNewPaymentMonth] = useState("");
	const [newPaymentYear, setNewPaymentYear] = useState("");
	const [newPaymentAmount, setNewPaymentAmount] = useState("");
	const [newPaymentDescription, setNewPaymentDescription] = useState("Re.-Nr.: ");

	useEffect(() => {
		document.title = 'Mtl. Gebühren — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`).then((res) => {
				if (res.status === 200 && res.data) {
					setRestaurants(res.data);
				}
			})
			.catch((err) => {})

		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/paid-charges`).then((res) => {
				setIsLoading(false);
				if (res.data) {
					setPaidChargesItems(res.data);
				} else {
					setPaidChargesItems([]);
					handleError();
				}
			}).catch((err) => {
				setIsLoading(false);
				handleError();
			})
	}, [reloadKey])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	const createPayment = () => {
		setIsLoading(true);

		// Validate
		if (newPaymentRestaurantId === "") {
			setIsLoading(false);
			setShowErrorSnackbar(true);
			return;
		}
		if (newPaymentMonth === "") {
			setIsLoading(false);
			setShowErrorSnackbar(true);
			return;
		}
		if (newPaymentYear === "") {
			setIsLoading(false);
			setShowErrorSnackbar(true);
			return;
		}
		if (newPaymentAmount === "") {
			setIsLoading(false);
			setShowErrorSnackbar(true);
			return;
		}
		if (newPaymentDescription === "") {
			setIsLoading(false);
			setShowErrorSnackbar(true);
			return;
		}

		axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/paid-charges`, {
			restaurantId: newPaymentRestaurantId,
			month: newPaymentMonth,
			year: newPaymentYear,
			amount: newPaymentAmount,
			description: newPaymentDescription
		}).then((res) => {
			setIsLoading(false);
			if (res.status === 200) {
				setSuccessSnackbarText("Zahlung erfolgreich erstellt");
				setShowSuccessSnackbar(true);
				setReloadKey(Math.random());
				setShowNewPaymentModal(false);
			} else {
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}

	const deletePayment = (id) => {
		setIsLoading(true);

		axios.delete(`${process.env.REACT_APP_API_URL}/api/administrate/paid-charges/${id}`).then((res) => {
			setIsLoading(false);
			if (res.status === 200) {
				setSuccessSnackbarText("Zahlung erfolgreich storniert");
				setShowSuccessSnackbar(true);
				setReloadKey(Math.random());
				setShowDeleteModal(false);
			} else {
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Button disabled={isLoading} onClick={() => {setShowNewPaymentModal(true)}} variant="contained" sx={{ mr: 1 }}>
									Eingehende Überweisung
								</Button>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<TableContainer className="PageMenu-Table">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Zahlungs-Id</TableCell>
										<TableCell>Restaurant</TableCell>
										<TableCell>Zeitraum</TableCell>
										<TableCell>Betrag</TableCell>
										<TableCell>Verw.-Zweck</TableCell>
										<TableCell>Status</TableCell>
										<TableCell align='right'>Erstellt</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{paidChargesItems.map((row, index) => (
										<TableRow
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											onClick={() => {
												setSelectedPaymentId(row.id);
												setShowDeleteModal(true);
											}}
										>
											<TableCell component="td" scope="row">
												#{row.id}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.restaurant_name} ({row.restaurant_id})
											</TableCell>
											<TableCell component="td" scope="row">
												{row.term_month}/{row.term_year}
											</TableCell>
											<TableCell component="td" scope="row">
												{parseFloat(row.amount).toFixed(2)}&nbsp;EUR
											</TableCell>
											<TableCell component="td" scope="row">
												{row.description}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.status}
											</TableCell>
											<TableCell component="td" align='right' scope="row">
												{row.created_at}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>

			</Paper>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

			<Dialog
				open={showNewPaymentModal}
				onClose={() => {
					if (!isLoading) {
						setShowNewPaymentModal(false)
					} else {
						return;
					}
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					Eingehende Überweisung
				</DialogTitle>
				<DialogContent style={{padding: '20px'}}>
					<Stack spacing={3}>
						<FormControl margin="dense" size='small' fullWidth variant="outlined">
							<InputLabel id="restaurant-label">Restaurant</InputLabel>
							<Select
								labelId="restaurant-label"
								id="restaurant"
								label="Restaurant"
								value={newPaymentRestaurantId}
								onChange={(event) => {
									setNewPaymentRestaurantId(event.target.value)
								}}				  
							>
								{
									restaurants.map((restaurant) => {
										return (
											<MenuItem key={restaurant.id} value={restaurant.id}>{restaurant.restaurant_name} (#{restaurant.id})</MenuItem>
										)
									})
								}
							</Select>
						</FormControl>

						<TextField
							name="price"
							fullWidth
							size="small"
							label="Überweisungsbetrag"
							value={newPaymentAmount}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(event) => {
								setNewPaymentAmount(event.target.value);
							}}
						/>
						
						<TextField
							name="Verwendungszweck"
							fullWidth
							size="small"
							label="Verwendungszweck"
							value={newPaymentDescription}
							onChange={(event) => {
								setNewPaymentDescription(event.target.value)
							}}
						/>

						<FormControl margin="dense" size='small' fullWidth variant="outlined">
							<InputLabel id="discount-type-label">Monat</InputLabel>
							<Select
								labelId="discount-type-label"
								id="discount-type"
								label="Monat"
								value={newPaymentMonth}
								onChange={(event) => {
									setNewPaymentMonth(event.target.value)
								}}				  
							>
								<MenuItem value={"01"}>01 Januar</MenuItem>
								<MenuItem value={"02"}>02 Februar</MenuItem>
								<MenuItem value={"03"}>03 März</MenuItem>
								<MenuItem value={"04"}>04 April</MenuItem>
								<MenuItem value={"05"}>05 Mai</MenuItem>
								<MenuItem value={"06"}>06 Juni</MenuItem>
								<MenuItem value={"07"}>07 Juli</MenuItem>
								<MenuItem value={"08"}>08 August</MenuItem>
								<MenuItem value={"09"}>09 September</MenuItem>
								<MenuItem value={"10"}>10 Oktober</MenuItem>
								<MenuItem value={"11"}>11 November</MenuItem>
								<MenuItem value={"12"}>12 Dezember</MenuItem>
							</Select>
						</FormControl>

						<FormControl margin="dense" size='small' fullWidth variant="outlined">
							<InputLabel id="discount-type-label">Jahr</InputLabel>
							<Select
								labelId="discount-type-label"
								id="discount-type"
								label="Jahr"
								value={newPaymentYear}
								onChange={(event) => {
									setNewPaymentYear(event.target.value)
								}}				  
							>
								<MenuItem value={"2022"}>2022</MenuItem>
								<MenuItem value={"2023"}>2023</MenuItem>
								<MenuItem value={"2024"}>2024</MenuItem>
								<MenuItem value={"2025"}>2025</MenuItem>
								<MenuItem value={"2026"}>2026</MenuItem>
								<MenuItem value={"2027"}>2027</MenuItem>
								<MenuItem value={"2028"}>2028</MenuItem>
								<MenuItem value={"2029"}>2029</MenuItem>
								<MenuItem value={"2030"}>2030</MenuItem>
								<MenuItem value={"2031"}>2031</MenuItem>
								<MenuItem value={"2032"}>2032</MenuItem>
								<MenuItem value={"2033"}>2033</MenuItem>
								<MenuItem value={"2034"}>2034</MenuItem>
								<MenuItem value={"2035"}>2035</MenuItem>
								<MenuItem value={"2036"}>2036</MenuItem>
								<MenuItem value={"2037"}>2037</MenuItem>
								<MenuItem value={"2038"}>2038</MenuItem>
								<MenuItem value={"2039"}>2039</MenuItem>
								<MenuItem value={"2040"}>2040</MenuItem>
								<MenuItem value={"2041"}>2041</MenuItem>
								<MenuItem value={"2042"}>2042</MenuItem>
								<MenuItem value={"2043"}>2043</MenuItem>
								<MenuItem value={"2044"}>2044</MenuItem>
								<MenuItem value={"2045"}>2045</MenuItem>
								<MenuItem value={"2046"}>2046</MenuItem>
								<MenuItem value={"2047"}>2047</MenuItem>
								<MenuItem value={"2048"}>2048</MenuItem>
								<MenuItem value={"2049"}>2049</MenuItem>
								<MenuItem value={"2050"}>2050</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button disabled={isLoading} onClick={() => {
						if (!isLoading) {
							setShowNewPaymentModal(false)
						} else {
							return;
						}
					}}>Abbrechen</Button>
					<Button disabled={isLoading} onClick={() => {
						createPayment()
					}} autoFocus>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={showDeleteModal}
				onClose={() => {setShowDeleteModal(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title">
					Zahlung wirklich stornieren?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Möchtest du diese Zahlung wirklich stornieren? Dieser Vorgang kann nicht rückgängig gemacht werden.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {setShowDeleteModal(false)}}>Nein, abbrechen</Button>
					<Button color="error" onClick={() => {deletePayment(selectedPaymentId)}} autoFocus>
						Ja, stornieren
					</Button>
				</DialogActions>
			</Dialog>

		</>
	);
}