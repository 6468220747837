import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios';

import './style.scss';

export default function StepMain(props) {

	const [isLoading, setIsLoading] = useState(false);
	const [userData, setUserData] = useState([]);
	const [restaurants, setRestaurants] = useState([]);
	const [commonFoodNames, setCommonFoodNames] = useState([]);
	const [categories, setCategories] = useState([]);
	const [formProductRestaurantError, setFormProductRestaurantError] = useState(false);
	const [formProductCategoryError, setFormProductCategoryError] = useState(false);
	const [formProductNameError, setFormProductNameError] = useState(false);
	const [forceHandleNextStep, setForceHandleNextStep] = useState(false);
	const [productNameWarningModalVisible, setProductNameWarningModalVisible] = useState(false);
	const [productNameWarningModalText, setProductNameWarningModalText] = useState("");

	useEffect(() => {

		setIsLoading(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/products/autocomplete`)
		.then((res) => {
			if (res.status === 200 && res.data) {
				// Everything OK
				let tempArray = [];
				res.data.forEach((item) => {
					tempArray.push({title:item.product_name});
				})
				setCommonFoodNames(tempArray);
				
				axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
				.then((res) => {
					if (res.status === 200 && res.data) {
						// Everything OK
						setRestaurants(res.data);
						
						axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/categories`)
						.then((res) => {
							if (res.status === 200 && res.data) {
								// Everything OK
								setCategories(res.data);

								setIsLoading(false);
							} else {
								// Error
								
							}
						})
						.catch((err) => {
							
						})
					} else {
						// Error
						
					}
				})
				.catch((err) => {
					
				})
			} else {
				// Error
				
			}
		})
		.catch((err) => {
			
		})
		

		

		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
			setUserData(userData);
			if (userData.restaurant_access_id != null || userData.restaurant_access_id != 0) {
				props.setFormProductRestaurantId(userData.restaurant_access_id);
			}
		}

	}, [])

	const productNameWarning = (name) => {
		if (name.length < 3) {
			setProductNameWarningModalText("Die Bezeichnung ist etwas kurz. Bist du sicher, dass du es so anlegen möchtest?");
			setProductNameWarningModalVisible(true);
			setForceHandleNextStep(true);
			return true;
		}

		if (name.length > 50) {
			setProductNameWarningModalText("Die Bezeichnung ist zu lang. Bist du sicher, dass du es so anlegen möchtest?");
			setProductNameWarningModalVisible(true);
			setForceHandleNextStep(true);
			return true;
		}

		const warningWords = [
			'pizza',
			'pizzeria',
			'pasta',
			'salat',
			'burger ',
			'getränk',
			'dessert',
			'desert'
		];
		if (warningWords.some(v => (name.toLowerCase()) === v)) {
			
			setProductNameWarningModalText("Du solltest Kategorien (" + name + ") nicht als Bezeichnung verwenden. Bist du sicher, dass du es so anlegen möchtest?");
			setProductNameWarningModalVisible(true);
			setForceHandleNextStep(true);
			return true;
		} else {
			
		}

		return false;
	}

	const handleNextStep = () => {

		props.isLoading(true);
		setIsLoading(true);

		setTimeout(() => {
			setFormProductNameError(false);
			if (props.formProductName.trim() === '') {
				setFormProductNameError(true);
				props.isLoading(false);
				setIsLoading(false);
				return;	
			}

			if (props.formProductRestaurantId === 0) {
				setFormProductRestaurantError(true);
				props.isLoading(false);
				setIsLoading(false);
				return;	
			}

			if (!forceHandleNextStep) {
				if (productNameWarning(props.formProductName.trim())) {
					props.isLoading(false);
					setIsLoading(false);
					return;	
				}
			}

			props.handleNext();

			props.isLoading(false);
			setIsLoading(false);
		}, 100)
	}

	return (
		<Box sx={{ width: '100%' }}>

			<div style={{padding: '30px 0'}}>

				{isLoading ? (
					<div style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100px'
					}}><CircularProgress disableShrink /></div>
				) : (
				<Stack spacing={2}>
					<Autocomplete
						freeSolo
						openOnFocus={false}
						options={commonFoodNames.map((option) => option.title)}
						value={props.formProductName}
						onInputChange={(event, newValue) => {
							props.setFormProductName(newValue);
							setFormProductNameError(false);
						}}
						renderInput={(params) => 
							<TextField
								autoFocus
								{...params}
								size="small"
								label="Bezeichnung"
								error={formProductNameError}
								helperText="Gebe den Namen des Produkts ein."
							/>}
					/>

					<TextField
						size="small"
						label="Beschreibung (optional)"
						helperText="Beschreibe den Geschmack des Produkts sowie die Zutaten. Nenne allerdings keine Allergene. Dieses Feld ist optional."
						multiline
						rows={3}
						value={props.formProductDescription}
						onChange={(e) => props.setFormProductDescription(e.target.value)}
					/>

					{(restaurants.length > 0 && userData.role_name === "admin") && (
						<FormControl fullWidth>
							<InputLabel id="tax-value-label">Restaurant auswählen</InputLabel>
							<Select
								size="small"
								labelId="tax-value-label"
								id="tax-value-select"
								value={props.formProductRestaurantId}
								label="Restaurant auswählen"
								error={formProductRestaurantError}
								onChange={(e) => {
									setFormProductRestaurantError(false)
									props.setFormProductRestaurantId(e.target.value)
								}}
							>
								<MenuItem value="0" disabled>Restaurant auswählen</MenuItem>
								{
									restaurants.map((restaurant) => {
										return (
											<MenuItem key={restaurant.id} value={restaurant.id}>{restaurant.restaurant_name}</MenuItem>
										)
									})
								}
							</Select>
						</FormControl>
					)}

					{categories.length > 0 && (
						<FormControl fullWidth>
							<InputLabel id="tax-value-label">Kategorie auswählen</InputLabel>
							<Select
								size="small"
								labelId="tax-value-label"
								id="tax-value-select"
								value={props.formProductCategoryId}
								label="Kategorie auswählen"
								error={formProductCategoryError}
								onChange={(e) => {
									setFormProductCategoryError(false)
									props.setFormProductCategoryId(e.target.value)
								}}
							>
								<MenuItem value="0" disabled>Kategorie auswählen</MenuItem>
								{
									categories.map((category) => {
										return (
											<MenuItem key={category.id} value={category.id}>{category.category_name}</MenuItem>
										)
									})
								}
							</Select>
						</FormControl>
					)}


					<FormControlLabel label="Produkt im Shop anzeigen" control={<Switch defaultChecked value={props.formProductEnabled} onChange={(event) => {props.setFormProductEnabled(event.target.checked)}} />} />

				</Stack>
				)}


			</div>

			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'flex-end' }}>
				<LoadingButton endIcon={<ArrowForwardIcon />} variant="contained" loading={isLoading} onClick={handleNextStep}>Nächster Schritt</LoadingButton>
			</Box>

			<Dialog
				open={productNameWarningModalVisible}
				onClose={() => {setProductNameWarningModalVisible(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title">
					Hinweis!
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{productNameWarningModalText}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {setProductNameWarningModalVisible(false)}} autoFocus>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>

		</Box>
	);
}