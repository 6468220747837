import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Currency from 'react-currency-formatter';
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Autocomplete from '@mui/material/Autocomplete';
import NumberFormat from 'react-number-format';
import Stack from '@mui/material/Stack';
import Loader from '../../../components/Loader';

import './style.scss';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
			decimalSeparator=","
            isNumericString
            suffix=" EUR"
			allowNegative={true}
			decimalScale={2}
			isAllowed={(values, sourceInfo) => {
				const { value } = values;
				return value;
			}}
        />
    );
});

export default function PageAdministrateInvoiceManagementEditor() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [restaurants, setRestaurants] = useState([]);
	const [restaurantId, setRestaurantId] = useState(0);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [editings, setEditings] = useState([]);

	const [newEditInvoiceRestaurant, setNewEditInvoiceRestaurant] = useState(0);
	const [newEditInvoicePosition, setNewEditInvoicePosition] = useState("");
	const [newEditInvoiceAmount, setNewEditInvoiceAmount] = useState(1);
	const [newEditInvoiceReason, setNewEditInvoiceReason] = useState("");
	
	useEffect(() => {
		document.title = 'Zukünftige RE bearbeiten — Rechnungsmanagement — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
		.then((res) => {
			if (res.data) {
				setRestaurants(res.data);
				setIsLoading(false);
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		});

	}, [reloadKey])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	const truncateText = (text, length) => {
		if (text.length <= length) {
			return text;
		}
		
		return text.substr(0, length) + '\u2026'
	}

	const onChangeRestaurant = (id) => {
		setIsLoading(true);
		setRestaurantId(id);

		if (id > 0) {
			axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/invoice-management/editings/${id}`)
			.then((res) => {
				if (res.data) {
					setEditings(res.data.editings);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				setIsLoading(false);
				handleError();
			})
		}

	}

	const createNewEdit = () => {

		// Check if all fields are filled
		if (newEditInvoiceRestaurant === 0 || newEditInvoicePosition === "" || newEditInvoiceAmount === 0 || newEditInvoiceReason === "") {
			return;
		}

		setIsLoading(true);

		// Create new edit
		axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/invoice-management/editings/` + newEditInvoiceRestaurant, {
			invoicePosition: newEditInvoicePosition,
			invoiceAmount: newEditInvoiceAmount,
			invoiceReason: newEditInvoiceReason
		}).then((res) => {
			if (res.data) {
				
				setIsLoading(false);
				setReloadKey(Math.random());
				setShowCreateModal(false);
				onChangeRestaurant(newEditInvoiceRestaurant);
			}
		}).catch((err) => {
			handleError();
		});

	}

	const deleteEditing = (id) => {
		setIsLoading(true);

		axios.delete(`${process.env.REACT_APP_API_URL}/api/administrate/invoice-management/editings/${id}`)
			.then((res) => {
				if (res.data) {
					setIsLoading(false);
					setReloadKey(Math.random());
					onChangeRestaurant(restaurantId);
				}
			}).catch((err) => {
				handleError();
			});
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Button onClick={() => {window.open("https://sales-x.deliverone.de", '_blank', 'noopener,noreferrer');}} variant="contained" sx={{ mr: 1 }}>
									SalesX starten
								</Button>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<>
						
							<FormControl size="small" label="Restaurant auswählen" fullWidth>
								<InputLabel id="user-type-label">Restaurant auswählen</InputLabel>
								<Select
									labelId="user-type-label"
									id="user-type"
									label="Restaurant auswählen"
									value={restaurantId}
									onChange={(event) => {
										onChangeRestaurant(event.target.value)
									}}				  
								>
									{restaurants.map((restaurant, index) => {
										return <MenuItem value={restaurant.id} key={index}>{restaurant.restaurant_name}&nbsp;<i style={{color:'#91a5b4'}}>— {truncateText((restaurant.restaurant_description != null ? restaurant.restaurant_description : ""), 20)} ({restaurant.restaurant_address_city})</i></MenuItem>
									})}
								</Select>
							</FormControl><br />

							{restaurantId > 0 && (

								<>

									{editings.length === 0 ? (
										<center style={{margin: '60px 0'}}>
											<Typography variant="h5" component="h5" gutterBottom>
												Keine Bearbeitungen vorhanden
											</Typography>
											<span>Für dieses Restaurant sind keine Bearbeitungen vorhanden.</span>
											<br />
											<br />

											<Button onClick={() => {setNewEditInvoiceRestaurant(restaurantId);setShowCreateModal(true)}} variant="contained">Bearbeitung anlegen</Button>
										</center>
									) : (

										<TableContainer className="PageMenu-Table" style={{marginTop:'20px'}}>
											<Table size="small" aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell>Restaurant</TableCell>
														<TableCell>Grund</TableCell>
														<TableCell>Position</TableCell>
														<TableCell align='right'>Betrag</TableCell>
														<TableCell>Status</TableCell>
														<TableCell align='right'>Angelegt</TableCell>
														<TableCell align='right'>Aktion</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{editings.map((row, index) => (
														<TableRow
															key={index}
															sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
														>
															<TableCell component="td" scope="row">
																{row.restaurant_id} - {row.restaurant_name}
															</TableCell>
															<TableCell component="td" scope="row">
																{row.invoice_edit_reason}
															</TableCell>
															<TableCell component="td" scope="row">
																{row.invoice_edit_position}
															</TableCell>
															<TableCell component="td" align="right" scope="row">
																<Currency
																	quantity={parseFloat(row.invoice_edit_amount)}
																	currency="EUR"
																/>
															</TableCell>
															<TableCell component="td" scope="row">
																Wird bei der nächsten Abrechnung gebucht.
															</TableCell>
															<TableCell component="td" align='right' scope="row">
																{row.created_at}
															</TableCell>
															<TableCell component="td" align='right' scope="row">
																<Button color="error" variant="contained" onClick={() => {deleteEditing(row.id)}} size="small">Entfernen</Button>
															</TableCell>
														</TableRow>
													))}

													<TableRow
														sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
														onClick={() => {setNewEditInvoiceRestaurant(restaurantId);setShowCreateModal(true)}}
													>
														<TableCell component="td" align='right' scope="row" colSpan={7}>
															Bearbeitung anlegen
														</TableCell>
													</TableRow>

												</TableBody>
											</Table>
										</TableContainer>

									)}

								</>

							)}
						
						</>
					)}
				</Box>

			</Paper>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>

			<Dialog
				open={showCreateModal}
				onClose={() => {
					if (!isLoading) {
						setShowCreateModal(false)
					} else {
						return;
					}
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					Neue Bearbeitung anlegen
				</DialogTitle>
				<DialogContent style={{padding: '20px'}}>
					<Stack spacing={3}>
						<FormControl size="small" label="Restaurant auswählen" fullWidth>
							<InputLabel id="user-type-label">Restaurant auswählen</InputLabel>
							<Select
								labelId="user-type-label"
								id="user-type"
								label="Restaurant auswählen"
								value={newEditInvoiceRestaurant}
								required
								onChange={(event) => {
									setNewEditInvoiceRestaurant(event.target.value)
								}}				  
							>
								{restaurants.map((restaurant, index) => {
									return <MenuItem value={restaurant.id} key={index}>{restaurant.restaurant_name}&nbsp;<i style={{color:'#91a5b4'}}>— {truncateText((restaurant.restaurant_description != null ? restaurant.restaurant_description : ""), 20)} ({restaurant.restaurant_address_city})</i></MenuItem>
								})}
							</Select>
						</FormControl>

						<TextField
							name="invoice_edit_position"
							fullWidth
							size="small"
							label="Position"
							required
							helperText="Hier wird die Wildcard-Funktion angewendet. Ist eine Position mit diesem Namen vorhanden, wird diese bearbeitet. Ist keine Position mit diesem Namen vorhanden, wird eine neue Position mit diesem Namen angelegt."
							value={newEditInvoicePosition}
							onChange={(event) => {
								setNewEditInvoicePosition(event.target.value);
							}}
						/>

						<TextField
							name="invoice_edit_amount"
							fullWidth
							size="small"
							required
							label="Betrag (+/-)"
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							helperText="Betrag in Euro, sowohl positive als auch negative Werte sind möglich. Bezieht sich auf den Stückpreis."
							value={newEditInvoiceAmount}
							onChange={(event) => {
								setNewEditInvoiceAmount(event.target.value);
							}}
						/>

						<TextField
							name="invoice_edit_reason"
							fullWidth
							size="small"
							required
							label="Grund für die Bearbeitung"
							helperText="Bitte gib hier den Grund für die Bearbeitung an. Dieser wird auf der Rechnung nicht angezeigt!"
							value={newEditInvoiceReason}
							onChange={(event) => {
								setNewEditInvoiceReason(event.target.value);
							}}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button disabled={isLoading} onClick={() => {
						if (!isLoading) {
							setShowCreateModal(false)
						} else {
							return;
						}
					}}>Abbrechen</Button>
					<Button disabled={isLoading} onClick={createNewEdit} autoFocus>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}