import React, { useState, useEffect } from "react";

import Box from '@mui/system/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../components/Loader';

import './style.scss';

function createData(id, name, categoryName, sku, price) {
	return { id, name, categoryName, sku, price };
}

function IngredientsIndex(props) {

	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [tableRows, setTableRows] = useState([]);
	const [originalRows, setOriginalRows] = useState([]);
	let navigate = useHistory();

	useEffect(() => {

		setIsLoading(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/products`)
		.then((res) => {

			setIsLoading(false);
			
			if (res.status === 200 && res.data) {
				// Everything OK
				let temporaryTableRows = [];
				res.data.forEach((product) => {
					temporaryTableRows.push(createData(product.id, product.product_name, product.category.category_name, '#' + product.id, `${(product.has_multiple_sizes ? `ab ` : '')}${product.lowest_price_formatted} €`));
				})
				setOriginalRows(temporaryTableRows);
				setTableRows(temporaryTableRows);
			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})
		
	}, [props.reloadKey])

	const handleError = (error) => {
		setShowErrorSnackbar(true)
		setIsLoading(false);
	}

	useEffect(() => {
		if (props.toolbarSearchQuery !== '') {

			// This is the function that filters the table rows
			

			// Search in the rows for name, category name, sku and price
			let filteredRows = originalRows.filter((row) => {
				return row.name.toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) ||
					row.categoryName.toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) || 
					row.sku.toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) || 
					row.id.toString().includes(props.toolbarSearchQuery.toLowerCase()) || 
					row.price.toLowerCase().includes(props.toolbarSearchQuery.toLowerCase())
			})
			
			setTableRows(filteredRows);
		} else {
			setTableRows(originalRows);
		}
	}, [props.toolbarSearchQuery])

	return (
		<Box padding={2}>
			{isLoading ? (
				<Loader />
			) : (
				<TableContainer className="PageMenu-Table">
					<Table size="small" aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>#</TableCell>
								<TableCell>Bezeichnung</TableCell>
								<TableCell>Kategorie</TableCell>
								<TableCell align="right">Preis</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableRows.map((row) => (
								<TableRow
									onClick={() => {navigate.push('/ingredients/' + row.id)}}
									key={row.id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component="th" scope="row">
										{row.sku}
									</TableCell>
									<TableCell>
										{row.name}
									</TableCell>
									<TableCell>
										{row.categoryName}
									</TableCell>
									<TableCell align="right">{row.price}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Box>
	)
}

export default IngredientsIndex;