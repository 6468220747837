import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Backdrop from '@mui/material/Backdrop'
import CloseIcon from '@mui/icons-material/Close'
import Loader from '../../../components/Loader';

import './style.scss';

export default function PageAdministrateFailedJobs() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [currentJobsItems, setCurrentJobsItems] = useState([]);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [showDetailsModalInfo, setShowDetailsModalInfo] = useState("Lädt...");
	const [showDetailsModal, setShowDetailsModal] = useState(false);

	useEffect(() => {
		document.title = 'Fehlgeschlagene Aufgaben — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/failed-jobs`).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setCurrentJobsItems(res.data);
			} else {
				setCurrentJobsItems([]);
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}, [reloadKey])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	const isJson = (str) => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<TableContainer className="PageMenu-Table">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Aufgaben-Id</TableCell>
										<TableCell>Verbindung</TableCell>
										<TableCell>Warteschlange</TableCell>
										<TableCell>Inhalt</TableCell>
										<TableCell>Fehler</TableCell>
										<TableCell align='right'>Aufgetreten</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{currentJobsItems.map((row, index) => (
										<TableRow
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											onClick={() => {
												setShowDetailsModalInfo("+++ PAYLOAD: +++ <br /><br />" + row.payload + "<br /><br /><br />+++ EXCEPTION: +++ <br /><br />" + row.exception);
												setShowDetailsModal(true);
											}}
										>
											<TableCell component="td" scope="row">
												#{row.id}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.connection}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.queue}
											</TableCell>
											<TableCell component="td" scope="row">
												{(isJson(row.payload) ? (JSON.parse(row.payload).displayName ? JSON.parse(row.payload).displayName : JSON.parse(row.payload).uuid) : row.payload)}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.exception.substring(0, 45)}...
											</TableCell>
											<TableCell component="td" align='right' scope="row">
												{row.failed_at}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>

			</Paper>

			<Dialog
				open={showDetailsModal}
				onClose={() => {setShowDetailsModal(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="fullWidth"
			>
				<DialogTitle id="alert-dialog-title">
					Informationen zum Fehler
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{fontFamily: "'Fira Code', monospace"}} dangerouslySetInnerHTML={{__html: showDetailsModalInfo}}></DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {setShowDetailsModal(false)}} autoFocus>Schließen</Button>
				</DialogActions>
			</Dialog>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

		</>
	);
}