import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";

import RestaurantsIndex from './RestaurantsIndex';

import './style.scss';

export default function PageRestaurants() {

	let navigate = useHistory();
	const [reloadKey, setReloadKey] = useState(Math.random());

	useEffect(() => {
		document.title = 'Restaurants — ' + process.env.REACT_APP_APPLICATION_NAME;
	}, [])

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Button onClick={() => {navigate.push("/restaurants/new")}} variant="contained" sx={{ mr: 1 }}>
									Erstellen
								</Button>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<RestaurantsIndex
					reloadKey={reloadKey}
				/>

			</Paper>

			
		</>
	);
}