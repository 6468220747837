import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import HolidayConfigurationModal from '../../components/HolidayConfigurationModal';

import './style.scss';

export default function StepMain(props) {

	const [isLoading, setIsLoading] = useState(false);
	const [formRestaurantNameError, setFormRestaurantNameError] = useState(false);
	const [formRestaurantLegalNameError, setFormRestaurantLegalNameError] = useState(false);
	const [formRestaurantTaxIdError, setFormRestaurantTaxIdError] = useState(false);
	const [formRestaurantSupervisoryAuthorityError, setFormRestaurantSupervisoryAuthorityError] = useState(false);
	const [formRestaurantImageError, setFormRestaurantImageError] = useState(false);
	const [formRestaurantLogoError, setFormRestaurantLogoError] = useState(false);
	const [forceHandleNextStep, setForceHandleNextStep] = useState(false);
	const [productNameWarningModalVisible, setProductNameWarningModalVisible] = useState(false);
	const [productNameWarningModalText, setProductNameWarningModalText] = useState("");
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [uploadedImageName, setUploadedImageName] = useState("Restaurant-Banner hochladen");
	const [uploadedLogoName, setUploadedLogoName] = useState("Logo hochladen");
	const [showHolidayModal, setShowHolidayModal] = useState(false);

	const productNameWarning = (name) => {
		if (name.length < 3) {
			setProductNameWarningModalText("Die Bezeichnung ist etwas kurz. Bist du sicher, dass du es so anlegen möchtest?");
			setProductNameWarningModalVisible(true);
			setForceHandleNextStep(true);
			return true;
		}

		if (name.length > 50) {
			setProductNameWarningModalText("Die Bezeichnung ist zu lang. Bist du sicher, dass du es so anlegen möchtest?");
			setProductNameWarningModalVisible(true);
			setForceHandleNextStep(true);
			return true;
		}

		return false;
	}

	const handleHolidayConfigurator = () => {
		setShowHolidayModal(true);
		
	}

	const handleNextStep = () => {

		props.isLoading(true);
		setIsLoading(true);

		setTimeout(() => {
			setFormRestaurantNameError(false);
			setFormRestaurantImageError(false);
			setFormRestaurantLogoError(false);
			if (props.formRestaurantName.trim() === '') {
				setFormRestaurantNameError(true);
				props.isLoading(false);
				setIsLoading(false);
				return;
			}

			if (!forceHandleNextStep) {
				if (productNameWarning(props.formRestaurantName.trim())) {
					props.isLoading(false);
					setIsLoading(false);
					return;	
				}
			}

			props.handleNext();

			props.isLoading(false);
			setIsLoading(false);
		}, 100)
	}

	const imageChange = (e) => {
		if (e.target.files[0]) {
			setIsLoading(true);
			setSnackbarMessage("Bild wird hochgeladen...");
			setShowSnackbar(true);
			setFormRestaurantImageError(false);

			if (e.target.files[0].size / 1024 / 1024 > 8) {
				setIsLoading(false);
				setSnackbarMessage("Bitte verwende ein kleines Bild (max. 8 MB).");
				setTimeout(() => {setShowSnackbar(false);}, 5000)
				return;
			}

			props.setFormRestaurantImage(e.target.files[0]);
			setUploadedImageName(e.target.files[0].name);
	
			setTimeout(() => {
				setIsLoading(false);
				setSnackbarMessage("Bild wurde hochgeladen.");
				setTimeout(() => {setShowSnackbar(false);}, 5000)
			}, 1000);
		}
	}
	
	const logoImageChange = (e) => {
		if (e.target.files[0]) {
			setIsLoading(true);
			setSnackbarMessage("Logo wird hochgeladen...");
			setShowSnackbar(true);
			setFormRestaurantLogoError(false);

			if (e.target.files[0].size / 1024 / 1024 > 8) {
				setIsLoading(false);
				setSnackbarMessage("Bitte verwende ein kleines Logo (max. 8 MB).");
				setTimeout(() => {setShowSnackbar(false);}, 5000)
				return;
			}

			props.setFormRestaurantLogo(e.target.files[0]);
			setUploadedLogoName(e.target.files[0].name);
	
			setTimeout(() => {
				setIsLoading(false);
				setSnackbarMessage("Logo wurde hochgeladen.");
				setTimeout(() => {setShowSnackbar(false);}, 5000)
			}, 1000);
		}
	}

	return (
		<Box sx={{ width: '100%' }}>

			<div style={{padding: '30px 0'}}>

				<Stack spacing={2}>
					<TextField
						size="small"
						label="Name"
						error={formRestaurantNameError}
						helperText="Gebe den Namen des Restaurants ein."
						value={props.formRestaurantName}
						onChange={(e) => {
							props.setFormRestaurantName(e.target.value)
							setFormRestaurantNameError(false);
						}}
					/>
					
					<TextField
						size="small"
						label="Juristischer Vertreter"
						error={formRestaurantLegalNameError}
						helperText="Gebe den juristischen Verter des Restaurants ein."
						value={props.formRestaurantLegalName}
						onChange={(e) => {
							props.setFormRestaurantLegalName(e.target.value)
							setFormRestaurantLegalNameError(false);
						}}
					/>
					
					<TextField
						size="small"
						label="Umsatzsteuer ID"
						error={formRestaurantTaxIdError}
						helperText="Gebe die Umsatzsteuer ID des Restaurants ein."
						value={props.formRestaurantTaxId}
						onChange={(e) => {
							props.setFormRestaurantTaxId(e.target.value)
							setFormRestaurantTaxIdError(false);
						}}
					/>
					
					<TextField
						size="small"
						label="Aufsichtsbehörde"
						error={formRestaurantSupervisoryAuthorityError}
						helperText="Gebe die Aufsichtsbehörde des Restaurants ein."
						value={props.formRestaurantSupervisoryAuthority}
						onChange={(e) => {
							props.setFormRestaurantSupervisoryAuthority(e.target.value)
							setFormRestaurantSupervisoryAuthorityError(false);
						}}
					/>

					<TextField
						size="small"
						label="Beschreibung (optional)"
						helperText="Beschreibe das Restaurant kurz. "
						multiline
						rows={3}
						value={props.formRestaurantDescription}
						onChange={(e) => props.setFormRestaurantDescription(e.target.value)}
					/>

					<Button
						variant="outlined"
						component="label"
						style={{marginBottom:'10px'}}
						color={formRestaurantImageError ? 'error' : 'primary'}
					>
						{uploadedImageName}
						<input
							type="file"
							hidden
							accept="image/*"
							onChange={imageChange}
						/>
					</Button>

					<Button
						variant="outlined"
						component="label"
						style={{marginBottom:'10px'}}
						color={formRestaurantLogoError ? 'error' : 'primary'}
					>
						{uploadedLogoName}
						<input
							type="file"
							hidden
							accept="image/*"
							onChange={logoImageChange}
						/>
					</Button>

					<FormControlLabel label="Restaurant im Shop anzeigen" control={<Switch checked={props.formRestaurantEnabled} value={props.formRestaurantEnabled} onChange={(event) => {props.setFormRestaurantEnabled(event.target.checked)}} />} />
					<FormControlLabel label="Besteck anbieten" control={<Switch checked={props.formCfgAcceptsCutleryEnabled} value={props.formCfgAcceptsCutleryEnabled} onChange={(event) => {props.setFormCfgAcceptsCutleryEnabled(event.target.checked)}} />} />
					<FormControlLabel label="Tringeld annehmen" control={<Switch checked={props.formCfgAcceptsTipEnabled} value={props.formCfgAcceptsTipEnabled} onChange={(event) => {props.setFormCfgAcceptsTipEnabled(event.target.checked)}} />} />
					<FormControlLabel label="Nur-Abholung" control={<Switch checked={props.formCfgPickupOnlyEnabled} value={props.formCfgPickupOnlyEnabled} onChange={(event) => {props.setFormCfgPickupOnlyEnabled(event.target.checked)}} />} />

				</Stack>

			</div>

			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'flex-end' }}>
				<LoadingButton style={{marginRight:10}} variant="text" loading={isLoading} onClick={handleHolidayConfigurator}>Urlaub planen</LoadingButton>
				<LoadingButton endIcon={<ArrowForwardIcon />} variant="contained" loading={isLoading} onClick={handleNextStep}>Nächster Schritt</LoadingButton>
			</Box>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSnackbar}
				message={snackbarMessage}
				sx={{ zIndex: 9999999 }}
				action={(
					<React.Fragment>
						{isLoading ? (
							<CircularProgress size={20} />
						) : (
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() => {setShowSnackbar(false)}}
							>
								<CloseIcon fontSize="small" />
							</IconButton>
						)}
					</React.Fragment>
				)}
			/>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>

			<Dialog
				open={productNameWarningModalVisible}
				onClose={() => {setProductNameWarningModalVisible(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title">
					Hinweis!
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{productNameWarningModalText}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {setProductNameWarningModalVisible(false)}} autoFocus>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>

			<HolidayConfigurationModal
				open={showHolidayModal}
				onClose={() => {setShowHolidayModal(false)}}
				onSave={() => {setShowHolidayModal(false)}}
				restaurantId={props.restaurantId}
				holidays={props.holidays}
			/>

		</Box>
	);
}

const commonFoodNames = [
	{ title: "Margarita" },
	{ title: "Mozzarella" },
	{ title: "Salami" },
	{ title: "Regina" },
	{ title: "Prosciutto" },
	{ title: "La Roma" },
	{ title: "Famosa" },
	{ title: "Milano" },
	{ title: "Diavolo" },
	{ title: "Tonno" },
	{ title: "Mare" },
	{ title: "Lachs" },
	{ title: "Funghi" },
	{ title: "Venezia" },
	{ title: "Hawaii" },
	{ title: "Anabella" },
	{ title: "Malangi" },
	{ title: "Vegetaria" },
	{ title: "MOD" },
	{ title: "Toskana" },
	{ title: "Bella" },
	{ title: "Vier Käse" },
	{ title: "Gyros" },
	{ title: "Parma" },
	{ title: "Istanbul" },
	{ title: "Rustika" },
	{ title: "Gamberetti" },
	{ title: "Quattro Stagioni" },
	{ title: "Palermo" },
	{ title: "Bologna" },
	{ title: "Rimini" },
	{ title: "Adria" },
	{ title: "Pizzabrot" },
];