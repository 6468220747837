import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

import IngredientsIndex from './IngredientsIndex';

import './style.scss';

export default function PageIngredients() {

	let navigate = useHistory();
	const [showMessageSnackbar, setShowMessageSnackbar] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editModalData, setEditModalData] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteModalData, setDeleteModalData] = useState(null);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [toolbarSearchQuery, setToolbarSearchQuery] = useState("");
	
	useEffect(() => {
		document.title = 'Extra Zutaten — ' + process.env.REACT_APP_APPLICATION_NAME;	
	})

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container spacing={2} style={{flexDirection:'row'}} alignItems="center">
							<Grid item>
								<SearchIcon color="inherit" sx={{ display: 'block' }} />
							</Grid>
							<Grid item xs>
								<TextField
									value={toolbarSearchQuery}
									onChange={(e) => setToolbarSearchQuery(e.target.value)}
									fullWidth
									placeholder="Suche nach Produkten, Kategorien oder ID"
									InputProps={{
										disableUnderline: true,
										sx: { fontSize: 'default' },
									}}
									variant="standard"
								/>
							</Grid>
							<Grid item>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<IngredientsIndex toolbarSearchQuery={toolbarSearchQuery} reloadKey={reloadKey} />

			</Paper>
			
		</>
	);
}