import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField'
import Pagination from '@mui/material/Pagination';
import Loader from '../../../components/Loader';
import { Autocomplete, createFilterOptions } from "@mui/material";

import './style.scss';

const defaultFilterOptions = createFilterOptions();
const OPTIONS_LIMIT = 10;

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function PageAdministratePushNotifications() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [pushNotifications, setPushNotifications] = useState([]);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [showDetailsModalInfo, setShowDetailsModalInfo] = useState("Lädt...");
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [showNewMessageModal, setShowNewMessageModal] = useState(false);
	const [newMessageUserType, setNewMessageUserType] = useState("all");
	const [newMessageUserId, setNewMessageUserId] = useState("");
	const [newMessageTitle, setNewMessageTitle] = useState("...");
	const [newMessageContent, setNewMessageContent] = useState("........");
	const [newMessageClickAction, setNewMessageClickAction] = useState("none");
	const [newMessageClickActionData, setNewMessageClickActionData] = useState("");
	const [customers, setCustomers] = useState([]);
	const [restaurants, setRestaurants] = useState([]);
	const [selectedRestaurantId, setSelectedRestaurantId] = useState(null);
	const [products, setProducts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [paginationPage, setPaginationPage] = useState(1);
	const [paginationTotalPages, setPaginationTotalPages] = useState(1);

	useEffect(() => {
		document.title = 'Push Benachrichtigungen — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/customers?push_notifications_token`)
		.then((res) => {
			if (res.status === 200 && res.data) {

				// Filter customers: only customers where push_notifications_token is not null
				let filteredCustomers = res.data.filter((customer) => {
					return customer.push_notifications_token !== null;
				});

				// Convert customers from object to array
				filteredCustomers = Object.values(filteredCustomers);

				console.log(typeof filteredCustomers);

				setCustomers(filteredCustomers);
				
				axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/push-notifications?page=${paginationPage}`).then((res) => {
					if (res.data) {
						setPushNotifications(res.data.data);

						axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
						.then((res) => {
							if (res.data) {
								setRestaurants(res.data);

								res.data.forEach((restaurant) => {

									axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/products?restaurant=${restaurant.id}`)
									.then((res) => {
										if (res.data) {
											
											setProducts((products) => {
												return {
													...products,
													[restaurant.id]: res.data
												}
											});

											axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/categories`)
											.then((res) => {
												if (res.status === 200 && res.data) {
													// Everything OK
													setCategories(res.data);
													setIsLoading(false);
												} else {
													// Error
													handleError();
												}
											})
											.catch((err) => {
												handleError();
											})
			
										} else {
											handleError();
										}
									})

								})

							} else {
								handleError();
							}
						})

					} else {
						setPushNotifications([]);
						handleError();
					}
				}).catch((err) => {
					setIsLoading(false);
					handleError();
				})
			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})

	}, [reloadKey])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	const sendNotification = () => {

		if (newMessageClickAction !== "none") {
			if (newMessageClickActionData === "" && newMessageClickAction !== "open_cart") {
				setSuccessSnackbarText("Bitte geben Sie einen Wert für die Klick-Aktion ein!");
				setShowSuccessSnackbar(true);
				return;
			}
		}

		setIsLoading(true);
		axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/push-notifications`, {
			user: (newMessageUserType === "other" ? newMessageUserId : newMessageUserType),
			title: newMessageTitle,
			message: newMessageContent,
			click_action: newMessageClickAction,
			click_action_data: newMessageClickActionData
		}).then((res) => {
			setIsLoading(false);
			if (res.status === 200) {
				setSuccessSnackbarText("Push Benachrichtigung wurde erfolgreich versendet an " + res.data.affected + " Personen!");
				setShowSuccessSnackbar(true);
				setReloadKey(Math.random());
				setShowNewMessageModal(false);
			} else {
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Button disabled={isLoading} onClick={() => setShowNewMessageModal(true)} variant="contained" sx={{ mr: 1 }}>
									Neue Nachricht
								</Button>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<TableContainer className="PageMenu-Table">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>#</TableCell>
										<TableCell>Empfänger</TableCell>
										<TableCell>Titel</TableCell>
										<TableCell>Inhalt</TableCell>
										<TableCell align='right'>Erstellt</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{pushNotifications.map((row, index) => (
										<TableRow
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											onClick={() => {
												// On click, show the full message
												setShowDetailsModalInfo(`
													<b>ID: </b>${row.id}<br />
													<b>Empfänger: </b>${row.name}, ${row.user_id}<br />
													<b>Titel: </b>${row.title}<br />
													<b>Inhalt: </b>${row.message}<br /><br />
													<b>Token: </b>${row.push_token}<br />
													<b>Erstellt: </b>${row.created_at}
												`);
												setShowDetailsModal(true);
											}}
										>
											<TableCell component="td" scope="row">
												#{row.id}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.name} (#{row.user_id})
											</TableCell>
											<TableCell component="td" scope="row">
												{
													row.title.substring(0, 20) + (row.title.length > 20 ? '...' : '')
												}
											</TableCell>
											<TableCell component="td" scope="row">
												{
													row.message.substring(0, 20) + (row.message.length > 20 ? '...' : '')
												}
											</TableCell>
											<TableCell align="right" component="td" scope="row">
												{row.created_at}<br />
												<i>({row.created_at_raw})</i>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}

					<div style={{marginTop: '20px'}}>
						<center className="CustomersPagination">
							<Pagination
								count={paginationTotalPages}
								page={paginationPage}
								variant="outlined"
								color="primary"
								onChange={(event, value) => {
									setPaginationPage(value)
									setTimeout(() => {
										setReloadKey(Math.random())
									}, 250)
								}}
							/>
						</center>
					</div>
				</Box>

			</Paper>

			<Dialog
				open={showDetailsModal}
				onClose={() => {setShowDetailsModal(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="fullWidth"
			>
				<DialogTitle id="alert-dialog-title">
					Informationen zur Benachrichtigung
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{fontFamily: "'Fira Code', monospace"}} dangerouslySetInnerHTML={{__html: showDetailsModalInfo}}></DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {setShowDetailsModal(false)}} autoFocus>Schließen</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={showNewMessageModal}
				onClose={() => {setShowNewMessageModal(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					Neue Benachrichtigung
				</DialogTitle>
				<DialogContent>

					<Stack spacing={3}>
						<FormControl margin="dense" size='small' fullWidth variant="outlined">
							<InputLabel id="restaurant-label">Restaurant</InputLabel>
							<Select
								labelId="restaurant-label"
								id="restaurant"
								label="Restaurant"
								value={newMessageUserType}
								onChange={(event) => {
									setNewMessageUserType(event.target.value)
								}}		
								disabled={isLoading}		  
							>
								<MenuItem value={"all"}>Globale Nachricht</MenuItem>
								<MenuItem value={"guest_only"}>Nur Gäste</MenuItem>
								<MenuItem value={"user_only"}>Nur Registrierte</MenuItem>
								<MenuItem value={"other"}>Einzelperson</MenuItem>
							</Select>
						</FormControl>

						{newMessageUserType === 'other' && (
							<Autocomplete
								getOptionLabel={(option) => option.id + " / " + option.name}
								filterOptions={filterOptions}
								id="combo-box-demo"
								options={customers}
								size="small"
								fullWidth
								onChange={(event, value) => {
									setNewMessageUserId(value.id)
								}}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								renderInput={(params) => <TextField {...params} label="Kunde" />}
							/>
						)}

						<TextField
							name="Titel"
							fullWidth
							size="small"
							label="Titel"
							value={newMessageTitle}
							onChange={(event) => {
								setNewMessageTitle(event.target.value)
							}}
							disabled={isLoading}
						/>

						<TextField
							name="Nachricht"
							fullWidth
							size="small"
							label="Nachricht"
							value={newMessageContent}
							onChange={(event) => {
								setNewMessageContent(event.target.value)
							}}
							disabled={isLoading}
						/>

						<FormControl margin="dense" size='small' fullWidth variant="outlined">
							<InputLabel id="click-action-label">Klick-Aktion</InputLabel>
							<Select
								labelId="click-action-label"
								id="click-action"
								label="Klick-Aktion"
								value={newMessageClickAction}
								onChange={(event) => {
									setNewMessageClickActionData("");
									setNewMessageClickAction(event.target.value)
								}}		
								disabled={isLoading}		  
							>
								<MenuItem value={"none"}>Keine Aktion</MenuItem>
								<MenuItem value={"open_url"}>URL öffnen</MenuItem>
								<MenuItem value={"open_product"}>Produkt öffnen</MenuItem>
								<MenuItem value={"open_category"}>Kategorie öffnen</MenuItem>
								<MenuItem value={"open_restaurant"}>Restaurant öffnen</MenuItem>
								<MenuItem value={"open_cart"}>Warenkorb öffnen</MenuItem>
							</Select>
						</FormControl>

						{newMessageClickAction === 'open_url' && (
							<TextField
								name="URL"
								fullWidth
								size="small"
								label="URL"
								value={newMessageClickActionData}
								onChange={(event) => {
									setNewMessageClickActionData(event.target.value)
								}}
								disabled={isLoading}
							/>
						)}

						{newMessageClickAction === 'open_product' && (
							<>
							
								<FormControl margin="dense" size='small' fullWidth variant="outlined">
									<InputLabel id="click-action-product-restaurants-label">Restaurant auswählen</InputLabel>
									<Select
										labelId="click-action-product-restaurants-label"
										id="click-action-product-restaurants"
										label="Restaurant auswählen"
										value={selectedRestaurantId}
										onChange={(event) => {
											setNewMessageClickActionData("");
											setSelectedRestaurantId(event.target.value)
										}}
										disabled={isLoading}		  
									>
										{restaurants.map((restaurant, index) => {
											return <MenuItem value={restaurant.id} key={index}>{restaurant.restaurant_name}</MenuItem>
										})}
									</Select>
								</FormControl>
							
								{(selectedRestaurantId !== "" && selectedRestaurantId !== null) && (
									<FormControl margin="dense" size='small' fullWidth variant="outlined">
										<InputLabel id="click-action-product-restaurants-label">Produkt auswählen</InputLabel>
										<Select
											labelId="click-action-products-label"
											id="click-action-products"
											label="Produkt auswählen"
											value={newMessageClickActionData}
											onChange={(event) => {
												setNewMessageClickActionData(event.target.value)
											}}
											disabled={isLoading}		  
										>
											{products[selectedRestaurantId].map((product, index) => {
												return <MenuItem value={product.id} key={index}>{product.id} / {product.category.category_name} / {product.product_name}</MenuItem>
											})}
										</Select>
									</FormControl>
								)}

							</>
						)}

						{newMessageClickAction === 'open_category' && (
							<FormControl margin="dense" size='small' fullWidth variant="outlined">
								<InputLabel id="click-action-categories-label">Kategorie auswählen</InputLabel>
								<Select
									labelId="click-action-categories-label"
									id="click-action-categories"
									label="Kategorie auswählen"
									value={newMessageClickActionData}
									onChange={(event) => {
										setNewMessageClickActionData(event.target.value)
									}}
									disabled={isLoading}		  
								>
									{categories.map((category, index) => {
										return <MenuItem value={category.id} key={index}>{category.category_name}</MenuItem>
									})}
								</Select>
							</FormControl>
						)}

						{newMessageClickAction === 'open_restaurant' && (
							<FormControl margin="dense" size='small' fullWidth variant="outlined">
								<InputLabel id="click-action-restaurants-label">Restaurant auswählen</InputLabel>
								<Select
									labelId="click-action-restaurants-label"
									id="click-action-restaurants"
									label="Restaurant auswählen"
									value={newMessageClickActionData}
									onChange={(event) => {
										setNewMessageClickActionData(event.target.value)
									}}
									disabled={isLoading}		  
								>
									{restaurants.map((restaurant, index) => {
										return <MenuItem value={restaurant.id} key={index}>{restaurant.restaurant_name}</MenuItem>
									})}
								</Select>
							</FormControl>
						)}

					</Stack>

				</DialogContent>
				<DialogActions>
					<Button onClick={() => {setShowDetailsModal(false)}} autoFocus>Schließen</Button>
					<Button onClick={sendNotification}>Senden</Button>
				</DialogActions>
			</Dialog>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

		</>
	);
}