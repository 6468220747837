import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import './style.scss';

export default function StepAddress(props) {

	const [isLoading, setIsLoading] = useState(false);
	const [formRestaurantStreetError, setFormRestaurantStreetError] = useState(false);
	const [formRestaurantCityError, setFormRestaurantCityError] = useState(false);
	const [formRestaurantMailError, setFormRestaurantMailError] = useState(false);
	const [formRestaurantPhoneError, setFormRestaurantPhoneError] = useState(false);
	const [formRestaurantPhone2Error, setFormRestaurantPhone2Error] = useState(false);

	const handleNextStep = () => {

		props.isLoading(true);
		setIsLoading(true);
		
		setTimeout(() => {

			if (props.formRestaurantStreet.trim() === '') {
				props.isLoading(false);
				setFormRestaurantStreetError(true);
				setIsLoading(false);
				return;
			}

			if (props.formRestaurantCity.trim() === '') {
				props.isLoading(false);
				setFormRestaurantCityError(true);
				setIsLoading(false);
				return;
			}

			if (props.formRestaurantPhone.trim() === '') {
				props.isLoading(false);
				setFormRestaurantPhoneError(true);
				setIsLoading(false);
				return;
			}

			if (props.formRestaurantPhone2.trim() === '') {
				props.isLoading(false);
				setFormRestaurantPhone2Error(true);
				setIsLoading(false);
				return;
			}

			props.handleNext();

			props.isLoading(false);
			setIsLoading(false);
		}, 1000)
	}

	return (
		<Box sx={{ width: '100%' }}>
			<div style={{padding: '30px 0'}}>
				<Stack spacing={2}>
					<TextField
						autoFocus
						size="small"
						label="Straße + Hausnummer"
						error={formRestaurantStreetError}
						value={props.formRestaurantStreet}
						onChange={(e) => {setFormRestaurantStreetError(false);props.setFormRestaurantStreet(e.target.value)}}
					/>
					
					<TextField
						size="small"
						label="PLZ + Stadt"
						error={formRestaurantCityError}
						value={props.formRestaurantCity}
						onChange={(e) => {setFormRestaurantCityError(false);props.setFormRestaurantCity(e.target.value)}}
					/>
					
					<TextField
						size="small"
						label="E-Mail Adresse"
						type="email"
						name="email"
						error={formRestaurantMailError}
						value={props.formRestaurantMail}
						onChange={(e) => {setFormRestaurantMailError(false);props.setFormRestaurantMail(e.target.value)}}
					/>
					
					<TextField
						size="small"
						label="Telefonnummer (für Kundenerreichbarkeit)"
						error={formRestaurantPhoneError}
						value={props.formRestaurantPhone}
						name="formRestaurantPhone"
						onChange={(e) => {setFormRestaurantPhoneError(false);props.setFormRestaurantPhone(e.target.value)}}
					/>
					
					<TextField
						size="small"
						label="Telefonnummer (für interne Erreichbarkeit)"
						error={formRestaurantPhone2Error}
						value={props.formRestaurantPhone2}
						onChange={(e) => {setFormRestaurantPhone2Error(false);props.setFormRestaurantPhone2(e.target.value)}}
					/>
				</Stack>
			</div>

			{props.asStep === true ? (
				<Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
					<Box sx={{ flex: "1 1 auto" }} />
					<Button onClick={props.handleBack}>
						Zurück
					</Button>
					<Button onClick={handleNextStep}>
						Weiter
					</Button>
				</Box>
			) : (
				<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between' }}>
					<LoadingButton startIcon={<ArrowBackIcon />} loading={isLoading} onClick={props.handleBack}>Zurück</LoadingButton>
					<LoadingButton endIcon={<ArrowForwardIcon />} variant="contained" loading={isLoading} onClick={handleNextStep}>Nächster Schritt</LoadingButton>
				</Box>
			)}

		</Box>
	);
}