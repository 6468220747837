import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Currency from 'react-currency-formatter';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../../components/Loader';

import './style.scss';

export default function PageAdministrateInvoiceManagementHistory() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [pendingApprovals, setPendingApprovals] = useState([]);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [iframeUrl, setIframeUrl] = useState("");

	useEffect(() => {
		document.title = 'Rechnungshistorie — Rechnungsmanagement — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/invoice-management/history`).then((res) => {
			setIsLoading(false);
			if (res.data) {
				
				setPendingApprovals(res.data.invoices);
			} else {
				setPendingApprovals([]);
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})

	}, [reloadKey])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	const showInvoice = (invoice) => {

		setIsLoading(true);
		setSelectedInvoice(invoice)

		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/invoice-management/history/` + invoice.id, {
			responseType: 'blob'
		}).then((res) => {
			setIsLoading(false);
			if (res.data) {
				
				setShowDetailsModal(true);
				const url = window.URL.createObjectURL(new Blob([res.data]));
				setIframeUrl(url);
			} else {
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})

	}

	const downloadInvoice = () => {

		setIsLoading(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/invoice-management/history/` + selectedInvoice.id, {
			responseType: 'blob'
		}).then((res) => {
			setIsLoading(false);
			if (res.data) {
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', selectedInvoice.invoice_no + ".pdf");
				document.body.appendChild(link);
				link.click();
			} else {
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})

	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Button onClick={() => {window.open("https://sales-x.deliverone.de", '_blank', 'noopener,noreferrer');}} variant="contained" sx={{ mr: 1 }}>
									SalesX starten
								</Button>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<TableContainer className="PageMenu-Table">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Status</TableCell>
										<TableCell>Re.Nr.</TableCell>
										<TableCell>Restaurant</TableCell>
										<TableCell align='right'>Summe</TableCell>
										<TableCell align='right'>Zeitpunkt</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{pendingApprovals.map((row, index) => (
										<TableRow
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											onClick={() => {
												showInvoice(row)
											}}
										>
											<TableCell component="td" scope="row">
												<Chip
													label={row.invoice_status === "approved" ? 'Bezahlt' : 'Abgelehnt'}
													color={row.invoice_status === "approved" ? 'success' : 'error'}
													size="small" 
												/>
											</TableCell>
											<TableCell component="td" scope="row">
												{row.invoice_no}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.restaurant_id} - {row.restaurant_name}
											</TableCell>
											<TableCell component="td" align='right' scope="row">
												<Currency
													quantity={parseFloat(row.invoice_total)}
													currency="EUR"
												/>{(row.invoice_gocardless_payment === 1) && " (GoCardless Auto-Payment)"}
											</TableCell>
											<TableCell component="td" align='right' scope="row">
												{row.created_at}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>

			</Paper>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

			<Dialog
                open={showDetailsModal}
                onClose={() => {setShowDetailsModal(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen={true}
            >
				<AppBar sx={{ position: 'relative' }} color="default">
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={() => {setShowDetailsModal(false)}}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Rechnung {selectedInvoice?.invoice_no}
						</Typography>
						<Button autoFocus style={{marginRight:'10px'}} variant="contained" color="inherit" onClick={downloadInvoice}>
							Herunterladen
						</Button>
					</Toolbar>
				</AppBar>
                <DialogContent style={{padding:0,overflow:"hidden"}}>
                    <iframe
						src={iframeUrl}
						width="100%"
						height="100%"
						frameBorder="0"
						allowFullScreen
						title='Rechnungs-Details'
					/>
                </DialogContent>
            </Dialog>

		</>
	);
}