import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Alert from '@mui/material/Alert'
import Loader from '../../../components/Loader';
import { TextField, Drawer } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Currency from 'react-currency-formatter';

import './style.scss';

let taxRegistrarList = [
    'Aachen',
    'Altenburg',
    'Amberg',
    'Ansbach',
    'Apolda',
    'Arnsberg',
    'Arnstadt',
    'Arnstadt Zweigstelle Ilmenau',
    'Aschaffenburg',
    'Augsburg',
    'Aurich',
    'Bad Hersfeld',
    'Bad Homburg v.d.H.',
    'Bad Kreuznach',
    'Bad Oeynhausen',
    'Bad Salzungen',
    'Bamberg',
    'Bayreuth',
    'Berlin (Charlottenburg)',
    'Bielefeld',
    'Bochum',
    'Bonn',
    'Braunschweig',
    'Bremen',
    'Chemnitz',
    'Coburg',
    'Coesfeld',
    'Cottbus',
    'Darmstadt',
    'Deggendorf',
    'Dortmund',
    'Dresden',
    'Duisburg',
    'Düren',
    'Düsseldorf',
    'Eisenach',
    'Erfurt',
    'Eschwege',
    'Essen',
    'Flensburg',
    'Frankfurt am Main',
    'Frankfurt/Oder',
    'Freiburg',
    'Friedberg',
    'Fritzlar',
    'Fulda',
    'Fürth',
    'Gelsenkirchen',
    'Gera',
    'Gießen',
    'Gotha',
    'Göttingen',
    'Greiz',
    'Gütersloh',
    'Hagen',
    'Hamburg',
    'Hamm',
    'Hanau',
    'Hannover',
    'Heilbad Heiligenstadt',
    'Hildburghausen',
    'Hildesheim',
    'Hof',
    'Homburg',
    'Ingolstadt',
    'Iserlohn',
    'Jena',
    'Kaiserslautern',
    'Kassel',
    'Kempten (Allgäu)',
    'Kiel',
    'Kleve',
    'Koblenz',
    'Köln',
    'Königstein',
    'Korbach',
    'Krefeld',
    'Landau',
    'Landshut',
    'Langenfeld',
    'Lebach',
    'Leipzig',
    'Lemgo',
    'Limburg',
    'Lübeck',
    'Ludwigshafen a. Rhein (Ludwigshafen)',
    'Lüneburg',
    'Mainz',
    'Mannheim',
    'Marburg',
    'Meiningen',
    'Memmingen',
    'Merzig',
    'Mönchengladbach',
    'Montabaur',
    'Mühlhausen',
    'München',
    'Münster',
    'Neubrandenburg',
    'Neunkirchen',
    'Neuruppin',
    'Neuss',
    'Nordhausen',
    'Nürnberg',
    'Offenbach am Main',
    'Oldenburg (Oldenburg)',
    'Osnabrück',
    'Ottweiler',
    'Paderborn',
    'Passau',
    'Pinneberg',
    'Pößneck',
    'Pößneck Zweigstelle Bad Lobenstein',
    'Potsdam',
    'Recklinghausen',
    'Regensburg',
    'Rostock',
    'Rudolstadt',
    'Rudolstadt Zweigstelle Saalfeld ',
    'Saarbrücken',
    'Saarlouis',
    'Schweinfurt',
    'Schwerin',
    'Siegburg',
    'Siegen',
    'Sömmerda',
    'Sondershausen',
    'Sonneberg',
    'Stadthagen',
    'Stadtroda',
    'Steinfurt',
    'Stendal',
    'St. Ingbert (St Ingbert)',
    'Stralsund',
    'Straubing',
    'Stuttgart',
    'St. Wendel (St Wendel)',
    'Suhl',
    'Tostedt',
    'Traunstein',
    'Ulm',
    'Völklingen',
    'Walsrode',
    'Weiden i. d. OPf.',
    'Weimar',
    'Wetzlar',
    'Wiesbaden',
    'Wittlich',
    'Wuppertal',
    'Würzburg',
    'Zweibrücken'
]

export default function PageAdministrateStripeConnectAccounts() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [accounts, setAccounts] = useState([]);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [collapsedRows, setCollapsedRows] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [deleteContext, setDeleteContext] = useState(0);
	const [mccList, setMccList] = useState([]);
	
	const [newAccountEmail, setNewAccountEmail] = useState("");
	const [newAccountBusinessMCC, setNewAccountBusinessMCC] = useState("");
	const [newAccountBusinessPhone, setNewAccountBusinessPhone] = useState("");
	const [newAccountBusinessURL, setNewAccountBusinessURL] = useState("");
	const [newAccountCompanyName, setNewAccountCompanyName] = useState("");
	const [newAccountAddressLine1, setNewAccountAddressLine1] = useState("");
	const [newAccountAddressPostalCode, setNewAccountAddressPostalCode] = useState("");
	const [newAccountAddressCity, setNewAccountAddressCity] = useState("");
	const [newAccountCountry, setNewAccountCountry] = useState("DE");
	const [newAccountCurrency, setNewAccountCurrency] = useState("EUR");
	const [newAccountExtAccountName, setNewAccountExtAccountName] = useState("");
	const [newAccountExtAccountType, setNewAccountExtAccountType] = useState("");
	const [newAccountExtAccountIban, setNewAccountExtAccountIban] = useState("");
	const [newAccountBusinessTaxId, setNewAccountBusinessTaxId] = useState("");
	const [newAccountBusinessTaxRegistrar, setNewAccountBusinessTaxRegistrar] = useState("");

	useEffect(() => {
		document.title = 'Connect-Konten — Stripe — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/stripe/connect-accounts/mcc-list`).then((res) => {
			if (res.data) {
				let list = [];
				for (let key in res.data) {
					list.push({
						key: key,
						value: res.data[key]
					})
				}
				
				setMccList(list);
			} else {
				setMccList([]);
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})

		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/stripe/connect-accounts`).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setAccounts(res.data.data);
			} else {
				setAccounts([]);
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}, [reloadKey])

	const handleError = () => {
		setShowErrorSnackbar(true);
		setShowSuccessSnackbar(false);
	}

	const generateDashboardLink = (id) => {
		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/stripe/connect-accounts/link/${id}`).then((res) => {
			setIsLoading(false);
			if (res.data.url) {
				setSuccessSnackbarText("Dashboard-Link generiert: " + res.data.url + " (in die Zwischenablage kopiert)");
				setShowSuccessSnackbar(true);
				
				// Copy to clipboard
				navigator.clipboard.writeText(res.data.url);
			} else {
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}

	const deleteConnectAccount = () => {
		setIsLoading(true);
		setShowDeleteModal(false);
		axios.delete(`${process.env.REACT_APP_API_URL}/api/administrate/stripe/connect-accounts/${deleteContext}`).then((res) => {
			setIsLoading(false);
			if (res.data.deleted === true) {
				setSuccessSnackbarText("Connect-Konto gelöscht!");
				setShowSuccessSnackbar(true);
				setReloadKey(Math.random());
			} else {
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}

	const createAccount = () => {
		setIsLoading(true);
		setShowCreateModal(false);
		axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/stripe/connect-accounts`, {
			newAccountEmail: newAccountEmail,
			newAccountBusinessMCC: newAccountBusinessMCC,
			newAccountBusinessPhone: newAccountBusinessPhone,
			newAccountBusinessURL: newAccountBusinessURL,
			newAccountCompanyName: newAccountCompanyName,
			newAccountAddressLine1: newAccountAddressLine1,
			newAccountAddressPostalCode: newAccountAddressPostalCode,
			newAccountAddressCity: newAccountAddressCity,
			newAccountCountry: newAccountCountry,
			newAccountCurrency: newAccountCurrency,
			newAccountExtAccountName: newAccountExtAccountName,
			newAccountExtAccountType: newAccountExtAccountType,
			newAccountExtAccountIban: newAccountExtAccountIban,
			newAccountBusinessTaxId: newAccountBusinessTaxId,
			newAccountBusinessTaxRegistrar: newAccountBusinessTaxRegistrar
		}).then((res) => {
			setIsLoading(false);
			if (res.data.id) {
				setSuccessSnackbarText("Connect-Konto angelegt!");
				setShowSuccessSnackbar(true);
				setReloadKey(Math.random());

				setNewAccountEmail("");
				setNewAccountBusinessMCC("");
				setNewAccountBusinessPhone("");
				setNewAccountBusinessURL("");
				setNewAccountCompanyName("");
				setNewAccountAddressLine1("");
				setNewAccountAddressPostalCode("");
				setNewAccountAddressCity("");
				setNewAccountCountry("DE");
				setNewAccountCurrency("EUR");
				setNewAccountExtAccountName("");
				setNewAccountExtAccountType("");
				setNewAccountExtAccountIban("");
				setNewAccountBusinessTaxId("");
				setNewAccountBusinessTaxRegistrar("");

			} else {
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Button onClick={() => {
									navigate.push("stripe-connect-accounts/create");
								}} variant="contained" sx={{ mr: 1 }}>
									Konto anlegen
								</Button>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<TableContainer className="PageMenu-Table">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Kto.Nr.</TableCell>
										<TableCell>Kto.Art</TableCell>
										<TableCell>Kd.Kat.</TableCell>
										<TableCell>Kd.Name</TableCell>
										<TableCell>Kd.Mail</TableCell>
										<TableCell>Land</TableCell>
										<TableCell>Währung</TableCell>
										<TableCell>Sperre</TableCell>
										<TableCell>Bankkonten</TableCell>
										<TableCell>Saldo verfügbar</TableCell>
										<TableCell>Saldo vorgemerkt</TableCell>
										<TableCell align='right'>Eröffnung</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{accounts.map((row, index) => (
										<>										
											<TableRow
												key={index}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												onClick={() => {
													if (collapsedRows.includes(index)) {
														setCollapsedRows([]);
													} else {
														setCollapsedRows([index]);
													}
												}}
											>
												<TableCell component="td" scope="row">
													{row.id}
												</TableCell>
												<TableCell component="td" scope="row">
													{row.type.toUpperCase()}
												</TableCell>
												<TableCell component="td" scope="row" dangerouslySetInnerHTML={{__html: row.business_profile.mcc}}></TableCell>
												<TableCell component="td" scope="row">
													{row.business_profile.name || "N/A"}
												</TableCell>
												<TableCell component="td" scope="row">
													{row.email || "N/A"}
												</TableCell>
												<TableCell component="td" scope="row">
													{row.country.toUpperCase()}
												</TableCell>
												<TableCell component="td" scope="row">
													{row.default_currency.toUpperCase()}
												</TableCell>
												<TableCell component="td" scope="row">
													{row.payouts_enabled ? "Freigegeben" : "Blockiert"}
												</TableCell>
												<TableCell component="td" scope="row">
													{row.external_accounts?.data?.length || 0} Kont{row.external_accounts?.data?.length === 1 ? "o" : "en"}
												</TableCell>
												<TableCell component="td" scope="row">
													<Currency quantity={((row.balance.available[0].amount || 0) / 100)} currency="EUR" />
												</TableCell>
												<TableCell component="td" scope="row">
													<Currency quantity={((row.balance.pending[0].amount || 0) / 100)} currency="EUR" />
												</TableCell>
												<TableCell component="td" align='right' scope="row">
													{new Date(row.created * 1000).toLocaleDateString("de-DE", {
														year: "numeric",
														month: "2-digit",
														day: "2-digit",
													})}
												</TableCell>
											</TableRow>
											{collapsedRows.includes(index) && (
												<React.Fragment key={index + "_context"}>
													<TableRow
														key={index + "_context_2"}
														sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
														onClick={() => {
															setDeleteContext(row.id);
															setShowDeleteModal(true);
														}}
														style={{
															background: '#eaeff1'
														}}
													>
														<TableCell component="td" scope="row" colSpan={12}>
															└ <b>Aktion:</b> Löschen
														</TableCell>
													</TableRow>
												</React.Fragment>
											)}
										</>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>

			</Paper>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

			<Dialog
				open={showDeleteModal}
				onClose={() => {setShowDeleteModal(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xs"
			>
				<DialogTitle id="alert-dialog-title">
					Stripe Connect-Konto wirklich löschen?
				</DialogTitle>
				<DialogContent>

					<Alert sx={{ overflow: 'hidden', marginBottom: '20px' }} variant="filled" severity="warning">
						<b>Hinweis: </b>Damit ein Konto gelöscht werden kann, muss der Kontostand auf 0,00 € gesetzt werden.
					</Alert>

					<DialogContentText id="alert-dialog-description">
						Möchtest du dieses Stripe Connect-Konto wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden! Sei dir sicher, dass du das Konto nicht mehr benötigst.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button disabled={isLoading} onClick={() => {setShowDeleteModal(false)}}>Nein, abbrechen</Button>
					<Button disabled={isLoading} color="error" onClick={deleteConnectAccount} autoFocus>
						Ja, löschen
					</Button>
				</DialogActions>
			</Dialog>

			<Drawer
				anchor={"right"}
				open={showCreateModal}
				onClose={() => {
					setShowCreateModal(false);
				}}

				PaperProps={{
					style: {
						width: "40%",
						backgroundColor: "white",
						padding: "20px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}
				}}
			>
				<div>
					<h2 style={{marginTop:0}}>Konto anlegen</h2>

					<TextField
						autoFocus
						margin="dense"
						id="mail"
						label="E-Mail"
						type="email"
						fullWidth
						variant="outlined"
						value={newAccountEmail}
						style={{marginBottom: '10px'}}
						size='small'
						onChange={(event) => {
							setNewAccountEmail(event.target.value)
						}}
					/>

					<FormControl margin="dense" size='small' fullWidth variant="outlined">
						<InputLabel id="newAccountBusinessMCC">Kunden-Kategorie</InputLabel>
						<Select
							labelId="newAccountBusinessMCC"
							id="newAccountBusinessMCC"
							label="Kunden-Kategorie"
							value={newAccountBusinessMCC}
							onChange={(event) => {
								setNewAccountBusinessMCC(event.target.value)
							}}				  
						>
							{
								mccList.map((mccItem) => {
									return (
										<MenuItem key={mccItem.key} value={mccItem.key} style={{whiteSpace: 'normal'}}>
											{mccItem.key} - {mccItem.value}
										</MenuItem>
									)
								})
							}
						</Select>
					</FormControl>

					<TextField
						margin="dense"
						id="newAccountBusinessPhone"
						label="Telefonnummer"
						type="text"
						variant="outlined"
						value={newAccountBusinessPhone}
						style={{marginBottom: '10px'}}
						size='small'
						fullWidth
						onChange={(event) => {
							setNewAccountBusinessPhone(event.target.value)
						}}
					/>

					<TextField
						margin="dense"
						id="newAccountBusinessURL"
						label="Webseite"
						type="text"
						variant="outlined"
						value={newAccountBusinessURL}
						style={{marginBottom: '10px'}}
						size='small'
						fullWidth
						onChange={(event) => {
							setNewAccountBusinessURL(event.target.value)
						}}
					/>

					<TextField
						margin="dense"
						id="newAccountCompanyName"
						label="Unternehmensname"
						type="text"
						variant="outlined"
						value={newAccountCompanyName}
						style={{marginBottom: '10px'}}
						size='small'
						fullWidth
						onChange={(event) => {
							setNewAccountCompanyName(event.target.value)
						}}
					/>

					<TextField
						margin="dense"
						id="newAccountAddressLine1"
						label="Straße und Hausnr."
						type="text"
						variant="outlined"
						value={newAccountAddressLine1}
						style={{marginBottom: '10px'}}
						size='small'
						fullWidth
						onChange={(event) => {
							setNewAccountAddressLine1(event.target.value)
						}}
					/>

					<TextField
						margin="dense"
						id="newAccountAddressPostalCode"
						label="Postleitzahl"
						type="text"
						variant="outlined"
						value={newAccountAddressPostalCode}
						style={{marginBottom: '10px'}}
						maxLength="5"
						size='small'
						fullWidth
						onChange={(event) => {
							setNewAccountAddressPostalCode(event.target.value)
						}}
					/>


					<TextField
						margin="dense"
						id="newAccountAddressCity"
						label="Ort"
						type="text"
						variant="outlined"
						value={newAccountAddressCity}
						style={{marginBottom: '10px'}}
						size='small'
						fullWidth
						onChange={(event) => {
							setNewAccountAddressCity(event.target.value)
						}}
					/>

					<TextField
						margin="dense"
						id="newAccountCurrency"
						label="Währung (z.B. EUR für Euro)"
						type="text"
						maxLength="3"
						minLength="3"
						fullWidth
						variant="outlined"
						value={newAccountCurrency}
						style={{marginBottom: '10px'}}
						size='small'
						onChange={(event) => {
							setNewAccountCurrency(event.target.value)
						}}
					/>

					<TextField
						margin="dense"
						id="newAccountCountry"
						label="Länder-Code (z.B. DE für Deutschland)"
						type="text"
						maxLength="2"
						minLength="2"
						fullWidth
						variant="outlined"
						value={newAccountCountry}
						style={{marginBottom: '10px'}}
						size='small'
						onChange={(event) => {
							setNewAccountCountry(event.target.value)
						}}
					/>

					<br />
					<br />
					<hr />
					<br />

					<TextField
						margin="dense"
						id="newAccountExtAccountName"
						label="Name d. Kontoinhabers des Auszahlungskontos"
						type="text"
						fullWidth
						variant="outlined"
						value={newAccountExtAccountName}
						style={{marginBottom: '10px'}}
						size='small'
						onChange={(event) => {
							setNewAccountExtAccountName(event.target.value)
						}}
					/>

					<TextField
						margin="dense"
						id="newAccountExtAccountIban"
						label="IBAN des Auszahlungskontos"
						type="text"
						fullWidth
						variant="outlined"
						value={newAccountExtAccountIban}
						style={{marginBottom: '10px'}}
						size='small'
						onChange={(event) => {
							setNewAccountExtAccountIban(event.target.value.toUpperCase().trim())
						}}
					/>

					<FormControl margin="dense" size='small' fullWidth variant="outlined">
						<InputLabel id="newAccountExtAccountType">Art d. Inhabers des Auszahlungskontos</InputLabel>
						<Select
							labelId="newAccountExtAccountType"
							id="newAccountExtAccountType"
							label="Art d. Inhabers des Auszahlungskontos"
							value={newAccountExtAccountType}
							onChange={(event) => {
								setNewAccountExtAccountType(event.target.value)
							}}				  
						>
							<MenuItem value="individual">Privatkunde</MenuItem>
							<MenuItem value="company">Firmenkunde</MenuItem>
						</Select>
					</FormControl>

					<br />
					<br />
					<hr />
					<br />

					<TextField
						margin="dense"
						id="newAccountBusinessTaxId"
						label="Handelsregisternummer"
						type="text"
						fullWidth
						variant="outlined"
						value={newAccountBusinessTaxId}
						style={{marginBottom: '10px'}}
						size='small'
						onChange={(event) => {
							setNewAccountBusinessTaxId(event.target.value.toUpperCase().trim())
						}}
					/>

					<FormControl margin="dense" size='small' fullWidth variant="outlined">
						<InputLabel id="newAccountBusinessTaxRegistrar">Handelsregister</InputLabel>
						<Select
							labelId="newAccountBusinessTaxRegistrar"
							id="newAccountBusinessTaxRegistrar"
							label="Handelsregister"
							value={newAccountBusinessTaxRegistrar}
							onChange={(event) => {
								setNewAccountBusinessTaxRegistrar(event.target.value)
							}}				  
						>
							{taxRegistrarList.map((taxRegistry) => (
								<MenuItem value={taxRegistry}>{taxRegistry}</MenuItem>
							))}
						</Select>
					</FormControl>

					<br />
					<br />
					<p>
						Mit der Nutzung von Stripe Connect stimmst du den <a href="https://deliverone.de/Rechtliche%20Informationen/Stripe%20Connect%20Nutzungsbedingungen.pdf" target="_blank" rel="noopener noreferrer">Stripe Connect Geschäftsbedingungen</a> zu.
					</p>

				</div>

				<div style={{
					marginTop: '35px',
				}}>
					<Button style={{marginTop:'10px'}} onClick={createAccount} fullWidth variant="contained">Anlegen</Button>
				</div>
			</Drawer>
		</>
	);
}