import React, { useState, useEffect } from "react"
import axios from "axios"

import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from '@mui/material/Snackbar'
import CloseIcon from '@mui/icons-material/Close'

function MenuDelete(props) {

    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false)
    const [deleteModalData, setDeleteModalData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [showSnackbar, setShowSnackbar] = useState(false)

	useEffect(() => {
        if (props.row) {
            setDeleteModalData(props.row)
        }
	}, [props])

    const onDelete = () => {
        setIsLoading(true)
		setSnackbarMessage("Menü-Kategorie wird gelöscht...")
		setShowSnackbar(true)

		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		}
		const API = "api/backend/restaurant/categories/" + deleteModalData.id
		const url = `${process.env.REACT_APP_API_URL}/${API}`
	
		axios.delete(url, config)
			.then(function (response) {
				if (response.status === 200) {                    
                    setIsLoading(false)
                    props.closeAllHandler()
					props.reloadIndex()
                    setSnackbarMessage("Menü-Kategorie wurde gelöscht.")
                    setTimeout(() => {setShowSnackbar(false)}, 5000)    
                } else {
					handleError(response)
				}
			})
			.catch(function (error) {
				handleError(error)
			})
    }

    const handleError = (error) => {
		setShowErrorSnackbar(true)
		
	}

	return (
        props.row && (
            <>
                <Dialog
                    open={props.isOpen}
                    onClose={props.closeHandler}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                >
                    <DialogTitle id="alert-dialog-title">
                        {deleteModalData ? `${deleteModalData.name} wirklich löschen?` : 'Wird geladen...'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Möchtest Du die Menü-Kategorie wirklich löschen? Dabei werden alle Produkte unwiderruflich gelöscht!</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.closeHandler}>Abbrechen</Button>
                        <Button color="error" onClick={onDelete} autoFocus>
                            Bestätigen
                        </Button>
                    </DialogActions>
                </Dialog>

                <Backdrop
                    sx={{ color: '#fff', zIndex: 999999 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar
                    open={showSnackbar}
                    message={snackbarMessage}
                    sx={{ zIndex: 9999999 }}
                    action={(
                        <React.Fragment>
                            {isLoading ? (
                                <CircularProgress size={20} />
                            ) : (
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => {setShowSnackbar(false)}}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            )}
                        </React.Fragment>
                    )}
                />
                <Snackbar
                    open={showErrorSnackbar}
                    message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
                    sx={{ zIndex: 9999999 }}
                    autoHideDuration={8000}
                    onClose={() => {setShowErrorSnackbar(false)}}
                />
            </>
        )
	)
}

export default MenuDelete