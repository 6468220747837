import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
 
const RedirectToLogin = () => {

    useEffect(() => {

        // Get the current location. If it is not the login page, redirect to the login page. and save the current location in the get parameter
        const { pathname, search } = window.location;
        if (pathname !== '/login') {
            window.location.href = `/login?r=${pathname}${search}`;
        } else {
            window.location.href = `/login`;
        }

    }, [])

}

const RouteGuard = ({ component: Component, ...rest }) => {
 
    function hasJWT() {
        let flag = false;
    
        //check user has JWT token
        localStorage.getItem("token") ? flag=true : flag=false
        
        return flag
    }
 
   return (
       <Route {...rest}
           render={props => (
               hasJWT() ?
                   <Component {...props} />
                   :
                   <RedirectToLogin />
           )}
       />
   );
};
 
export default RouteGuard;