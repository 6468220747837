import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

import AllergensIndex from './AllergensIndex';
import AllergensEdit from './AllergensEdit';
import AllergensDelete from './AllergensDelete';
import AllergensCreate from './AllergensCreate';

import './style.scss';

export default function PageAllergens() {

	const [showEditModal, setShowEditModal] = useState(false);
	const [editModalData, setEditModalData] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteModalData, setDeleteModalData] = useState(null);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [reloadKey, setReloadKey] = useState(Math.random());

	useEffect(() => {

		document.title = 'Allergene — ' + process.env.REACT_APP_APPLICATION_NAME;
		
	}, [])

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Button onClick={() => {setShowCreateModal(true)}} variant="contained" sx={{ mr: 1 }}>
									Erstellen
								</Button>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<AllergensIndex
					editHandler={(row) => {
						setEditModalData(row);
						setShowEditModal(true);
					}}
					reloadKey={reloadKey}
				/>

			</Paper>

			<AllergensEdit
				isOpen={showEditModal}
				closeHandler={() => {setShowEditModal(false)}}
				row={editModalData}
				reloadIndex={() => {setReloadKey(Math.random())}}
				deleteHandler={(row) => {setDeleteModalData(row);setShowDeleteModal(true)}}
			/>

			<AllergensDelete
				reloadIndex={() => {setReloadKey(Math.random())}}
				isOpen={showDeleteModal}
				closeHandler={() => {setShowDeleteModal(false)}}
				closeAllHandler={() => {setShowEditModal(false);setShowDeleteModal(false)}}
				row={deleteModalData}
			/>

			<AllergensCreate
				isOpen={showCreateModal}
				closeHandler={() => {setShowCreateModal(false)}}
				reloadIndex={() => {setReloadKey(Math.random())}}
			/>

			
		</>
	);
}