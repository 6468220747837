import React, {useEffect, useState} from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Currency from 'react-currency-formatter';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import emptyImage from '../../assets/empty.png';
import { useHistory, useParams } from "react-router-dom";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Loader from '../../components/Loader';

import './style.scss';

export default function PageInboxMessage() {

    let navigate = useHistory();
	let params = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [message, setMessage] = useState([]);

	useEffect(() => {

		document.title = 'Postfach — ' + process.env.REACT_APP_APPLICATION_NAME;
	
		setIsLoading(true)
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/dashboard-inbox/` + params.messageId)
			.then(res => {
				
                setIsLoading(false);
				if (res.data.messages.length === 0) {
					navigate.push("/dashboard/inbox");
				} else {
					setMessage(res.data.messages[0]);
				}
			})

		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
		}

		if (userData.role_name === "admin") {
			setIsAdmin(true);
		}
		
	}, [params.messageId])

	const downloadFile = () => {
		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/dashboard-inbox-download/` + params.messageId, {
			responseType: 'blob'
		})
			.then(res => {
				setIsLoading(false);
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', message.file_path);
				document.body.appendChild(link);
				link.click();
			})

	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{justifyContent:'space-between'}} spacing={1} alignItems="center">
							<Grid item>
								{isLoading ? (
									<>Lädt...</>
								) : (
									<b>{message.subject}</b>
								)}
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>

				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<>
							<div className="PageInboxMessage-Message" dangerouslySetInnerHTML={
								{__html: message.message}
							}></div>

							{message.file_name && (
								<div className="PageInboxMessage-MessageFooter">
									<Button
										variant="contained"
										disableElevation
										startIcon={<FileDownloadIcon />}
										onClick={downloadFile}
									>
										{message.file_name} ({message.file_size})
									</Button>
								</div>
							)}
						</>
					)}
				</Box>
			</Paper>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>
		</>
	);
}