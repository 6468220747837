import React, { useState, useEffect } from "react";

import Box from '@mui/system/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';

function createData(id, name) {
	return { id, name };
}

function AllergensIndex(props) {

	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [tableRows, setTableRows] = useState([]);
	
	useEffect(() => {
		
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/allergens`)
		.then((res) => {
			if (res.status === 200 && res.data) {
				// Everything OK
				let temporaryTableRows = [];
				res.data.forEach((category) => {
					temporaryTableRows.push(createData(category.id, category.allergen_name));
				})
				setTableRows(temporaryTableRows);
			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})
		
	}, [props.reloadKey])

	const handleError = (error) => {
		setShowErrorSnackbar(true)
		
	}

	return (
		<Box padding={2}>
			<TableContainer className="PageMenu-Table">
				<Table size="small" aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Allergen-ID</TableCell>
							<TableCell>Bezeichnung</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tableRows.map((row) => (
							<TableRow
								onClick={() => {props.editHandler(row)}}
								key={row.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell component="th" scope="row">
									#{row.id}
								</TableCell>
								<TableCell component="th" scope="row">
									{row.name}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
		</Box>
	)
}

export default AllergensIndex;