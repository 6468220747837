import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function StepSuccess(props) {

    useEffect(() => {
        
    }, [])

	return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
            padding: '20px'
        }}>
            <Box style={{
                display: 'flex',
                width: '100%',
                textAlign: 'center',
                padding: '20px 0',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100%'
            }}>
                
                <ErrorOutlineIcon style={{
                    fontSize: '100px',
                    color: 'rgb(239, 83, 80)'
                }} />

                <h2 style={{
                    padding: 0
                }}>Vertrag nicht erstellt!</h2>
                <p style={{
                    padding: 0
                }}>Der Vertrag konnte nicht erstellt werden. Bitte versuchen Sie es erneut!</p>

            </Box>

        </div>
	);
}