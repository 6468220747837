import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Currency from 'react-currency-formatter';
import Loader from '../../../components/Loader';

import './style.scss';

export default function PageAdministrateDonations() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [donationsItems, setDonationsItems] = useState([]);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [pendingDonationAmount, setPendingDonationAmount] = useState(0);

	useEffect(() => {
		document.title = 'Spenden — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/donations`).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setDonationsItems(res.data);

				

				let pendingDonationAmountTmp = 0;
				res.data.forEach((donation) => {

					// Get all donations, where updated_at is this month
					// updated_at is in SQL format, so we need to convert it to JS format
					let donationDate = new Date(donation.updated_at);
					let donationMonth = donationDate.getMonth();
					let donationYear = donationDate.getFullYear();

					let currentDate = new Date();
					let currentMonth = currentDate.getMonth();
					let currentYear = currentDate.getFullYear();

					if (donationMonth === currentMonth && donationYear === currentYear && donation.direct_donation === 0 && donation.status === "In Arbeit...") {
						pendingDonationAmountTmp += donation.amount;
					}

				})
				setPendingDonationAmount(pendingDonationAmountTmp);

			} else {
				setDonationsItems([]);
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}, [reloadKey])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="center" justifyContent={"space-between"}>
							<Grid item>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {window.location.reload()}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid item>
								<i style={{fontSize:'14px',marginRight:'15px'}}>
									Vorgemerkte Spenden: <Currency quantity={pendingDonationAmount} currency="EUR" />
								</i>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<TableContainer className="PageMenu-Table">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Spenden-Id</TableCell>
										<TableCell>Kunde</TableCell>
										<TableCell>Bestellung</TableCell>
										<TableCell>Spenden-Betrag</TableCell>
										<TableCell>Zahlungsmethode</TableCell>
										<TableCell>Überweisungs-Art</TableCell>
										<TableCell>Zahlungsstatus</TableCell>
										<TableCell align='right'>Zeitpunkt</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{donationsItems.map((row, index) => (
										<TableRow
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="td" scope="row">
												#{row.id}
											</TableCell>
											<TableCell component="td" scope="row">
												#{row.user_id} ({row.username})
											</TableCell>
											<TableCell component="td" scope="row">
												#{row.order_id}
											</TableCell>
											<TableCell component="td" scope="row">
												{parseFloat(row.amount).toFixed(2)} EUR
											</TableCell>
											<TableCell component="td" scope="row">
												{row.payment_method.toUpperCase()}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.direct_donation === 1 ? "Echtzeit-Spende" : "Standard-Spende"}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.status}
											</TableCell>
											<TableCell component="td" align='right' scope="row">
												{row.created_at}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>

			</Paper>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

		</>
	);
}