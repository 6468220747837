import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Currency from 'react-currency-formatter';
import Chip from '@mui/material/Chip';
import Loader from '../../../components/Loader';

import './style.scss';

export default function PageAdministratePayouts() {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [payoutItems, setPayoutItems] = useState([]);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [showDetailsModalInfo, setShowDetailsModalInfo] = useState("Lädt...");
	const [showDetailsModal, setShowDetailsModal] = useState(false);

	useEffect(() => {
		document.title = 'Auszahlungen — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/payouts`).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setPayoutItems(res.data);
			} else {
				setPayoutItems([]);
				handleError();
			}
		}).catch((err) => {
			setIsLoading(false);
			handleError();
		})
	}, [reloadKey])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	const updatePayoutStatus = (payoutId, status) => {
		setIsLoading(true);
		axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/payouts/update`, {
			payoutId: payoutId,
			status: status
		}).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setReloadKey(Math.random());
				setSuccessSnackbarText("Auszahlung erfolgreich aktualisiert");
				setShowSuccessSnackbar(true);
			} else {
				handleError();
			}
		}).catch((err) => {
			handleError();
		})
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<TableContainer className="PageMenu-Table">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Buchungs-Nr.</TableCell>
										<TableCell>Buchungslauf</TableCell>
										<TableCell>Restaurant</TableCell>
										<TableCell>Betrag</TableCell>
										<TableCell>Provision</TableCell>
										<TableCell>Status</TableCell>
										<TableCell align='right'>Verarbeitet</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{payoutItems.map((row, index) => (
										<TableRow
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											onClick={() => {
												setShowDetailsModalInfo(row);
												setShowDetailsModal(true);
											}}
										>
											<TableCell component="td" scope="row">
												#{row.id}
											</TableCell>
											<TableCell component="td" scope="row">
												{row.payout_week}/{row.payout_year}
											</TableCell>
											<TableCell component="td" scope="row">
												#{row.payout_restaurant_id}, {row.payout_restaurant_name}
											</TableCell>
											<TableCell component="td" scope="row">
												<Currency
													quantity={parseFloat(row.payout_total_amount)}
													currency="EUR"
												/>
											</TableCell>
											<TableCell component="td" scope="row">
												<Currency
													quantity={parseFloat(row.payout_pending_fee)}
													currency="EUR"
												/>
											</TableCell>
											<TableCell component="td" scope="row">
												<Chip
													label={row.payout_paid === 1 ? 'Bezahlt' : 'Ausstehend'}
													color={row.payout_paid === 1 ? 'success' : 'error'}
													size="small" 
												/>
											</TableCell>
											<TableCell component="td" align='right' scope="row">
												{row.created_at}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>

			</Paper>

			<Dialog
				open={showDetailsModal}
				onClose={() => {setShowDetailsModal(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="fullWidth"
			>
				<DialogTitle id="alert-dialog-title">
					Informationen zur Buchung
				</DialogTitle>
				<DialogContent>
						Der Buchungslauf für die Kalenderwoche {showDetailsModalInfo.payout_week}/{showDetailsModalInfo.payout_year} wurde erfolgreich durchgeführt.<br />
						Zu überweisen: <b><Currency quantity={parseFloat(showDetailsModalInfo.payout_total_amount)} currency="EUR" /></b><br />
						<b>Bankverbindung:</b><br />
						<b>EMPF: </b>{showDetailsModalInfo.payout_name}<br />
						<b>IBAN: </b>{showDetailsModalInfo.payout_iban}<br />
						<b>BIC: </b>{showDetailsModalInfo.payout_bic}<br />
						<b>VERW: </b>DELIVERONE ONLINE-ZAHLUNGEN BUCHUNGSWOCHE {showDetailsModalInfo.first_day_of_week} - {showDetailsModalInfo.last_day_of_week}, Buchungs-Nr. {showDetailsModalInfo.id}<br /><br />
						<i>Eine Provision i.H.v. <Currency quantity={parseFloat(showDetailsModalInfo.payout_pending_fee)} currency="EUR" /> wird abgerechnet!</i>	

						<table size="small" className="PayoutTable" style={{borderCollapse:'collapse',borderSpacing:0,border:'1px solid #0f1f33'}}>
                            <tr>
                                <th style={{textAlign:'left'}}>Bestell-Nr.</th>
                                <th style={{textAlign:'left'}}>Kunde</th>
                                <th style={{textAlign:'left'}}>Bestellzeit</th>
                                <th style={{textAlign:'left'}}>Bezahlmethode</th>
                                <th style={{textAlign:'left'}}>Bestellwert</th>
                            </tr>
							{showDetailsModalInfo.payout_table && (
								showDetailsModalInfo.payout_table.map((row, index) => (
									<tr>
										<td style={{textAlign:'left'}}>{row.order_id}</td>
										<td style={{textAlign:'left'}}>{row.order_user}</td>
										<td style={{textAlign:'left'}}>{row.order_date}</td>
										<td style={{textAlign:'left'}}>{row.order_payment_method}</td>
										<td style={{textAlign:'right'}}><Currency quantity={parseFloat(row.order_payment_amount)} currency="EUR" /></td>
									</tr>
								))
							)}
                            <tr>
                                <td colSpan='4' style={{textAlign:'left'}}><b>=TOTAL</b></td>
                                <td style={{textAlign:'right'}}><b><Currency quantity={parseFloat(showDetailsModalInfo.payout_total_amount)} currency="EUR" /></b></td>
                            </tr>
                        </table>

				</DialogContent>
				<DialogActions style={{justifyContent: 'space-between'}}>
					<div>
						<Button disabled={isLoading} onClick={() => {updatePayoutStatus(showDetailsModalInfo.id, '0')}} color="error">Ausstehend</Button>
						<Button disabled={isLoading} onClick={() => {updatePayoutStatus(showDetailsModalInfo.id, '1')}} color="success">Bezahlt</Button>
					</div>
					<Button disabled={isLoading} onClick={() => {setShowDetailsModal(false)}} autoFocus>Schließen</Button>
				</DialogActions>
			</Dialog>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

		</>
	);
}