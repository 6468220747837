import React, {useEffect, useState} from 'react';

import Box from '@mui/system/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory, useParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CircularProgress from '@mui/material/CircularProgress'
import NumberFormat from 'react-number-format';
import './style.scss';
import { DialogContentText } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import LoadingButton from '@mui/lab/LoadingButton';
import Loader from '../../components/Loader';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
			decimalSeparator=","
            isNumericString
            suffix=" EUR"
			allowNegative={false}
			decimalScale={2}
        />
    );
});


function createData(id, name, prices) {
	return { id, name, prices };
}

export default function PageIngredientDetails() {

	let navigate = useHistory()
	let { productId } = useParams()
	const [tableRows, setTableRows] = useState([]);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [extraIngredientId, setExtraIngredientId] = useState(0);
	const [prices, setPrices] = useState(false);
	const [showPricesModal, setShowPricesModal] = useState(false);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [productSizes, setProductSizes] = useState([]);
	const [newData, setNewData] = useState([]);
	const [newDataTitle, setNewDataTitle] = useState("");
	const [autoCreate, setAutoCreate] = useState(true);

	useEffect(() => {
		document.title = 'Extra Zutaten anzeigen — ' + process.env.REACT_APP_APPLICATION_NAME;

		setIsLoading(true);

		axios.get(`${process.env.REACT_APP_API_URL}/api/restaurant/extra-ingredients/${productId}`)
		.then((res) => {

			setIsLoading(false);
			
			if (res.status === 200 && res.data) {
				// Everything OK
				let temporaryTableRows = [];
				
				res.data.forEach((extraIngredient) => {
					temporaryTableRows.push(createData(extraIngredient.id, extraIngredient.extra_ingredient_name, extraIngredient.formatted_prices));
				})
				setTableRows(temporaryTableRows);
			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})

		axios.get(`${process.env.REACT_APP_API_URL}/api/restaurant/products?id=${productId}`)
		.then((res) => {
			setProductSizes(res.data[0].sizes);
		})
		.catch((err) => {handleError(err)})

	}, [reloadKey])

	const handleError = (error) => {
		setShowErrorSnackbar(true)	
		setIsLoading(false);
	}

	const savePrices = () => {
		setIsLoading(true)
		axios.post(`${process.env.REACT_APP_API_URL}/api/restaurant/extra-ingredients/${productId}/prices`, {
			extra_ingredient_id: extraIngredientId,
			prices: prices
		})
		.then((res) => {
			setIsLoading(false)
			setShowPricesModal(false);
			setReloadKey(Math.random())
			setShowSuccessSnackbar(true);
		})
	}

	const deleteExtraIngredient = () => {
		setIsLoading(true)

		axios.delete(`${process.env.REACT_APP_API_URL}/api/restaurant/extra-ingredients/${extraIngredientId}`)
			.then((res) => {
				setIsLoading(false)
				setReloadKey(Math.random())
				setShowSuccessSnackbar(true);
			})
	}

	const createNewIngredient = () => {

		setIsLoading(true)

		if (newDataTitle !== "") {
			axios.post(`${process.env.REACT_APP_API_URL}/api/restaurant/extra-ingredients/${productId}/create`, {
				prices: newData,
				title: newDataTitle,
				autoCreate: autoCreate
			})
			.then((res) => {
				setIsLoading(false)
				setShowCreateModal(false);
				setReloadKey(Math.random())
				setShowSuccessSnackbar(true);
			})
		} else {
			setIsLoading(false)
		}

	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<LoadingButton loading={isLoading} onClick={() => {setShowCreateModal(true)}} variant="contained" sx={{ mr: 1 }}>
									Erstellen
								</LoadingButton>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<TableContainer className="PageMenu-Table">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>#</TableCell>
										<TableCell>Bezeichnung</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{tableRows.map((row) => (
										<TableRow
											onClick={() => {
												setExtraIngredientId(row.id)
												setPrices(row.prices)
												setShowPricesModal(true)
											}}
											key={row.id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="th" scope="row">
												{row.id}
											</TableCell>
											<TableCell>
												{row.name}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>

			</Paper>

			<Dialog
				open={showPricesModal}
				onClose={() => {
					if (!isLoading) {
						setShowPricesModal(false)
					} else {
						return;
					}
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					Extra-Zutat bearbeiten
				</DialogTitle>
				<DialogContent style={{padding: '20px'}}>

					{prices && (
						prices.map((input, index) => (
							<div key={index} style={{display: 'flex',justifyContent: 'space-between', marginBottom:15}}>
								<div style={{width:'50%',paddingRight:'5px'}}>
									<span style={{lineHeight: '40px'}}>{input.size}</span>
								</div>
								<div style={{width:'50%',paddingLeft:'5px',display: 'flex',flexDirection: 'row'}}>
									<TextField
										name="price"
										fullWidth
										size="small"
										label="Bruttopreis"
										value={input.price}
										InputProps={{
											inputComponent: NumberFormatCustom,
										}}
										onChange={(event) => {
											// Set the new value
											const newPrices = [...prices];
											newPrices[index].price = event.target.value;
											setPrices(newPrices);

										}}
									/>
								</div>
							</div>
						))
					)}

				</DialogContent>
				<DialogActions>
					<LoadingButton loading={isLoading} color='error' onClick={() => {
						if (!isLoading) {
							deleteExtraIngredient()
						} else {
							return;
						}
					}}>Löschen</LoadingButton>
					<LoadingButton loading={isLoading} onClick={() => {
						if (!isLoading) {
							setShowPricesModal(false)
						} else {
							return;
						}
					}}>Abbrechen</LoadingButton>
					<LoadingButton loading={isLoading} onClick={() => {
						savePrices()
					}} autoFocus>
						Speichern
					</LoadingButton>
				</DialogActions>
			</Dialog>

			<Dialog
				open={showCreateModal}
				onClose={() => {
					if (!isLoading) {
						setShowCreateModal(false)
					} else {
						return;
					}
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					Extra-Zutat erstellen
				</DialogTitle>
				<DialogContent style={{padding: '20px'}}>

					<DialogContentText>Hinweis: Leer lassen, falls die Extra-Zutat nicht für die Größe/Variante verfügbar sein soll.</DialogContentText><br />


					<b>Informationen:</b><br /><br />
					<TextField
						name="Bezeichnung"
						fullWidth
						size="small"
						label="Bezeichnung"
						value={newDataTitle}
						onChange={(event) => {
							setNewDataTitle(event.target.value)
						}}
					/><br /><br />

					<b>Preise:</b><br /><br />

					{productSizes && (
						productSizes.map((input, index) => (
							<div key={index} style={{display: 'flex',justifyContent: 'space-between', marginBottom:15}}>
								<div style={{width:'50%',paddingRight:'5px'}}>
									<span style={{lineHeight: '40px'}}>{input.product_size_shortname}</span>
								</div>
								<div style={{width:'50%',paddingLeft:'5px',display: 'flex',flexDirection: 'row'}}>
									<TextField
										name="price"
										fullWidth
										size="small"
										label="Bruttopreis"
										value={
											// Get the price for the current size from newData by size_shortname. If not found, return 0.
											newData.find(item => item.size === input.id) ? newData.find(item => item.size === input.id).price : 0
										}
										InputProps={{
											inputComponent: NumberFormatCustom,
										}}
										onChange={(event) => {
											// Add new price to array of prices if it doesn't exist yet. If it does, update the price.
											let data = [...newData];
											if (data.find(item => item.size === input.id)) {
												data.find(item => item.size === input.id).price = (event.target.value);
											} else {
												data.push({
													size: input.id,
													price: (event.target.value)
												});
											}
											setNewData(data);
										}}
									/>
								</div>
							</div>
						))
					)}

					<br />
					<FormControlLabel control={<Switch checked={autoCreate} onChange={(e) => setAutoCreate(e.target.checked)} />} label="Extra-Zutat für alle anderen Produkte in der selben Kategorie automatisch anlegen." />
				</DialogContent>
				<DialogActions>
					<LoadingButton loading={isLoading} onClick={() => {
						if (!isLoading) {
							setShowCreateModal(false)
						} else {
							return;
						}
					}}>Abbrechen</LoadingButton>
					<LoadingButton loading={isLoading} onClick={() => {
						createNewIngredient()
					}} autoFocus>
						Speichern
					</LoadingButton>
				</DialogActions>
			</Dialog>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message="Die Extra-Zutat wurde erfolgreich gespeichert. Es kann bis zu 15 Minuten dauern, bis die Änderungen aktiv sind."
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>
			
		</>
	);
}