import React, {useEffect,useState} from 'react';
import './style.scss';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import { Alert, AlertTitle } from '@mui/material';
import {setAuthToken} from '../../utils/setAuthToken';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Logo from '../../assets/logos/Logo.svg';

const imagesArray = [
    '1.jpeg',
    '2.jpeg',
    '3.jpeg',
    '4.jpeg',
    '5.jpeg',
    '6.jpeg',
	'7.jpeg',
	'8.jpeg',
	'9.jpeg',
];

let theme = createTheme({
	palette: {
		primary: {
			light: '#b7de4d',
			main: '#a1c53d',
			dark: '#7a9a23',
		},
	},
	typography: {
		h5: {
			fontWeight: 500,
			fontSize: 26,
			letterSpacing: 0.5,
		},
		fontFamily: [
			'Open Sans',
			'Roboto',
			'sans-serif',
		].join(','),
	},
	shape: {
		borderRadius: 8,
	},
	components: {
		MuiTab: {
			defaultProps: {
				disableRipple: true,
			},
		},
	},
	mixins: {
		toolbar: {
			minHeight: 48,
		},
	},
});

theme = {
	...theme,
	components: {
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: '#081627',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
				},
				contained: {
					boxShadow: 'none',
					'&:active': {
						boxShadow: 'none',
					},
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					marginLeft: theme.spacing(1),
				},
				indicator: {
					height: 3,
					borderTopLeftRadius: 3,
					borderTopRightRadius: 3,
					backgroundColor: theme.palette.common.white,
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					margin: '0 16px',
					minWidth: 0,
					padding: 0,
					[theme.breakpoints.up('md')]: {
						padding: 0,
						minWidth: 0,
					},
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					padding: theme.spacing(1),
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					borderRadius: 4,
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					backgroundColor: 'rgb(255,255,255,0.15)',
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						color: '#4fc3f7',
					},
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				primary: {
					fontSize: 14,
					fontWeight: theme.typography.fontWeightMedium,
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: 'inherit',
					minWidth: 'auto',
					marginRight: theme.spacing(2),
					'& svg': {
						fontSize: 20,
					},
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					width: 32,
					height: 32,
				},
			},
		},
	},
};

export default function PageAuthLogin() {

	const queryParameters = new URLSearchParams(window.location.search)

    let navigate = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [formMail, setFormMail] = useState("");
    const [formPassword, setFormPassword] = useState("");
    const [formHasError, setFormHasError] = useState(false);
    const [errorSession, setErrorSession] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [randomImage, setRandomImage] = useState(imagesArray[Math.floor(Math.random() * imagesArray.length)]);

    const submitForm = () => {
        setShowErrorSnackbar(false);
        setIsLoading(true)
        setFormHasError(false);

        axios.post(`${process.env.REACT_APP_API_URL}/oauth/token`, {
            grant_type: 'password',
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            username: formMail,
            password: formPassword
        })
        .then(function (response) {
            if (response.status === 200) {
                let accessToken = response.data.access_token;
                let refreshToken = response.data.refresh_token;
                let expiresIn = (response.data.expires_in / 60);

                axios.get(`${process.env.REACT_APP_API_URL}/api/me`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                .then((res) => {
                    setIsLoading(false)
                    if (res.data.data) {

                        // Check if the user has a role
                        if (res.data.data.role_name === 'admin' || res.data.data.role_name === 'restaurant') {

                            localStorage.setItem("token", accessToken);
                            localStorage.setItem("data", JSON.stringify(res.data.data));
                            setAuthToken(accessToken);

                            // Check if the GET-Parameter "r" is set
							if (queryParameters.get('r')) {
								window.location.href = queryParameters.get('r')
							} else {
								window.location.href = '/'
							}

                        } else {
                            setShowErrorSnackbar(true);
                            setFormHasError(true)
                        }

                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    setShowErrorSnackbar(true);
                    setFormHasError(true)
                })

                // Get user data

            } else {
                setIsLoading(false)
                setShowErrorSnackbar(true);
                setFormHasError(true)
            }
        })
        .catch(function (error) {
            setIsLoading(false)
            setFormHasError(true)
            setShowErrorSnackbar(true);
        });

    }

	const findGetParameter = (parameterName) => {
		var result = null,
			tmp = [];
		window.location.search
			.substr(1)
			.split("&")
			.forEach(function (item) {
			  tmp = item.split("=");
			  if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
			});
		return result;
	}

    useEffect(() => {
		document.title = 'Log In — ' + process.env.REACT_APP_APPLICATION_NAME;	

		if (findGetParameter('error') === "session") {
			setErrorSession(true)
		} else {
			setErrorSession(false)
		}
	})

    return (
        <ThemeProvider theme={theme}>
            <div className="login-container">

                <img src={Logo} style={{
                    height: '35px',
                    marginBottom: '20px'
                }} alt="" className="logo" />

                <br />
                <p>Bitte logge dich an um dein Restaurant zu verwalten.</p><br /><br />

				{errorSession && (
					<>
						<Alert variant="filled" severity="error">
							<AlertTitle>Deine Sitzung wurde beendet!</AlertTitle>
							Bitte überprüfe deine Internetverbindung und starte den DeliverOne-Manager neu!
						</Alert><br />
					</>
				)}

                <TextField onKeyPress={(e) => {if(e.key === "Enter"){submitForm()}}} disabled={isLoading} error={formHasError} value={formMail} onChange={(e) => {setFormMail(e.target.value)}} fullWidth label="E-Mail Adresse" type="email" variant="outlined" /><br />
                <TextField onKeyPress={(e) => {if(e.key === "Enter"){submitForm()}}} disabled={isLoading} error={formHasError} value={formPassword} onChange={(e) => {setFormPassword(e.target.value)}} fullWidth label="Passwort" type="password" variant="outlined" /><br />

                <LoadingButton loading={isLoading} disabled={isLoading} onClick={submitForm} fullWidth variant="contained">Anmelden</LoadingButton>
                <LoadingButton loading={isLoading} disabled={isLoading} onClick={() => {
                    // Open page in new tab
                    window.open('https://deliverone.de/reset-password', '_blank');
                }} fullWidth variant="outlined">Passwort vergessen?</LoadingButton>

                <Snackbar
                    open={showErrorSnackbar}
                    message="Deine E-Mail Adresse oder Passwort ist falsch!"
                    sx={{ zIndex: 9999999 }}
                    autoHideDuration={8000}
                    onClose={() => {setShowErrorSnackbar(false)}}
                />

            </div>
            <div className="login-background" style={{
                backgroundImage: `url(/LoginBackgrounds/${randomImage})`
            }}><div></div></div>
        </ThemeProvider>
    )
}