import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import Loader from '../../../../components/Loader';
import axios from 'axios';

// Steps
import StepCustomer from './StepCustomer';
import StepRestaurant from './StepRestaurant';
import PageAdministrateStripeConnectAccountCreate from '../../Stripe/ConnectAccountCreate';
import PageAdministrateRestaurantParameter from '../../RestaurantParameter';
import StepBilling from './StepBilling';
import StepDocuments from './StepDocuments';
import StepInbox from './StepInbox';
import StepSuccess from './StepSuccess';
import StepError from './StepError';

const steps = [
	'Personensatz',
	'Restaurant',
	'Legitimation',
	'Vertragsparameter',
	'Abrechnung',
	'Dokumente',
	'Elektronisches Postfach'
];

export default function ProcessOutlet() {
	const [activeStep, setActiveStep] = useState(0);
	const [stepError, setStepError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingText, setLoadingText] = useState("Lädt...");

	const [formValues, setFormValues] = useState({});

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const handleStepError = (value) => {
		setStepError(value);
	}

	const handleSubmit = () => {
		setIsLoading(true);
		setLoadingText("Vertrag wird erstellt... Dies kann bis zu 10 Minuten dauern...")
		axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/contracts/setup`, formValues)
        .then((res) => {
			setIsLoading(false);
			if (res.data.status === "success") {
				setActiveStep(7);
				res.data.files.forEach((file) => {
					downloadPdfFile(file);
				})
			} else {
				setActiveStep(8);
			}
        })
        .catch((err) => {
			setIsLoading(false);
            setActiveStep(8);
        })
	}

	const downloadPdfFile = (fileName) => {

		fileName = fileName.replace(".pdf", "")

		setIsLoading(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/contracts/download/${fileName}`, {
			responseType: 'blob'
		}).then(res => {
			window.open(URL.createObjectURL(res.data), fileName);
			setIsLoading(false)
		})
	}

	return (
		<div style={{
			display: 'flex',
			flexDirection: 'row',
			padding: '0 60px'
		}}>
			{isLoading && <Loader text={loadingText} />}
			<div style={{
				width: '300px'
			}}>
				<Stepper activeStep={activeStep} orientation="vertical">
					{steps.map((label, index) => {

						const labelProps = {};
						if (stepError === true) {
							if (index === activeStep) {
								labelProps.error = true;
							}
						}

						return (
							<Step key={label}>
								<StepLabel {...labelProps}>{label}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
			</div>
			<Paper style={{
				flex: '1 1 auto',
			}}>
				{activeStep === 0 && (<StepCustomer
										formValues={formValues}
										setFormValues={setFormValues}
										activeStep={activeStep}
										steps={steps}
										handleNext={handleNext}
										handleBack={handleBack}
										handleReset={handleReset}
										handleStepError={handleStepError} />)}
				{activeStep === 1 && (<StepRestaurant
										formValues={formValues}
										setFormValues={setFormValues}				
										asStep={true}
										isLoading={isLoading}
										setIsLoading={setIsLoading}
										handleNext={handleNext}
										handleBack={handleBack}
										handleReset={handleReset}
										handleStepError={handleStepError} />)}
				{activeStep === 2 && (<PageAdministrateStripeConnectAccountCreate
										formValues={formValues}
										setFormValues={setFormValues}				
										asStep={true}
										isLoading={isLoading}
										setIsLoading={setIsLoading}
										handleNext={handleNext}
										handleBack={handleBack}
										handleReset={handleReset}
										handleStepError={handleStepError} />)}
				{activeStep === 3 && (<PageAdministrateRestaurantParameter
										formValues={formValues}
										setFormValues={setFormValues}				
										asStep={true}
										isLoading={isLoading}
										setIsLoading={setIsLoading}
										handleNext={handleNext}
										handleBack={handleBack}
										handleReset={handleReset}
										handleStepError={handleStepError} />)}
				{activeStep === 4 && (<StepBilling
										formValues={formValues}
										setFormValues={setFormValues}
										activeStep={activeStep}
										steps={steps}
										isLoading={isLoading}
										setIsLoading={setIsLoading}
										handleNext={handleNext}
										handleBack={handleBack}
										handleReset={handleReset}
										handleStepError={handleStepError} />)}
				{activeStep === 5 && (<StepDocuments
										formValues={formValues}
										setFormValues={setFormValues}
										activeStep={activeStep}
										steps={steps}
										handleNext={handleNext}
										handleBack={handleBack}
										handleReset={handleReset}
										handleStepError={handleStepError} />)}
				{activeStep === 6 && (<StepInbox
										formValues={formValues}
										setFormValues={setFormValues}
										activeStep={activeStep}
										steps={steps}
										handleNext={handleSubmit}
										handleBack={handleBack}
										handleReset={handleReset}
										handleStepError={handleStepError} />)}
				{activeStep === 7 && (<StepSuccess />)}
				{activeStep === 8 && (<StepError />)}
			</Paper>

		</div>
	);
}