import React, { useState, useEffect } from "react";

import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AssistImage from '../../assets/deliverone-assist.png';
import AssistMenuImage from '../../assets/deliverone-assist-menu.png';
import AssistPaymentImage from '../../assets/deliverone-assist-payment.png';
import AssistCustomerServiceImage from '../../assets/deliverone-assist-customer-service.png';
import AssistMarketingImage from '../../assets/deliverone-assist-marketing.png';
import AssistSentimentAnalyticsImage from '../../assets/deliverone-assist-sentiment-analytics.png';
import AssistAdvertisingImage from '../../assets/deliverone-assist-advertising.png';

import './style.scss';

function RecommendationsIndex(props) {
	return (
        <>
            {props.recommendations.length === 0 ? (
                <center style={{
                    display: 'block',
                    padding: '35px',
                    borderRadius: '15px',
                    background: 'whitesmoke',
                    margin: '5px',
                    border: '1px solid #d7d7d7',
                }}>

                    <img src={AssistImage} className="Assist-image" alt="DeliverOne Assist" />

                    <h3 className='Assist-subtitle' style={{marginTop:'0'}}>Wir stellen vor:</h3>
                    <h1 style={{marginTop:'0'}}>DeliverOne Assist</h1>

                    <h3 style={{fontWeight:"normal"}}>
                        Die intelligente Lösung für Restaurants, um ihren Umsatz zu steigern und den Kundenservice zu verbessern.
                    </h3>
                </center>
            ) : (
                <>
                    {props.recommendations.map((recommendation) => (
                        <div className="Assist-recommendation" key={recommendation.id}>
                            <span className="Assist-recommendation-title">{recommendation.recommendation_description}</span><br />
                            <span className="Assist-recommendation-subtitle" dangerouslySetInnerHTML={{__html: recommendation.recommendation_value}}></span><br />
                            <div className="Assist-hr Assist-hr--small"></div>
                            <div className="Assist-recommendation-actions">
                                <IconButton aria-label="Annehmen" color="success" size="small" onClick={() => {props.approveChange(recommendation.id)}} style={{marginRight:'10px'}}>
                                    <DoneIcon fontSize="small" />
                                </IconButton>
                                <IconButton aria-label="Ablehnen" color="error" size="small" onClick={() => {props.denyChange(recommendation.id)}}>
                                    <DeleteOutlineIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </>
	)
}

export default RecommendationsIndex;