import React, {useEffect, useState} from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/system/Box';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Snackbar from '@mui/material/Snackbar'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import deLocale from 'date-fns/locale/de';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import ChartPostalCodes from '../../components/Charts/PostalCodes';
import ChartOrderDays from '../../components/Charts/OrderDays';
import ChartOrderTimes from '../../components/Charts/OrderTimes';
import ChartPopularProducts from '../../components/Charts/PopularProducts';
import ChartOrderPaymentMethods from '../../components/Charts/OrderPaymentMethods';
import ChartTraffic from '../../components/Charts/Traffic';
import ChartOrderSources from '../../components/Charts/OrderSources';
import ChartAvgOrderCountByWeek from '../../components/Charts/AvgOrderCountByWeek';
import ChartAvgOrderValueByWeek from '../../components/Charts/AvgOrderValueByWeek';
import ChartSignUpTimes from '../../components/Charts/SignUpTimes';
import Loader from '../../components/Loader';

import './style.scss';

export default function PageCostAndStats() {

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));  
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [isLoading, setIsLoading] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [calendarModal, setCalendarModal] = useState(false)
	const [calendarDate, setCalendarDate] = useState(new Date());

	const [chartPostalCodes, setChartPostalCodes] = useState([]);
	const [chartOrderDays, setChartOrderDays] = useState([]);
	const [chartOrderTimes, setChartOrderTimes] = useState([]);
	const [chartPopularProducts, setChartPopularProducts] = useState([]);
	const [chartCheckoutPaymentMethods, setChartCheckoutPaymentMethods] = useState([]);
	const [chartTraffic, setChartTraffic] = useState([]);
	const [chartOrderSources, setChartOrderSources] = useState([]);
	const [chartAvgOrderCountByWeek, setChartAvgOrderCountByWeek] = useState([]);
	const [chartAvgOrderValueByWeek, setChartAvgOrderValueByWeek] = useState([]);
	const [chartSignUpTimes, setChartSignUpTimes] = useState([]);

	useEffect(() => {

		document.title = 'Statistiken & Analyse — ' + process.env.REACT_APP_APPLICATION_NAME;
	
		setIsLoading(true)
	
		let dateYmd = calendarDate.getFullYear() + "-" + ("0" + (calendarDate.getMonth() + 1)).slice(-2) + "-" + ("0" + calendarDate.getDate()).slice(-2);
		
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/stats?date=` + dateYmd)
			.then(res => {
				setIsLoading(false)
				setChartPostalCodes(res.data.data.postal_codes);
				setChartOrderDays(res.data.data.order_days);
				setChartOrderTimes(res.data.data.order_times);
				setChartPopularProducts(res.data.data.popular_products);
				setChartTraffic(res.data.data.traffic);
				setChartOrderSources(res.data.data.order_sources);
				setChartCheckoutPaymentMethods(res.data.data.checkout_payment_methods);
				setChartAvgOrderCountByWeek(res.data.data.avg_order_count_by_week);
				setChartAvgOrderValueByWeek(res.data.data.avg_order_value_by_week);
				setChartSignUpTimes(res.data.data.sign_up_times);
			})

		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
		}

		if (userData.role_name === "admin") {
			setIsAdmin(true);
		}
		
	}, [reloadKey, calendarDate])

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								<Tooltip title="Stichtag auswählen">
									<IconButton onClick={() => {setCalendarModal(true)}}>
										<CalendarTodayIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>

				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<Grid
							container 
							spacing={2}
						>
							<Grid item xs={12} lg={6} padding={1}><ChartPostalCodes data={chartPostalCodes} /></Grid>
							
							<Grid item xs={12} lg={6} padding={1}><ChartOrderDays data={chartOrderDays} /></Grid>

							<Grid item xs={12} lg={6} padding={1}><ChartOrderTimes data={chartOrderTimes} /></Grid>
							
							<Grid item xs={12} lg={6} padding={1}><ChartPopularProducts data={chartPopularProducts} /></Grid>
							
							<Grid item xs={12} lg={6} padding={1}><ChartOrderPaymentMethods data={chartCheckoutPaymentMethods} /></Grid>
							
							<Grid item xs={12} lg={6} padding={1}><ChartTraffic data={chartTraffic} /></Grid>
							
							<Grid item xs={12} lg={6} padding={1}><ChartOrderSources data={chartOrderSources} /></Grid>
							
							<Grid item xs={12} lg={6} padding={1}><ChartAvgOrderCountByWeek data={chartAvgOrderCountByWeek} /></Grid>

							<Grid item xs={12} lg={6} padding={1}><ChartAvgOrderValueByWeek data={chartAvgOrderValueByWeek} /></Grid>

							<Grid item xs={12} lg={6} padding={1}><ChartSignUpTimes data={chartSignUpTimes} /></Grid>
						</Grid>
					)}
				</Box>
			</Paper>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

			<Dialog
				fullScreen={fullScreen}
				open={calendarModal}
				onClose={() => {
					setCalendarModal(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{fullScreen ? (
					<AppBar sx={{ position: 'relative' }}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={() => {
									setCalendarModal(false);
								}}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
							<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
								Datum auswählen
							</Typography>
						</Toolbar>
					</AppBar>
				) : (
					<DialogTitle id="alert-dialog-title">
						Datum auswählen
					</DialogTitle>
				)}
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Wähle einen Stichtag aus, um die Statistiken zu diesem Datum zu laden.
					</DialogContentText>

					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
						<StaticDatePicker
							displayStaticWrapperAs="desktop"
							value={calendarDate}
							onChange={(newValue) => {
								setCalendarDate(newValue);
							}}
							renderInput={(params) => <TextField {...params} />}
							minDate={new Date("Sat Jan 01 2022 12:00:00 GMT+0100 (Mitteleuropäische Normalzeit)")}
							maxDate={new Date()}
							disableFuture
							openTo="day"
						/>
					</LocalizationProvider>

				</DialogContent>
				<DialogActions>
					<Button onClick={() => {
						setCalendarModal(false);
					}} autoFocus>
						Anwenden
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}