import React, { useState, useEffect } from "react";

import Box from '@mui/system/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import LockIcon from '@mui/icons-material/Lock';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonIcon from '@mui/icons-material/Person';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../components/Loader';
import Currency from 'react-currency-formatter';
import { useHistory } from "react-router-dom";

import Pagination from '@mui/material/Pagination';

function createData(id, order_total_sum, order_status, order_delivery_type, order_payment_type, created_at) {
	return { id, order_total_sum, order_status, order_delivery_type, order_payment_type, created_at };
}

function CustomerOrdersIndex(props) {

	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [tableRows, setTableRows] = useState([]);
	const [paginationPage, setPaginationPage] = useState(1);
	const [paginationTotalPages, setPaginationTotalPages] = useState(1);
	const [isAdmin, setIsAdmin] = useState(false);
	let navigate = useHistory();
	
	useEffect(() => {
		
		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
		}
		if (userData.role_name === "admin") {
			setIsAdmin(true);
		}

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/customer-orders/${props.userId}?page=${paginationPage}`)
		.then((res) => {
			if (res.status === 200 && res.data) {
				// Everything OK
				if (res.data.current_page) {

					setPaginationPage(res.data.current_page);
					setPaginationTotalPages(res.data.last_page);

					let temporaryTableRows = [];
					res.data.data.forEach((order) => {
						temporaryTableRows.push(createData(order.id, order.order_total_sum, order.order_status_string, order.order_delivery_type, order.order_payment_type, order.created_at));
					})
					setTableRows(temporaryTableRows);
					setIsLoading(false);
				}
			} else {
				// Error
				handleError(res)
			}
		})
		.catch((err) => {handleError(err)})
		
	}, [props.reloadKey, props.sortBy]);

	const handleError = (error) => {
		setIsLoading(false);
		setShowErrorSnackbar(true)
	}

	return (
		<Box padding={2}>
			{isLoading ? (
				<Loader />
			) : (
				<TableContainer className="PageMenu-Table">
					<Table size="small" aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell style={{padding:'6px 16px'}}>Bestellnr.</TableCell>
								<TableCell style={{padding:'6px 16px'}}>Total</TableCell>
								<TableCell style={{padding:'6px 16px'}}>Status</TableCell>
								<TableCell style={{padding:'6px 16px'}}>Lieferart</TableCell>
								<TableCell style={{padding:'6px 16px'}}>Zahlungsmethode</TableCell>
								<TableCell style={{padding:'6px 16px'}}>Datum</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableRows.map((row) => (
								<TableRow
									onClick={() => {
										navigate.push("/orders/" + row.id);
									}}
									key={row.id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component="td" style={{whiteSpace: 'nowrap'}} scope="row">
										#{row.id}
									</TableCell>
									<TableCell component="td" scope="row">
										<Currency
											quantity={parseFloat(row.order_total_sum)}
											currency="EUR"
										/>
									</TableCell>
									<TableCell component="td" scope="row">
										{row.order_status}
									</TableCell>
									<TableCell component="td" scope="row">
										{row.order_delivery_type === "delivery" ? "Lieferung" : "Abholung"}
									</TableCell>
									<TableCell component="td" scope="row">
										{row.order_payment_type === 'cash' && 'Barzahlung'}
										{row.order_payment_type === 'eccard' && 'EC-Karte'}
										{row.order_payment_type === 'creditcard' && 'Kreditkarte (Zahlung abgeschlossen)'}
										{row.order_payment_type === 'apple-pay' && 'Apple Pay (Zahlung abgeschlossen)'}
										{row.order_payment_type === 'paypal' && 'PayPal (Zahlung abgeschlossen)'}
										{row.order_payment_type.includes("deliverone-payments") && 'DeliverOne Payments (Zahlung abgeschlossen)'}
									</TableCell>
									<TableCell component="td" scope="row">
										{row.created_at} Uhr
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}

			<div style={{marginTop: '20px'}}>
				<center className="CustomersPagination">
					<Pagination
						count={paginationTotalPages}
						page={paginationPage}
						variant="outlined"
						color="primary"
						onChange={(event, value) => {
							setIsLoading(true);
							setPaginationPage(value)
							setTimeout(() => {
								props.setReloadKey(Math.random())
							}, 250)
						}}
					/>
				</center>
			</div>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
		</Box>
	)
}

export default CustomerOrdersIndex;