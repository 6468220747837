import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import WifiIcon from '@mui/icons-material/Wifi';

import TerminalsIndex from './TerminalsIndex';

import axios from 'axios';

import './style.scss';

export default function PageTerminals() {

	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [toolbarSearchQuery, setToolbarSearchQuery] = useState("");
	const [editModalData, setEditModalData] = useState(null);

	const [showConnectModal, setShowConnectModal] = useState(false);
	const [deviceType, setDeviceType] = useState("bluetooth");
	const [pairingCode, setPairingCode] = useState("");
	const [bluetoothDeviceSerialNo, setBluetoothDeviceSerialNo] = useState("");

	
	useEffect(() => {
		document.title = 'Terminals — ' + process.env.REACT_APP_APPLICATION_NAME;
	})

	const connectHandler = () => {
		setShowErrorSnackbar(false)

		if (deviceType === "bluetooth") {
			if (bluetoothDeviceSerialNo === "") {
				setShowErrorSnackbar(true);
				return;
			}
		} else {
			if (pairingCode === "") {
				setShowErrorSnackbar(true);
				return;
			}
		}

		axios.post(process.env.REACT_APP_API_URL + '/api/terminals/connect', {
			deviceType: deviceType,
			pairingCode: pairingCode,
			bluetoothDeviceSerialNo: bluetoothDeviceSerialNo
		}).then((response) => {
			if (response.data.success === true) {
				setReloadKey(Math.random());
				setShowConnectModal(false);
				setPairingCode("")
			} else {
				setShowErrorSnackbar(true);
			}
		}).catch((error) => {
			setShowErrorSnackbar(true);
		});

	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container spacing={2} style={{flexDirection:'row'}} alignItems="center" justifyContent="flex-end">
							<Grid item>
								<Button onClick={() => {setShowConnectModal(true)}} variant="contained" sx={{ mr: 1 }}>
									Terminal verbinden
								</Button>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<TerminalsIndex setEditModalOpen={() => {}} setEditModalData={setEditModalData} toolbarSearchQuery={toolbarSearchQuery} reloadKey={reloadKey} />

				<Dialog
                    open={showConnectModal}
                    onClose={() => {
						setShowConnectModal(false);
					}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
					fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        Terminal neu verbinden
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
							Verbinden Sie ein neues Terminal-Gerät. Geben Sie dazu den Pairing-Code ein, den Sie beim einrichten des Gerätes erhalten. Bitte beachten Sie, dass Sie das Gerät vorher mit dem Internet verbinden müssen.
						</DialogContentText>

						<br />

						<FormControl>
							<FormLabel id="radio-buttons-group-label">Geräte-Art</FormLabel>
							<RadioGroup
								aria-labelledby="radio-buttons-group-label"
								defaultValue="bluetooth"
								name="radio-buttons-group"
								value={deviceType}
								onChange={(e) => {setDeviceType(e.target.value)}}
							>
								<FormControlLabel value="bluetooth" control={<Radio />} label={<span style={{display: 'flex',alignContent: 'center',gap: '10px'}}><Chip icon={<BluetoothIcon />} label="Bluetooth" size="small" /> DeliverOne Terminal Starter</span>} />
								<FormControlLabel value="internet" control={<Radio />} label={<span style={{display: 'flex',alignContent: 'center',gap: '10px'}}><Chip icon={<WifiIcon />} label="Internet" size="small" /> DeliverOne Terminal Enterprise</span>} />
							</RadioGroup>
						</FormControl>
						<br />
						<br />

						{deviceType === "internet" ? (
							<TextField
								fullWidth
								size="small"
								label="Pairing-Code"
								required
								value={pairingCode}
								onChange={(event) => {
									setPairingCode(event.target.value);
								}}
							/>
							) : (
							<>
								<TextField
									fullWidth
									size="small"
									label="Seriennr."
									required
									value={bluetoothDeviceSerialNo}
									helperText="Die Seriennummer findest du auf der Rückseite des Gerätes (S/N) oder über die Einstellungen des Gerätes."
									onChange={(event) => {
										setBluetoothDeviceSerialNo(event.target.value.toUpperCase().trim().replaceAll(/\s/g,'').replaceAll("S/N", ""));
									}}
								/>
							</>
						)}

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
							setShowConnectModal(false);
						}}>Abbrechen</Button>
                        <Button onClick={connectHandler} autoFocus>
                            Verbinden
                        </Button>
                    </DialogActions>
                </Dialog>

			</Paper>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
		</>
	);
}