import React, {useEffect, useState} from 'react';
import axios from "axios";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';

import ProductCreatedImage from '../../assets/product-created.svg';
import ErrorImage from '../../assets/error.svg';

// Steps
import StepMain from './StepMain';
import StepIngredients from './StepIngredients';
import StepPrices from './StepPrices';

import { useParams, useHistory } from "react-router-dom";

import './style.scss';

export default function PageProductNew() {

	let params = useParams();
	let navigate = useHistory();

	const [isLoading, setIsLoading] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set());
	const [hasError, setHasError] = useState(false);

	// Form data
	const [formProductName, setFormProductName] = useState('');
	const [formProductDescription, setFormProductDescription] = useState('');
	const [formProductRestaurantId, setFormProductRestaurantId] = useState(0);
	const [formProductCategoryId, setFormProductCategoryId] = useState(0);
	const [formProductEnabled, setFormProductEnabled] = useState(true);
	const [formProductIngredients, setFormProductIngredients] = useState('');
	const [formProductAllergens, setFormProductAllergens] = useState([{
		id: 1,
		title: "Glutenhaltiges Getreide"
	}]);
	const [formProductSinglePrice, setFormProductSinglePrice] = useState(0);
	const [formProductTaxValue, setFormProductTaxValue] = useState(1);
	const [formProductHasMultiplePrices, setFormProductHasMultiplePrices] = useState(false)
	const [formProductMultiplePrices, setFormProductMultiplePrices] = useState([
		{label: 'Klein', price: '4.90'}
	]);

	const steps = [
		'Grunddaten',
		'Zutaten',
		'Preise'
	];

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}
	
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		window.location.reload();
	};

	useEffect(() => {

		document.title = 'Produkt erstellen — Speisekarte — ' + process.env.REACT_APP_APPLICATION_NAME;
		
	}, [params])

	const handleSubmit = () => {

		// Create Product

		const formData = new FormData();

		formData.append("formProductName", formProductName);
		formData.append("formProductDescription", formProductDescription);
		formData.append("formProductRestaurantId", formProductRestaurantId);
		formData.append("formProductCategoryId", formProductCategoryId);
		formData.append("formProductEnabled", formProductEnabled);
		formData.append("formProductIngredients", formProductIngredients);
		formData.append("formProductAllergens", JSON.stringify(formProductAllergens));
		formData.append("formProductSinglePrice", formProductSinglePrice);
		formData.append("formProductTaxValue", formProductTaxValue);
		formData.append("formProductHasMultiplePrices", formProductHasMultiplePrices);
		formData.append("formProductMultiplePrices", JSON.stringify(formProductMultiplePrices));

		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};
		const API = "api/backend/restaurant/products";
		const url = `${process.env.REACT_APP_API_URL}/${API}`;
	
		axios.post(url, formData)
			.then(function (response) {
				if (response.status === 200) {
					if (response.data.status === 'success') {
						setIsLoading(false)
						handleNext();
					} else {
						setIsLoading(false)
						setHasError(true)
						handleNext();
						
					}
				} else {
					setIsLoading(false)
					setHasError(true)
					handleNext();
					
				}
			})
			.catch(function (error) {
				setIsLoading(false)
				setHasError(true)
				handleNext();
				
			})
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{justifyContent:'space-between'}} spacing={1} alignItems="center">
							<Grid item>
								{activeStep === 0 && (<><b>Grunddaten</b> — Informationen zu Deinem Artikel</>)}
								{activeStep === 1 && (<><b>Zutaten</b> — Allergene und Zutatenliste</>)}
								{activeStep === 2 && (<><b>Preise</b> — Preisinformationen &amp; Steuern</>)}
							</Grid>
							<Grid item>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {window.location.reload()}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<Box padding={2}>

				<Stepper activeStep={activeStep}>
					{steps.map((label, index) => {
						const stepProps = {};
						const labelProps = {};
						if (isStepSkipped(index)) {
							stepProps.completed = false;
						}
						return (
							<Step key={label} {...stepProps}>
								<StepLabel {...labelProps}>{label}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
				{activeStep === steps.length ? (
					<React.Fragment>
						<div style={{textAlign:'center',padding:'45px 0'}}>
							<img style={{width:'300px',marginBottom:'30px'}} src={(hasError ? ErrorImage : ProductCreatedImage)} alt="" /><br />
							<Typography variant="h6" gutterBottom>
								{hasError ? 'Ein Fehler ist aufgetreten. Bitte kontaktiere den Support: +49 8342 7050-752' : 'Dein Produkt wurde erfolgreich erstellt!'}
							</Typography>
						</div>
						<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
							<Box sx={{ flex: '1 1 auto' }} />
							<Button onClick={handleReset}>Weiteres Produkt erstellen</Button>
						</Box>
					</React.Fragment>
				) : (
					activeStep === 0 ? <StepMain
											isLoading={setIsLoading}
											handleNext={handleNext}
											handleBack={handleBack}
											formProductName={formProductName}
											setFormProductName={setFormProductName}
											formProductDescription={formProductDescription}
											setFormProductDescription={setFormProductDescription}
											formProductEnabled={formProductEnabled}
											setFormProductEnabled={setFormProductEnabled}
											formProductRestaurantId={formProductRestaurantId}
											setFormProductRestaurantId={setFormProductRestaurantId}
											formProductCategoryId={formProductCategoryId}
											setFormProductCategoryId={setFormProductCategoryId}
										/> :
					activeStep === 1 ? <StepIngredients
											isLoading={setIsLoading}
											handleNext={handleNext}
											handleBack={handleBack}
											formProductIngredients={formProductIngredients}
											setFormProductIngredients={setFormProductIngredients}
											formProductAllergens={formProductAllergens}
											setFormProductAllergens={setFormProductAllergens}
										/> : 
										<StepPrices
											isLoading={setIsLoading}
											handleNext={handleNext}
											handleBack={handleBack}
											handleSubmit={handleSubmit}

											formProductSinglePrice={formProductSinglePrice}
											setFormProductSinglePrice={setFormProductSinglePrice}

											formProductTaxValue={formProductTaxValue}
											setFormProductTaxValue={setFormProductTaxValue}

											formProductHasMultiplePrices={formProductHasMultiplePrices}
											setFormProductHasMultiplePrices={setFormProductHasMultiplePrices}

											formProductMultiplePrices={formProductMultiplePrices}
											setFormProductMultiplePrices={setFormProductMultiplePrices}
										/>
				)}

				</Box>

			</Paper>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={isLoading}
				message="Wird gespeichert..."
				sx={{ zIndex: 9999999 }}
				action={(
					<CircularProgress style={{marginRight:'10px'}} size={20} />
				)}
			/>
			
		</>
	);
}