import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

import CouriersIndex from './CouriersIndex';
import CustomersCreate from '../PageCustomers/CustomersCreate';
import CustomersEdit from '../PageCustomers/CustomersEdit';

import axios from 'axios';

import './style.scss';

export default function PageCouriers() {

	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [toolbarSearchQuery, setToolbarSearchQuery] = useState("");
	const [editModalData, setEditModalData] = useState(null);

	const [showCustomerCreateModal, setShowCustomerCreateModal] = useState(false);
	const [showCustomerEditModal, setShowCustomerEditModal] = useState(false);
	const [deleteModalData, setDeleteModalData] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [restaurantStreet, setRestaurantStreet] = useState("");
	const [restaurantCity, setRestaurantCity] = useState("");
	const [restaurantId, setRestaurantId] = useState("");
	
	useEffect(() => {
		document.title = 'Kuriere — ' + process.env.REACT_APP_APPLICATION_NAME;

		// Get local storage data
		let data = localStorage.getItem('data');
		if (data) {
			data = JSON.parse(data);
			setRestaurantStreet(data["street"]);
			setRestaurantCity(data["city"]);
			setRestaurantId(data["restaurant_access_id"]);
		}

	})

	const deleteHandler = () => {
		if (deleteModalData) {
			setShowErrorSnackbar(false);
			// Delete customer
			axios.delete(`${process.env.REACT_APP_API_URL}/api/backend/couriers/${deleteModalData.id}`)
				.then((response) => {
					setReloadKey(Math.random());
					setShowDeleteModal(false);
					setShowCustomerCreateModal(false);
					setShowCustomerEditModal(false);
				})
				.catch((error) => {
					setShowErrorSnackbar(true)
				})
		}
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container spacing={2} style={{flexDirection:'row'}} alignItems="center">
							<Grid item>
								<SearchIcon color="inherit" sx={{ display: 'block' }} />
							</Grid>
							<Grid item xs>
								<TextField
									value={toolbarSearchQuery}
									onChange={(e) => setToolbarSearchQuery(e.target.value)}
									fullWidth
									placeholder="Suche nach Namen, E-Mails oder Telefonnummern"
									InputProps={{
										disableUnderline: true,
										sx: { fontSize: 'default' },
									}}
									variant="standard"
								/>
							</Grid>
							<Grid item>
								<Button onClick={() => {setShowCustomerCreateModal(true)}} variant="contained" sx={{ mr: 1 }}>
									Kurier erstellen
								</Button>
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				
				<CouriersIndex setEditModalOpen={setShowCustomerEditModal} setEditModalData={setEditModalData} toolbarSearchQuery={toolbarSearchQuery} reloadKey={reloadKey} />

				<CustomersCreate
					isOpen={showCustomerCreateModal}
					closeHandler={() => {setShowCustomerCreateModal(false)}}
					reloadIndex={() => {setReloadKey(Math.random())}}
					courier={true}

					courierFormData={{
						customerStreet: (restaurantStreet || "?"),
						customerCity: (restaurantCity || "?"),
						customerRestaurantId: (restaurantId || "?"),
						customerPassword: "DELIVERONE_COURIER_DEFAULT_PASSWORD",
						customerRole: "courier"
					}}
				/>

				<CustomersEdit
					isOpen={showCustomerEditModal}
					closeHandler={() => {setShowCustomerEditModal(false)}}
					row={editModalData}
					reloadIndex={() => {setReloadKey(Math.random())}}
					deleteHandler={(row) => {setDeleteModalData(row);setShowDeleteModal(true)}}

					courier={true}
					courierFormData={{
						customerStreet: (restaurantStreet || "?"),
						customerCity: (restaurantCity || "?"),
						customerRestaurantId: (restaurantId || "?"),
						customerPassword: "DELIVERONE_COURIER_DEFAULT_PASSWORD",
						customerRole: "courier",
						customerDataPolicy: (editModalData?.rawDetails?.data_policy_agreed),
						customerTerms: (editModalData?.rawDetails?.terms_and_conditions_agreed),
						customerTracking: (editModalData?.rawDetails?.tracking_agreed)
					}}
				/>

				<Dialog
                    open={showDeleteModal}
                    onClose={() => {
						setShowDeleteModal(false);
					}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                >
                    <DialogTitle id="alert-dialog-title">
                        {deleteModalData ? `${deleteModalData.name} wirklich löschen?` : 'Wird geladen...'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Möchtest Du diesen Kurier wirklich sperren?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
							setShowDeleteModal(false);
						}}>Abbrechen</Button>
                        <Button color="error" onClick={deleteHandler} autoFocus>
                            Bestätigen
                        </Button>
                    </DialogActions>
                </Dialog>

			</Paper>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
		</>
	);
}