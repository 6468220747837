import React, { useState, useEffect } from "react"
import axios from "axios"

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import deLocale from 'date-fns/locale/de';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function PromotionsEdit(props) {

	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false)
    const [editModalData, setEditModalData] = useState(null)
	const [editModalStatus, setEditModalStatus] = useState(true)
	const [isLoading, setIsLoading] = useState(false)
	const [snackbarMessage, setSnackbarMessage] = useState("")
	const [showSnackbar, setShowSnackbar] = useState(false)
	const [productsList, setProductsList] = useState([])
	const [isAdmin, setIsAdmin] = useState(false);

	useEffect(() => {
		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
		}

		if (userData.role_name === "admin") {
			setIsAdmin(true);
		}

        if (props.row) {
			
            setEditModalData(props.row)
            setEditModalStatus(props.row.status)

			// Fetch all products
			setIsLoading(true)
			setSnackbarMessage("Produkte werden geladen...")
			setShowSnackbar(true)
				axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/products`)
			.then((res) => {
				setIsLoading(false)
				setSnackbarMessage("...")
				setShowSnackbar(false)
				if (res.status === 200 && res.data) {
					setProductsList(res.data)
				} else {
					// Error
					handleError(res)
				}
			})
			.catch((err) => {
				setIsLoading(false)
				setSnackbarMessage("...")
				setShowSnackbar(false)
				handleError(err)
			})

        }
	}, [props])

	const resetForm = () => {
		setEditModalData(null)
		setEditModalStatus(true)
	}

    const onSave = () => {
		setIsLoading(true)
		setSnackbarMessage("Aktion wird gespeichert...")
		setShowSnackbar(true)

		// Format Date for PHP
		let formattedEndDate = "";
		let date = new Date(editModalData.end_date_mdy)
		formattedEndDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()

		const formData = new FormData()
		formData.append("promotionEndDate", formattedEndDate)
		formData.append("promotionProductId", editModalData.product_id)
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		}
		const API = "api/backend/restaurant/promotions/" + editModalData.id
		const url = `${process.env.REACT_APP_API_URL}/${API}`
	
		axios.post(url, formData)
			.then(function (response) {
				if (response.status === 200) {
					setIsLoading(false)
					resetForm()
					props.closeHandler()
					props.reloadIndex()
					setSnackbarMessage("Aktion wurde gespeichert.")			
					setTimeout(() => {setShowSnackbar(false)}, 5000)			
				} else {
					handleError(response)
				}
			})
			.catch(function (error) {
				handleError(error)
			})
	}

	const handleError = (error) => {
		setShowErrorSnackbar(true)
		
	}

	return (
        props.row && (
            <>
                <Dialog fullWidth className="PageMenu-Modal" open={props.isOpen} onClose={props.closeHandler}>
                    <DialogTitle>{editModalData ? `Aktion #${editModalData.id}` : 'Wird geladen...'}</DialogTitle>
                    <DialogContent>
						<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
							<DateTimePicker
								disabled
								renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" {...props} />}
								label="Start-Zeitpunkt"
								value={new Date((editModalData ? editModalData.start_date_mdy : ''))}
								onChange={() => {return}}
							/>
						</LocalizationProvider>
						<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
							<DateTimePicker
								renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" {...props} />}
								label="End-Zeitpunkt"
								value={new Date((editModalData ? editModalData.end_date_mdy : ''))}
								onChange={(newValue) => {
									setEditModalData({
										...editModalData,
										end_date_mdy: newValue
									})
								}}
								minDate={new Date()}
							/>
						</LocalizationProvider>
						<FormControl variant="standard" margin="dense" fullWidth>
							<InputLabel id="demo-simple-select-label">Produkt</InputLabel>
							<Select
								fullWidth
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={editModalData ? editModalData.product_id : ''}
								label="Produkt"
								onChange={(event) => {
									setEditModalData({
										...editModalData,
										product_id: event.target.value
									})
								}}
							>
								{productsList.map((product) => (
									<MenuItem key={product.id} value={product.id}>{isAdmin ? product.restaurant_name + " / ": ""}{(product.category && product.category.category_name)} / {product.product_name}</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<small>* Mit dem Speichern der Aktion/Promotion bin ich mit den Gebühren einverstanden: 3,00 EUR pro angefangenem Kalendertag. Die Abrechnung erfolgt mit der monatlichen Rechnung.</small>
                    </DialogContent>
                    <DialogActions>
                        <div></div>
                        <div>
                            <Button style={{marginRight:'5px'}} onClick={props.closeHandler}>Schließen</Button>
                            <Button onClick={onSave}>Speichern*</Button>
                        </div>
                    </DialogActions>
                </Dialog>
				<Snackbar
					open={showSnackbar}
					message={snackbarMessage}
					sx={{ zIndex: 9999999 }}
					action={(
						<React.Fragment>
							{isLoading ? (
								<CircularProgress size={20} />
							) : (
								<IconButton
									size="small"
									aria-label="close"
									color="inherit"
									onClick={() => {setShowSnackbar(false)}}
								>
									<CloseIcon fontSize="small" />
								</IconButton>
							)}
						</React.Fragment>
					)}
				/>
                <Snackbar
                    open={showErrorSnackbar}
                    message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
                    sx={{ zIndex: 9999999 }}
                    autoHideDuration={8000}
                    onClose={() => {setShowErrorSnackbar(false)}}
                />
            </>
        )
	)
}

export default PromotionsEdit