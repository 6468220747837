import React, { useState, useEffect } from "react";

import Box from '@mui/system/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableRow from '@mui/material/TableRow';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import TablePagination from '@mui/material/TablePagination';
import Loader from '../../components/Loader';

import EnhancedTableHead from "../../components/EnhancedTable/EnhancedTableHead";

function createData(id, name, categoryName, sku, price, countPrices, active) {
	return { id, name, categoryName, sku, price, countPrices, active };
}

const headCells = [
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: 'Bezeichnung',
	},
	{
		id: 'categoryName',
		numeric: false,
		disablePadding: false,
		label: 'Kategorie',
	},
	{
		id: 'countPrices',
		numeric: true,
		disablePadding: false,
		label: 'Preisvarianten',
	},
	{
		id: 'price',
		numeric: true,
		disablePadding: false,
		label: 'Preis',
	},
	{
		id: "status",
		numeric: true,
		disablePadding: false,
		label: "Freigeschalten",
	}
];

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
	  const order = comparator(a[0], b[0]);
	  if (order !== 0) {
		return order;
	  }
	  return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}
  
function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function ProductsIndex(props) {

	const [isLoading, setIsLoading] = useState(false);
	const [reloadKey, setReloadKey] = useState(0);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [tableRows, setTableRows] = useState([]);
	const [originalRows, setOriginalRows] = useState([]);
	const [order, setOrder] = React.useState('desc');
  	const [orderBy, setOrderBy] = React.useState('id');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(150);
	let navigate = useHistory();

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {

		setIsLoading(true);

		let userData = localStorage.getItem("data");
		if (userData) {
			userData = JSON.parse(userData);
		}

		let fetchUrl = "";

		if (userData.role_name === "admin") {
			if (props.restaurantId === 0) {
				fetchUrl = `${process.env.REACT_APP_API_URL}/api/backend/restaurant/products`
			} else {
				fetchUrl = `${process.env.REACT_APP_API_URL}/api/backend/restaurant/products?restaurant=` + props.restaurantId
			}
		} else {
			fetchUrl = `${process.env.REACT_APP_API_URL}/api/backend/restaurant/products`
		}

		axios.get(fetchUrl)
		.then((res) => {
			
			if (res.status === 200 && res.data) {
				// Everything OK
				let temporaryTableRows = [];
				res.data.forEach((product) => {
					let countSizes = product.sizes.length;
					temporaryTableRows.push(createData(
						product.id,
						product.product_name,
						product.category.category_name,
						product.id,
						`${(product.has_multiple_sizes ? `ab ` : '')}${product.lowest_price_formatted} €`,
						countSizes,
						product.product_status === 1 ? true : false
					));
				})

				// Sort by product id
				temporaryTableRows.sort((a, b) => {
					return a.id - b.id;
				})

				setOriginalRows(temporaryTableRows);
				setTableRows(temporaryTableRows);
				setIsLoading(false);
			} else {
				// Error
				handleError(res)
				setIsLoading(false);
			}
		})
		.catch((err) => {handleError(err)})
		
	}, [props.reloadKey, props.restaurantId, reloadKey])

	const handleError = (error) => {
		
		setShowErrorSnackbar(true)
	}

	useEffect(() => {
		if (props.toolbarSearchQuery !== '') {

			// This is the function that filters the table rows
			

			// Search in the rows for name, category name, sku and price
			let filteredRows = originalRows.filter((row) => {
				return row.name.toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) ||
					row.categoryName?.toString().toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) || 
					row.sku?.toString().toLowerCase().includes(props.toolbarSearchQuery.toLowerCase()) || 
					row.id?.toString().includes(props.toolbarSearchQuery.toLowerCase()) || 
					row.price?.toString().toLowerCase().includes(props.toolbarSearchQuery.toLowerCase())
			})
			
			setTableRows(filteredRows);
		} else {
			setTableRows(originalRows);
		}
	}, [props.toolbarSearchQuery])

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	return (
		<Box padding={2}>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<TableContainer className="PageMenu-Table">
						<Table size="small" aria-label="simple table">
							<EnhancedTableHead
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								rowCount={tableRows.length}
								headCells={headCells}
							/>
							<TableBody>
								{stableSort(tableRows, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={() => {navigate.push('/products/' + row.id)}}
											key={row.id}
										>
											<TableCell>{row.name}</TableCell>
											<TableCell>{row.categoryName}</TableCell>
											<TableCell style={{width: '3rem'}} align="right">{row.countPrices}</TableCell>
											<TableCell align="right">{row.price}</TableCell>
											<TableCell align="right" onClick={(event) => {
												event.stopPropagation();
											}}>
												<FormControlLabel label="Freigeschalten" control={<Switch size="small" checked={row.active} value={row.active} onChange={(event) => {
													event.preventDefault();
													
													setIsLoading(true);
													axios.post(`${process.env.REACT_APP_API_URL}/api/backend/restaurant/products/status/${row.id}`)
														.then((res) => {
															setReloadKey(reloadKey + 1);
														})
														.catch((err) => {
															handleError(err);
														});
												}} />} />
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[20, 40, 60, 150]}
						component="div"
						count={tableRows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage="Zeilen pro Seite"
					/>
				</>
			)}

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
		</Box>
	)
}

export default ProductsIndex;