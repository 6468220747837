import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NumberFormat from 'react-number-format';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SaveIcon from '@mui/icons-material/Save';

import './style.scss';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
			decimalSeparator=","
            isNumericString
            suffix=" EUR"
			allowNegative={false}
			decimalScale={2}
        />
    );
});

export default function StepPrices(props) {

	const [isLoading, setIsLoading] = useState(false);

	const submitForm = () => {

		props.isLoading(true);
		setIsLoading(true);
	
		if (props.formProductHasMultipleSizes) {
			// Remove all empty prices
			props.formProductSizes.forEach((input, index) => {
				if (input.label.trim() === '') {
					removeFields(index);
				}
			});
	
			// Check if there are empty inputs
			let hasEmptyInputs = false;
			// Remove all empty prices
			props.formProductSizes.forEach((input, index) => {
				
				if (input.label.toString().trim() === '') {hasEmptyInputs = true;}
				if (input.price.toString().trim() === '') {hasEmptyInputs = true;}
			});
	
			if (props.formProductSizes.length === 0 || hasEmptyInputs) {
				setIsLoading(false);
				props.isLoading(false);
				return;
			}
		}
		
		
		props.isLoading(false);
		
		props.handleSubmit();
	}

	const addField = () => {
		let newfield = { label: '', price: '' }
		props.setFormProductSizes([...props.formProductSizes, newfield])
	}

	const removeFields = (index) => {
		let data = [...props.formProductSizes];
		data.splice(index, 1)
		if (data.length === 0) {
			data = [{ label: '', price: '' }]
		}
		props.setFormProductSizes(data)
	}

	useEffect(() => {
		let pricesArray = [];
		props.formProductSizes.forEach((price) => {
			pricesArray.push({label: price.product_size_shortname, price: price.product_price})
		})
		props.setFormProductSizes(pricesArray);
	}, [])

	return (
		<Box sx={{ width: '100%' }}>
			<div style={{padding: '30px 0'}}>
				<Stack spacing={2}>

					<FormControlLabel control={<Switch checked={props.formProductHasMultipleSizes} onChange={(e) => {props.setFormProductHasMultipleSizes(e.target.checked)}} />} label="Das Produkt hat mehrere Preise &amp; Größen" />

					{!props.formProductHasMultipleSizes ? (
						<TextField
							size="small"
							label="Bruttopreis"
							value={props.formProductSinglePrice}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(e) => props.setFormProductSinglePrice(e.target.value)}
						/>
						) : (
						<>
							{
								props.formProductSizes.map((input, index) => (
									<div key={index} style={{display: 'flex',justifyContent: 'space-between'}}>
										<div style={{width:'50%',paddingRight:'5px'}}>
											<TextField
												name="label"
												fullWidth
												size="small"
												label="Bezeichnung"
												value={input.label}
												onChange={(event) => {
													let data = [...props.formProductSizes];
													data[index]["label"] = event.target.value;
													props.setFormProductSizes(data);
												}}
											/>
										</div>
										<div style={{width:'50%',paddingLeft:'5px',display: 'flex',flexDirection: 'row'}}>
											<TextField
												name="price"
												fullWidth
												size="small"
												label="Bruttopreis"
												value={input.price}
												InputProps={{
													inputComponent: NumberFormatCustom,
												}}
												onChange={(event) => {
													let data = [...props.formProductSizes];
													data[index]["price"] = event.target.value;
													props.setFormProductSizes(data);
												}}
											/>
											<Tooltip title="Zeile löschen">
												<IconButton onClick={() => removeFields(index)} style={{marginLeft: '10px'}} size="small" aria-label="delete">
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</div>
									</div>
								))
							}
						</>
					)}

					{props.formProductHasMultipleSizes && (
						<div>
							<Tooltip title="Zeile hinzufügen">
								<IconButton onClick={addField} aria-label="add">
									<AddIcon />
								</IconButton>
							</Tooltip>
						</div>
					)}

					<FormControl fullWidth>
						<InputLabel id="tax-value-label">Steuersatz</InputLabel>
						<Select
							size="small"
							labelId="tax-value-label"
							id="tax-value-select"
							value={props.formProductTaxValue}
							label="Steuersatz"
							onChange={(e) => {
								props.setFormProductTaxValue(e.target.value)
							}}
						>
							<MenuItem value={0}>00,00 % — Steuerfrei</MenuItem>
							<MenuItem value={7}>07,00 % — Ermäßigter Steuersatz</MenuItem>
							<MenuItem value={19}>19,00 % — Regulärer Steuersatz</MenuItem>
						</Select>
					</FormControl>
				</Stack>
			</div>

			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between' }}>
				<LoadingButton startIcon={<ArrowBackIcon />} loading={isLoading} onClick={props.handleBack}>Zurück</LoadingButton>
				<LoadingButton endIcon={<SaveIcon />} variant="contained" onClick={submitForm} loading={isLoading}>Speichern</LoadingButton>
			</Box>
		</Box>
	);
}