import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';

import './style.scss';

export default function StepOpeningHours(props) {

	const [isLoading, setIsLoading] = useState(false);
	
	const [mondayIsOpen, setMondayIsOpen] = useState(true);
	const [mondayOpenTime1, setMondayOpenTime1] = useState("10:00");
	const [mondayCloseTime1, setMondayCloseTime1] = useState("12:00");
	const [mondayOpenTime2, setMondayOpenTime2] = useState("14:00");
	const [mondayCloseTime2, setMondayCloseTime2] = useState("22:00");
	
	const [tuesdayIsOpen, setTuesdayIsOpen] = useState(true);
	const [tuesdayOpenTime1, setTuesdayOpenTime1] = useState("10:00");
	const [tuesdayCloseTime1, setTuesdayCloseTime1] = useState("12:00");
	const [tuesdayOpenTime2, setTuesdayOpenTime2] = useState("14:00");
	const [tuesdayCloseTime2, setTuesdayCloseTime2] = useState("22:00");
	
	const [wednesdayIsOpen, setWednesdayIsOpen] = useState(true);
	const [wednesdayOpenTime1, setWednesdayOpenTime1] = useState("10:00");
	const [wednesdayCloseTime1, setWednesdayCloseTime1] = useState("12:00");
	const [wednesdayOpenTime2, setWednesdayOpenTime2] = useState("14:00");
	const [wednesdayCloseTime2, setWednesdayCloseTime2] = useState("22:00");
	
	const [thursdayIsOpen, setThursdayIsOpen] = useState(true);
	const [thursdayOpenTime1, setThursdayOpenTime1] = useState("10:00");
	const [thursdayCloseTime1, setThursdayCloseTime1] = useState("12:00");
	const [thursdayOpenTime2, setThursdayOpenTime2] = useState("14:00");
	const [thursdayCloseTime2, setThursdayCloseTime2] = useState("22:00");
	
	const [fridayIsOpen, setFridayIsOpen] = useState(true);
	const [fridayOpenTime1, setFridayOpenTime1] = useState("10:00");
	const [fridayCloseTime1, setFridayCloseTime1] = useState("12:00");
	const [fridayOpenTime2, setFridayOpenTime2] = useState("14:00");
	const [fridayCloseTime2, setFridayCloseTime2] = useState("22:00");
	
	const [saturdayIsOpen, setSaturdayIsOpen] = useState(true);
	const [saturdayOpenTime1, setSaturdayOpenTime1] = useState("10:00");
	const [saturdayCloseTime1, setSaturdayCloseTime1] = useState("12:00");
	const [saturdayOpenTime2, setSaturdayOpenTime2] = useState("14:00");
	const [saturdayCloseTime2, setSaturdayCloseTime2] = useState("22:00");
	
	const [sundayIsOpen, setSundayIsOpen] = useState(true);
	const [sundayOpenTime1, setSundayOpenTime1] = useState("10:00");
	const [sundayCloseTime1, setSundayCloseTime1] = useState("12:00");
	const [sundayOpenTime2, setSundayOpenTime2] = useState("14:00");
	const [sundayCloseTime2, setSundayCloseTime2] = useState("22:00");

	useEffect(() => {
		// Foreach through props.formRestaurantOpeningHours and set the state

		

		let mondayOpeningHours = [];
		let tuesdayOpeningHours = [];
		let wednesdayOpeningHours = [];
		let thursdayOpeningHours = [];
		let fridayOpeningHours = [];
		let saturdayOpeningHours = [];
		let sundayOpeningHours = [];

		props.formRestaurantOpeningHours.forEach(openingHour => {
			let day = "";
			if (openingHour.day === "1") {day = "monday"}
			if (openingHour.day === "2") {day = "tuesday"}
			if (openingHour.day === "3") {day = "wednesday"}
			if (openingHour.day === "4") {day = "thursday"}
			if (openingHour.day === "5") {day = "friday"}
			if (openingHour.day === "6") {day = "saturday"}
			if (openingHour.day === "7") {day = "sunday"}

			// Open/Closed Status
			if (openingHour.status === "closed") {
				if (day === "monday") {setMondayIsOpen(false)}
				if (day === "tuesday") {setTuesdayIsOpen(false)}
				if (day === "wednesday") {setWednesdayIsOpen(false)}
				if (day === "thursday") {setThursdayIsOpen(false)}
				if (day === "friday") {setFridayIsOpen(false)}
				if (day === "saturday") {setSaturdayIsOpen(false)}
				if (day === "sunday") {setSundayIsOpen(false)}
			} else {
				if (day === "monday") {setMondayIsOpen(true)}
				if (day === "tuesday") {setTuesdayIsOpen(true)}
				if (day === "wednesday") {setWednesdayIsOpen(true)}
				if (day === "thursday") {setThursdayIsOpen(true)}
				if (day === "friday") {setFridayIsOpen(true)}
				if (day === "saturday") {setSaturdayIsOpen(true)}
				if (day === "sunday") {setSundayIsOpen(true)}
			}

			// Open/Closed Time (maybe with multiple opening hours)
			if (day === "monday") {
				// Push the opening hour to the array
				mondayOpeningHours.push(openingHour);
			}
			if (day === "tuesday") {
				// Push the opening hour to the array
				tuesdayOpeningHours.push(openingHour);
			}
			if (day === "wednesday") {
				// Push the opening hour to the array
				wednesdayOpeningHours.push(openingHour);
			}
			if (day === "thursday") {
				// Push the opening hour to the array
				thursdayOpeningHours.push(openingHour);
			}
			if (day === "friday") {
				// Push the opening hour to the array
				fridayOpeningHours.push(openingHour);
			}
			if (day === "saturday") {
				// Push the opening hour to the array
				saturdayOpeningHours.push(openingHour);
			}
			if (day === "sunday") {
				// Push the opening hour to the array
				sundayOpeningHours.push(openingHour);
			}

		});

		// Now loop through the array and set the state
		// Check if the array has more than one opening hour
		if (mondayOpeningHours.length > 1) {
			// Set the state
			setMondayOpenTime1(mondayOpeningHours[0].open_time);
			setMondayCloseTime1(mondayOpeningHours[0].close_time);
			setMondayOpenTime2(mondayOpeningHours[1].open_time);
			setMondayCloseTime2(mondayOpeningHours[1].close_time);
		} else {
			if (mondayOpeningHours.length == 0) {
				setMondayOpenTime1("");	
				setMondayCloseTime1("");	
				setMondayOpenTime2("");	
				setMondayCloseTime2("");	
			} else {
				// Set the state
				setMondayOpenTime1(mondayOpeningHours[0].open_time);
				setMondayCloseTime1(mondayOpeningHours[0].close_time);
				setMondayOpenTime2("");
				setMondayCloseTime2("");
			}
		}

		if (tuesdayOpeningHours.length > 1) {
			// Set the state
			setTuesdayOpenTime1(tuesdayOpeningHours[0].open_time);
			setTuesdayCloseTime1(tuesdayOpeningHours[0].close_time);
			setTuesdayOpenTime2(tuesdayOpeningHours[1].open_time);
			setTuesdayCloseTime2(tuesdayOpeningHours[1].close_time);
		} else {
			if (tuesdayOpeningHours.length == 0) {
				setTuesdayOpenTime1("");	
				setTuesdayCloseTime1("");	
				setTuesdayOpenTime2("");	
				setTuesdayCloseTime2("");	
			} else {
				// Set the state
				setTuesdayOpenTime1(tuesdayOpeningHours[0].open_time);
				setTuesdayCloseTime1(tuesdayOpeningHours[0].close_time);
				setTuesdayOpenTime2("");
				setTuesdayCloseTime2("");
			}
		}

		if (wednesdayOpeningHours.length > 1) {
			// Set the state
			setWednesdayOpenTime1(wednesdayOpeningHours[0].open_time);
			setWednesdayCloseTime1(wednesdayOpeningHours[0].close_time);
			setWednesdayOpenTime2(wednesdayOpeningHours[1].open_time);
			setWednesdayCloseTime2(wednesdayOpeningHours[1].close_time);
		} else {
			if (wednesdayOpeningHours.length == 0) {
				setWednesdayOpenTime1("");	
				setWednesdayCloseTime1("");	
				setWednesdayOpenTime2("");	
				setWednesdayCloseTime2("");	
			} else {
				// Set the state
				setWednesdayOpenTime1(wednesdayOpeningHours[0].open_time);
				setWednesdayCloseTime1(wednesdayOpeningHours[0].close_time);
				setWednesdayOpenTime2("");
				setWednesdayCloseTime2("");
			}
		}

		if (thursdayOpeningHours.length > 1) {
			// Set the state
			setThursdayOpenTime1(thursdayOpeningHours[0].open_time);
			setThursdayCloseTime1(thursdayOpeningHours[0].close_time);
			setThursdayOpenTime2(thursdayOpeningHours[1].open_time);
			setThursdayCloseTime2(thursdayOpeningHours[1].close_time);
		} else {
			if (thursdayOpeningHours.length == 0) {
				setThursdayOpenTime1("");	
				setThursdayCloseTime1("");	
				setThursdayOpenTime2("");	
				setThursdayCloseTime2("");	
			} else {
				// Set the state
				setThursdayOpenTime1(thursdayOpeningHours[0].open_time);
				setThursdayCloseTime1(thursdayOpeningHours[0].close_time);
				setThursdayOpenTime2("");
				setThursdayCloseTime2("");
			}
		}

		if (fridayOpeningHours.length > 1) {
			// Set the state
			setFridayOpenTime1(fridayOpeningHours[0].open_time);
			setFridayCloseTime1(fridayOpeningHours[0].close_time);
			setFridayOpenTime2(fridayOpeningHours[1].open_time);
			setFridayCloseTime2(fridayOpeningHours[1].close_time);
		} else {
			if (fridayOpeningHours.length == 0) {
				setFridayOpenTime1("");	
				setFridayCloseTime1("");	
				setFridayOpenTime2("");	
				setFridayCloseTime2("");	
			} else {
				// Set the state
				setFridayOpenTime1(fridayOpeningHours[0].open_time);
				setFridayCloseTime1(fridayOpeningHours[0].close_time);
				setFridayOpenTime2("");
				setFridayCloseTime2("");
			}
		}
		if (saturdayOpeningHours.length > 1) {
			// Set the state
			setSaturdayOpenTime1(saturdayOpeningHours[0].open_time);
			setSaturdayCloseTime1(saturdayOpeningHours[0].close_time);
			setSaturdayOpenTime2(saturdayOpeningHours[1].open_time);
			setSaturdayCloseTime2(saturdayOpeningHours[1].close_time);
		} else {
			if (saturdayOpeningHours.length == 0) {
				setSaturdayOpenTime1("");	
				setSaturdayCloseTime1("");	
				setSaturdayOpenTime2("");	
				setSaturdayCloseTime2("");	
			} else {
				// Set the state
				setSaturdayOpenTime1(saturdayOpeningHours[0].open_time);
				setSaturdayCloseTime1(saturdayOpeningHours[0].close_time);
				setSaturdayOpenTime2("");
				setSaturdayCloseTime2("");
			}
		}

		if (sundayOpeningHours.length > 1) {
			// Set the state
			setSundayOpenTime1(sundayOpeningHours[0].open_time);
			setSundayCloseTime1(sundayOpeningHours[0].close_time);
			setSundayOpenTime2(sundayOpeningHours[1].open_time);
			setSundayCloseTime2(sundayOpeningHours[1].close_time);
		} else {
			if (sundayOpeningHours.length == 0) {
				setSundayOpenTime1("");	
				setSundayCloseTime1("");	
				setSundayOpenTime2("");	
				setSundayCloseTime2("");	
			} else {
				// Set the state
				setSundayOpenTime1(sundayOpeningHours[0].open_time);
				setSundayCloseTime1(sundayOpeningHours[0].close_time);
				setSundayOpenTime2("");
				setSundayCloseTime2("");
			}
		}


		
		
		
		
		
		
		

	}, [])

	const submitForm = () => {

		const openingHours = {
			'monday': {
				'IsOpen': mondayIsOpen,
				'OpenTime1': mondayOpenTime1,
				'CloseTime1': mondayCloseTime1,
				'OpenTime2': mondayOpenTime2,
				'CloseTime2': mondayCloseTime2
			},
			'tuesday': {
				'IsOpen': tuesdayIsOpen,
				'OpenTime1': tuesdayOpenTime1,
				'CloseTime1': tuesdayCloseTime1,
				'OpenTime2': tuesdayOpenTime2,
				'CloseTime2': tuesdayCloseTime2
			},
			'wednesday': {
				'IsOpen': wednesdayIsOpen,
				'OpenTime1': wednesdayOpenTime1,
				'CloseTime1': wednesdayCloseTime1,
				'OpenTime2': wednesdayOpenTime2,
				'CloseTime2': wednesdayCloseTime2
			},
			'thursday': {
				'IsOpen': thursdayIsOpen,
				'OpenTime1': thursdayOpenTime1,
				'CloseTime1': thursdayCloseTime1,
				'OpenTime2': thursdayOpenTime2,
				'CloseTime2': thursdayCloseTime2
			},
			'friday': {
				'IsOpen': fridayIsOpen,
				'OpenTime1': fridayOpenTime1,
				'CloseTime1': fridayCloseTime1,
				'OpenTime2': fridayOpenTime2,
				'CloseTime2': fridayCloseTime2
			},
			'saturday': {
				'IsOpen': saturdayIsOpen,
				'OpenTime1': saturdayOpenTime1,
				'CloseTime1': saturdayCloseTime1,
				'OpenTime2': saturdayOpenTime2,
				'CloseTime2': saturdayCloseTime2
			},
			'sunday': {
				'IsOpen': sundayIsOpen,
				'OpenTime1': sundayOpenTime1,
				'CloseTime1': sundayCloseTime1,
				'OpenTime2': sundayOpenTime2,
				'CloseTime2': sundayCloseTime2
			}
		}

		props.handleSubmit(openingHours);

	}

	return (
		<Box sx={{ width: '100%' }}>
			<div style={{padding: '30px 0'}}>
				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={mondayIsOpen} onChange={(e) => {setMondayIsOpen(e.target.checked)}} color="primary" />}
								label="Montag"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!mondayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={mondayOpenTime1}
								name="mondayOpenTime1"
								onChange={(e) => {setMondayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!mondayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={mondayCloseTime1}
								name="mondayCloseTime1"
								onChange={(e) => {setMondayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!mondayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={mondayOpenTime2}
								name="mondayOpenTime2"
								onChange={(e) => {setMondayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!mondayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={mondayCloseTime2}
								name="mondayCloseTime2"
								onChange={(e) => {setMondayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>

				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={tuesdayIsOpen} onChange={(e) => {setTuesdayIsOpen(e.target.checked)}} color="primary" />}
								label="Dienstag"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!tuesdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={tuesdayOpenTime1}
								name="tuesdayOpenTime1"
								onChange={(e) => {setTuesdayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!tuesdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={tuesdayCloseTime1}
								name="tuesdayCloseTime1"
								onChange={(e) => {setTuesdayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!tuesdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={tuesdayOpenTime2}
								name="tuesdayOpenTime2"
								onChange={(e) => {setTuesdayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!tuesdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={tuesdayCloseTime2}
								name="tuesdayCloseTime2"
								onChange={(e) => {setTuesdayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>

				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={wednesdayIsOpen} onChange={(e) => {setWednesdayIsOpen(e.target.checked)}} color="primary" />}
								label="Mittwoch"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!wednesdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={wednesdayOpenTime1}
								name="wednesdayOpenTime1"
								onChange={(e) => {setWednesdayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!wednesdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={wednesdayCloseTime1}
								name="wednesdayCloseTime1"
								onChange={(e) => {setWednesdayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!wednesdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={wednesdayOpenTime2}
								name="wednesdayOpenTime2"
								onChange={(e) => {setWednesdayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!wednesdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={wednesdayCloseTime2}
								name="wednesdayCloseTime2"
								onChange={(e) => {setWednesdayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>

				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={thursdayIsOpen} onChange={(e) => {setThursdayIsOpen(e.target.checked)}} color="primary" />}
								label="Donnerstag"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!thursdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={thursdayOpenTime1}
								name="thursdayOpenTime1"
								onChange={(e) => {setThursdayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!thursdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={thursdayCloseTime1}
								name="thursdayCloseTime1"
								onChange={(e) => {setThursdayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!thursdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={thursdayOpenTime2}
								name="thursdayOpenTime2"
								onChange={(e) => {setThursdayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!thursdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={thursdayCloseTime2}
								name="thursdayCloseTime2"
								onChange={(e) => {setThursdayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>

				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={fridayIsOpen} onChange={(e) => {setFridayIsOpen(e.target.checked)}} color="primary" />}
								label="Freitag"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!fridayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={fridayOpenTime1}
								name="fridayOpenTime1"
								onChange={(e) => {setFridayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!fridayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={fridayCloseTime1}
								name="fridayCloseTime1"
								onChange={(e) => {setFridayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!fridayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={fridayOpenTime2}
								name="fridayOpenTime2"
								onChange={(e) => {setFridayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!fridayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={fridayCloseTime2}
								name="fridayCloseTime2"
								onChange={(e) => {setFridayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>

				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={saturdayIsOpen} onChange={(e) => {setSaturdayIsOpen(e.target.checked)}} color="primary" />}
								label="Samstag"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!saturdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={saturdayOpenTime1}
								name="saturdayOpenTime1"
								onChange={(e) => {setSaturdayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!saturdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={saturdayCloseTime1}
								name="saturdayCloseTime1"
								onChange={(e) => {setSaturdayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!saturdayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={saturdayOpenTime2}
								name="saturdayOpenTime2"
								onChange={(e) => {setSaturdayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!saturdayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={saturdayCloseTime2}
								name="saturdayCloseTime2"
								onChange={(e) => {setSaturdayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>
				
				<div>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}>
							<FormControlLabel
								value="end"
								control={<Switch checked={sundayIsOpen} onChange={(e) => {setSundayIsOpen(e.target.checked)}} color="primary" />}
								label="Sonntag"
								labelPlacement="end"
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!sundayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={sundayOpenTime1}
								name="sundayOpenTime1"
								onChange={(e) => {setSundayOpenTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!sundayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={sundayCloseTime1}
								name="sundayCloseTime1"
								onChange={(e) => {setSundayCloseTime1(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid container style={{marginBottom:'20px'}} spacing={2}>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!sundayIsOpen}
								fullWidth
								size="small"
								label="Öffnung"
								value={sundayOpenTime2}
								name="sundayOpenTime2"
								onChange={(e) => {setSundayOpenTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								disabled={!sundayIsOpen}
								fullWidth
								size="small"
								label="Schließung"
								value={sundayCloseTime2}
								name="sundayCloseTime2"
								onChange={(e) => {setSundayCloseTime2(e.target.value)}}
								type="time"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</div>
			</div>

			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between' }}>
				<LoadingButton startIcon={<ArrowBackIcon />} loading={isLoading} onClick={props.handleBack}>Zurück</LoadingButton>
				<LoadingButton endIcon={<SaveIcon />} variant="contained" onClick={submitForm} loading={isLoading}>Speichern</LoadingButton>
			</Box>
		</Box>
	);
}