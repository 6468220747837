import React, {useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import Box from '@mui/material/Box';
import { useParams, useLocation } from "react-router-dom";

import axios from 'axios';

import './style.scss';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PK;

export default function PageCourierPosition() {

	const location = useLocation();
	let params = useParams();
	const [authToken, setAuthToken] = useState("");
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [lng, setLng] = useState(0);
	const [lat, setLat] = useState(0);
	const [zoom, setZoom] = useState(1);
	
	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token) {
			setAuthToken(token);
		}

		if (map.current) return; // initialize map only once
		let updateSource = null;

		document.title = 'Kuriere — ' + process.env.REACT_APP_APPLICATION_NAME;

		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/streets-v12',
			zoom: zoom,
			center: [lng, lat]
		});

		map.current.on('load', async () => {

			map.current.loadImage('/SystemAssets/map-marker.png', async (error, image) => {
					if (error) throw error;

					map.current.addImage('mapMarker', image);

					const geojson = await getLocation();
		
					map.current.addSource('courier', {
						type: 'geojson',
						data: geojson
					});

					map.current.addLayer({
						'id': 'courier',
						'type': 'symbol',
						'source': 'courier',
						'layout': {
							'icon-image': 'mapMarker'
						}
					});

					getLocation(updateSource);
		
					updateSource = setInterval(async () => {
						if (location.pathname.includes("/couriers/")) {
							getLocation(updateSource, true);
						}
					}, 6000);
		
					function getLocation(updateSource, interval = false) {
						try {

							axios.get(process.env.REACT_APP_API_URL + '/api/backend/couriers/courier-location/' + params.courierId)
							.then((response) => {
								let geojson = {
									type: "FeatureCollection",
									features: [
										{
											type: "Feature",
											geometry: {
												type: "Point",
												coordinates: [response.data.lng, response.data.lat],
											},
										}
									],
								};
								
								map.current.getSource('courier').setData(geojson);
								
								map.current.flyTo({
									center: [response.data.lng, response.data.lat],
									zoom: 18,
									speed: (interval ? 0.1 : 3),
									curve: 1,
								});
							})
							.catch((error) => {
								
								setShowErrorSnackbar(true);
							});
						} catch (err) {
							// If the updateSource interval is defined, clear the interval to stop updating the source.
							if (updateSource) clearInterval(updateSource);
							throw new Error(err);
						}
					}			
				}
			);


		})

		// Clean up on unmount
		return () => {
			if (map.current) map.current.remove();
			// If the updateSource interval is defined, clear the interval to stop updating the source.
			if (updateSource) clearInterval(updateSource);
		}
	}, [])

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				
				<div ref={mapContainer} className="map-container" />

			</Paper>

			<Box sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden', marginTop:'15px' }}>
				<small><i>Powered by DeliverOne Couriers</i></small>
			</Box>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
		</>
	);
}