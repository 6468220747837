import * as React from 'react';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import PageAuthLogin from './pages/PageAuthLogin';
import PageHome from './pages/PageHome';
import PageMenu from './pages/PageMenu';
import PageAllergens from './pages/PageAllergens';
import PageCustomers from './pages/PageCustomers';
import PageCustomerOrders from './pages/PageCustomerOrders';

import PageOrderDetails from './pages/PageOrderDetails';

import PageProducts from './pages/PageProducts';
import PageProductDetails from './pages/PageProductDetails';
import PageProductNew from './pages/PageProductNew';

import PageRestaurants from './pages/PageRestaurants';
import PageRestaurantEdit from './pages/PageRestaurantEdit';
import PageRestaurantNew from './pages/PageRestaurantNew';

import PageIngredients from './pages/PageIngredients';
import PageIngredientDetails from './pages/PageIngredientDetails';

import PagePromotions from './pages/PagePromotions';

import PageCostAndStats from './pages/PageCostAndStats';
import PageFinanceCenter from './pages/PageFinanceCenter';
import PageSettings from './pages/PageSettings';
import PageSettingsSingleHolidays from './pages/PageSettings/SingleHolidays';
import PageInbox from './pages/PageInbox';
import PageInboxCreate from './pages/PageInboxCreate';
import PageInboxMessage from './pages/PageInboxMessage';
import PageCouriers from './pages/PageCouriers';
import PageCourierPosition from './pages/PageCourierPosition';
import PageTerminals from './pages/PageTerminals';
import PageTerminalPayments from './pages/PageTerminalPayments';
import PageDeliverOneAssist from './pages/PageDeliverOneAssist';

import PageAdministrateCacheManagement from './pages/PageAdministrate/CacheManagement';
import PageAdministrateCoupons from './pages/PageAdministrate/Coupons';
import PageAdministrateDonations from './pages/PageAdministrate/Donations';
import PageAdministrateCurrentJobs from './pages/PageAdministrate/CurrentJobs';
import PageAdministrateFailedJobs from './pages/PageAdministrate/FailedJobs';
import PageAdministrateBankTransactions from './pages/PageAdministrate/BankTransactions';
import PageAdministratePaidCharges from './pages/PageAdministrate/PaidCharges';
import PageAdministratePasswordResets from './pages/PageAdministrate/PasswordResets';
import PageAdministrateRestaurantParameter from './pages/PageAdministrate/RestaurantParameter';
import PageAdministrateDeliveryAreas from './pages/PageAdministrate/DeliveryAreas';
import PageAdministrateDeliverOneRewardsTransactions from './pages/PageAdministrate/DeliverOneRewardsTransactions';
import PageAdministratePushNotifications from './pages/PageAdministrate/PushNotifications';
import PageAdministratePayouts from './pages/PageAdministrate/Payouts';
import PageAdministrateInvoiceManagement from './pages/PageAdministrate/InvoiceManagement';
import PageAdministrateInvoiceManagementHistory from './pages/PageAdministrate/InvoiceManagement/History';
import PageAdministrateInvoiceManagementEditor from './pages/PageAdministrate/InvoiceManagement/Editor';
import PageAdministrateBanking from './pages/PageAdministrate/Banking';
import PageAdministrateBankingAuthorize from './pages/PageAdministrate/Banking/Authorize';
import PageAdministrateBankingAAccounts from './pages/PageAdministrate/Banking/AAccounts';
import PageAdministrateBankingBAccounts from './pages/PageAdministrate/Banking/BAccounts';
import PageAdministrateBankingCAccounts from './pages/PageAdministrate/Banking/CAccounts';
import PageAdministrateBankingPendingTransfers from './pages/PageAdministrate/Banking/PendingTransfers';
import PageAdministrateStripeConnectAccounts from './pages/PageAdministrate/Stripe/ConnectAccounts';
import PageAdministrateStripeConnectAccountCreate from './pages/PageAdministrate/Stripe/ConnectAccountCreate';
import PageAdministrateContractManagement from './pages/PageAdministrate/ContractManagement';
import PageAdministrateContractManagementSetup from './pages/PageAdministrate/ContractManagement/Setup';

import DashboardOutlet from './utils/DashboardOutlet';
import { history } from './utils/history';
import RouteGuard from "./utils/RouteGuard";
import {setAuthToken} from "./utils/setAuthToken";
import {
	Router,
	Switch,
	Route
} from "react-router-dom";  
import axios from 'axios';

import './style.scss';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
});

export default function Paperbase() {

	const [hasLoaded, setHasLoaded] = React.useState(false);

	React.useEffect(() => {

		// If there is a localstorage variable bootup, remove it
		if (localStorage.getItem("bootup")) {
			localStorage.removeItem("bootup");
		}

		const token = localStorage.getItem("token");
		if (token) {
			setAuthToken(token);
			setHasLoaded(true);

			axios.get(`${process.env.REACT_APP_API_URL}/api/me`).then((res) => {
				if (res.data.data) {

					// Check if the user has a role
					if (res.data.data.role_name === 'admin' || res.data.data.role_name === 'restaurant') {

						localStorage.setItem("data", JSON.stringify(res.data.data));

					} else {
						localStorage.clear();
						window.location.href = "/login?logout=true";
					}

				}
			})
			.catch((error) => {
				
			})
		} else {
			setHasLoaded(true);
		}
	})

	return (
		<>
			<Router history={history}>
				{hasLoaded && (
					<Switch>
						<RouteGuard exact path="/" component={() => <DashboardOutlet><PageHome /></DashboardOutlet>} />
						<RouteGuard exact path="/menu" component={() => <DashboardOutlet><PageMenu /></DashboardOutlet>} />
						<RouteGuard exact path="/allergens" component={() => <DashboardOutlet><PageAllergens /></DashboardOutlet>} />
						<RouteGuard exact path="/customers" component={() => <DashboardOutlet><PageCustomers /></DashboardOutlet>} />
						<RouteGuard exact path="/customer-orders/:userId" component={() => <DashboardOutlet><PageCustomerOrders /></DashboardOutlet>} />

						<RouteGuard exact path="/orders/:orderId" component={() => <DashboardOutlet><PageOrderDetails /></DashboardOutlet>} />
						
						<RouteGuard exact path="/products" component={() => <DashboardOutlet><PageProducts /></DashboardOutlet>} />
						<RouteGuard exact path="/products/new" component={() => <DashboardOutlet><PageProductNew /></DashboardOutlet>} />
						<RouteGuard exact path="/products/:productId" component={() => <DashboardOutlet><PageProductDetails /></DashboardOutlet>} />

						<RouteGuard exact path="/ingredients" component={() => <DashboardOutlet><PageIngredients /></DashboardOutlet>} />
						<RouteGuard exact path="/ingredients/:productId" component={() => <DashboardOutlet><PageIngredientDetails /></DashboardOutlet>} />

						<RouteGuard exact path="/restaurants" component={() => <DashboardOutlet><PageRestaurants /></DashboardOutlet>} />
						<RouteGuard exact path="/restaurants/new" component={() => <DashboardOutlet><PageRestaurantNew /></DashboardOutlet>} />
						<RouteGuard exact path="/restaurants/:restaurantId" component={() => <DashboardOutlet><PageRestaurantEdit /></DashboardOutlet>} />
						
						<RouteGuard exact path="/promotions" component={() => <DashboardOutlet><PagePromotions /></DashboardOutlet>} />
						
						<RouteGuard exact path="/cost-and-stats" component={() => <DashboardOutlet><PageCostAndStats /></DashboardOutlet>} />
						<RouteGuard exact path="/finance-center" component={() => <DashboardOutlet><PageFinanceCenter /></DashboardOutlet>} />
						<RouteGuard exact path="/settings" component={() => <DashboardOutlet><PageSettings /></DashboardOutlet>} />
						<RouteGuard exact path="/settings/single-holidays" component={() => <DashboardOutlet><PageSettingsSingleHolidays /></DashboardOutlet>} />
						<RouteGuard exact path="/inbox" component={() => <DashboardOutlet><PageInbox /></DashboardOutlet>} />
						<RouteGuard exact path="/inbox-create" component={() => <DashboardOutlet><PageInboxCreate /></DashboardOutlet>} />
						<RouteGuard exact path="/inbox/:messageId" component={() => <DashboardOutlet><PageInboxMessage /></DashboardOutlet>} />
						<RouteGuard exact path="/couriers" component={() => <DashboardOutlet><PageCouriers /></DashboardOutlet>} />
						<RouteGuard exact path="/couriers/:courierId" component={() => <DashboardOutlet><PageCourierPosition /></DashboardOutlet>} />
						<RouteGuard exact path="/terminals" component={() => <DashboardOutlet><PageTerminals /></DashboardOutlet>} />
						<RouteGuard exact path="/terminals/payments" component={() => <DashboardOutlet><PageTerminalPayments /></DashboardOutlet>} />
						<RouteGuard exact path="/deliverone-assist" component={() => <DashboardOutlet><PageDeliverOneAssist /></DashboardOutlet>} />

						<RouteGuard exact path="/administrate/cache-management" component={() => <DashboardOutlet><PageAdministrateCacheManagement /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/coupons" component={() => <DashboardOutlet><PageAdministrateCoupons /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/donations" component={() => <DashboardOutlet><PageAdministrateDonations /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/current-jobs" component={() => <DashboardOutlet><PageAdministrateCurrentJobs /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/failed-jobs" component={() => <DashboardOutlet><PageAdministrateFailedJobs /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/bank-transactions" component={() => <DashboardOutlet><PageAdministrateBankTransactions /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/paid-charges" component={() => <DashboardOutlet><PageAdministratePaidCharges /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/password-resets" component={() => <DashboardOutlet><PageAdministratePasswordResets /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/restaurant-parameter" component={() => <DashboardOutlet><PageAdministrateRestaurantParameter /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/delivery-areas" component={() => <DashboardOutlet><PageAdministrateDeliveryAreas /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/deliverone-rewards-transactions" component={() => <DashboardOutlet><PageAdministrateDeliverOneRewardsTransactions /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/push-notifications" component={() => <DashboardOutlet><PageAdministratePushNotifications /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/payouts" component={() => <DashboardOutlet><PageAdministratePayouts /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/invoice-management" component={() => <DashboardOutlet><PageAdministrateInvoiceManagement /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/invoice-management/history" component={() => <DashboardOutlet><PageAdministrateInvoiceManagementHistory /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/invoice-management/editor" component={() => <DashboardOutlet><PageAdministrateInvoiceManagementEditor /></DashboardOutlet>} />
						
						<RouteGuard exact path="/administrate/banking-authorize" component={() => <DashboardOutlet><PageAdministrateBankingAuthorize /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/banking" component={() => <DashboardOutlet><PageAdministrateBanking /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/banking/a-accounts" component={() => <DashboardOutlet><PageAdministrateBankingAAccounts /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/banking/b-accounts" component={() => <DashboardOutlet><PageAdministrateBankingBAccounts /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/banking/c-accounts" component={() => <DashboardOutlet><PageAdministrateBankingCAccounts /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/banking/pending-transfers" component={() => <DashboardOutlet><PageAdministrateBankingPendingTransfers /></DashboardOutlet>} />
						
						<RouteGuard exact path="/administrate/stripe-connect-accounts" component={() => <DashboardOutlet><PageAdministrateStripeConnectAccounts /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/stripe-connect-accounts/create" component={() => <DashboardOutlet><PageAdministrateStripeConnectAccountCreate /></DashboardOutlet>} />
						
						<RouteGuard exact path="/administrate/contract-management" component={() => <DashboardOutlet><PageAdministrateContractManagement /></DashboardOutlet>} />
						<RouteGuard exact path="/administrate/contract-management/setup" component={() => <DashboardOutlet><PageAdministrateContractManagementSetup /></DashboardOutlet>} />

						<Route exact path="/login" component={() => <PageAuthLogin />} />
					</Switch>
				)}
			</Router>
		</>
	);
}