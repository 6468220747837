import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/system/Box';
import axios from 'axios';
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Loader from '../../components/Loader';
import RecommendationsIndex from './RecommendationsIndex';

// Assets
import AssistImage from '../../assets/deliverone-assist.png';
import AssistMenuImage from '../../assets/deliverone-assist-menu.png';
import AssistPaymentImage from '../../assets/deliverone-assist-payment.png';
import AssistCustomerServiceImage from '../../assets/deliverone-assist-customer-service.png';
import AssistMarketingImage from '../../assets/deliverone-assist-marketing.png';
import AssistSentimentAnalyticsImage from '../../assets/deliverone-assist-sentiment-analytics.png';
import AssistAdvertisingImage from '../../assets/deliverone-assist-advertising.png';

import './style.scss';

export default function PageDeliverOneAssist() {

	const [isLoading, setIsLoading] = useState(false);
	const [isEnabled, setIsEnabled] = useState(false);
	const [noActions, setNoActions] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showEnableModal, setShowEnableModal] = useState(false);
	const [enableDataPolicyAgreed, setEnableDataPolicyAgreed] = useState(false);
	const [enableTermsOfServiceAgreed, setEnableTermsOfServiceAgreed] = useState(false);
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [recommendations, setRecommendations] = useState([]);

	useEffect(() => {
		document.title = 'DeliverOne Assist (beta) — ' + process.env.REACT_APP_APPLICATION_NAME;

		setIsLoading(true)
		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/deliverone-assist/status`)
			.then(res => {
				if (res.status === 200) {
					// eslint-disable-next-line eqeqeq
					if (res.data.enabled == true) {
						setIsEnabled(true);

						// Get all recommendations
						axios.get(`${process.env.REACT_APP_API_URL}/api/backend/deliverone-assist/recommendations`)
							.then(res => {
								if (res.status === 200) {
									setIsLoading(false);
									setRecommendations(res.data.recommendations)
								} else {
									setIsLoading(false);
									setShowErrorSnackbar(true);
								}
							})
							.catch(err => {
								setIsLoading(false);
								setShowErrorSnackbar(true);
							})

					} else {
						setIsLoading(false);
					}

					if (res.data.no_actions) {
						setIsLoading(false);
						setNoActions(true);
					}

				} else {
					setIsLoading(false);
					setShowErrorSnackbar(true);
				}
			})
			.catch(err => {
				setIsLoading(false);
				setShowErrorSnackbar(true);
			})

	}, [reloadKey]);

	const enableAssist = () => {
		setIsLoading(true)
		axios.post(`${process.env.REACT_APP_API_URL}/api/backend/deliverone-assist/enable`)
			.then(res => {
				if (res.status === 200) {
					setShowEnableModal(false);
					setIsLoading(false);
					setReloadKey(Math.random());
					setSuccessSnackbarText(res.data.message)
					setShowSuccessSnackbar(true);
				} else {
					setIsLoading(false);
					setShowErrorSnackbar(true);
				}
			})
			.catch(err => {
				setIsLoading(false);
				setShowErrorSnackbar(true);
			})
	}

	const approveChange = (id) => {
		setIsLoading(true)
		axios.post(`${process.env.REACT_APP_API_URL}/api/backend/deliverone-assist/approve/${id}`)
			.then(res => {
				if (res.status === 200) {
					setIsLoading(false);
					setReloadKey(Math.random());
					setSuccessSnackbarText(res.data.message)
					setShowSuccessSnackbar(true);
				} else {
					setIsLoading(false);
					setShowErrorSnackbar(true);
				}
			})
			.catch(err => {
				setIsLoading(false);
				setShowErrorSnackbar(true);
			})
	}

	const denyChange = (id) => {
		setIsLoading(true)
		axios.post(`${process.env.REACT_APP_API_URL}/api/backend/deliverone-assist/deny/${id}`)
			.then(res => {
				if (res.status === 200) {
					setIsLoading(false);
					setReloadKey(Math.random());
					setSuccessSnackbarText(res.data.message)
					setShowSuccessSnackbar(true);
				} else {
					setIsLoading(false);
					setShowErrorSnackbar(true);
				}
			})
			.catch(err => {
				setIsLoading(false);
				setShowErrorSnackbar(true);
			})
	}

	return (
		<>
			{isLoading && <Loader />}
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				{isEnabled && (
					<AppBar
						position="static"
						color="default"
						elevation={0}
						sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
					>
						<Toolbar className="PageMenu-AppBar">
							<Grid container spacing={2} style={{flexDirection:'row'}} alignItems="center" justifyContent="flex-end">
								<Grid item>
									<Tooltip title="Aktualisieren">
										<IconButton onClick={() => {setReloadKey(Math.random())}}>
											<RefreshIcon color="inherit" sx={{ display: 'block' }} />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Toolbar>
					</AppBar>
				)}
				<Box padding={2}>
					{!isEnabled ? (
						<center style={{
							display: 'block',
							padding: '35px',
							borderRadius: '15px',
							background: 'whitesmoke',
							margin: '5px',
							border: '1px solid #d7d7d7',
						}}>

							<img src={AssistImage} className="Assist-image" alt="DeliverOne Assist" />

							<h3 className='Assist-subtitle' style={{marginTop:'0'}}>Wir stellen vor:</h3>
							<h1 style={{marginTop:'0'}}>DeliverOne Assist</h1>

							<h3 style={{fontWeight:"normal"}}>
								Die intelligente Lösung für Restaurants, um ihren Umsatz zu steigern und den Kundenservice zu verbessern.
							</h3>
							
							<br />
							
							<LoadingButton loading={isLoading} onClick={() => {
								setShowEnableModal(true)
							}} disabled={noActions} variant="contained" sx={{ mr: 1 }}>
								{!noActions ? (
									"DeliverOne Assist aktivieren"
								) : "DeliverOne Assist arbeitet zur Zeit an deinem Restaurant!"}
							</LoadingButton>

							<br />

							<div className="Assist-hr"></div>

							<br />

							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={2}>
									<Grid item md={12} lg={4}>
										<div className="Assist-feature">
											<div>
												<span className="Assist-feature-title">Speisekarte</span>
												<br />
												<span className="Assist-feature-description">Verführerische Beschreibungen, die den Geschmack Ihrer Gerichte lebendig werden lassen.</span>
											</div>
											<img src={AssistMenuImage} alt="Speisekarte" className="Assist-feature-image" />
										</div>
									</Grid>
									<Grid item md={12} lg={4}>
										<div className="Assist-feature">
											<div>
												<span className="Assist-feature-title">Zahlungsabwicklung</span>
												<br />
												<span className="Assist-feature-description">Intelligente Zahlungsabwicklung für ein bequemes und effizientes Bestellerlebnis.</span>
											</div>
											<img src={AssistPaymentImage} alt="Zahlungsabwicklung" className="Assist-feature-image" />
										</div>
									</Grid>
									<Grid item md={12} lg={4}>
										<div className="Assist-feature">
											<div>
												<span className="Assist-feature-title">Kundenservice</span>
												<br />
												<span className="Assist-feature-description">Persönlicher Kundenservice für eine erstklassige Erfahrung und schnelle Problemlösungen.</span>
											</div>
											<img src={AssistCustomerServiceImage} alt="Kundenservice" className="Assist-feature-image" />
										</div>
									</Grid>
									<Grid item md={12} lg={4}>
										<div className="Assist-feature">
											<div>
												<span className="Assist-feature-title">Produktvorschläge</span>
												<br />
												<span className="Assist-feature-description">Maßgeschneiderte Empfehlungen für unvergessliche Geschmackserlebnisse.</span>
											</div>
											<img src={AssistMarketingImage} alt="Produktvorschläge" className="Assist-feature-image" />
										</div>
									</Grid>
									<Grid item md={12} lg={4}>
										<div className="Assist-feature">
											<div>
												<span className="Assist-feature-title">Sentiment-Analyse</span>
												<br />
												<span className="Assist-feature-description">Echtzeit-Einblicke in Kundenerfahrungen und Meinungen für eine verbesserte Kundenzufriedenheit.</span>
											</div>
											<img src={AssistSentimentAnalyticsImage} alt="Sentiment-Analyse" className="Assist-feature-image" />
										</div>
									</Grid>
									<Grid item md={12} lg={4}>
										<div className="Assist-feature">
											<div>
												<span className="Assist-feature-title">Werbekampagnen</span>
												<br />
												<span className="Assist-feature-description">Optimierte und gezielte Werbekampagnen für maximale Reichweite und Effektivität.</span>
											</div>
											<img src={AssistAdvertisingImage} alt="Werbekampagnen" className="Assist-feature-image" />
										</div>
									</Grid>
								</Grid>
							</Box>

						</center>
					) : (
						<RecommendationsIndex
							reloadKey={reloadKey}
							recommendations={recommendations}
							approveChange={approveChange}
							denyChange={denyChange}
						/>
					)}
				</Box>
			</Paper>

			<Dialog
				open={showEnableModal}
				onClose={() => {
					setShowEnableModal(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					DeliverOne Assist aktivieren
				</DialogTitle>
				<DialogContent className="Assist-modal-content">
					<DialogContentText id="alert-dialog-description" style={{color:"black"}}>
						Aktivieren Sie DeliverOne Assist, um Ihren Umsatz zu steigern und Ihren Kundenservice zu verbessern (kostenlos während der Beta-Phase).
					</DialogContentText>

					<br />

					<FormControlLabel control={<Checkbox checked={enableDataPolicyAgreed} onChange={(event) => {setEnableDataPolicyAgreed(event.target.checked)}} />} label={<>Ich stimme der Datenschutzerklärung von <a href="https://deliverone.de/datenschutz" target="_blank" rel="noreferrer">DeliverOne</a> &amp; von <a href="https://openai.com/policies/privacy-policy" target="_blank" rel="noreferrer">OpenAI</a> zu.</>} />
					<FormControlLabel control={<Checkbox checked={enableTermsOfServiceAgreed} onChange={(event) => {setEnableTermsOfServiceAgreed(event.target.checked)}} />} label={<>Ich stimme den Nutzungsbedingungen von <a href="https://deliverone.de/agb" target="_blank" rel="noreferrer">DeliverOne</a> &amp; von <a href="https://openai.com/policies/terms-of-use" target="_blank" rel="noreferrer">OpenAI</a> zu.</>} />

				</DialogContent>
				<DialogActions>
					<LoadingButton loading={isLoading} onClick={() => {
						setShowEnableModal(false);
					}}>Abbrechen</LoadingButton>
					<LoadingButton
						loading={isLoading}
						onClick={enableAssist}
						autoFocus
						disabled={!(enableDataPolicyAgreed && enableTermsOfServiceAgreed)}
					>
						Aktivieren
					</LoadingButton>
				</DialogActions>
			</Dialog>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>
		</>
	);
}