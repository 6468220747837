import React, {useEffect, useState} from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './style.scss';
import HolidayConfigurationModal from '../../components/HolidayConfigurationModal';
import SingleHolidayConfigurationModal from '../../components/SingleHolidayConfigurationModal';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Loader from '../../components/Loader';
import { useHistory } from 'react-router-dom';

let IBAN = require('iban');

export default function PageSettings() {

	let navigate = useHistory();
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [isLoading, setIsLoading] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);
	const [showHolidayModal, setShowHolidayModal] = useState(false);
	const [showSingleHolidayModal, setShowSingleHolidayModal] = useState(false);
	const [customerRestaurants, setCustomerRestaurants] = useState([]);
	const [restaurantId, setRestaurantId] = useState(0);

	const [restaurantConfiguration, setRestaurantConfiguration] = useState([]);

	const [cfgAcceptCutlery, setCfgAcceptCutlery] = useState(false);
	const [cfgAcceptPriority, setCfgAcceptPriority] = useState(false);
	const [cfgAcceptTip, setCfgAcceptTip] = useState(false);
	const [cfgPickupOnly, setCfgPickupOnly] = useState(false);
	const [cfgBankName, setCfgBankName] = useState("");
	const [cfgBankIban, setCfgBankIban] = useState("");
	const [cfgBankIbanError, setCfgBankIbanError] = useState(false);
	const [cfgBankBic, setCfgBankBic] = useState("");

	const handleHolidayConfigurator = () => {
		setShowHolidayModal(true);
	}

	const handleSingleHolidayConfigurator = () => {
		navigate.push("/settings/single-holidays")
	}

	useEffect(() => {
		setRestaurantConfiguration([]);

		document.title = 'Konfiguration — ' + process.env.REACT_APP_APPLICATION_NAME;
	
		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
		}

		
		if (userData.role_name === "admin") {
			setIsAdmin(true);
			setIsLoading(true)
			axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
				.then((res) => {
					if (res.data) {
						setCustomerRestaurants(res.data);
						setIsLoading(false);
						if (restaurantId !== 0) {
							const API = "api/backend/restaurants/" + restaurantId;
							const url = `${process.env.REACT_APP_API_URL}/${API}`;
						
							setIsLoading(true);
							axios.get(url)
								.then(function (response) {
									if (response.status === 200) {
										let restaurantData = response.data;
				
										
					
										if (!restaurantData.id) {
											// Restaurant not found
											window.location.href = 'location: /?error';
											return;
										}
										setRestaurantConfiguration(restaurantData);
				
										setCfgAcceptCutlery(restaurantData.configuration.accepts_cutlery == "true");
										setCfgAcceptPriority(restaurantData.configuration.accepts_priority == "true");
										setCfgAcceptTip(restaurantData.configuration.accepts_tip == "true");
										setCfgPickupOnly(restaurantData.configuration.pickup_only == "true");
				
										setCfgBankName(restaurantData.configuration.payout_name);
										setCfgBankIban(restaurantData.configuration.payout_iban);
										setCfgBankBic(restaurantData.configuration.payout_bic);
				
										setIsLoading(false);
									} else {
										
									}
								})
								.catch(function (error) {
									
								})
						}
					}
				})
				.catch((err) => {})
		} else {
			setIsLoading(true)
			setRestaurantId(userData.restaurant_access_id);
			const API = "api/backend/restaurants/" + userData.restaurant_access_id;
			const url = `${process.env.REACT_APP_API_URL}/${API}`;
		
			axios.get(url)
				.then(function (response) {
					if (response.status === 200) {
						let restaurantData = response.data;

						
	
						if (!restaurantData.id) {
							// Restaurant not found
							window.location.href = 'location: /?error';
							return;
						}
						setRestaurantConfiguration(restaurantData);

						setCfgAcceptCutlery(restaurantData.configuration.accepts_cutlery == "true");
						setCfgAcceptPriority(restaurantData.configuration.accepts_priority == "true");
						setCfgAcceptTip(restaurantData.configuration.accepts_tip == "true");
						setCfgPickupOnly(restaurantData.configuration.pickup_only == "true");

						setCfgBankName(restaurantData.configuration.payout_name);
						setCfgBankIban(restaurantData.configuration.payout_iban);
						setCfgBankBic(restaurantData.configuration.payout_bic);

						setIsLoading(false);
					} else {
						
					}
				})
				.catch(function (error) {
					
				})
		}
		
	}, [reloadKey, restaurantId])

	const truncateText = (text, length) => {
		if (text.length <= length) {
			return text;
		}
		
		return text.substr(0, length) + '\u2026'
	}

	const handleSubmit = () => {

		// Create Product

		setIsLoading(true);

		let userData = localStorage.getItem("data");

		if (userData) {
			userData = JSON.parse(userData);
		}

		const formData = new FormData();

		formData.append("formCfgAcceptsCutleryEnabled", cfgAcceptCutlery);
		formData.append("formCfgAcceptsTipEnabled", cfgAcceptTip);
		formData.append("formCfgPickupOnlyEnabled", cfgPickupOnly);
		formData.append("formCfgAcceptPriority", cfgAcceptPriority);
		formData.append("formRestaurantPayoutName", cfgBankName);
		formData.append("formRestaurantPayoutIban", cfgBankIban);
		formData.append("formRestaurantPayoutBic", cfgBankBic);

		const API = "api/backend/restaurants/" + restaurantId;
		const url = `${process.env.REACT_APP_API_URL}/${API}`;
	
		axios.post(url, formData)
			.then(function (response) {
				if (response.status === 200) {
					if (response.data.status === 'success') {
						setIsLoading(false)
					} else {
						setIsLoading(false)
					}
				} else {
					setIsLoading(false)
				}
			})
			.catch(function (error) {
				setIsLoading(false)
			})
	}

	return (
		<>
			<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar className="PageMenu-AppBar">
						<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
							<Grid item>
								{isAdmin && (
									<Button onClick={() => {window.open("https://sales-x.deliverone.de", '_blank', 'noopener,noreferrer');}} variant="contained" sx={{ mr: 1 }}>
										SalesX starten
									</Button>
								)}
								<Tooltip title="Aktualisieren">
									<IconButton onClick={() => {setReloadKey(Math.random())}}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>

				<Box padding={2}>
					{isLoading ? (
						<Loader />
					) : (
						<FormGroup>

							{isAdmin && (
								<>
									<FormControl size="small" label="Restaurant auswählen" fullWidth>
										<InputLabel id="user-type-label">Restaurant auswählen</InputLabel>
										<Select
											labelId="user-type-label"
											id="user-type"
											label="Restaurant auswählen"
											value={restaurantId}
											onChange={(event) => {
												setRestaurantId(event.target.value)
											}}				  
										>
											{customerRestaurants.map((restaurant, index) => {
												return <MenuItem value={restaurant.id} key={index}>{restaurant.restaurant_name}&nbsp;<i style={{color:'#91a5b4'}}>— {truncateText((restaurant.restaurant_description != null ? restaurant.restaurant_description : ""), 20)} ({restaurant.restaurant_address_city})</i></MenuItem>
											})}
										</Select>
									</FormControl><br />
								</>
							)}

							{(isAdmin && restaurantId == 0) ? (<></>) : (
								<>
									<Stack spacing={2}>
										<br />
										<TextField
											size="small"
											label="Auszahlung Empfänger-Name"
											helperText="Gebe den Namen des Empfänger der Auszahlung ein."
											value={cfgBankName}
											onChange={(e) => {
												setCfgBankName(e.target.value)
											}}
										/>
										<TextField
											size="small"
											label="Auszahlung Empfänger-IBAN"
											helperText="Gebe die IBAN des Empfänger der Auszahlung ein."
											value={cfgBankIban}
											error={cfgBankIbanError}
											onChange={(e) => {
												setCfgBankIban(e.target.value)
												setCfgBankIbanError(!IBAN.isValid(e.target.value));

												if (IBAN.isValid(e.target.value)) {
													axios.get('https://openiban.com/validate/' + e.target.value + '?getBIC=true')
														.then(res => {
															if (res.data) {
																if (res.data.bankData) {
																	if (res.data.bankData.bic) {
																		setCfgBankBic(res.data.bankData.bic)
																	}
																}
															}
														})
												}
											}}
										/>
										<TextField
											size="small"
											label="Auszahlung Empfänger-BIC"
											helperText="Gebe die BIC des Empfänger der Auszahlung ein."
											value={cfgBankBic}
											onChange={(e) => {
												setCfgBankBic(e.target.value)
											}}
										/>
									</Stack>

									<div style={{
										width: '100%',
										height: '1px',
										background: '#dadada',
										margin: '25px 0'
									}}>
									</div>

									<Tooltip placement='left' title="Bietest du deinen Kund:innen kostenfrei Einwegbesteck an?"><FormControlLabel label="Wir bieten kostenfrei Besteck an." control={<Switch checked={cfgAcceptCutlery} value={cfgAcceptCutlery} onChange={(event) => {setCfgAcceptCutlery(event.target.checked)}} />} /></Tooltip>
								
									<Tooltip placement='left' title="Nimmst du Priority-Bestellungen an? Deine Kund:innen zahlen 5,00 € Extra, damit die Bestellung als erstes abgefertigt wird."><FormControlLabel label="Wir nehmen Priority-Bestellungen an (5,00 € Gebühr für deine Kund:innen)." control={<Switch checked={cfgAcceptPriority} value={cfgAcceptPriority} onChange={(event) => {setCfgAcceptPriority(event.target.checked)}} />} /></Tooltip>
								
									<Tooltip placement='left' title="Nimmst du Trinkgeld von deinen Kund:innen an? Du musst dies an deine Fahrer:innen auszahlen. Bitte achte dabei auf die steuerliche Vorgehensweise!"><FormControlLabel label="Wir nehmen Trinkgeld an." control={<Switch checked={cfgAcceptTip} value={cfgAcceptTip} onChange={(event) => {setCfgAcceptTip(event.target.checked)}} />} /></Tooltip>
								
									<Tooltip placement='left' title="Bist du ein Pickup-Only Restaurant?"><FormControlLabel label="Wir bieten nur Abholung an." control={<Switch checked={cfgPickupOnly} value={cfgPickupOnly} onChange={(event) => {setCfgPickupOnly(event.target.checked)}} />} /></Tooltip>

									<div style={{
										width: '100%',
										height: '1px',
										background: '#dadada',
										margin: '15px 0'
									}}>
									</div>

									<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
										<i><small>Hinweis: DeliverOne übernimmt keine Haftung für jegliche Schäden oder rechtliche-, juristische-, Konsequenzen. Einige oben genannten Punkte können deine Steuerlast beeinflussen!</small></i>
									</Box>

									<div style={{
										width: '100%',
										height: '1px',
										background: '#dadada',
										margin: '15px 0'
									}}>
									</div>

									<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
										<LoadingButton style={{marginRight:10}} variant="text" loading={isLoading} onClick={handleSingleHolidayConfigurator}>Feiertage</LoadingButton>
										<LoadingButton style={{marginRight:10}} variant="text" loading={isLoading} onClick={handleHolidayConfigurator}>Urlaub planen</LoadingButton>
										<LoadingButton endIcon={<ArrowForwardIcon />} variant="contained" loading={isLoading} onClick={() => {
											handleSubmit();
										}}>Speichern</LoadingButton>
									</Box>
								</>
							)}

						</FormGroup>
					)}
				</Box>
			</Paper>

			{(restaurantId && restaurantConfiguration.holidays) ? (
				<HolidayConfigurationModal
					open={showHolidayModal}
					onClose={() => {setShowHolidayModal(false);setReloadKey(Math.random())}}
					onSave={() => {setShowHolidayModal(false);setReloadKey(Math.random())}}
					restaurantId={restaurantId}
					holidays={restaurantConfiguration.holidays}
				/>
			) : (<></>)}
		</>
	);
}