import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './style.scss';

export default function BootUp(props) {

    const theme = useTheme();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [isLoading, setIsLoading] = useState(false);
	const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

    const [checks, setChecks] = useState([
        {
            name: "Server überprüfen...",
            subtitle: "Läuft...",
            status: "loading" // loading, success, error
        },
        {
            name: "Sitzung überprüfen...",
            subtitle: "Läuft...",
            status: "loading" // loading, success, error
        },
        {
            name: "Datenbankverbindung aufbauen...",
            subtitle: "Läuft...",
            status: "loading" // loading, success, error
        },
        {
            name: "Restaurant-Informationen laden...",
            subtitle: "Läuft...",
            status: "loading" // loading, success, error
        },
    ])

    useEffect(() => {

        if (!localStorage.getItem("bootup")) {
            // Run check no. 1 (Database connection)
            setIsLoading(true);
            checkServer();
        }

    })

    const closeBootUp = () => {
        setIsLoading(false);

        // To prevent the user from going back to the bootup screen set a localstorage variable
        localStorage.setItem("bootup", "true");
    }

    const updateCheck = (index, status, subtitle) => {
        
        let newChecks = checks;
        newChecks[index].status = status;
        newChecks[index].subtitle = subtitle;
        setChecks(newChecks);
        forceUpdate();
    }

    const checkServer = () => {
        // Check if server is online /ping
        if (checks[0].status === "loading") {
            axios.get(`${process.env.REACT_APP_API_URL}/api/ping`).then((res) => {
                if (res.data) {
                    if (res.data === "online") {
                        // Server is online
                        setTimeout(() => {
                            updateCheck(0, "success", "Online");

                            checkSession();
                        }, 10);
                    } else {
                        setTimeout(() => {
                            updateCheck(0, "error", "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752!");
                        }, 10);
    
                    }
                }
            })
        }
    }

    const checkSession = () => {
        // Check if server is online /ping
        if (checks[1].status === "loading") {
            axios.get(`${process.env.REACT_APP_API_URL}/api/me`).then((res) => {
                if (res.data.data) {
                    if (res.data.data.id) {
                        setTimeout(() => {
                            updateCheck(1, "success", "Identifizierung erfolgreich");

                            checkDatabase();
                        }, 10);
                    } else {
                        setTimeout(() => {
                            localStorage.clear();
                            window.location.href = "/login?error=session";
                            return;
                        }, 10);
                    }
                } else {
                    setTimeout(() => {
                        localStorage.clear();
                        window.location.href = "/login?error=session";
                        return;
                    }, 10);
                }
            }).catch(() => {
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/login?error=session";
                    return;
                }, 10);
            })
        }
    }
    
    const checkDatabase = () => {
        // Check if server is online /ping
        if (checks[2].status === "loading") {

            let restaurantId = localStorage.getItem('data');
            if (restaurantId) {
                restaurantId = JSON.parse(restaurantId).restaurant_access_id;
                
                if (restaurantId === 0) {
                    setTimeout(() => {
                        updateCheck(2, "success", "Datenbankverbindung erfolgreich (administrativer Zugang!)");

                        checkRestaurant();
                    }, 10);
                } else {
                    axios.get(process.env.REACT_APP_API_URL + '/api/backend/restaurants/' + restaurantId).then((res) => {
                        if (res.data) {
                            if (res.data.id) {
                                setTimeout(() => {
                                    updateCheck(2, "success", "Verbindung erfolgreich aufgebaut");

                                    checkRestaurant();
                                }, 10);
                            } else {
                                setTimeout(() => {
                                    updateCheck(2, "error", "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752!");
                                }, 10);
                            }
                        } else {
                            setTimeout(() => {
                                updateCheck(2, "error", "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752!");
                            }, 10);
                        }
                    }).catch(() => {
                        setTimeout(() => {
                            updateCheck(2, "error", "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752!");
                        }, 10);
                    })
                }

            } else {
                setTimeout(() => {
                    updateCheck(2, "error", "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752!");
                }, 10);
            }

        }
    }
    
    const checkRestaurant = () => {
        // Check if server is online /ping
        if (checks[3].status === "loading") {

            let restaurantId = localStorage.getItem('data');
            if (restaurantId) {
                restaurantId = JSON.parse(restaurantId).restaurant_access_id
                
                if (restaurantId === 0) {
                    setTimeout(() => {
                        updateCheck(3, "success", "Keine Restaurantinformationen geladen (administrativer Zugang!)");

                        setTimeout(() => {
                            closeBootUp();
                        }, 10);
                    }, 10);
                } else {
                    axios.get(process.env.REACT_APP_API_URL + '/api/backend/restaurants/' + restaurantId).then((res) => {
                        if (res.data) {
                            if (res.data.id) {

                                if (res.data?.has_unread_messages) {
                                    if (res.data?.has_unread_messages === true) {
                                        //setHasUnreadMessages(true);
                                    }
                                }

                                setTimeout(() => {
                                    updateCheck(3, "success", "Restaurant-Informationen erfolgreich geladen");

                                    setTimeout(() => {
                                        closeBootUp();
                                    }, 10);

                                }, 10);
                            } else {
                                setTimeout(() => {
                                    updateCheck(3, "error", "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752!");
                                }, 10);
                            }
                        } else {
                            setTimeout(() => {
                                updateCheck(3, "error", "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752!");
                            }, 10);
                        }
                    }).catch(() => {
                        setTimeout(() => {
                            updateCheck(3, "error", "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752!");
                        }, 10);
                    })
                }

            } else {
                setTimeout(() => {
                    updateCheck(3, "error", "Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752!");
                }, 10);
            }

        }
    }

	return (
        <>
            <Dialog
                open={isLoading}
                onClose={() => {setIsLoading(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
                fullScreen={fullScreen}
            >
                <DialogTitle id="alert-dialog-title">
                    DeliverOne Manager — Willkommen!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte habe einen Moment Geduld, während DeliverOne Manager hochfährt...
                    </DialogContentText><br />

                    {checks.map((check, index) => (
                        <div className="bootup-step" key={index}>
                            <div className={`bootup-step-dot bootup-step-dot--${check.status}`}>
                                {check.status === "success" && <CheckIcon />}
                                {check.status === "error" && <WarningAmberIcon />}
                            </div>
                            <div>
                                <span className="bootup-step-title">{check.name}</span>
                                <span className="bootup-step-subtitle">{check.subtitle}</span>
                            </div>
                        </div>
                    ))}

                </DialogContent>
            </Dialog>

            <Dialog
                open={hasUnreadMessages}
                onClose={() => {setHasUnreadMessages(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
            >
                <DialogTitle id="alert-dialog-title">
                    Du hast ungelesene Nachrichten!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Du hast ungelesene Nachrichten von DeliverOne erhalten. Bitte überprüfe deine Nachrichten.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setHasUnreadMessages(false)}} color="primary" variant="contained" autoFocus>
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>

        </>
	);
}