import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import Currency from 'react-currency-formatter';

export default function StepBilling(props) {

    const [salesXProducts, setSalesXProducts] = useState([])

    let formInputs = [
        {
            label: 'GoCardless Kunden ID',
            name: 'gocardless_customer_id',
            type: 'text',
            required: true,
        },
        {
            label: 'GoCardless Bankkonto ID',
            name: 'gocardless_customer_bank_account_id',
            type: 'text',
            required: true,
        },
        {
            label: 'GoCardless Mandats ID',
            name: 'gocardless_mandate_id',
            type: 'text',
            required: true,
        },
        {
            label: 'SalesX Rechnungsposten-Bezeichnung',
            name: 'salesx_article_name',
            type: 'text',
            required: true
        },
        {
            label: 'Vertragsobjekt',
            name: 'salesx_product',
            type: 'select',
            required: true,
            options: []
        }
    ]

    useEffect(() => {

        props.setIsLoading(true)

        let tmpFormValues = props.formValues;
        formInputs.forEach((input) => {
            tmpFormValues[input.name] = input.default ? input.default : "";
        })
        tmpFormValues["salesx_product"] = "";
        props.setFormValues(tmpFormValues);


        axios.get(process.env.REACT_APP_API_URL + '/api/administrate/contracts/sales-x-products').then((response) => {
            setSalesXProducts(response.data.products);
            formInputs[4].options = response.data.products.map((product) => {
                return {
                    label: product.name,
                    value: product.id
                }
            });
            props.setIsLoading(false);
        })
        .catch((error) => {
            
            props.handleStepError(true);
            props.setIsLoading(false);
        })

        setTimeout(() => {
            let url = "https://manage.gocardless.com/";
            // Open GoCardless in new tab in background
            let win = window.open(url, '_blank');
            win.focus();
        }, 1000)
    }, [])

    const handleNext = () => {

        let formValid = true;
        formInputs.forEach((input) => {
            if (!props.formValues[input.name]) {
                formValid = false;
            } else {
                if (input.required && props.formValues[input.name] === "") {
                    formValid = false;
                }
            }
        })

        if (formValid) {
            
            props.handleNext();
        } else {
            props.handleStepError(true);
        }
    }

	return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
            padding: '20px'
        }}>
            <Box style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                rowGap: '20px'
            }}>
                
                {formInputs.map((input, index) => {
                    if (input.type === "text" || input.type === "number") {
                        return (
                            <TextField
                                id={index + "_" + input.name}
                                label={input.label}
                                variant="outlined"
                                size='small'
                                type={input.type}
                                required={input.required}
                                value={props.formValues[input.name]}
                                onChange={(e) => {
                                    props.setFormValues({
                                        ...props.formValues,
                                        [input.name]: e.target.value
                                    })
                                }}
                            />
                        )
                    } else if (input.type === "checkbox") {
                        return (
                            <FormControlLabel
                                control={<Checkbox
                                            id={index + "_" + input.name}
                                            required={input.required}
                                            defaultChecked={input.defaultChecked ? input.defaultChecked : false}
                                        />}
                                label={input.label}
                                value={props.formValues[input.name]}
                                onChange={(e) => {
                                    props.setFormValues({
                                        ...props.formValues,
                                        [input.name]: e.target.checked
                                    })
                                }}
                            />
                        )
                    } else if (input.type === "select") {
                        return (
                            salesXProducts.length > 0 && (
                                <FormControl fullWidth>
                                    <InputLabel id={index + "_" + input.name + "-label"}>{input.label}</InputLabel>
                                    <Select
                                        id={index + "_" + input.name}
                                        label={input.label}
                                        variant="outlined"
                                        size='small'
                                        required={input.required}
                                        value={props.formValues[input.name] ? props.formValues[input.name] : input.default}
                                        onChange={(e) => {
                                            props.setFormValues({
                                                ...props.formValues,
                                                [input.name]: e.target.value
                                            })
                                        }}
                                    >
                                        {salesXProducts.map((product, index) => {
                                            return (
                                                <MenuItem value={product.id} key={index}>{product.product_sku} {product.product_name} (<Currency currency="EUR" quantity={product.product_gross_price} />)</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            )
                        )
                    }
                })}

            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>

                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleNext}>
                    {props.activeStep === props.steps.length - 1 ? "Beenden" : "Weiter"}
                </Button>
            </Box>

        </div>
	);
}