import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function StepSuccess(props) {

    useEffect(() => {
        
    }, [])

	return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
            padding: '20px'
        }}>
            <Box style={{
                display: 'flex',
                width: '100%',
                textAlign: 'center',
                padding: '20px 0',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100%'
            }}>
                
                <CheckCircleOutlineIcon style={{
                    fontSize: '100px',
                    color: 'rgb(161, 197, 61)'
                }} />

                <h2 style={{
                    padding: 0
                }}>Vertrag erfolgreich erstellt!</h2>
                <p style={{
                    padding: 0
                }}>Der Vertrag wurde erfolgreich erstellt und ist nun in der Vertragsverwaltung sichtbar!</p>

            </Box>

        </div>
	);
}