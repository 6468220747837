import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Backdrop from '@mui/material/Backdrop'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import NumberFormat from 'react-number-format';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Loader from '../../../components/Loader';

import './style.scss';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
			decimalSeparator=","
            isNumericString
            suffix=" EUR"
			allowNegative={false}
			decimalScale={2}
        />
    );
});

const PercentFormatCustom = React.forwardRef(function PercentFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
			decimalSeparator=","
            isNumericString
            suffix=" %"
			allowNegative={false}
			decimalScale={3}
			isAllowed={(values, sourceInfo) => {
				const { value } = values;
				return value < (100 + 1);
			}}			
        />
    );
});

export default function PageAdministrateRestaurantParameter(props) {

	let navigate = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState("Lädt...");
	const [reloadKey, setReloadKey] = useState(Math.random());
	const [restaurants, setRestaurants] = useState([]);
	const [selectedRestaurant, setSelectedRestaurant] = useState("");

	const [restaurantMonthlyFee, setRestaurantMonthlyFee] = useState("");
	const [restaurantOrderCommissionPercent, setRestaurantOrderCommissionPercent] = useState("");
	const [restaurantOrderCommissionEuro, setRestaurantOrderCommissionEuro] = useState("");
	const [restaurantCardCommissionPercent, setRestaurantCardCommissionPercent] = useState("");
	const [restaurantCardCommissionEuro, setRestaurantCardCommissionEuro] = useState("");
	const [restaurantCashCommissionPercent, setRestaurantCashCommissionPercent] = useState("");
	const [restaurantCashCommissionEuro, setRestaurantCashCommissionEuro] = useState("");
	const [restaurantPayPalCommissionPercent, setRestaurantPayPalCommissionPercent] = useState("");
	const [restaurantPayPalCommissionEuro, setRestaurantPayPalCommissionEuro] = useState("");
	const [restaurantFreeDiscounts, setRestaurantFreeDiscounts] = useState("");
	const [restaurantLastUpdated, setRestaurantLastUpdated] = useState("");
	
	useEffect(() => {
		document.title = 'Restaurant Parameter — Administration — ' + process.env.REACT_APP_APPLICATION_NAME;
		setIsLoading(true);
		if (props.asStep === true) {
			props.setIsLoading(true);
		}

		axios.get(`${process.env.REACT_APP_API_URL}/api/backend/restaurants`)
			.then((res) => {
				if (res.status === 200 && res.data) {
					// Everything OK
					setIsLoading(false);
					if (props.asStep === true) {
						props.setIsLoading(false);
						props.handleStepError(false);
					}
					setRestaurants(res.data);
				} else {
					// Error
					
				}
			})
			.catch((err) => {
				if (props.asStep === true) {
					props.setIsLoading(false);
					props.handleStepError(true);
				}
			})

		if (props.asStep === true) {

			setRestaurantMonthlyFee(0)
			setRestaurantOrderCommissionPercent(0)
			setRestaurantOrderCommissionEuro(0)
			setRestaurantCardCommissionPercent(0)
			setRestaurantCardCommissionEuro(0)
			setRestaurantCashCommissionPercent(0)
			setRestaurantCashCommissionEuro(0)
			setRestaurantPayPalCommissionPercent(0)
			setRestaurantPayPalCommissionEuro(0)
			setRestaurantFreeDiscounts(0)

			setSelectedRestaurant(props.formValues.restaurantId);
			props.handleStepError(false);
		} else {
			if (selectedRestaurant !== null && selectedRestaurant !== "") {
				setIsLoading(true);
				axios.get(`${process.env.REACT_APP_API_URL}/api/administrate/restaurant-parameter/${selectedRestaurant}`).then((res) => {
					setIsLoading(false);
					if (res.data) {
						setRestaurantMonthlyFee(res.data.restaurant_monthly_fee);
						setRestaurantOrderCommissionPercent(res.data.restaurant_order_commission_percent);
						setRestaurantOrderCommissionEuro(res.data.restaurant_order_commission_eur);
						setRestaurantCardCommissionPercent(res.data.restaurant_card_commission_percent);
						setRestaurantCardCommissionEuro(res.data.restaurant_card_commission_eur);
						setRestaurantCashCommissionPercent(res.data.restaurant_cash_commission_percent);
						setRestaurantCashCommissionEuro(res.data.restaurant_cash_commission_eur);
						setRestaurantPayPalCommissionPercent(res.data.restaurant_paypal_commission_percent);
						setRestaurantPayPalCommissionEuro(res.data.restaurant_paypal_commission_eur);
						setRestaurantFreeDiscounts(res.data.restaurant_free_discounts);
						setRestaurantLastUpdated(res.data.updated_at);
					} else {
						handleError();
					}
				}).catch((err) => {
					setIsLoading(false);
					handleError();
				})
			}
		}

	}, [reloadKey, selectedRestaurant, props.formValues.restaurantId])

	const handleError = () => {
		setShowErrorSnackbar(true);
	}

	const saveParameter = () => {
		// Validate Inputs
		if (restaurantMonthlyFee === "" || restaurantOrderCommissionPercent === "" || restaurantOrderCommissionEuro === "" || restaurantCardCommissionPercent === "" || restaurantCardCommissionEuro === "" || restaurantCashCommissionPercent === "" || restaurantCashCommissionEuro === "" || restaurantFreeDiscounts === "") {
			handleError();
			if (props.asStep === true) {
				props.handleStepError(true);
			}
			return;
		}

		setIsLoading(true);
		if (props.asStep === true) {
			props.setIsLoading(true);
		}

		axios.post(`${process.env.REACT_APP_API_URL}/api/administrate/restaurant-parameter/${selectedRestaurant}`, {
			restaurant_monthly_fee: restaurantMonthlyFee,
			restaurant_order_commission_percent: restaurantOrderCommissionPercent,
			restaurant_order_commission_euro: restaurantOrderCommissionEuro,
			restaurant_card_commission_percent: restaurantCardCommissionPercent,
			restaurant_card_commission_euro: restaurantCardCommissionEuro,
			restaurant_cash_commission_percent: restaurantCashCommissionPercent,
			restaurant_cash_commission_euro: restaurantCashCommissionEuro,
			restaurant_paypal_commission_percent: restaurantPayPalCommissionPercent,
			restaurant_paypal_commission_euro: restaurantPayPalCommissionEuro,
			restaurant_free_discounts: restaurantFreeDiscounts
		}).then((res) => {
			setIsLoading(false);
			if (res.data) {
				setSuccessSnackbarText("Restaurant Parameter erfolgreich gespeichert!");
				setShowSuccessSnackbar(true);
				setReloadKey(Math.random());
				if (props.asStep === true) {
					props.setIsLoading(false);
					props.handleStepError(false);
					props.handleNext();
				}
			} else {
				handleError();
				if (props.asStep === true) {
					props.setIsLoading(false);
					props.handleStepError(true);
				}
			}
		}).catch((err) => {
			setIsLoading(false);
			if (props.asStep === true) {
				props.setIsLoading(false);
				props.handleStepError(true);
			}
			handleError();
		})
	}

	const FormContent = () => {
		return (
			<Stack spacing={3}>

				{props.asStep !== true && (
					<>
						<FormControl margin="dense" size='small' fullWidth variant="outlined">
							<InputLabel id="restaurant-label">Restaurant</InputLabel>
							<Select
								labelId="restaurant-label"
								id="restaurant"
								label="Restaurant"
								value={selectedRestaurant}
								onChange={(event) => {
									setSelectedRestaurant(event.target.value)
								}}				  
							>
								{
									restaurants.map((restaurant) => {
										return (
											<MenuItem key={restaurant.id} value={restaurant.id}>{restaurant.restaurant_name} (#{restaurant.id})</MenuItem>
										)
									})
								}
							</Select>
						</FormControl>
								
						<div style={{marginBottom: '10px',height:'1px',width:'100%',background:'#d1e0e2'}}></div>
					</>
				)}

				{selectedRestaurant !== null && selectedRestaurant !== "" && (
					<>
						<TextField
							name="restaurantMonthlyFee"
							fullWidth
							size="small"
							label="Monatliche Vertragsgebühr in EUR"
							value={restaurantMonthlyFee}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(event) => {
								setRestaurantMonthlyFee(event.target.value);
							}}
						/><div style={{marginBottom: '10px',height:'1px',width:'100%',background:'#d1e0e2'}}></div>

						<TextField
							name="restaurantOrderCommissionPercent"
							fullWidth
							size="small"
							label="Bestell-Provision in Prozent"
							value={restaurantOrderCommissionPercent}
							InputProps={{
								inputComponent: PercentFormatCustom,
							}}
							onChange={(event) => {
								setRestaurantOrderCommissionPercent(event.target.value);
							}}
						/>

						<TextField
							name="restaurantOrderCommissionEuro"
							fullWidth
							size="small"
							label="Bestell-Provision in EUR"
							value={restaurantOrderCommissionEuro}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(event) => {
								setRestaurantOrderCommissionEuro(event.target.value);
							}}
						/><div style={{marginBottom: '10px',height:'1px',width:'100%',background:'#d1e0e2'}}></div>

						<TextField
							name="restaurantCardCommissionPercent"
							fullWidth
							size="small"
							label="Karten-Provision (nur STRIPE) in Prozent"
							value={restaurantCardCommissionPercent}
							InputProps={{
								inputComponent: PercentFormatCustom,
							}}
							onChange={(event) => {
								setRestaurantCardCommissionPercent(event.target.value);
							}}
						/>

						<TextField
							name="restaurantCardCommissionEuro"
							fullWidth
							size="small"
							label="Karten-Provision (nur STRIPE) in EUR"
							value={restaurantCardCommissionEuro}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(event) => {
								setRestaurantCardCommissionEuro(event.target.value);
							}}
						/><div style={{marginBottom: '10px',height:'1px',width:'100%',background:'#d1e0e2'}}></div>

						<TextField
							name="restaurantCashCommissionPercent"
							fullWidth
							size="small"
							label="Bar-Provision in Prozent"
							value={restaurantCashCommissionPercent}
							InputProps={{
								inputComponent: PercentFormatCustom,
							}}
							onChange={(event) => {
								setRestaurantCashCommissionPercent(event.target.value);
							}}
						/>

						<TextField
							name="restaurantCashCommissionEuro"
							fullWidth
							size="small"
							label="Bar-Provision in EUR"
							value={restaurantCashCommissionEuro}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(event) => {
								setRestaurantCashCommissionEuro(event.target.value);
							}}
						/><div style={{marginBottom: '10px',height:'1px',width:'100%',background:'#d1e0e2'}}></div>
						
						<TextField
							name="restaurantPayPalCommissionPercent"
							fullWidth
							size="small"
							label="PayPal-Provision in Prozent"
							value={restaurantPayPalCommissionPercent}
							InputProps={{
								inputComponent: PercentFormatCustom,
							}}
							onChange={(event) => {
								setRestaurantPayPalCommissionPercent(event.target.value);
							}}
						/>

						<TextField
							name="restaurantPayPalCommissionEuro"
							fullWidth
							size="small"
							label="PayPal-Provision in EUR"
							value={restaurantPayPalCommissionEuro}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(event) => {
								setRestaurantPayPalCommissionEuro(event.target.value);
							}}
						/><div style={{marginBottom: '10px',height:'1px',width:'100%',background:'#d1e0e2'}}></div>

						<TextField
							name="restaurantFreeDiscounts"
							fullWidth
							size="small"
							label="Mtl. kostenlose Rabatte"
							value={restaurantFreeDiscounts}
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							onChange={(event) => {
								setRestaurantFreeDiscounts(event.target.value);
							}}
						/>

						{props.asStep === true ? (
							<Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
								<Box sx={{ flex: "1 1 auto" }} />
								<Button onClick={saveParameter}>
									Weiter
								</Button>
							</Box>
						) : (
							<>
								<br />
								<Button onClick={saveParameter} variant="contained">Speichern</Button>
							</>
						)}

						{props.asStep !== true && (
							<>
								<br />
								<i>Letzte Aktualisierung: {restaurantLastUpdated}</i>
							</>
						)}

					</>
				)}

			</Stack>
		)
	}

	return (
		<>

			{props.asStep === true ? (
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: '100%',
					justifyContent: 'space-between',
					padding: '20px'
				}}>
					<Box style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						rowGap: '20px'
					}}>
						{FormContent(props)}
					</Box>
				</div>
			) : (
				<Paper sx={{ maxWidth: {xs:'100%',sm:'calc(100% - 100px)'}, margin: 'auto', overflow: 'hidden' }}>
					<AppBar
						position="static"
						color="default"
						elevation={0}
						sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
					>
						<Toolbar className="PageMenu-AppBar">
							<Grid container style={{flexDirection:'row-reverse'}} spacing={1} alignItems="right">
								<Grid item>
									<Tooltip title="Aktualisieren">
										<IconButton onClick={() => {setReloadKey(Math.random())}}>
											<RefreshIcon color="inherit" sx={{ display: 'block' }} />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Toolbar>
					</AppBar>
					
					<Box padding={2}>
						{isLoading ? (
							<Loader />
						) : (
							FormContent(props)
						)}
					</Box>

				</Paper>
			)}

			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorSnackbar}
				message="Ein Fehler ist aufgetreten! Bitte kontaktiere den Support: +49 8342 7050-752"
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowErrorSnackbar(false)}}
			/>
			
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showSuccessSnackbar}
				message={successSnackbarText}
				sx={{ zIndex: 9999999 }}
				autoHideDuration={8000}
				onClose={() => {setShowSuccessSnackbar(false)}}
			/>

		</>
	);
}