import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';

export default function StepDocuments(props) {

    const documents = [
        {
            value: 'VERTRAG',
            name: '912.00.02 Vertrag',
            description: 'Vertrag zwischen DeliverOne und dem teilnehmenden Restaurant. Pflichtdokument.',
            required: true,
        },
        {
            value: 'EINWILLIGUNG_DATENAUSTAUSCH',
            name: '912.00.03 Einwilligung zum elektronischen Datenaustausch mit Finanzbehörden',
            description: 'Einwilligung zum elektronischen Datenaustausch mit Finanzbehörden wie BaFin, Finanzamt, etc. Pflichtdokument.',
            required: true,
        },
        {
            value: 'AGB_DATENSCHUTZ',
            name: 'AGB & Datenschutzerklärung',
            description: 'Allgemeine Geschäftsbedingungen & Datenschutzerklärung für die Nutzung der DeliverOne Plattform. Pflichtdokument.',
            required: true,
        },
        {
            value: 'DELIVERONE_COURIER_GEBUEHRENAUFSTELLUNG',
            name: 'DeliverOne Courier Gebührenaufstellung',
            description: 'Gebührenaufstellung für die Nutzung der DeliverOne Courier Funktion. Optional.',
            required: false,
        },
        {
            value: 'DELIVERONE_TERMINALS_GEBUEHRENAUFSTELLUNG',
            name: 'DeliverOne Terminals Gebührenaufstellung',
            description: 'Gebührenaufstellung für die Nutzung der DeliverOne Terminals Geräte. Optional.',
            required: false,
        },
        {
            value: 'SEPA_LASTSCHRIFTMANDAT',
            name: 'SEPA-Lastschriftmandat',
            description: 'SEPA-Lastschriftmandat für die Nutzung der DeliverOne Plattform. Optional.',
            required: false,
        },
        {
            value: 'STRIPE_CONNECT_NUTZUNGSBEDINGUNGEN',
            name: 'Stripe Connect Nutzungsbedingungen',
            description: 'Nutzungsbedingungen für die Nutzung von Stripe Connect, zur Legitimation, Identifikation sowie zur wöchentlichen Abrechnung. Optional.',
            required: false,
        },
    ]

    const [checked, setChecked] = React.useState([0]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
  
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
  
        setChecked(newChecked);

        
    };

    useEffect(() => {
        setChecked(documents.filter(document => document.required).map(document => document.value));
    }, [])

    const handleNext = () => {
        let tmpFormValues = props.formValues;
        tmpFormValues.documents = checked;
        props.setFormValues(tmpFormValues);
        props.handleNext();
    }

	return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
            padding: '20px'
        }}>
            <Box style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                rowGap: '20px'
            }}>
                
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {documents.map((document, index) => (
                        <ListItem
                            disablePadding
                            key={index}
                        >
                        <ListItemButton role={undefined} onClick={handleToggle(document.value)} disabled={document.required} dense>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={checked.indexOf(document.value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': index }}
                                    required={document.required}
                                    disabled={document.required}
                                />
                            </ListItemIcon>
                            <ListItemText id={index} primary={document.name} secondary={document.description} />
                        </ListItemButton>
                    </ListItem>
                    ))}
                </List>

            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleNext}>
                    Weiter
                </Button>
            </Box>

        </div>
	);
}